import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import {
    AccreditationPositionProperty,
    PositionId,
    PositionsCategory
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { PositionsCategoryCellInput } from './positionsCategoryCellInput';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsCategoryCellProps {
    positionCategory: Pick<PositionsCategory, 'id' | 'name'>;
    positionId: PositionId;

    reload(): void;
}

export const PositionsCategoryCell = (props: IPositionsCategoryCellProps) => {
    const {
        history,
        params: { organizationId, eventId }
    } = useHeavent();
    const { isEditMode } = useSegmentsContext();
    const css = isEditMode
        ? {
              ellipsis: true
          }
        : {
              cursor: 'pointer',
              ellipsis: true,
              '&:hover': {
                  textDecoration: 'underline'
              }
          };

    return (
        <PositionsUpdateCell
            initialValue={props.positionCategory.id}
            positionId={props.positionId}
            property={AccreditationPositionProperty.Category}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <PositionsCategoryCellInput value={value} setValue={setValue} />
            )}
        >
            <Box
                font="gray900 textSm medium"
                width={1}
                css={css}
                title={props.positionCategory.name}
                onClick={() => {
                    if (!isEditMode) {
                        history.push(
                            HeaventPaths.POSITION_CATEGORY(
                                organizationId,
                                eventId,
                                props.positionCategory.id
                            )
                        );
                    }
                }}
            >
                {props.positionCategory.name}
            </Box>
        </PositionsUpdateCell>
    );
};
