import { IIcon } from 'common/src/designSystem/components/i';
import { assertUnreachable } from 'common/src/util/assertUnreachable';

export enum PositionsRendering {
    Calendar = 'Calendar',
    List = 'List',
    Map = 'Map',
    SlotsList = 'SlotsList'
}

export function positionsRenderingIcon(positionsRendering: PositionsRendering): IIcon {
    switch (positionsRendering) {
        case PositionsRendering.Calendar:
            return 'calendar';
        case PositionsRendering.List:
            return 'table';
        case PositionsRendering.Map:
            return 'map-location';
        case PositionsRendering.SlotsList:
            return 'table';
        default:
            return assertUnreachable(positionsRendering);
    }
}
