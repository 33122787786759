import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import {
    AccreditationId,
    AccreditationsAccreditationFragment,
    AccreditationsSortAttributes
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { isNonEmptyString } from 'common/src/util/string';
import { AccreditationDefaultColumns } from 'common/src/vo/segments/accreditationsSegmentsService';
import { compact } from 'lodash-es';
import * as React from 'react';
import {
    executeAccreditationsAccreditationQuery,
    useAccreditationsQuery
} from '../../generated/graphqlHooks';
import { AccreditationRow } from './accreditationRow';

export const AccreditationsList = () => {
    const {
        translate,
        params: { organizationId, eventId, segmentId }
    } = useHeavent();
    const {
        columns,
        columnsSearch,
        limit,
        nameDebounced,
        offset,
        possibleColumns,
        predicates,
        reloadKey,
        selectAllState,
        setLimit,
        setOffset,
        setSort,
        setStates,
        sort,
        states,
        toggleRow,
        toggleSelectAll
    } = useSegmentsContext<AccreditationId, AccreditationsSortAttributes>();
    const includes = React.useMemo(
        () => ({
            includeConditions: columns.includes(AccreditationDefaultColumns.Conditions),
            includeCreatedBy: columns.includes(AccreditationDefaultColumns.CreatedBy),
            includeTags: columns.includes(AccreditationDefaultColumns.Tags)
        }),
        [columns]
    );
    const { data, isLoading, reload } = useAccreditationsQuery({
        ...includes,
        eventId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        columnsSearch: isNonEmptyArray(columnsSearch) ? columnsSearch : undefined,
        predicates,
        limit,
        offset,
        sort
    });
    const selectedFields = React.useMemo(
        () => columns.flatMap((slug) => compact([possibleColumns.find((pc) => pc.slug === slug)])),
        [possibleColumns, columns]
    );
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.accreditations);
    const [accreditations, setAccreditations] = React.useState<
        AccreditationsAccreditationFragment[]
    >([]);
    const [idToLoading, setIdToLoading] = React.useState<Record<AccreditationId, boolean>>({});
    const reloadAccreditation = React.useCallback(
        async (id: AccreditationId) => {
            setIdToLoading((ids) => ({ ...ids, [id]: true }));

            const {
                event: { accreditation }
            } = await executeAccreditationsAccreditationQuery(
                { ...includes, eventId, id },
                await getToken()
            );

            setAccreditations((as) => as.map((a) => (a.id === id ? accreditation : a)));
            setIdToLoading((ids) => ({ ...ids, [id]: false }));
        },
        [includes, setIdToLoading, setAccreditations]
    );

    React.useEffect(() => {
        setAccreditations(data.event?.accreditations.nodes ?? []);
    }, [data.event]);

    React.useEffect(() => {
        if (reloadKey > 1) {
            reload();
        }
    }, [reloadKey]);

    React.useEffect(() => {
        setStates(
            Object.fromEntries(
                (data.event?.accreditations.nodes ?? []).map((accreditation) => [
                    `a${accreditation.id}`,
                    { id: accreditation.id, state: 'unchecked' }
                ])
            )
        );
    }, [data.event]);

    return (
        <>
            <Flex width={1} css={{ flex: '1', overflow: 'hidden' }}>
                <TableGrid
                    headerRow={
                        <HeaderRow>
                            <HeaderCell justify="center" width={48}>
                                <Checkbox state={selectAllState} onClick={toggleSelectAll} />
                            </HeaderCell>

                            {selectedFields.map((field) =>
                                field.slug === AccreditationDefaultColumns.Id ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        width={100}
                                        attribute={AccreditationsSortAttributes.Id}
                                        sort={sort}
                                        setSort={setSort}
                                    >
                                        {field.name}
                                    </HeaderCellSort>
                                ) : field.slug === AccreditationDefaultColumns.Category ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={AccreditationsSortAttributes.Category}
                                        sort={sort}
                                        setSort={setSort}
                                    >
                                        {translate('cat_gorie_00291')}
                                    </HeaderCellSort>
                                ) : field.slug === AccreditationDefaultColumns.Name ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={AccreditationsSortAttributes.Name}
                                        sort={sort}
                                        setSort={setSort}
                                    >
                                        {translate('nom_de_l_accr_d_11908')}
                                    </HeaderCellSort>
                                ) : field.slug === AccreditationDefaultColumns.Resources ? (
                                    <HeaderCell key={field.slug} width={256}>
                                        {translate('membres_11310')}
                                    </HeaderCell>
                                ) : field.slug === AccreditationDefaultColumns.Icon ? (
                                    <HeaderCell key={field.slug} width={100}>
                                        {field.name}
                                    </HeaderCell>
                                ) : field.slug === AccreditationDefaultColumns.Acronym ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={AccreditationsSortAttributes.Acronym}
                                        sort={sort}
                                        setSort={setSort}
                                    >
                                        {field.name}
                                    </HeaderCellSort>
                                ) : (
                                    <HeaderCell key={field.slug}>
                                        <Box title={field.name} css={{ ellipsis: true }}>
                                            {field.name}
                                        </Box>
                                    </HeaderCell>
                                )
                            )}

                            <HeaderCell width={100} />
                        </HeaderRow>
                    }
                    paginationRow={
                        <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                            <PaginationCell
                                limit={limit}
                                numberOfPages={numberOfPages}
                                offset={offset}
                                showLimits={true}
                                totalCount={totalCount}
                                setLimit={setLimit}
                                setOffset={setOffset}
                            />
                        </PaginationRow>
                    }
                >
                    {isLoading ? (
                        <>
                            <RowSkeleton />
                            <RowSkeleton />
                            <RowSkeleton />
                        </>
                    ) : (
                        accreditations.map((accreditation) => {
                            if (idToLoading[accreditation.id]) {
                                return <RowSkeleton key={accreditation.id} />;
                            } else {
                                return (
                                    <AccreditationRow
                                        key={accreditation.id}
                                        accreditation={accreditation}
                                        columns={selectedFields}
                                        eventId={eventId}
                                        organizationId={organizationId}
                                        segmentId={segmentId}
                                        state={states[`a${accreditation.id}`]?.state ?? 'unchecked'}
                                        reload={reload}
                                        reloadAccreditation={reloadAccreditation}
                                        toggleRow={toggleRow}
                                    />
                                );
                            }
                        })
                    )}
                </TableGrid>
            </Flex>
        </>
    );
};
