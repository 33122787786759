import { injectable } from 'inversify';
import { compact, pick, uniq } from 'lodash-es';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import {
    CustomFieldWithConditionFragment,
    EditVolunteerRegistrationSlotFragment,
    Event,
    FormPositionRanking,
    FormsUsersInfo,
    FormsUsersInfosSlot,
    RegisterAccreditationDisplay,
    RegisterPositionDisplay,
    RegisterSlotDisplay,
    UserEmail,
    UserInfoAccreditationsInput,
    UserInfoPositionsInput,
    UsersInfo,
    UsersInfoId,
    VolunteersRegistration,
    VolunteersRegistrationInput,
    VolunteersRegistrationsSlotInput
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { isNonEmptyArray } from '../util/array';
import { assertUnreachable } from '../util/assertUnreachable';
import { getDays } from '../vo/volunteerRegistrationSlot';
import { EventFieldForSchema } from './fieldInput';
import { InputService } from './inputService';
import { UserInfoInputService } from './userInfoInput';
import { VolunteerRegistrationSlotInputService } from './volunteerRegistrationSlotInput';

export interface IUpdateVolunteerRegistrationValues {
    email: UserEmail;
    volunteerRegistration: VolunteersRegistrationInput;
}

export interface IVolunteersRegistrationsAdminAddValues {
    usersInfosIds: UsersInfoId[];
    sendVolunteerRegistrationEmail: boolean;
}

export interface IVolunteerRegistrationAdminCreateValues {
    email: UserEmail;
    sendVolunteerRegistrationEmail: boolean;
    firstName: string;
    lastName: string;
}

@injectable()
export class VolunteerRegistrationInputService extends InputService {
    constructor(
        protected translationService: TranslationService,
        private userInfoInputService: UserInfoInputService,
        private volunteerRegistrationSlotInputService: VolunteerRegistrationSlotInputService
    ) {
        super(translationService);
    }

    updateVolunteerRegistrationValuesDefault(
        volunteerRegistration: Pick<
            VolunteersRegistration,
            'state' | 'positionsCategoriesIds' | 'positionsIds' | 'positionsSlotsIds'
        > & { userInfo: Pick<UsersInfo, 'id' | 'email' | 'fields'> } & {
            slots: EditVolunteerRegistrationSlotFragment[];
        },
        event: Pick<
            Event,
            | 'startAt'
            | 'endAt'
            | 'slotDisplay'
            | 'positionDisplay'
            | 'daysDisplay'
            | 'ranges'
            | 'country'
        >,
        customFields: CustomFieldWithConditionFragment[]
    ): IUpdateVolunteerRegistrationValues {
        const positionCategoriesIds =
            event.positionDisplay === RegisterPositionDisplay.Category
                ? Object.fromEntries(
                      volunteerRegistration.positionsCategoriesIds.map((positionCategoryId) => [
                          `positionCategory${positionCategoryId}`,
                          true
                      ])
                  )
                : {};
        const positionsIds =
            event.positionDisplay === RegisterPositionDisplay.Position
                ? Object.fromEntries(
                      volunteerRegistration.positionsIds.map((positionId) => [
                          `position${positionId}`,
                          true
                      ])
                  )
                : {};
        const positionsSlotsIds =
            event.positionDisplay === RegisterPositionDisplay.Slot
                ? Object.fromEntries(
                      volunteerRegistration.positionsSlotsIds.map((positionSlotId) => [
                          `slot${positionSlotId}`,
                          true
                      ])
                  )
                : {};
        const days =
            event.slotDisplay === RegisterSlotDisplay.DisplayDays
                ? Object.fromEntries(
                      getDays(event.startAt, event.endAt, event.ranges, event.daysDisplay).map(
                          (day, index) => {
                              const isSelected =
                                  volunteerRegistration.slots.find(({ startDate }) =>
                                      startDate.equals(day)
                                  ) !== undefined;

                              return [`day${index}`, isSelected];
                          }
                      )
                  )
                : {};

        return {
            email: volunteerRegistration.userInfo.email,
            volunteerRegistration: {
                userInfo: this.userInfoInputService.userInfoInputDefault(
                    volunteerRegistration.userInfo,
                    customFields,
                    event.country
                ),
                slots:
                    event.slotDisplay === RegisterSlotDisplay.Hide
                        ? []
                        : volunteerRegistration.slots.map((slot) =>
                              pick(slot, ['startDate', 'startTime', 'endDate', 'endTime'])
                          ),
                positionsSlotsIds:
                    event.positionDisplay === RegisterPositionDisplay.Slot
                        ? volunteerRegistration.positionsSlotsIds
                        : [],
                positionsIds:
                    event.positionDisplay === RegisterPositionDisplay.Position
                        ? volunteerRegistration.positionsIds
                        : [],
                positionsCategoriesIds:
                    event.positionDisplay === RegisterPositionDisplay.Category
                        ? volunteerRegistration.positionsCategoriesIds
                        : []
            },
            ...positionCategoriesIds,
            ...positionsIds,
            ...positionsSlotsIds,
            ...days
        };
    }

    informationsFields(eventsFields: EventFieldForSchema[]) {
        return {
            userInfo: this.userInfoInputService.userInfoInputSchema(eventsFields)
        };
    }

    volunteerRegistrationInformationsSchema(eventsFields: EventFieldForSchema[]) {
        return yup.object().shape({
            volunteerRegistration: yup.object().shape({
                ...this.informationsFields(eventsFields)
            })
        });
    }

    slotsFields(
        slotDisplay: RegisterSlotDisplay,
        eventStartDate: DateTime,
        eventEndDate: DateTime
    ): any {
        switch (slotDisplay) {
            case RegisterSlotDisplay.Display:
            case RegisterSlotDisplay.DisplayDays:
            case RegisterSlotDisplay.Calendar:
            case RegisterSlotDisplay.Custom:
                return {
                    slots: yup
                        .array()
                        .of(
                            this.volunteerRegistrationSlotInputService.volunteersRegistrationsSlotInputSchema(
                                eventStartDate,
                                eventEndDate
                            )
                        )
                        .min(1, this.t('vous_devez_ajou_68961'))
                };
            case RegisterSlotDisplay.Hide:
                return {};
            default:
                return assertUnreachable(slotDisplay);
        }
    }

    volunteersRegistrationsSlotsUpdateInputDefault(
        slots:
            | Pick<
                  FormsUsersInfosSlot,
                  'range' | 'startDate' | 'startTime' | 'endDate' | 'endTime'
              >[]
            | undefined
    ): VolunteersRegistrationsSlotInput[] {
        return isNonEmptyArray(slots)
            ? slots.map((uSlot) => ({
                  startDate: uSlot.startDate,
                  startTime: uSlot.startTime,
                  endDate: uSlot.endDate,
                  endTime: uSlot.endTime
              }))
            : [];
    }

    volunteerRegistrationSlotsSchema(
        slotDisplay: RegisterSlotDisplay,
        eventStartDate: DateTime,
        eventEndDate: DateTime
    ) {
        return yup.object().shape({
            volunteerRegistration: yup.object().shape({
                ...this.slotsFields(slotDisplay, eventStartDate, eventEndDate)
            })
        });
    }

    userInfoSlotsSchema(
        slotDisplay: RegisterSlotDisplay,
        eventStartDate: DateTime,
        eventEndDate: DateTime
    ) {
        return yup.object().shape({
            userInfo: yup.object().shape({
                slots: yup.array().of(
                    yup.object().shape({
                        formId: yup.number().required(),
                        ...this.slotsFields(slotDisplay, eventStartDate, eventEndDate)
                    })
                )
            })
        });
    }

    volunteersRegistrationsWishedPositionsInputDefault(): Omit<UserInfoPositionsInput, 'formId'> {
        return {
            positionsCategoriesIds: [],
            positionsIds: [],
            positionsSlotsIds: []
        };
    }

    volunteersRegistrationsWishedPositionsUpdateInputDefault(
        formUserInfo:
            | Pick<FormsUsersInfo, 'positionsCategoriesIds' | 'positionsIds' | 'positionsSlotsIds'>
            | undefined
    ): Omit<UserInfoPositionsInput, 'formId'> {
        return formUserInfo
            ? {
                  positionsCategoriesIds: formUserInfo?.positionsCategoriesIds,
                  positionsIds: formUserInfo?.positionsIds,
                  positionsSlotsIds: formUserInfo?.positionsSlotsIds
              }
            : this.volunteersRegistrationsWishedPositionsInputDefault();
    }

    wishedPositionsFields(
        positionRanking: FormPositionRanking,
        positionDisplay: RegisterPositionDisplay
    ): any {
        if (positionRanking === FormPositionRanking.Ranked) {
            if (positionDisplay === RegisterPositionDisplay.Category) {
                return {
                    positionsCategoriesIds: yup
                        .array()
                        .of(yup.number().typeError(this.t('s_lectionner_un_26253')))
                        .min(1, this.t('vous_devez_s_le_32044'))
                        .test('different', this.t('les_missions_so_04441'), (value) => {
                            const values = compact((value || []).filter((id) => id !== -1));

                            return isNonEmptyArray(values) && uniq(values).length === values.length;
                        })
                };
            } else if (positionDisplay === RegisterPositionDisplay.Position) {
                return {
                    positionsIds: yup
                        .array()
                        .of(yup.number().typeError(this.t('s_lectionner_un_26253')))
                        .min(1, this.t('vous_devez_s_le_89603'))
                        .test('different', this.t('les_missions_so_04441'), (value) => {
                            const values = compact((value || []).filter((id) => id !== -1));

                            return isNonEmptyArray(values) && uniq(values).length === values.length;
                        })
                };
            } else {
                throw new Error('Should not happen');
            }
        } else {
            switch (positionDisplay) {
                case RegisterPositionDisplay.Category:
                    return {
                        positionsCategoriesIds: yup
                            .array()
                            .of(yup.number().typeError(this.t('s_lectionner_un_26253')))
                            .min(1, this.t('vous_devez_s_le_32044'))
                    };
                case RegisterPositionDisplay.Position:
                    return {
                        positionsIds: yup
                            .array()
                            .of(yup.number().typeError(this.t('s_lectionner_un_26253')))
                            .min(1, this.t('vous_devez_s_le_89603'))
                    };
                case RegisterPositionDisplay.Slot:
                    return {
                        positionsSlotsIds: yup
                            .array()
                            .of(yup.number())
                            .min(1, this.t('vous_devez_s_le_89603'))
                    };
                case RegisterPositionDisplay.None:
                    return {};
                default:
                    return assertUnreachable(positionDisplay);
            }
        }
    }

    volunteerRegistrationWishedPositionsSchema(
        positionRanking: FormPositionRanking,
        positionDisplay: RegisterPositionDisplay
    ) {
        return yup.object().shape({
            volunteerRegistration: yup.object().shape({
                ...this.wishedPositionsFields(positionRanking, positionDisplay)
            })
        });
    }

    userInfoWishedPositionsSchema(
        positionRanking: FormPositionRanking,
        positionDisplay: RegisterPositionDisplay
    ) {
        return yup.object().shape({
            userInfo: yup.object().shape({
                missions: yup.array().of(
                    yup.object().shape({
                        formId: yup.number().required(),
                        ...this.wishedPositionsFields(positionRanking, positionDisplay)
                    })
                )
            })
        });
    }

    volunteersRegistrationsWishedAccreditationsInputDefault(): Omit<
        UserInfoAccreditationsInput,
        'formId'
    > {
        return {
            accreditationsSlotsIds: []
        };
    }

    volunteersRegistrationsWishedAccreditationsUpdateInputDefault(
        formUserInfo: Pick<FormsUsersInfo, 'accreditationsSlotsIds'> | undefined
    ): Omit<UserInfoAccreditationsInput, 'formId'> {
        return formUserInfo
            ? { accreditationsSlotsIds: formUserInfo.accreditationsSlotsIds }
            : this.volunteersRegistrationsWishedAccreditationsInputDefault();
    }

    accreditationsFields(accreditationDisplay: RegisterAccreditationDisplay): any {
        switch (accreditationDisplay) {
            case RegisterAccreditationDisplay.Accreditation:
                return {
                    accreditationsSlotsIds: yup
                        .array()
                        .of(yup.number().typeError(this.t('s_lectionner_un_96571')))
                        .min(1, this.t('vous_devez_s_le_72138'))
                };
            case RegisterAccreditationDisplay.None:
                return {};
            default:
                return assertUnreachable(accreditationDisplay);
        }
    }

    volunteerRegistrationAccreditationsSchema(accreditationDisplay: RegisterAccreditationDisplay) {
        return yup.object().shape({
            volunteerRegistration: yup.object().shape({
                ...this.accreditationsFields(accreditationDisplay)
            })
        });
    }

    userInfoAccreditationsSchema(accreditationDisplay: RegisterAccreditationDisplay) {
        return yup.object().shape({
            userInfo: yup.object().shape({
                accreditations: yup.array().of(
                    yup.object().shape({
                        formId: yup.number().required(),
                        ...this.accreditationsFields(accreditationDisplay)
                    })
                )
            })
        });
    }

    updateVolunteerRegistrationSchema(
        eventsFields: EventFieldForSchema[],
        privateCustomFields: CustomFieldWithConditionFragment[],
        slotDisplay: RegisterSlotDisplay,
        positionRanking: FormPositionRanking,
        positionDisplay: RegisterPositionDisplay,
        eventStartDate: DateTime,
        eventEndDate: DateTime
    ): yup.Schema<any> {
        let volunteerRegistrationFields = {
            userInfo: this.userInfoInputService.userInfoInputSchema(
                eventsFields,
                privateCustomFields
            )
        };

        volunteerRegistrationFields = {
            ...volunteerRegistrationFields,
            ...this.slotsFields(slotDisplay, eventStartDate, eventEndDate),
            ...this.wishedPositionsFields(positionRanking, positionDisplay)
        };

        return yup.object().shape({
            email: yup
                .string()
                .required(this.t('l_e_mail_est_re_04856'))
                .email(this.t('l_e_mail_est_re_04856')),
            volunteerRegistration: yup.object().shape({
                ...volunteerRegistrationFields
            })
        });
    }

    adminCreateSchema() {
        return yup.object().shape({
            email: yup
                .string()
                .email(this.t('l_e_mail_n_est_57882'))
                .required(this.t('l_e_mail_n_est_57882')),
            sendVolunteerRegistrationEmail: yup.boolean().required(),
            firstName: yup.string(),
            lastName: yup.string()
        });
    }

    adminAddSchema() {
        return yup.object().shape({
            usersInfosIds: yup.array().of(yup.number()).min(1, this.t('vous_devez_s_le_83608')),
            sendVolunteerRegistrationEmail: yup.boolean().required()
        });
    }
}
