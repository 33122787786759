import { compact, range, uniq } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

const getFrontBackground = (population: string) => {
    switch (population) {
        case 'ORGA':
            return 'https://assets.recrewteer.com/badges/lnh/Orga_Recto_v2.jpg';
        case 'Bénévoles':
            return 'https://assets.recrewteer.com/badges/lnh/Benevole_Recto.jpg';
        case 'MEDIA PRESSE':
            return 'https://assets.recrewteer.com/badges/lnh/Media_presse_Recto.jpg';
        case 'MEDIA PHOTOGRAPHE':
            return 'https://assets.recrewteer.com/badges/lnh/Media_Photo_Recto.jpg';
        case 'EQUIPES':
            return 'https://assets.recrewteer.com/badges/lnh/Equipe_Recto.jpg';
        case 'VIP':
            return 'https://assets.recrewteer.com/badges/lnh/VIP_recto.jpg';
        default:
            return '';
    }
};

/*
const getBackBackground = (population: string) => {
    switch (population) {
        case 'ORGA':
            return 'https://assets.recrewteer.com/badges/lnh/Orga_Verso.jpg';
        case 'Bénévoles':
            return 'https://assets.recrewteer.com/badges/lnh/Benevole_Verso.jpg';
        case 'MEDIA PRESSE':
            return 'https://assets.recrewteer.com/badges/lnh/Media_Presse_Verso.jpg';
        case 'MEDIA PHOTOGRAPHE':
            return 'https://assets.recrewteer.com/badges/lnh/Media_Photo_Verso.jpg';
        case 'EQUIPES':
            return 'https://assets.recrewteer.com/badges/lnh/Equipe_Verso.jpg';
        case 'VIP':
            return 'https://assets.recrewteer.com/badges/lnh/VIP_verso.jpg';
        default:
            return '';
    }
};
*/

const getColor = (population: string) => {
    switch (population) {
        case 'ORGA':
            return '#6f2c91';
        case 'Bénévoles':
            return '#72BF44';
        case 'MEDIA PRESSE':
            return '#294EA2';
        case 'MEDIA PHOTOGRAPHE':
            return '#ED1C24';
        case 'EQUIPES':
            return '#EBCB00';
        case 'VIP':
            return '#F7941D';
        default:
            return '';
    }
};

interface IBadgeLnhProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeLnh = (props: IBadgeLnhProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const position = vr.positionsSlotsUsersInfos[0]?.position?.name || '';
    const acronyms = uniq(
        compact(vr.accreditationsUsersInfos.map((aui) => aui.accreditation.acronym))
    );
    const title =
        ui.population === 'Bénévoles'
            ? position
            : ui.population === 'ORGA'
            ? ui.fields.cf36081
            : ui.population === 'EQUIPES'
            ? ui.lnhClub
            : ui.population === 'MEDIA PRESSE' || ui.population === 'MEDIA PHOTOGRAPHE'
            ? ui.lnhMedia
            : ui.population === 'VIP'
            ? ui.lnhEntreprise
            : '';

    return (
        <>
            <Flex
                height={321}
                width={212}
                css={{
                    background: `url(${getFrontBackground(ui.population)}) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$tuskerGrotesk',
                    fontWeight: '500',
                    position: 'relative'
                }}
            >
                <Box
                    height={58}
                    width={58}
                    css={{
                        left: '125px',
                        position: 'absolute',
                        top: '30px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img src={ui.picture!.url} height="100%" width="100%" />
                    )}
                </Box>

                <Box
                    css={{
                        fontSize: '11px',
                        left: '39px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '127px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    css={{
                        fontSize: '11px',
                        left: '39px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '156px'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Box
                    css={{
                        fontSize: '11px',
                        left: '39px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '186px'
                    }}
                >
                    {title}
                </Box>

                <Flex
                    css={{
                        gap: '2px',
                        left: '31px',
                        position: 'absolute',
                        top: '241px'
                    }}
                >
                    {range(1, 9).map((i) => (
                        <Flex
                            key={i}
                            align="center"
                            justify="center"
                            height={17}
                            width={17}
                            css={{
                                background: 'white',
                                borderRadius: '17px',
                                color: getColor(ui.population),
                                fontFamily: '$gilroy',
                                fontSize: '7px',
                                fontWeight: '700',
                                visibility: acronyms.includes(i.toString(10)) ? 'visible' : 'hidden'
                            }}
                        >
                            {i}
                        </Flex>
                    ))}
                </Flex>
            </Flex>

            {/*<Flex
                height={321}
                width={212}
                css={{
                    background: `url(${getBackBackground(ui.population)}) no-repeat`,
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
            />*/}
        </>
    );
};
