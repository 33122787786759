import type * as Stitches from '@stitches/react';
import { createStitches } from '@stitches/react';
import { CommonEnvVars } from '../../envVars';
import { HeaventEnv } from '../../heaventEnv';
import { assertUnreachable } from '../../util/assertUnreachable';
import { isNonEmptyString } from '../../util/string';
import {
    convertBorderStringToProperties,
    DesignSystemBorder,
    DesignSytemBorderColorRadius
} from '../util/border';

// TODO: delete once old design system is removed
const getOldThemeColors = () => ({
    oldBlue: '#1292ee',
    oldBlueLight: 'rgba(18, 146, 238, 0.15)',
    oldBlueDark: '#0779cb',
    oldBlueDarkDark: '#05558e',
    oldNavy: '#272e3c',
    oldRed: '#d93025',
    oldRedLight: 'rgba(217, 48, 37, 0.15)',
    oldGreen: '#2ab87a',
    oldGreenLight: 'rgba(42, 184, 122, 0.15)',
    oldYellow: '#f7c137',
    oldYellowLight: 'rgba(247, 193, 55, 0.15)',
    oldCyan: '#00c1d4',
    oldCyanLight: 'rgba(0, 193, 212, 0.15)',
    oldPurple: '#8c54ff',
    oldPurpleLight: 'rgba(140, 84, 255, 0.15)',
    oldWhite: '#ffffff',
    oldWhiteBackground: '#ffffff',
    oldBlueGray: '#596377',
    oldBlueBayoux: '#637178',
    oldBlack: '#434c5e',
    oldBlackLight: '#5a6679',
    oldSolitude: '#ebecf0',
    oldGray: '#fafafa',
    oldGrayDark: '#1d2b36',
    oldGrayLight: '#f4f5f7',
    oldGrayBorder: '#d4d9e2',
    oldGrayIcon: '#919aab',
    oldGrayDisabled: '#f7f7f7',
    oldEventBackground: '#f4f5f7',
    oldEventStepBackground: '#ffffff',
    oldEventFont: '#1d2b36'
});

export const getRecrewteerPrimaryColors = () => ({
    cyan: '',
    primary200: '#eef7ff',
    primary300: '#dceeff',
    primary400: '#b3daff',
    primary700: '#0284fe',
    primary800: '#026dd6'
});

const getPrimaryColors = () => {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
            return {
                cyan: '',
                primary200: '#fafcf5',
                primary300: '#ecf1d8',
                primary400: '#b3daff',
                primary700: '#9ebb3b',
                primary800: '#89ab13'
            };
        case HeaventEnv.Mso:
            return {
                cyan: '#7fd6de',
                primary200: '#dde5ec',
                primary300: '#a3b9cc',
                primary400: '#c2c2c2',
                primary700: '#195180',
                primary800: '#1e629a'
            };
        case HeaventEnv.Recrewteer:
            return getRecrewteerPrimaryColors();
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
};

const media = {
    mobile: 'screen and (max-width: 743px)',
    tablet: 'screen and (min-width: 744px)',
    desktop: 'screen and (min-width: 1280px)',
    // TODO: delete once old design system is removed
    oldBreakpoint: 'max-width: 1200px',
    oldBreakPointMinWidth: 'min-width: 1201px'
};

const FALLBACK_FONTS = `-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`;

const { css, styled, theme, config, getCssText, createTheme, keyframes } = createStitches({
    theme: {
        colors: {
            ...getOldThemeColors(),
            ...getPrimaryColors(),
            gray25: '#fcfcfd',
            gray50: '#f9fafb',
            gray100: '#f2f4f7',
            gray200: '#e4e7ec',
            gray300: '#d0d5dd',
            gray400: '#98a2b3',
            gray500: '#667085',
            gray600: '#475467',
            gray700: '#344054',
            gray800: '#1d2939',
            gray900: '#101828',
            primary25: '#fcfaff',
            primary50: '#f8fbff',
            primary100: '#f0f8ff',
            primary500: '#9e77ed',
            primary600: '#4ba7fe',
            primary900: '#01408f',
            error25: '#fffbfa',
            error50: '#fef3f2',
            error100: '#fee4e2',
            error200: '#fecdca',
            error300: '#fda29b',
            error400: '#f97066',
            error500: '#f04438',
            error600: '#d92d20',
            error700: '#b42318',
            error800: '#912018',
            error900: '#7a271a',
            warning25: '#fffcf5',
            warning50: '#fffaeb',
            warning100: '#fef0c7',
            warning200: '#fedf89',
            warning300: '#fec84b',
            warning400: '#fdb022',
            warning500: '#f79009',
            warning600: '#dc6803',
            warning700: '#b54708',
            warning800: '#93370d',
            warning900: '#7a2e0e',
            success25: '#f6fef9',
            success50: '#ecfdf3',
            success100: '#d1fadf',
            success200: '#a6f4c5',
            success300: '#6ce9a6',
            success400: '#32d583',
            success500: '#12b76a',
            success600: '#039855',
            success700: '#027a48',
            success800: '#05603a',
            success900: '#054f31',
            blue25: '#f5faff',
            blue50: '#eff8ff',
            blue100: '#d1e9ff',
            blue200: '#b2ddff',
            blue300: '#84caff',
            blue400: '#53b1fd',
            blue500: '#2e90fa',
            blue600: '#1570ef',
            blue700: '#175cd3',
            blue800: '#1849a9',
            blue900: '#194185',
            blueGray25: '#fcfcfd',
            blueGray50: '#f8f9fc',
            blueGray100: '#eaecf5',
            blueGray200: '#c8cce5',
            blueGray300: '#9ea5d1',
            blueGray400: '#717bbc',
            blueGray500: '#4e5ba6',
            blueGray600: '#3e4784',
            blueGray700: '#363f72',
            blueGray800: '#293056',
            blueGray900: '#272e3c',
            blueLight100: '#e0eaff',
            blueLight400: '#36bffa',
            blueLight600: '#0086C9',
            blueLight900: '#0b4a6f',
            purple25: '#fafaff',
            purple50: '#f4f3ff',
            purple100: '#ebe9fe',
            purple200: '#d9d6fe',
            purple300: '#bdb4fe',
            purple400: '#9b8afb',
            purple500: '#7a5af8',
            purple600: '#6938ef',
            purple700: '#5925dc',
            purple800: '#4a1fb8',
            purple900: '#3e1c96',
            pink25: '#fef6fb',
            pink50: '#fdf2fa',
            pink100: '#fce7f6',
            pink200: '#fcceee',
            pink300: '#faa7e0',
            pink400: '#f670c7',
            pink500: '#ee46bc',
            pink600: '#dd2590',
            pink700: '#c11574',
            pink800: '#9e165f',
            pink900: '#851651',
            orange100: '#ffead5',
            orange400: '#fd853a',
            orange600: '#ec4a0a',
            indigo100: '#e0eaff',
            indigo600: '#444ce7',
            indigo700: '#3538cd',
            white: 'white'
        },
        space: {
            1: '4px',
            2: '8px',
            3: '12px',
            4: '16px',
            5: '20px',
            6: '24px',
            7: '32px',
            8: '40px',
            9: '48px',
            10: '64px',
            11: '80px',
            12: '96px',
            13: '128px'
        },
        fonts: {
            default: `'Open Sans', ${FALLBACK_FONTS}`,
            grafik: `Graphik, ${FALLBACK_FONTS}`,
            montserrat: `Montserrat, ${FALLBACK_FONTS}`,
            madurai: `'Hind Madurai', ${FALLBACK_FONTS}`,
            gravur: `'Gravur Condensed', ${FALLBACK_FONTS}`,
            anton: `Anton, ${FALLBACK_FONTS}`,
            brito: `'Brito Tri', ${FALLBACK_FONTS}`,
            orbitron: `Orbitron, ${FALLBACK_FONTS}`,
            dharma: `'Dharma Punk', ${FALLBACK_FONTS}`,
            roboto: `Roboto, ${FALLBACK_FONTS}`,
            poppins: `Poppins, ${FALLBACK_FONTS}`,
            inter: `Inter, ${FALLBACK_FONTS}`,
            paytone: `'Paytone One', ${FALLBACK_FONTS}`,
            gothamBlack: `'Gotham Black', ${FALLBACK_FONTS}`,
            gothamBold: `'Gotham Bold', ${FALLBACK_FONTS}`,
            gothamBook: `'Gotham Book', ${FALLBACK_FONTS}`,
            gothamMedium: `'Gotham Medium', ${FALLBACK_FONTS}`,
            now: `'Now Medium', ${FALLBACK_FONTS}`,
            franklinGothic: `'Franklin Gothic', ${FALLBACK_FONTS}`,
            kobe: `Kobe, ${FALLBACK_FONTS}`,
            nowMedium: `"Now Medium", ${FALLBACK_FONTS}`,
            nowSemiBold: `"Now SemiBold", ${FALLBACK_FONTS}`,
            badTyp: `BadTyp, ${FALLBACK_FONTS}`,
            lemonMilk: `LemonMilk, ${FALLBACK_FONTS}`,
            futura: `Futura, ${FALLBACK_FONTS}`,
            urbanist: `Urbanist, ${FALLBACK_FONTS}`,
            unbounded: `Unbounded, ${FALLBACK_FONTS}`,
            magallanes: `Magallanes, ${FALLBACK_FONTS}`,
            parisine: `ParisineBold, ${FALLBACK_FONTS}`,
            bricolage: `Bricolage, ${FALLBACK_FONTS}`,
            paris2024: `Paris2024, ${FALLBACK_FONTS}`,
            dinCondensed: `DinCondensed, ${FALLBACK_FONTS}`,
            gothamRoundedBook: `"Gotham Rounded Book", ${FALLBACK_FONTS}`,
            gothamRoundedBold: `"Gotham Rounded Bold", ${FALLBACK_FONTS}`,
            gothamRoundedMedium: `"Gotham Rounded Medium", ${FALLBACK_FONTS}`,
            albert: `"Albert Sans", ${FALLBACK_FONTS}`,
            bebasNeue: `BebasNeue, ${FALLBACK_FONTS}`,
            encode: `"Encode Sans", ${FALLBACK_FONTS}`,
            gilroy: `Gilroy, ${FALLBACK_FONTS}`,
            tuskerGrotesk: `TuskerGrotesk, ${FALLBACK_FONTS}`,
            rubik: `Rubik, ${FALLBACK_FONTS}`,
            bnCartaNoirBold: `"BN Carta Noir Bold", ${FALLBACK_FONTS}`,
            firaSans: `"Fira Sans", ${FALLBACK_FONTS}`,
            arimo: `Arimo, ${FALLBACK_FONTS}`,
            neueMontreal: `NeueMontreal, ${FALLBACK_FONTS}`,
            helveticaBlackCondensed: `HelveticaBlackCondensed, ${FALLBACK_FONTS}`,
            zuumeMedium: `"Zuume Medium", ${FALLBACK_FONTS}`,
            zuumeBold: `"Zuume Bold", ${FALLBACK_FONTS}`,
            formulaCondensedBold: `FormulaCondensedBold, ${FALLBACK_FONTS}`,
            raleway: `Raleway, ${FALLBACK_FONTS}`,
            avenir: `Avenir, ${FALLBACK_FONTS}`
        },
        fontSizes: {
            textXs: '12px',
            textSm: '14px',
            textMd: '16px',
            textLg: '18px',
            textXl: '20px',
            displayXs: '24px',
            displaySm: '30px',
            displayMd: '36px',
            displayLg: '48px',
            displayXl: '60px',
            display2Xl: '72px'
        },
        fontWeights: {
            oldBold: 600, // TODO: delete once old design system is removed
            regular: 400,
            medium: 500,
            semiBold: 600,
            bold: 700
        },
        radii: {
            1: '4px',
            2: '8px',
            3: '12px'
        },
        shadows: {
            xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            md: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
            mdReverse:
                '0px -4px 8px -2px rgba(16, 24, 40, 0.1), 0px -2px 4px -2px rgba(16, 24, 40, 0.06)',
            lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
            xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
            '2xl': '0px 24px 48px -12px rgba(16, 24, 40, 0.25)',
            '3xl': '0px 32px 64px -12px rgba(16, 24, 40, 0.2)',
            // TODO: delete once old design system is removed
            old: 'rgba(11, 37, 75, 0.08) 0 0 0 1px, rgba(11, 37, 75, 0.12) 0 2px 4px -2px'
        },
        sizes: {
            container: '1170px',
            containerTablet: '704px',
            modalSm: '480px',
            modalMd: '640px'
        }
    },
    media,
    utils: {
        bd: (value: DesignSystemBorder | DesignSytemBorderColorRadius | true) => {
            let borderColor = '$gray200';
            let borderRadius = '$2';
            let boxShadow: string | undefined = '$xs';

            if (isNonEmptyString(value)) {
                const convertResult = convertBorderStringToProperties(value);

                if (convertResult.borderColor) {
                    borderColor = `$${convertResult.borderColor}`;
                }

                if (convertResult.borderRadius) {
                    borderRadius = `$${convertResult.borderRadius}`;
                }

                if (convertResult.boxShadow) {
                    boxShadow = `$${convertResult.boxShadow}`;
                } else {
                    boxShadow = undefined;
                }
            }

            return {
                borderColor,
                borderRadius,
                borderStyle: `solid`,
                borderWidth: `1px`,
                boxShadow
            };
        },
        clamp: (lines: string) => ({
            display: '-webkit-box',
            overflow: 'hidden',
            '-webkit-line-clamp': lines,
            '-webkit-box-orient': 'vertical'
        }),
        ellipsis: () => ({
            overflow: 'hidden',
            'text-overflow': 'ellipsis',
            'white-space': 'nowrap'
        }),
        px: (value: string) => ({
            paddingLeft: value,
            paddingRight: value
        }),
        py: (value: string) => ({
            paddingBottom: value,
            paddingTop: value
        })
    }
});

const overriddenTheme = createTheme(`${CommonEnvVars.HEAVENT_ENV}-theme`, {});

export { media, css, styled, theme, config, getCssText, overriddenTheme, keyframes, createTheme };

export type CSS = Stitches.CSS<typeof config>;

export type ResponsiveProperty<T> =
    | T
    | {
          '@initial'?: T;
          '@mobile'?: T;
          '@tablet'?: T;
          '@desktop'?: T;
      };
