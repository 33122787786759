import { SelectSegment } from 'common-front/src/segments/massActions/selectSegment';
import { SearchRow } from 'common-front/src/segments/searchRow';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { SegmentTableHeader } from 'common-front/src/segments/segmentTableHeader';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    SegmentCustomFieldFragment,
    UsersInfoId,
    UsersInfosSortAttributes
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { CommunityUsersHeader } from './communityUsersHeader';
import { CommunityUsersList } from './communityUsersList';
import { UsersActions } from './usersActions';

interface ICommunityUsersContentProps {
    customFields: SegmentCustomFieldFragment[];
}

export const CommunityUsersContent = ({ customFields }: ICommunityUsersContentProps) => {
    const translate = useTranslate();
    const { limit, reloadKey, segment, setReloadKey } = useSegmentsContext<
        UsersInfoId,
        UsersInfosSortAttributes
    >();

    return (
        <>
            <CommunityUsersHeader
                customFields={customFields}
                reload={() => {
                    setReloadKey(reloadKey + 1);
                }}
            />

            <Spacer height="7" />

            <SegmentTableHeader
                searchRow={
                    <SearchRow
                        actions={<UsersActions />}
                        searchPlaceholder={translate('rechercher_un_m_05904')}
                        showEditColumns={true}
                        showEditMode={true}
                        showSearchColumns={true}
                    />
                }
                selectSegment={
                    <SelectSegment
                        pageSelectedText={translate('les_1_membres_00895', limit)}
                        segmentSelectedText={translate(
                            'les_1_membres_56748',
                            segment.count,
                            segment.name
                        )}
                    />
                }
            />

            <CommunityUsersList customFields={customFields} />
        </>
    );
};
