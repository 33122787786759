import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { featureToIcon } from 'common-front/src/vo/feature';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Link } from 'common/src/designSystem/components/link';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import { BackofficeOrganizationsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { LocaleFormats } from 'common/src/util/luxon';
import * as React from 'react';
import { OrganizationRowDropdown } from './organizationRowDropdown';

interface IOrganizationRowProps {
    organization: BackofficeOrganizationsQuery['backoffice']['organizations']['nodes'][0];

    reload(): void;
}

export const OrganizationRow = (props: IOrganizationRowProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);

    return (
        <Row
            css={{
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            <Cell width={100}>{props.organization.id}</Cell>
            <Cell
                css={{
                    '& a': {
                        color: '$primary700 !important'
                    }
                }}
            >
                <Link to={`/organization/${props.organization.id}`}>{props.organization.name}</Link>
            </Cell>
            <Cell width={200}>
                {dateTimeService.toLocaleString(
                    props.organization.insertedAt,
                    LocaleFormats.DateOnly.MonthLong
                )}
            </Cell>
            <Cell width={200}>
                {dateTimeService.toLocaleString(
                    props.organization.periodEndAt,
                    LocaleFormats.DateOnly.MonthLong
                )}
            </Cell>
            <Cell>
                <Flex
                    align="center"
                    gap="2"
                    wrap="wrap"
                    height={1}
                    width={1}
                    css={{ overflow: 'auto', py: '$2' }}
                >
                    {props.organization.features.map((feature) => (
                            <Badge key={feature} leftIcon={featureToIcon(feature)}>
                                {feature}
                            </Badge>
                        ))}
                </Flex>
            </Cell>
            <Cell width={200}>{props.organization.numberOfEvents}</Cell>
            <CellControls justify="end">
                <Tooltip>
                    <Trigger>
                        <Box>
                            <I
                                icon="pen"
                                to={HeaventPaths.EDIT_BACKOFFICE_ORGANIZATION(
                                    props.organization.id
                                )}
                            />
                        </Box>
                    </Trigger>

                    <Content placement="top">{translate('_diter_62574')}</Content>
                </Tooltip>

                <OrganizationRowDropdown organization={props.organization} reload={props.reload} />
            </CellControls>
        </Row>
    );
};
