import { Spacer, SpacerHeight } from 'common/src/designSystem/components/spacer';
import { CSS, styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

const _Separator = styled('div', {
    variants: {
        direction: {
            horizontal: {
                width: '100%'
            },
            vertical: {
                height: '100%'
            }
        },
        color: {
            gray100: {},
            gray200: {}
        }
    },
    compoundVariants: [
        {
            direction: 'horizontal',
            color: 'gray100',
            css: {
                borderTop: '1px solid $gray100'
            }
        },
        {
            direction: 'vertical',
            color: 'gray100',
            css: {
                borderRight: '1px solid $gray100'
            }
        },
        {
            direction: 'horizontal',
            color: 'gray200',
            css: {
                borderTop: '1px solid $gray200'
            }
        },
        {
            direction: 'vertical',
            color: 'gray200',
            css: {
                borderRight: '1px solid $gray200'
            }
        }
    ],
    defaultVariants: {
        color: 'gray200'
    }
});

interface ISeparatorProps {
    color?: 'gray100' | 'gray200';
    css?: CSS;
    direction: 'horizontal' | 'vertical';
}

export const Separator = (props: ISeparatorProps) => (
    <_Separator
        aria-orientation={props.direction}
        color={props.color}
        css={props.css}
        direction={props.direction}
        role="separator"
    />
);

interface IHorizontalSpacerSeparatorProps {
    color?: 'gray100' | 'gray200';
    height: SpacerHeight;
}

export const HorizontalSpacerSeparator = (props: IHorizontalSpacerSeparatorProps) => (
    <>
        <Spacer height={props.height} />
        <_Separator color={props.color} direction="horizontal" role="separator" />
        <Spacer height={props.height} />
    </>
);
