import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { AccreditationPositionProperty, Position } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsNameCellProps {
    position: Pick<Position, 'id' | 'name'> & { numberOfSlots?: number };

    reload(): void;
}

export const PositionsNameCell = (props: IPositionsNameCellProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { isEditMode } = useSegmentsContext();
    const css = isEditMode
        ? { ellipsis: true }
        : {
              cursor: 'pointer',
              ellipsis: true,
              '&:hover': {
                  textDecoration: 'underline'
              }
          };

    return (
        <PositionsUpdateCell
            initialValue={props.position.name}
            positionId={props.position.id}
            property={AccreditationPositionProperty.Name}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput
                    label={translate('nom_de_la_missi_64605')}
                    value={value}
                    onChange={setValue}
                />
            )}
        >
            <Box
                font="gray900 textSm medium"
                width={1}
                css={css}
                title={props.position.name}
                onClick={() => {
                    if (!isEditMode) {
                        history.push(
                            HeaventPaths.POSITION(organizationId, eventId, props.position.id)
                        );
                    }
                }}
            >
                {props.position.name}
            </Box>
        </PositionsUpdateCell>
    );
};
