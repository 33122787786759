import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { useVolunteerRegistrationDeleteMutation } from 'common-front/src/generated/graphqlHooks';
import {
    EventId,
    Feature,
    UsersInfoId,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { useVolunteersRegistrationsMassWeezeventSynchronizeMutation } from '../../../generated/graphqlHooks';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { UserProfileAction } from './userProfileAction';
import { UserProfileActionsContainer } from './userProfileActionsContainer';

interface IEventUserProfileActionsGeneralProps {
    eventId: EventId;
    isWeezeventConnected: boolean;
    nameOrEmail: string;
    userInfoId: UsersInfoId;
    volunteerRegistrationId: VolunteersRegistrationId;
}

export const EventUserProfileActionsGeneral = (props: IEventUserProfileActionsGeneralProps) => {
    const translate = useTranslate();
    const { hasFeature } = useOrganizationContext();
    const { isEventAdmin } = useEventContext();
    const {
        mutate: volunteersRegistrationsMassWeezeventSynchronize,
        isLoading: isVolunteersRegistrationsMassWeezeventSynchronizeLoading
    } = useVolunteersRegistrationsMassWeezeventSynchronizeMutation();
    const { mutate: volunteerRegistrationDelete } = useVolunteerRegistrationDeleteMutation();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const showWeezevent =
        hasFeature(Feature.Weezevent) && props.isWeezeventConnected && isEventAdmin;

    return (
        <>
            <UserProfileActionsContainer title={translate('g_n_ral_64953')}>
                {showWeezevent && (
                    <UserProfileAction
                        color="error"
                        icon="arrows-rotate"
                        isLoading={isVolunteersRegistrationsMassWeezeventSynchronizeLoading}
                        onClick={async () => {
                            await volunteersRegistrationsMassWeezeventSynchronize({
                                eventId: props.eventId,
                                usersInfosIds: [props.userInfoId]
                            });
                        }}
                    >
                        {translate('synchroniser_av_82451')}
                    </UserProfileAction>
                )}

                <UserProfileAction
                    color="error"
                    icon="user-slash"
                    onClick={() => {
                        setIsDeleteModalOpen(true);
                    }}
                >
                    {translate('d_sinscrire_de_06416')}
                </UserProfileAction>
            </UserProfileActionsContainer>

            {isDeleteModalOpen && (
                <DeleteModal
                    text={translate('d_sinscription_69522', props.nameOrEmail)}
                    subtext={[
                        translate('_tes_vous_s_r_d_29576'),
                        translate('_1_sera_toujou_13334', props.nameOrEmail)
                    ]}
                    buttonText={translate('d_sinscrire_27046')}
                    onDelete={async () => {
                        await volunteerRegistrationDelete({
                            eventId: props.eventId,
                            volunteerRegistrationId: props.volunteerRegistrationId
                        });

                        // TODO props.onDelete();
                    }}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                    }}
                />
            )}
        </>
    );
};
