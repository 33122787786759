import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

interface IDayProps {
    color: string;
    children: React.ReactNode;
    visible: boolean;
}

const Day = (props: IDayProps) => {
    return (
        <Flex
            align="center"
            justify="center"
            height={28}
            width={45}
            css={{
                background: props.color,
                flex: '1',
                visibility: props.visible ? 'visible' : 'hidden'
            }}
        >
            <Box
                css={{
                    color: 'white',
                    fontFamily: '$raleway',
                    fontSize: '17px',
                    fontWeight: '700'
                }}
            >
                {props.children}
            </Box>
        </Flex>
    );
};

interface IBadgeVendangesProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeVendanges = (props: IBadgeVendangesProps) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;
    const accreditations = vr.accreditationsUsersInfos.map(
        ({ accreditation }) => accreditation.acronym
    );

    return (
        <Flex
            height={325}
            width={204}
            css={{
                background: `url(https://assets.recrewteer.com/badges/vendanges/vendanges_2024.jpg) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    borderRadius: '92px',
                    height: '92px',
                    left: '56px',
                    overflow: 'hidden',
                    position: 'absolute',
                    top: '133px',
                    width: '92px'
                }}
            >
                {userInfo.picture && <img src={userInfo.picture?.url} height="100%" width="100%" />}
            </Box>

            <Box
                css={{
                    color: '#802c8d',
                    fontFamily: '$raleway',
                    fontSize: '12px',
                    fontWeight: '800',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '240px',
                    width: '100%'
                }}
            >
                {userInfo.name}
            </Box>

            <Flex
                direction="column"
                css={{
                    left: '0',
                    position: 'absolute',
                    top: '146px'
                }}
            >
                <Day color="#98cd00" visible={accreditations.includes('JEU 19')}>
                    JEU
                </Day>
                <Day
                    color="#ff9f01"
                    visible={
                        accreditations.includes('VEN 20') ||
                        accreditations.includes('VEN 20 & SAM 21')
                    }
                >
                    VEN
                </Day>
                <Day
                    color="#a655b1"
                    visible={
                        accreditations.includes('SAM 21') ||
                        accreditations.includes('VEN 20 & SAM 21')
                    }
                >
                    SAM
                </Day>
            </Flex>

            <Flex
                css={{
                    height: '53px',
                    left: '0',
                    position: 'absolute',
                    top: '272px',
                    width: '100%'
                }}
            >
                <Flex
                    align="center"
                    justify="center"
                    css={{
                        background: '#6eafe2',
                        height: '100%',
                        flex: '1',
                        visibility: accreditations.includes('1') ? 'visible' : 'hidden'
                    }}
                >
                    <Box css={{ color: 'white', fontSize: '32px', fontWeight: '700' }}>1</Box>
                </Flex>

                <Flex
                    align="center"
                    justify="center"
                    css={{
                        background: '#9261a8',
                        height: '100%',
                        flex: '1',
                        visibility: accreditations.includes('2') ? 'visible' : 'hidden'
                    }}
                >
                    <Box css={{ color: 'white', fontSize: '32px', fontWeight: '700' }}>2</Box>
                </Flex>

                <Flex
                    align="center"
                    justify="center"
                    css={{
                        background: '#accb44',
                        height: '100%',
                        flex: '1',
                        visibility: accreditations.includes('3') ? 'visible' : 'hidden'
                    }}
                >
                    <Box css={{ color: 'white', fontSize: '32px', fontWeight: '700' }}>3</Box>
                </Flex>

                <Flex
                    align="center"
                    justify="center"
                    css={{
                        background: '#e7a726',
                        height: '100%',
                        flex: '1',
                        visibility: accreditations.includes('4') ? 'visible' : 'hidden'
                    }}
                >
                    <Box css={{ color: 'white', fontSize: '32px', fontWeight: '700' }}>4</Box>
                </Flex>

                <Flex
                    align="center"
                    justify="center"
                    css={{
                        background: '#d23780',
                        height: '100%',
                        flex: '1',
                        visibility: accreditations.includes('5') ? 'visible' : 'hidden'
                    }}
                >
                    <Box css={{ color: 'white', fontSize: '32px', fontWeight: '700' }}>5</Box>
                </Flex>
            </Flex>
        </Flex>
    );
};
