import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const getBackground = (population: string) => {
    if (population.includes('Bénévoles')) {
        return 'https://assets.recrewteer.com/badges/infernal/a4/Badge_benevole.jpg';
    } else if (population.includes('Staff Infernal')) {
        return 'https://assets.recrewteer.com/badges/infernal/a4/Badge_direction.jpg';
    } else if (population.includes('Exposant')) {
        return 'https://assets.recrewteer.com/badges/infernal/a4/Badge_exposant.jpg';
    } else if (population.includes('Médical')) {
        return 'https://assets.recrewteer.com/badges/infernal/a4/Badge_medical.jpg';
    } else if (population.includes('Officiel')) {
        return 'https://assets.recrewteer.com/badges/infernal/a4/Badge_officiel.jpg';
    } else if (population.includes('Partenaire')) {
        return 'https://assets.recrewteer.com/badges/infernal/a4/Badge_Partenaire.jpg';
    } else if (population.includes('Prestataire')) {
        return 'https://assets.recrewteer.com/badges/infernal/a4/Badge_presta.jpg';
    } else if (population.includes('Média')) {
        return 'https://assets.recrewteer.com/badges/infernal/a4/Badge_media.jpg';
    } else {
        return '';
    }
};

interface IInfernalBadgeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const InfernalBadge = (props: IInfernalBadgeProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const population = ui.infernalPopulation || 'Staff Infernal';
    const acronyms = vr.accreditationsUsersInfos.map((aui) => aui.accreditation.acronym!);

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(${getBackground(population)}) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Box
                width={397}
                textAlign="center"
                css={{
                    color: 'white',
                    fontSize: '40px',
                    fontWeight: '700',
                    position: 'absolute',
                    top: '262px'
                }}
            >
                {ui.firstName}
            </Box>

            <Box
                width={397}
                textAlign="center"
                css={{
                    color: 'white',
                    fontSize: '40px',
                    position: 'absolute',
                    top: '309px'
                }}
            >
                {ui.lastName}
            </Box>

            <Box
                height={54}
                width={54}
                css={{
                    background: 'white',
                    left: '332px',
                    padding: '2px',
                    position: 'absolute',
                    top: '402px'
                }}
            >
                <Box height={1} width={1}>
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=60&width=60`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>
            </Box>

            <Flex
                width={367}
                css={{
                    left: '15px',
                    position: 'absolute',
                    top: '476px'
                }}
            >
                <Flex align="center" gap="6" height={44} width={1} css={{ px: '10px' }}>
                    <Box
                        height={30}
                        width={30}
                        css={{
                            visibility: acronyms.includes('PC') ? 'visible' : 'hidden'
                        }}
                    >
                        <img
                            src="https://assets.recrewteer.com/badges/infernal/icons/pc.jpg"
                            height="100%"
                            width="100%"
                        />
                    </Box>

                    <Box
                        height={30}
                        width={30}
                        css={{
                            visibility: acronyms.includes('PART') ? 'visible' : 'hidden'
                        }}
                    >
                        <img
                            src="https://assets.recrewteer.com/badges/infernal/icons/vip.jpg"
                            height="100%"
                            width="100%"
                        />
                    </Box>

                    <Box
                        height={23}
                        width={29}
                        css={{
                            visibility: acronyms.includes('DEP') ? 'visible' : 'hidden'
                        }}
                    >
                        <img
                            src="https://assets.recrewteer.com/badges/infernal/icons/arrive.jpg"
                            height="100%"
                            width="100%"
                        />
                    </Box>

                    <Box
                        height={30}
                        width={16}
                        css={{
                            visibility: acronyms.includes('CR') ? 'visible' : 'hidden'
                        }}
                    >
                        <img
                            src="https://assets.recrewteer.com/badges/infernal/icons/repas.jpg"
                            height="100%"
                            width="100%"
                        />
                    </Box>

                    <Box
                        height={30}
                        width={17}
                        css={{
                            visibility: acronyms.includes('CB') ? 'visible' : 'hidden'
                        }}
                    >
                        <img
                            src="https://assets.recrewteer.com/badges/infernal/icons/boisson.jpg"
                            height="100%"
                            width="100%"
                        />
                    </Box>

                    <Box
                        height={30}
                        width={30}
                        css={{
                            visibility:
                                acronyms.includes('PVIP') || acronyms.includes('PEXP')
                                    ? 'visible'
                                    : 'hidden'
                        }}
                    >
                        <img
                            src="https://assets.recrewteer.com/badges/infernal/icons/parking.jpg"
                            height="100%"
                            width="100%"
                        />
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};
