import { Form } from 'common-front/src/components/form/form';
import { useUserInfoSlotsUpdateMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { IUserUpdateSlotsValues } from 'common-front/src/users/updateSlots/userUpdateSlotsValues';
import { useReferrerContext } from 'common-front/src/util/referrerContext';
import { UserUpdateSlotsInfosQuery } from 'common/src/generated/types';
import { VolunteerRegistrationInputService } from 'common/src/input/volunteerRegistrationInput';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { UserUpdateSlots } from './userUpdateSlots';

interface IUserUpdateSlotsFormProps {
    event: UserUpdateSlotsInfosQuery['event'];
    forms: UserUpdateSlotsInfosQuery['event']['forms'];
    userInfo: UserUpdateSlotsInfosQuery['organization']['userInfo'];
}

export const UserUpdateSlotsForm = (props: IUserUpdateSlotsFormProps) => {
    const {
        history,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const volunteerRegistrationInput = useService(VolunteerRegistrationInputService);
    const { getReferrerPath } = useReferrerContext();

    const { mutate, isLoading } = useUserInfoSlotsUpdateMutation();
    const initialSelectedSlots = React.useMemo(() => props.forms.map((form) => {
            const formUserInfo = props.userInfo.formsUsersInfos.find(
                (fui) => fui.formId === form.id
            );

            return {
                formId: form.id,
                slots: volunteerRegistrationInput.volunteersRegistrationsSlotsUpdateInputDefault(
                    formUserInfo?.slots
                )
            };
        }), [props.forms, props.userInfo]);

    return (
        <Form
            direction="column"
            height={1}
            width={1}
            initialValues={{
                userInfo: { slots: initialSelectedSlots }
            }}
            onSubmit={async (values: IUserUpdateSlotsValues) => {
                await mutate({
                    eventId,
                    userInfoId,
                    userInfoSlots: values.userInfo.slots
                });

                history.push(getReferrerPath() ?? HeaventPaths.VOLUNTEERS(organizationId, eventId));
            }}
            render={({ form, handleSubmit, values }) => (
                    <UserUpdateSlots
                        event={props.event}
                        forms={props.forms}
                        isLoading={isLoading}
                        userInfo={props.userInfo}
                        values={values}
                        change={form.change}
                        handleSubmit={handleSubmit}
                    />
                )}
        />
    );
};
