import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { BadgeSalamandreA7 } from './badgeSalamandreA7';

interface IBadgeSalamandreA4Props {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeSalamandreA4 = (props: IBadgeSalamandreA4Props) => (
        <Flex height={A4_SIZES['96dpi'].height} width={A4_SIZES['96dpi'].width}>
            <BadgeSalamandreA7 event={props.event} />
        </Flex>
    );
