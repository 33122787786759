import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { CustomFieldsValueInput } from 'common/src/generated/types';
import { removeAtIndex, reorder } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    Droppable,
    DroppableProvided
} from 'react-beautiful-dnd';

interface ICreateUpdateSelectValuesProps {
    isEdit: boolean;
    isSelectV2: boolean;
    values: CustomFieldsValueInput[];

    change(field: string, value: any): void;
}

export const CreateUpdateSelectValues = (props: ICreateUpdateSelectValuesProps) => {
    const translate = useTranslate();
    const onDragEnd = React.useCallback(
        ({ reason, source, destination }) => {
            if (reason === 'DROP' && destination && source.index !== destination.index) {
                props.change(
                    'customField.values',
                    reorder(props.values, source.index, destination.index)
                );
            }
        },
        [props.values, props.change]
    );
    const canEdit = !props.isEdit || props.isSelectV2;

    return (
        <Flex direction="column" gap="1">
            <Box font="gray700 textSm medium">{translate('valeurs_de_la_l_43599')}</Box>

            <Flex
                direction="column"
                gap="3"
                css={{
                    background: '$gray100',
                    borderRadius: '$1',
                    padding: '$4'
                }}
            >
                {canEdit ? (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="1">
                            {(droppableProvided: DroppableProvided) => (
                                    <Flex
                                        direction="column"
                                        gap="3"
                                        {...droppableProvided.droppableProps}
                                        ref={droppableProvided.innerRef}
                                    >
                                        {props.values.map((_, index) => (
                                                <Draggable
                                                    key={index}
                                                    draggableId={index.toString()}
                                                    index={index}
                                                >
                                                    {(draggableProvided: DraggableProvided) => (
                                                            <Flex
                                                                align="center"
                                                                gap="4"
                                                                {...draggableProvided.draggableProps}
                                                                ref={draggableProvided.innerRef}
                                                            >
                                                                <Box
                                                                    color="gray300"
                                                                    fontSize="textMd"
                                                                    css={{
                                                                        cursor: 'grab'
                                                                    }}
                                                                    {...draggableProvided.dragHandleProps}
                                                                >
                                                                    <I
                                                                        icon="grip-vertical"
                                                                        iconStyle="solid"
                                                                    />
                                                                </Box>

                                                                <Box css={{ flex: '1' }}>
                                                                    <TextInput
                                                                        name={`customField.values[${index}].value`}
                                                                    />
                                                                </Box>

                                                                <Box
                                                                    color="gray500"
                                                                    css={{
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => {
                                                                        props.change(
                                                                            'customField.values',
                                                                            removeAtIndex(
                                                                                props.values,
                                                                                index
                                                                            )
                                                                        );
                                                                    }}
                                                                >
                                                                    <I icon="trash-can" />
                                                                </Box>
                                                            </Flex>
                                                        )}
                                                </Draggable>
                                            ))}

                                        {droppableProvided.placeholder}
                                    </Flex>
                                )}
                        </Droppable>
                    </DragDropContext>
                ) : (
                    props.values.map((_, index) => (
                            <Box key={index} css={{ flex: '1' }}>
                                <TextInput name={`customField.values[${index}].value`} />
                            </Box>
                        ))
                )}

                <Box
                    font="primary700 textSm medium"
                    css={{ cursor: 'pointer' }}
                    onClick={() => {
                        props.change('customField.values', props.values.concat([{ value: '' }]));
                    }}
                >
                    + {translate('ajouter_une_nou_98358')}
                </Box>
            </Flex>
        </Flex>
    );
};
