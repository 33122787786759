import { CSS } from '@stitches/react';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { Link } from 'common/src/designSystem/components/link';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { LinkProps } from 'react-router-dom';
import { closeOtherDropdowns } from './dropdown';

const _Item = styled('div', {
    alignItems: 'center',
    display: 'flex',
    fontSize: '$textSm',
    fontWeight: '$regular',
    minHeight: '40px',
    px: '$4',
    py: '$2',
    userSelect: 'none',
    width: '100%',
    ellipsis: '',
    variants: {
        color: {
            default: {
                cursor: 'pointer',
                color: '$gray700',
                '&:hover': {
                    background: '$primary50',
                    color: '$primary700'
                }
            },
            disabled: {
                color: '$gray400',
                cursor: 'not-allowed'
            },
            heading: {
                color: '$gray500',
                fontSize: '$textXs',
                fontWeight: '$semiBold',
                cursor: 'default'
            },
            red: {
                cursor: 'pointer',
                color: '$error700',
                '&:hover': {
                    background: '$error50'
                }
            }
        }
    },
    defaultVariants: {
        color: 'default'
    }
});

type IItemColor = 'default' | 'red' | 'disabled' | 'heading';

interface IItemProps
    extends Omit<LinkProps, 'onClick' | 'to'>,
        Partial<Pick<LinkProps, 'to'>>,
        // Required to allow `onClick.e` to be `React.MouseEvent<HTMLDivElement>`
        Pick<React.AnchorHTMLAttributes<any>, 'onClick' | 'role'> {
    color?: IItemColor;
    // eslint-disable-next-line react/no-unused-prop-types
    css?: CSS; // Unknown why this causes linter error
    children: React.ReactNode;

    onClick?(e: React.MouseEvent | React.KeyboardEvent): void;
}

export const Item = ({ children, color, css, download, to, onClick, target, role }: IItemProps) =>
    color === 'disabled' || !to ? (
        <_Item
            css={css}
            color={color || 'default'}
            onClick={(e) => onClick?.(e)}
            role={role ?? "menuitem"}
            tabIndex={!to ? (role !== 'presentation' ? 0 : undefined) : undefined}
        >
            {children}
        </_Item>
    ) : (
        <Link download={download} onClick={(e) => onClick?.(e)} target={target} to={to}>
            <_Item css={css} color={color || 'default'}>
                {children}
            </_Item>
        </Link>
    );

interface IItemIconProps extends IItemProps {
    icon: IIcon;
    isDisabled?: boolean;
}

export const ItemIcon = ({
    children,
    color,
    download,
    icon,
    isDisabled = false,
    onClick,
    to,
    target
}: IItemIconProps) => (
    <Item
        color={color}
        css={{
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            opacity: isDisabled ? '0.6' : 'inherit'
        }}
        download={download}
        target={target}
        to={to}
        onClick={(e) => {
            if (!isDisabled) {
                closeOtherDropdowns(e.target);

                onClick?.(e);
            }
        }}
    >
        <Flex align="center" gap="3" height={1} width={1}>
            <Flex align="center" height={1} justify="start" width={16}>
                <I icon={icon} />
            </Flex>

            <Box css={{ flex: '1', ellipsis: '' }}>{children}</Box>
        </Flex>
    </Item>
);
