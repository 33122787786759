import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { DelegationProperty, DelegationsDelegationFragment } from 'common/src/generated/types';
import * as React from 'react';
import { DelegationsTagsCellInput } from './delegationsTagsCellInput';
import { DelegationsUpdateCell } from './delegationsUpdateCell';

interface IDelegationsTagsCellProps {
    delegation: DelegationsDelegationFragment;

    reload(): void;
}

export const DelegationsTagsCell = (props: IDelegationsTagsCellProps) => {
    const badges = React.useMemo(
        () =>
            props.delegation.tags.map((tag) => ({
                id: tag.id,
                color: 'gray',
                icon: null,
                text: tag.name
            })),
        [props.delegation.tags]
    );

    return (
        <DelegationsUpdateCell
            delegation={props.delegation}
            initialValue={props.delegation.tags.map((t) => t.id)}
            property={DelegationProperty.Tag}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <DelegationsTagsCellInput value={value} setValue={setValue} />
            )}
        >
            <BadgesPlus badges={badges} />
        </DelegationsUpdateCell>
    );
};
