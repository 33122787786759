import { Blank } from 'common-front/src/components/blank/blank';
import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { OrderRightPanel } from 'common-front/src/designSystem/components/orderRightPanel';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Svgs } from 'common-front/src/util/assets';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { Table } from 'common/src/designSystem/components/table/table';
import { PossibleDuplicatesQuery, UsersInfoId } from 'common/src/generated/types';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { usePossibleDuplicatesQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { CommunityPossibleDuplicates } from './communityPossibleDuplicates';

interface ICommunityUsersDuplicatesContentProps {
    organization: PossibleDuplicatesQuery['organization'];
}

const CommunityUsersDuplicatesContent = (props: ICommunityUsersDuplicatesContentProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const { localOrganization, updateLocalOrganization } = useOrganizationContext();
    const customFields = props.organization.customFields.nodes;
    const [possibleDuplicates, setPossibleDuplicates] = React.useState(
        props.organization.possibleDuplicates
    );
    const [idToSelectedId, setIdToSelectedId] = React.useState<Record<string, UsersInfoId>>(
        Object.fromEntries(possibleDuplicates.map(({ id, usersInfos }) => [id, usersInfos[0].id]))
    );
    const [isFieldsSelectorOpen, setIsFieldsSelectorOpen] = React.useState(false);
    const numberOfDuplicates = possibleDuplicates.length ?? 0;
    const [displayedColumns, _setDisplayedColumns] = React.useState(
        localOrganization?.duplicatesColumns || []
    );
    const possibleColumns = React.useMemo(
        () =>
            sortBy(
                customFields
                    .map((cf) => ({
                        name: cf.name,
                        slug: cf.slug
                    }))
                    .concat([
                        {
                            name: translate('d_l_gations_78318'),
                            slug: 'delegations'
                        }
                    ]),
                (c) => c.name
            ),
        [customFields]
    );
    const setDisplayedColumns = React.useCallback(
        async (newColumns: string[]) => {
            _setDisplayedColumns(newColumns);

            await updateLocalOrganization({ duplicatesColumns: newColumns });
        },
        [_setDisplayedColumns]
    );

    return (
        <>
            <FullScreenPopup
                title={translate('gestion_des_mem_14016')}
                closePath={CommunityPaths.COMMUNITY_USERS(organizationId)}
            >
                <CenteredContainer>
                    <Flex align="center" justify="between">
                        <Flex direction="column" gap="1">
                            <Flex align="center" gap="2">
                                <Box font="gray900 textMd medium">
                                    {translate('liste_des_membr_20115')}
                                </Box>

                                {numberOfDuplicates > 0 && (
                                    <Badge color="warning" size="sm">
                                        {translate('_1_doublon_pot_74382', numberOfDuplicates)}
                                    </Badge>
                                )}
                            </Flex>

                            <Box color="gray500">{translate('s_lectionner_le_91896')}</Box>
                        </Flex>

                        <Button
                            color="white"
                            leftIcon="gear"
                            onClick={() => {
                                setIsFieldsSelectorOpen(true);
                            }}
                        >
                            {translate('_diter_les_colo_39630')}
                        </Button>
                    </Flex>

                    <Spacer height="6" />

                    {numberOfDuplicates === 0 ? (
                        <Table>
                            <HeaderRow>
                                <HeaderCell>{translate('nom_du_membre_69353')}</HeaderCell>
                                <HeaderCell>{translate('membre_principa_09597')}</HeaderCell>
                            </HeaderRow>

                            <Row css={{ height: '400px' }}>
                                <Cell css={{ height: '100%' }}>
                                    <Blank
                                        imageSrc={Svgs.AssignmentsBlank}
                                        title={translate('il_n_y_a_pas_de_78722')}
                                        subtitle=""
                                    />
                                </Cell>
                            </Row>
                        </Table>
                    ) : (
                        possibleDuplicates.map(({ id, usersInfos }) => (
                            <React.Fragment key={id}>
                                <CommunityPossibleDuplicates
                                    customFields={customFields}
                                    displayColumns={displayedColumns}
                                    selectedId={idToSelectedId[id]}
                                    usersInfos={usersInfos}
                                    onSelect={(userInfoId) => {
                                        setIdToSelectedId((values) => ({
                                            ...values,
                                            [id]: userInfoId
                                        }));
                                    }}
                                    onSuccess={() => {
                                        setPossibleDuplicates((values) =>
                                            values.filter((v) => v.id !== id)
                                        );
                                    }}
                                />

                                <Spacer height="4" />
                            </React.Fragment>
                        ))
                    )}
                </CenteredContainer>
            </FullScreenPopup>

            {isFieldsSelectorOpen && (
                <OrderRightPanel
                    columns={possibleColumns}
                    initialSelectedColumns={displayedColumns}
                    saveButtonText={translate('_diter_62574')}
                    searchPlaceholder={translate('rechercher_un_c_86475')}
                    subtitle={translate('personnaliser_l_96096')}
                    title={translate('_dition_des_col_70300')}
                    onClose={() => {
                        setIsFieldsSelectorOpen(false);
                    }}
                    onSave={setDisplayedColumns}
                />
            )}
        </>
    );
};

export const CommunityUsersDuplicates = () => {
    const {
        params: { organizationId }
    } = useHeavent();
    const { data, loader } = usePossibleDuplicatesQuery({ organizationId });

    return loader || <CommunityUsersDuplicatesContent organization={data.organization} />;
};
