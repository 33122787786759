import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A6_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const NAMES = [
    'TERRAIN',
    'VESTIAIRES',
    'ESPACE PRESSE',
    'SALON NEODIF',
    'SALON GEOFIT',
    'SALON PRESTIGE'
];

const BACKGROUNDS: { [name: string]: string } = {
    TERRAIN: 'Mode_Isolation_1.png',
    VESTIAIRES: 'Mode_Isolation_2.png',
    'ESPACE PRESSE': 'Mode_Isolation_3.png',
    'SALON NEODIF': 'Mode_Isolation_4.png',
    'SALON GEOFIT': 'Mode_Isolation_5.png',
    'SALON PRESTIGE': 'Mode_Isolation_6.png'
};

const Dot: React.FunctionComponent<React.ComponentProps<typeof Box> & { name: string }> = ({
    css,
    name
}) => (
    <Box
        css={
            {
                background: `url(https://assets.recrewteer.com/badges/hbc/lnh/${BACKGROUNDS[name]}) no-repeat`,
                backgroundSize: 'cover',
                height: '47px',
                position: 'absolute',
                top: '463px',
                width: '47px',
                ...css
            } as React.ComponentProps<typeof Box>['css']
        }
    />
);

export const BadgeHbcLnh = ({ event }: { event: DocumentUserCustomBadgeFragment }) => {
    const vr = event.volunteerRegistration;
    const ui = vr.userInfo;

    const position = ui.hbcMission;
    const accreditations = vr.accreditationsUsersInfos.reduce(
        (acc, aui) => {
            if (NAMES.includes((aui.accreditation?.name || '').toUpperCase())) {
                return { ...acc, [aui.accreditation.name]: true };
            } else {
                return acc;
            }
        },
        {} as {
            [name: string]: boolean;
        }
    );

    return (
        <Flex
            height={A6_SIZES['96dpi'].height}
            width={A6_SIZES['96dpi'].width}
            css={{
                fontFamily: '$zuumeMedium',
                position: 'relative'
            }}
        >
            <Box
                height={111}
                width={111}
                css={{
                    left: '52px',
                    position: 'absolute',
                    top: '205px'
                }}
            >
                {isNonEmptyString(ui.picture?.url) && (
                    <img src={ui.picture!.url} height="100%" width="100%" />
                )}
            </Box>

            <Box
                width={77}
                textAlign="center"
                css={{
                    left: '69px',
                    position: 'absolute',
                    top: '317px'
                }}
            >
                {ui.id}
            </Box>

            <Box
                css={{
                    fontSize: '20px',
                    left: '178px',
                    lineHeight: 'normal',
                    position: 'absolute',
                    right: '52px',
                    textAlign: 'center',
                    top: '205px'
                }}
            >
                {ui.lastName}
            </Box>
            <Box
                css={{
                    fontSize: '20px',
                    left: '178px',
                    lineHeight: 'normal',
                    position: 'absolute',
                    right: '52px',
                    textAlign: 'center',
                    top: '229px'
                }}
            >
                {ui.firstName}
            </Box>
            <Box
                css={{
                    fontFamily: '$zuumeBold',
                    fontSize: '25px',
                    fontWeight: '700',
                    left: '178px',
                    lineHeight: 'normal',
                    position: 'absolute',
                    right: '52px',
                    textAlign: 'center',
                    top: '261px'
                }}
            >
                {ui.fields.cf38077}
            </Box>
            <Box
                css={{
                    fontSize: '20px',
                    left: '178px',
                    lineHeight: 'normal',
                    position: 'absolute',
                    right: '52px',
                    textAlign: 'center',
                    textDecoration: 'uppercase',
                    top: '298px'
                }}
            >
                {position}
            </Box>
            {accreditations['TERRAIN'] && <Dot name="TERRAIN" css={{ left: '45px' }} />}
            {accreditations['VESTIAIRES'] && <Dot name="VESTIAIRES" css={{ left: '96px' }} />}
            {accreditations['ESPACE PRESSE'] && (
                <Dot name="ESPACE PRESSE" css={{ left: '149px' }} />
            )}
            {accreditations['SALON NEODIF'] && <Dot name="SALON NEODIF" css={{ left: '201px' }} />}
            {accreditations['SALON GEOFIT'] && <Dot name="SALON GEOFIT" css={{ left: '253px' }} />}
            {accreditations['SALON PRESTIGE'] && (
                <Dot name="SALON PRESTIGE" css={{ left: '306px' }} />
            )}
        </Flex>
    );
};
