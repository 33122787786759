import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    AccreditationId,
    AccreditationsQuery,
    EventId,
    OrganizationId,
    SegmentId
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { PossibleColumn } from 'common/src/vo/segment';
import { AccreditationDefaultColumns } from 'common/src/vo/segments/accreditationsSegmentsService';
import * as React from 'react';
import { PositionCreatedByCell } from '../../positions/list/list/cells/positionCreatedByCell';
import { PositionRowResources } from '../../positions/list/list/positionRowResources';
import { AccreditationRowDropdown } from './accreditationRowDropdown';
import { AccreditationsAcronymCell } from './cells/accreditationsAcronymCell';
import { AccreditationsCategoryCell } from './cells/accreditationsCategoryCell';
import { AccreditationsColorCell } from './cells/accreditationsColorCell';
import { AccreditationsConditionsCell } from './cells/accreditationsConditionsCell';
import { AccreditationsIconCell } from './cells/accreditationsIconCell';
import { AccreditationsNameCell } from './cells/accreditationsNameCell';
import { AccreditationsTagsCell } from './cells/accreditationsTagsCell';

interface IAccreditationRowProps {
    accreditation: AccreditationsQuery['event']['accreditations']['nodes'][0];
    columns: PossibleColumn[];
    eventId: EventId;
    organizationId: OrganizationId;
    segmentId: SegmentId;
    state: ICheckboxState;

    reload(): void;
    reloadAccreditation(id: AccreditationId): void;
    toggleRow(key: string, newState: ICheckboxState): void;
}

export const AccreditationRow = (props: IAccreditationRowProps) => {
    const { history, translate } = useHeavent();
    const reloadAccreditation = React.useCallback(() => {
        props.reloadAccreditation(props.accreditation.id);
    }, [props.accreditation, props.reloadAccreditation]);

    return (
        <Row
            css={{
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            <Cell justify="center" width={48}>
                <Checkbox
                    state={props.state}
                    onClick={(newState, e) => {
                        e.nativeEvent.stopImmediatePropagation();
                        e.stopPropagation();

                        props.toggleRow(`a${props.accreditation.id}`, newState);
                    }}
                />
            </Cell>

            {props.columns.map((field) =>
                field.slug === AccreditationDefaultColumns.Id ? (
                    <Cell key={field.slug} width={100}>
                        {props.accreditation.id}
                    </Cell>
                ) : field.slug === AccreditationDefaultColumns.Category ? (
                    <AccreditationsCategoryCell
                        key={field.slug}
                        accreditationId={props.accreditation.id}
                        accreditationCategory={props.accreditation.accreditationCategory}
                        reload={reloadAccreditation}
                    />
                ) : field.slug === AccreditationDefaultColumns.Name ? (
                    <AccreditationsNameCell
                        key={field.slug}
                        accreditation={props.accreditation}
                        reload={reloadAccreditation}
                    />
                ) : field.slug === AccreditationDefaultColumns.SlotName ||
                  field.slug === AccreditationDefaultColumns.SlotDate ? (
                    <Cell key={field.slug}>-</Cell>
                ) : field.slug === AccreditationDefaultColumns.Resources ? (
                    <Cell key={field.slug} width={256}>
                        <PositionRowResources
                            assignedResources={props.accreditation.assignedResources}
                            resources={props.accreditation.maxResources}
                        />
                    </Cell>
                ) : field.slug === AccreditationDefaultColumns.Tags ? (
                    <AccreditationsTagsCell
                        key={field.slug}
                        accreditation={props.accreditation}
                        reload={reloadAccreditation}
                    />
                ) : field.slug === AccreditationDefaultColumns.Conditions ? (
                    <AccreditationsConditionsCell
                        key={field.slug}
                        accreditation={props.accreditation}
                    />
                ) : field.slug === AccreditationDefaultColumns.Address ? (
                    <Cell key={field.slug}>{props.accreditation.address}</Cell>
                ) : field.slug === AccreditationDefaultColumns.Latitude ? (
                    <Cell key={field.slug}>{props.accreditation.latitude}</Cell>
                ) : field.slug === AccreditationDefaultColumns.Longitude ? (
                    <Cell key={field.slug}>{props.accreditation.longitude}</Cell>
                ) : field.slug === AccreditationDefaultColumns.Acronym ? (
                    <AccreditationsAcronymCell
                        key={field.slug}
                        accreditation={props.accreditation}
                        reload={reloadAccreditation}
                    />
                ) : field.slug === AccreditationDefaultColumns.Color ? (
                    <AccreditationsColorCell
                        key={field.slug}
                        accreditation={props.accreditation}
                        reload={reloadAccreditation}
                    />
                ) : field.slug === AccreditationDefaultColumns.Icon ? (
                    <AccreditationsIconCell
                        key={field.slug}
                        accreditation={props.accreditation}
                        reload={reloadAccreditation}
                    />
                ) : field.slug === AccreditationDefaultColumns.FillingRate ? (
                    <Cell key={field.slug}>{props.accreditation.fillingRate}%</Cell>
                ) : field.slug === AccreditationDefaultColumns.CreatedBy ? (
                    <PositionCreatedByCell
                        key={field.slug}
                        createdBy={props.accreditation.createdBy}
                    />
                ) : (
                    <Cell />
                )
            )}

            <CellControls justify="center">
                <Tooltip>
                    <Trigger>
                        <Box>
                            <I
                                icon="pen"
                                onClick={() => {
                                    history.push(
                                        HeaventPaths.EDIT_ACCREDITATION(
                                            props.organizationId,
                                            props.eventId,
                                            props.accreditation.id
                                        )
                                    );
                                }}
                            ></I>
                        </Box>
                    </Trigger>

                    <Content placement="top">{translate('_diter_62574')}</Content>
                </Tooltip>

                <AccreditationRowDropdown
                    accreditation={props.accreditation}
                    eventId={props.eventId}
                    organizationId={props.organizationId}
                    reload={props.reload}
                />
            </CellControls>
        </Row>
    );
};
