import { DateInput } from 'common-front/src/designSystem/form/date/dateInput';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { DelegationProperty, Form, Tag } from 'common/src/generated/types';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import * as React from 'react';

interface IDelegationsMassEditFieldInputProps {
    forms: Array<Pick<Form, 'id' | 'name'>>;
    property: DelegationProperty;
    tags: Array<Pick<Tag, 'id' | 'name'>>;
    value: any;
}

export const DelegationsMassEditFieldInput = (props: IDelegationsMassEditFieldInputProps) => {
    switch (props.property) {
        case DelegationProperty.DeadlineDate:
            return <DateInput name="massEdit.value" />;
        case DelegationProperty.Form:
            return (
                <RichSelect isSearchVisible={true} multiple={false} name="massEdit.value">
                    {props.forms.map((form) => (
                        <option key={form.id} value={form.id}>
                            {form.name}
                        </option>
                    ))}
                </RichSelect>
            );
        case DelegationProperty.MaxResources:
            return <TextInput name="massEdit.value" parseInt={true} />;
        case DelegationProperty.Name:
            throw new Error('Should not happen');
        case DelegationProperty.Tag:
            return (
                <RichSelect isSearchVisible={true} multiple={true} name="massEdit.value">
                    {props.tags.map((tag) => (
                        <option key={tag.id} value={tag.id}>
                            {tag.name}
                        </option>
                    ))}
                </RichSelect>
            );
        default:
            return assertUnreachable(props.property);
    }
};
