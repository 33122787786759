import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    EventId,
    OrganizationId,
    PositionId,
    PositionsPositionFragment,
    PositionState,
    SegmentCustomFieldFragment,
    SegmentId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { LocaleFormats } from 'common/src/util/luxon';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import { PositionDefaultColumns } from 'common/src/vo/segments/positionsSegmentsService';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { PositionCreatedByCell } from './cells/positionCreatedByCell';
import { PositionsAcronymCell } from './cells/positionsAcronymCell';
import { PositionsCategoryCell } from './cells/positionsCategoryCell';
import { PositionsColorCell } from './cells/positionsColorCell';
import { PositionsConditionsCell } from './cells/positionsConditionsCell';
import { PositionsIconCell } from './cells/positionsIconCell';
import { PositionsNameCell } from './cells/positionsNameCell';
import { PositionsTagsCell } from './cells/positionsTagsCell';
import { PositionRowDropdown } from './positionRowDropdown';
import { PositionRowResources } from './positionRowResources';

interface IPositionRowProps {
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    eventId: EventId;
    organizationId: OrganizationId;
    position: PositionsPositionFragment;
    segmentId: SegmentId;
    state: ICheckboxState;

    reload(): void;
    reloadPosition(id: PositionId): void;
    toggleRow(key: string, newState: ICheckboxState): void;
}

export const PositionRow = (props: IPositionRowProps) => {
    const { history, translate } = useHeavent();
    const {
        event: {
            permissions: { isEventAdmin }
        },
        isPositionCategoryAdmin
    } = useEventContext();
    const dateTimeService = useService(DateTimeService);
    const fieldService = useService(FieldService);
    const slugToCustomField = React.useMemo(
        () => new Map(props.customFields.map((cf) => [cf.slug, cf])),
        [props.customFields]
    );
    const reloadPosition = React.useCallback(() => {
        props.reloadPosition(props.position.id);
    }, [props.position, props.reloadPosition]);
    const interval = React.useMemo(() => {
        const start = props.position.range.start!;
        const end = props.position.range.end!;

        if (start.startOf('day').equals(end.startOf('day'))) {
            return dateTimeService.toLocaleString(start, LocaleFormats.DateOnly.MonthShort);
        } else {
            return `${dateTimeService.toLocaleString(
                start,
                LocaleFormats.DateOnly.MonthShort
            )} → ${dateTimeService.toLocaleString(end, LocaleFormats.DateOnly.MonthShort)}`;
        }
    }, [props.position]);

    return (
        <Row
            css={{
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            {isEventAdmin && (
                <Cell justify="center" width={48}>
                    <Checkbox
                        state={props.state}
                        onClick={(newState, e) => {
                            e.nativeEvent.stopImmediatePropagation();
                            e.stopPropagation();

                            props.toggleRow(`p${props.position.id}`, newState);
                        }}
                    />
                </Cell>
            )}

            {props.columns.map((field) => {
                const customField = slugToCustomField.get(field.slug);

                return field.slug == PositionDefaultColumns.Id ? (
                    <Cell key={field.slug} width={100}>
                        {props.position.id}
                    </Cell>
                ) : field.slug === PositionDefaultColumns.Category ? (
                    <PositionsCategoryCell
                        key={field.slug}
                        positionId={props.position.id}
                        positionCategory={props.position.category}
                        reload={reloadPosition}
                    />
                ) : field.slug === PositionDefaultColumns.Name ? (
                    <PositionsNameCell
                        key={field.slug}
                        position={props.position}
                        reload={reloadPosition}
                    />
                ) : field.slug === PositionDefaultColumns.Slot ? (
                    <Cell key={field.slug}>
                        <Flex direction="column" width={1} css={{ overflow: 'hidden' }}>
                            <Box font="gray900 textSm medium">{interval}</Box>

                            <Box color="gray500">
                                {translate('creneau', props.position.numberOfSlots)}
                            </Box>
                        </Flex>
                    </Cell>
                ) : field.slug === PositionDefaultColumns.SlotName ? (
                    <Cell key={field.slug}>-</Cell>
                ) : field.slug === PositionDefaultColumns.Resources ? (
                    <Cell key={field.slug} width={256}>
                        <PositionRowResources
                            assignedResources={props.position.assignedResources}
                            resources={props.position.resources}
                        />
                    </Cell>
                ) : field.slug === PositionDefaultColumns.Tags ? (
                    <PositionsTagsCell
                        key={field.slug}
                        position={props.position}
                        reload={reloadPosition}
                    />
                ) : field.slug === PositionDefaultColumns.Conditions ? (
                    <PositionsConditionsCell key={field.slug} position={props.position} />
                ) : field.slug === PositionDefaultColumns.Address ? (
                    <Cell key={field.slug}>{props.position.address}</Cell>
                ) : field.slug === PositionDefaultColumns.Latitude ? (
                    <Cell key={field.slug}>{props.position.latitude}</Cell>
                ) : field.slug === PositionDefaultColumns.Longitude ? (
                    <Cell key={field.slug}>{props.position.longitude}</Cell>
                ) : field.slug === 'isVisible' ? (
                    <Cell key={field.slug}>
                        <Flex align="center" gap="3" width={1}>
                            {props.position.isVisible ? (
                                <>
                                    <Box>
                                        <I icon="square-check" />
                                    </Box>

                                    <Box>{translate('visible_27909')}</Box>
                                </>
                            ) : (
                                <>
                                    <Box>
                                        <I icon="square" />
                                    </Box>

                                    <Box>{translate('non_visible_30040')}</Box>
                                </>
                            )}
                        </Flex>
                    </Cell>
                ) : field.slug === PositionDefaultColumns.Acronym ? (
                    <PositionsAcronymCell
                        key={field.slug}
                        position={props.position}
                        reload={reloadPosition}
                    />
                ) : field.slug === PositionDefaultColumns.Color ? (
                    <PositionsColorCell
                        key={field.slug}
                        position={props.position}
                        reload={reloadPosition}
                    />
                ) : field.slug === PositionDefaultColumns.Icon ? (
                    <PositionsIconCell
                        key={field.slug}
                        position={props.position}
                        reload={reloadPosition}
                    />
                ) : field.slug === PositionDefaultColumns.State ? (
                    <Cell key={field.slug}>
                        {props.position.state === PositionState.Full ? (
                            <Badge color="success">{translate(props.position.state)}</Badge>
                        ) : (
                            <Badge color="warning">{translate(props.position.state)}</Badge>
                        )}
                    </Cell>
                ) : field.slug === PositionDefaultColumns.FillingRate ? (
                    <Cell key={field.slug}>{props.position.fillingRate}%</Cell>
                ) : field.slug === PositionDefaultColumns.CheckedInRate ? (
                    <Cell key={field.slug}>{props.position.checkedInRate || 0}%</Cell>
                ) : field.slug === PositionDefaultColumns.CreatedBy ? (
                    <PositionCreatedByCell key={field.slug} createdBy={props.position.createdBy} />
                ) : (
                    <Cell key={field.slug}>
                        {customField
                            ? fieldService.getValueString(customField, props.position, {
                                  fileReturnValue: 'name'
                              })
                            : ''}
                    </Cell>
                );
            })}

            {isPositionCategoryAdmin() && (
                <CellControls justify="center">
                    {isPositionCategoryAdmin(props.position.category.id) && (
                        <>
                            <Tooltip>
                                <Trigger>
                                    <Box>
                                        <I
                                            icon="pen"
                                            onClick={() => {
                                                history.push(
                                                    HeaventPaths.EDIT_POSITION(
                                                        props.organizationId,
                                                        props.eventId,
                                                        props.position.id
                                                    )
                                                );
                                            }}
                                        />
                                    </Box>
                                </Trigger>

                                <Content placement="top">{translate('_diter_62574')}</Content>
                            </Tooltip>

                            <PositionRowDropdown
                                position={props.position}
                                organizationId={props.organizationId}
                                eventId={props.eventId}
                                reload={props.reload}
                            />
                        </>
                    )}
                </CellControls>
            )}
        </Row>
    );
};
