import { UpdateCell } from 'common-front/src/components/updateCell';
import { DateInput } from 'common-front/src/designSystem/components/date/dateInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { AccreditationsAccreditationSlotFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { pick } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useAccreditationSlotUpdateMutation } from '../../../generated/graphqlHooks';

interface IAccreditationSlotDateCellProps {
    accreditationSlot: AccreditationsAccreditationSlotFragment;

    reload(): void;
}

export const AccreditationSlotDateCell = (props: IAccreditationSlotDateCellProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const { mutate } = useAccreditationSlotUpdateMutation();
    const date = React.useMemo(
        () =>
            props.accreditationSlot.date
                ? dateTimeService.toLocaleString(
                      props.accreditationSlot.date,
                      LocaleFormats.DateOnly.WeekdayLongMonthLong
                  )
                : '-',
        [props.accreditationSlot]
    );
    const initialValue = React.useMemo(
        () =>
            props.accreditationSlot.date?.isValid
                ? props.accreditationSlot.date
                : DateTime.invalid('Invalid'),
        [props.accreditationSlot]
    );

    return (
        <UpdateCell
            initialValue={initialValue}
            mutate={async (date) =>
                mutate({
                    eventId,
                    accreditationSlotId: props.accreditationSlot.id,
                    accreditationSlot: {
                        ...pick(props.accreditationSlot, ['id', 'name', 'maxResources']),
                        date
                    }
                })
            }
            reload={props.reload}
            renderInput={(value, setValue) => (
                <DateInput label={translate('Date')} value={value} onChange={setValue} />
            )}
        >
            <Box
                font="gray900 textSm medium"
                width={1}
                css={{
                    ellipsis: true,
                    textTransform: 'capitalize'
                }}
                title={date}
            >
                {date}
            </Box>
        </UpdateCell>
    );
};
