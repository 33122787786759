import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import {
    DelegationId,
    DelegationsDelegationFragment,
    DelegationsSortAttributes,
    EventId,
    OrganizationEventLevel,
    OrganizationId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { DelegationDefaultColumns } from 'common/src/vo/segments/delegationsSegmentsService';
import { compact } from 'lodash-es';
import * as React from 'react';
import {
    executeDelegationsDelegationQuery,
    useDelegationsQuery
} from '../../generated/graphqlHooks';
import { DelegationRow } from './delegationRow';

interface IDelegationsListProps {
    eventId: Emptyable<EventId>;
    level: OrganizationEventLevel;
    organizationId: OrganizationId;
}

export const DelegationsList = (props: IDelegationsListProps) => {
    const translate = useTranslate();
    const {
        columns,
        columnsSearch,
        limit,
        nameDebounced,
        offset,
        possibleColumns,
        predicates,
        reloadKey,
        selectAllState,
        setLimit,
        setOffset,
        setSort,
        setStates,
        sort,
        states,
        toggleRow,
        toggleSelectAll
    } = useSegmentsContext<DelegationId, DelegationsSortAttributes>();
    const { data, isLoading, reload } = useDelegationsQuery({
        organizationId: props.organizationId,
        level: props.level,
        eventId: props.eventId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        columnsSearch: isNonEmptyArray(columnsSearch) ? columnsSearch : null,
        predicates,
        limit,
        offset,
        sort
    });
    const selectedFields = React.useMemo(
        () => columns.flatMap((slug) => compact([possibleColumns.find((pc) => pc.slug === slug)])),
        [possibleColumns, columns]
    );
    const { numberOfPages, totalCount } = usePaginationInfos(data.organization?.delegations);
    const [delegations, setDelegations] = React.useState<DelegationsDelegationFragment[]>([]);
    const [idToLoading, setIdToLoading] = React.useState<Record<DelegationId, boolean>>({});
    const reloadDelegation = React.useCallback(
        async (id: DelegationId) => {
            setIdToLoading((ids) => ({ ...ids, [id]: true }));

            const {
                organization: { delegation }
            } = await executeDelegationsDelegationQuery(
                { organizationId: props.organizationId, id },
                await getToken()
            );

            setDelegations((ds) => ds.map((d) => (d.id === id ? delegation : d)));
            setIdToLoading((ids) => ({ ...ids, [id]: false }));
        },
        [setIdToLoading, setDelegations]
    );

    React.useEffect(() => {
        setDelegations(data.organization?.delegations.nodes ?? []);
    }, [data.organization]);

    React.useEffect(() => {
        if (reloadKey > 1) {
            reload();
        }
    }, [reloadKey]);

    React.useEffect(() => {
        setStates(
            Object.fromEntries(
                (data.organization?.delegations.nodes ?? []).map((delegation) => [
                    `d${delegation.id}`,
                    { id: delegation.id, state: 'unchecked' }
                ])
            )
        );
    }, [data.organization]);

    return (
        <Flex width={1} css={{ flex: '1', overflow: 'hidden' }}>
            <TableGrid
                headerRow={
                    <HeaderRow>
                        <HeaderCell justify="center" width={48}>
                            <Checkbox state={selectAllState} onClick={toggleSelectAll} />
                        </HeaderCell>

                        {selectedFields.map((field) =>
                            field.slug === DelegationDefaultColumns.Id ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    width={100}
                                    attribute={DelegationsSortAttributes.Id}
                                    sort={sort}
                                    setSort={setSort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === DelegationsSortAttributes.Name ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={DelegationsSortAttributes.Name}
                                    sort={sort}
                                    setSort={setSort}
                                >
                                    {translate('nom_de_la_d_l_g_28087')}
                                </HeaderCellSort>
                            ) : (
                                <HeaderCell key={field.slug}>
                                    <Box title={field.name} css={{ ellipsis: true }}>
                                        {field.name}
                                    </Box>
                                </HeaderCell>
                            )
                        )}

                        <HeaderCell width={100} />
                    </HeaderRow>
                }
                paginationRow={
                    <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                        <PaginationCell
                            limit={limit}
                            numberOfPages={numberOfPages}
                            offset={offset}
                            showLimits={true}
                            totalCount={totalCount}
                            setLimit={setLimit}
                            setOffset={setOffset}
                        />
                    </PaginationRow>
                }
            >
                {isLoading ? (
                    <>
                        <RowSkeleton />
                        <RowSkeleton />
                        <RowSkeleton />
                    </>
                ) : (
                    delegations.map((delegation) => {
                        if (idToLoading[delegation.id]) {
                            return <RowSkeleton key={delegation.id} />;
                        } else {
                            return (
                                <DelegationRow
                                    key={delegation.id}
                                    columns={selectedFields}
                                    delegation={delegation}
                                    eventId={props.eventId}
                                    organizationId={props.organizationId}
                                    state={states[`d${delegation.id}`]?.state ?? 'unchecked'}
                                    reload={reload}
                                    reloadDelegation={reloadDelegation}
                                    toggleRow={toggleRow}
                                />
                            );
                        }
                    })
                )}
            </TableGrid>
        </Flex>
    );
};
