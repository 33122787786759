import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import {
    AccreditationId,
    AccreditationsAccreditationSlotFragment,
    AccreditationsSlotId,
    AccreditationsSlotsSortAttributes
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { isNonEmptyString } from 'common/src/util/string';
import { AccreditationDefaultColumns } from 'common/src/vo/segments/accreditationsSegmentsService';
import { compact } from 'lodash-es';
import * as React from 'react';
import {
    executeAccreditationsAccreditationSlotQuery,
    useAccreditationsSlotsListQuery
} from '../../generated/graphqlHooks';
import { AccreditationSlotRow } from './accreditationSlotRow';

export const AccreditationsSlotsList = () => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const {
        columns,
        columnsSearch,
        limit,
        nameDebounced,
        offset,
        possibleColumns,
        predicates,
        reloadKey,
        setLimit,
        setOffset,
        setSort,
        sort
    } = useSegmentsContext<AccreditationId, AccreditationsSlotsSortAttributes>();
    const includes = React.useMemo(
        () => ({
            includeConditions: columns.includes(AccreditationDefaultColumns.Conditions),
            includeCreatedBy: columns.includes(AccreditationDefaultColumns.CreatedBy),
            includeTags: columns.includes(AccreditationDefaultColumns.Tags)
        }),
        [columns]
    );
    const { data, isLoading, reload } = useAccreditationsSlotsListQuery({
        ...includes,
        eventId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        columnsSearch: isNonEmptyArray(columnsSearch) ? columnsSearch : null,
        predicates,
        limit,
        offset,
        sort
    });
    const selectedFields = React.useMemo(
        () => columns.flatMap((slug) => compact([possibleColumns.find((pc) => pc.slug === slug)])),
        [possibleColumns, columns]
    );
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.accreditationsSlots);
    const [accreditationsSlots, setAccreditationsSlots] = React.useState<
        AccreditationsAccreditationSlotFragment[]
    >([]);
    const [idToLoading, setIdToLoading] = React.useState<Record<AccreditationsSlotId, boolean>>({});
    const reloadAccreditationSlot = React.useCallback(
        async (id: AccreditationsSlotId) => {
            setIdToLoading((ids) => ({ ...ids, [id]: true }));

            const {
                event: { accreditationSlot }
            } = await executeAccreditationsAccreditationSlotQuery(
                { ...includes, eventId, id },
                await getToken()
            );

            setAccreditationsSlots((ass) =>
                ass.map((as) => (as.id === id ? accreditationSlot : as))
            );
            setIdToLoading((ids) => ({ ...ids, [id]: false }));
        },
        [includes, setIdToLoading, setAccreditationsSlots]
    );

    React.useEffect(() => {
        setAccreditationsSlots(data.event?.accreditationsSlots.nodes ?? []);
    }, [data.event]);

    React.useEffect(() => {
        if (reloadKey > 1) {
            reload();
        }
    }, [reloadKey]);

    return (
        <Flex width={1} css={{ flex: '1', overflow: 'hidden' }}>
            <TableGrid
                headerRow={
                    <HeaderRow>
                        {selectedFields.map((field) =>
                            field.slug === AccreditationDefaultColumns.Id ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    width={100}
                                    attribute={AccreditationsSlotsSortAttributes.Id}
                                    sort={sort}
                                    setSort={setSort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === AccreditationDefaultColumns.Category ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={AccreditationsSlotsSortAttributes.Category}
                                    sort={sort}
                                    setSort={setSort}
                                >
                                    {translate('cat_gorie_00291')}
                                </HeaderCellSort>
                            ) : field.slug === AccreditationDefaultColumns.Name ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={AccreditationsSlotsSortAttributes.Accreditation}
                                    sort={sort}
                                    setSort={setSort}
                                >
                                    {translate('nom_de_l_accr_d_11908')}
                                </HeaderCellSort>
                            ) : field.slug === AccreditationDefaultColumns.SlotName ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={AccreditationsSlotsSortAttributes.Name}
                                    sort={sort}
                                    setSort={setSort}
                                >
                                    {translate('nom_du_cr_neau_54351')}
                                </HeaderCellSort>
                            ) : field.slug === AccreditationDefaultColumns.SlotDate ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={AccreditationsSlotsSortAttributes.Date}
                                    sort={sort}
                                    setSort={setSort}
                                >
                                    {translate('Date')}
                                </HeaderCellSort>
                            ) : field.slug === AccreditationDefaultColumns.Resources ? (
                                <HeaderCell key={field.slug} width={256}>
                                    {translate('membres_11310')}
                                </HeaderCell>
                            ) : field.slug === AccreditationDefaultColumns.Icon ? (
                                <HeaderCell key={field.slug} width={100}>
                                    {field.name}
                                </HeaderCell>
                            ) : field.slug === AccreditationDefaultColumns.Acronym ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={AccreditationsSlotsSortAttributes.Acronym}
                                    sort={sort}
                                    setSort={setSort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : (
                                <HeaderCell key={field.slug}>
                                    <Box title={field.name} css={{ ellipsis: true }}>
                                        {field.name}
                                    </Box>
                                </HeaderCell>
                            )
                        )}

                        <HeaderCell width={100} />
                    </HeaderRow>
                }
                paginationRow={
                    <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                        <PaginationCell
                            limit={limit}
                            numberOfPages={numberOfPages}
                            offset={offset}
                            showLimits={true}
                            totalCount={totalCount}
                            setLimit={setLimit}
                            setOffset={setOffset}
                        />
                    </PaginationRow>
                }
            >
                {isLoading ? (
                    <>
                        <RowSkeleton />
                        <RowSkeleton />
                        <RowSkeleton />
                    </>
                ) : (
                    accreditationsSlots.map((accreditationSlot) => {
                        if (idToLoading[accreditationSlot.id]) {
                            return <RowSkeleton key={accreditationSlot.id} />;
                        } else {
                            return (
                                <AccreditationSlotRow
                                    key={accreditationSlot.id}
                                    accreditationSlot={accreditationSlot}
                                    columns={selectedFields}
                                    event={data.event}
                                    reloadAccreditationSlot={reloadAccreditationSlot}
                                />
                            );
                        }
                    })
                )}
            </TableGrid>
        </Flex>
    );
};
