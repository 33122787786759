import { injectable } from 'inversify';
import { ALL_DELEGATIONSTATE, EventId, FilterType, OrganizationId } from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { Filter, PossibleColumn, SegmentService } from '../segment';

export enum DelegationDefaultColumns {
    AssignedResources = 'resources',
    CreatedBy = 'createdBy',
    DeadlineDate = 'deadlineDate',
    FillingRate = 'fillingRate',
    Form = 'form',
    Id = 'id',
    Leaders = 'leaders',
    MaxResources = 'maxResources',
    Name = 'name',
    State = 'delegationState',
    Tags = 'tags'
}

export enum DelegationAccreditationDefaultColumns {
    Category = 'category',
    FillingRate = 'fillingRate',
    HasLimit = 'hasLimit',
    MaxResources = 'maxResources',
    Name = 'name',
    WishedFillingRate = 'wishedFillingRate'
}

@injectable()
export class DelegationsSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getDelegationsFilters(organizationId: OrganizationId, eventId?: EventId): Filter[] {
        return [
            {
                color: 'warning',
                slug: 'delegationState',
                name: this.t('status_06428'),
                fieldType: FilterType.Select,
                values: ALL_DELEGATIONSTATE.map((state) => ({
                    id: state,
                    name: state
                })),
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: 'form',
                name: this.t('Form'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                organizationId,
                eventId
            },
            {
                color: 'warning',
                slug: 'deadlineDate',
                name: this.t('date_limite_d_i_79779'),
                fieldType: FilterType.Date
            },
            {
                color: 'warning',
                slug: 'tag',
                name: this.t('Tag'),
                fieldType: FilterType.Select,
                needLoading: true,
                organizationId
            },
            {
                color: 'warning',
                slug: 'resources',
                name: this.t('nombre_de_membr_08851'),
                fieldType: FilterType.Number
            },
            {
                color: 'warning',
                slug: 'fillingRate',
                name: this.t('taux_de_remplis_11913'),
                fieldType: FilterType.Number
            }
        ];
    }

    getDelegationsPossibleColumns(): PossibleColumn[] {
        return [
            {
                slug: DelegationDefaultColumns.Id,
                name: this.t('id_51738'),
                isSearchable: true
            },
            {
                slug: DelegationDefaultColumns.Name,
                name: this.t('nom_de_la_d_l_g_28087'),
                isSearchable: true
            },
            {
                slug: DelegationDefaultColumns.State,
                name: this.t('status_06428')
            },
            {
                slug: DelegationDefaultColumns.Leaders,
                name: this.t('responsables_84923')
            },
            {
                slug: DelegationDefaultColumns.Form,
                name: this.t('Form'),
                isSearchable: true
            },
            {
                slug: DelegationDefaultColumns.DeadlineDate,
                name: this.t('date_limite_d_i_79779')
            },
            {
                slug: DelegationDefaultColumns.MaxResources,
                name: this.t('MaxResources')
            },
            {
                slug: DelegationDefaultColumns.AssignedResources,
                name: this.t('membres_11310')
            },
            {
                slug: DelegationDefaultColumns.Tags,
                name: this.t('tags_79499'),
                isSearchable: true
            },
            {
                slug: DelegationDefaultColumns.FillingRate,
                name: this.t('taux_de_remplis_11913')
            },
            {
                slug: DelegationDefaultColumns.CreatedBy,
                name: this.t('cr_e_par_30622'),
                isSearchable: true
            }
        ];
    }
}
