import { pick } from 'lodash-es';
import * as React from 'react';
import { Flex, IFlexProps } from '../flex';
import { CSS } from '../stitches';

interface IHeaderCellProps
    extends Pick<IFlexProps, 'direction' | 'align' | 'justify' | 'wrap' | 'gap'> {
    css?: CSS;
    children?: React.ReactNode;
    width?: number;

    onClick?(): void;
}

export const HeaderCell = (props: IHeaderCellProps) => (
    <Flex
        className="table-header-cell"
        align="center"
        height={1}
        css={{
            color: '$gray500',
            flex: props.width ? `0 ${props.width}px` : '1',
            overflow: 'hidden',
            px: '$3',
            ...(props.css as any)
        }}
        onClick={() => {
            props.onClick?.();
        }}
        {...pick(props, ['direction', 'align', 'justify', 'wrap', 'gap'])}
    >
        {props.children}
    </Flex>
);
