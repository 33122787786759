import { compact, sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const getPage1Background = (bracelet: string, isVolunteer: boolean) => {
    if (isVolunteer) {
        return 'https://assets.recrewteer.com/badges/rose/v3/benevole/benevole_page1.jpg';
    } else if (bracelet === 'Direction' || bracelet === 'All Access') {
        return 'https://assets.recrewteer.com/badges/rose/v3/direction/direction_page1.jpg';
    } else if (bracelet === 'Backstage') {
        return 'https://assets.recrewteer.com/badges/rose/v3/backstage/backstage_page1.jpg';
    } else if (bracelet === 'Stand & Food & Bar') {
        return 'https://assets.recrewteer.com/badges/rose/v3/stand/stand_page1.jpg';
    } else if (bracelet === 'Agora' || bracelet === 'Agora F') {
        return 'https://assets.recrewteer.com/badges/rose/v3/agora/agora_page1.jpg';
    } else {
        return '';
    }
};

const getPage2Background = (bracelet: string, isVolunteer: boolean) => {
    if (isVolunteer) {
        return 'https://assets.recrewteer.com/badges/rose/v3/benevole/benevole_page2.jpg';
    } else if (bracelet === 'Direction' || bracelet === 'All Access') {
        return 'https://assets.recrewteer.com/badges/rose/v3/direction/direction_page2.jpg';
    } else if (bracelet === 'Backstage') {
        return 'https://assets.recrewteer.com/badges/rose/v3/backstage/backstage_page2.jpg';
    } else if (bracelet === 'Agora' || bracelet === 'Agora F') {
        return 'https://assets.recrewteer.com/badges/rose/v3/agora/agora_page2.jpg';
    } else {
        return '';
    }
};

const getPage3Background = (bracelet: string, isVolunteer: boolean) => {
    if (isVolunteer) {
        return 'https://assets.recrewteer.com/badges/rose/v3/benevole/benevole_page3.jpg';
    } else if (bracelet === 'Direction' || bracelet === 'All Access') {
        return 'https://assets.recrewteer.com/badges/rose/v3/direction/direction_page3.jpg';
    } else if (bracelet === 'Backstage') {
        return 'https://assets.recrewteer.com/badges/rose/v3/backstage/backstage_page3.jpg';
    } else if (bracelet === 'Stand & Food & Bar') {
        return 'https://assets.recrewteer.com/badges/rose/v3/stand/stand_page3.jpg';
    } else if (bracelet === 'Agora' || bracelet === 'Agora F') {
        return 'https://assets.recrewteer.com/badges/rose/v3/agora/agora_page3.jpg';
    } else {
        return '';
    }
};

interface IVolunteerBraceletProps {
    left: number;
    top: number;
}

const VolunteerBracelet = (props: IVolunteerBraceletProps) => (
    <Box
        css={{
            left: `${props.left}px`,
            position: 'absolute',
            top: `${props.top}px`
        }}
    >
        <img
            src="https://assets.recrewteer.com/badges/rose/v3/benevole/bracelet_asset.png"
            width="38px"
            height="19px"
        />
    </Box>
);

interface IBadgeRoseProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeRose = (props: IBadgeRoseProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const isVolunteer = props.event.id === 3571;
    const bracelet =
        vr.accreditationsUsersInfos.find((aui) => aui.accreditationCategory.id === 1602)
            ?.accreditation.name || '';
    const showCatering =
        bracelet === 'Direction' || bracelet === 'All Access' || bracelet === 'Backstage';
    const accreditationDays = sortBy(
        uniqBy(
            vr.accreditationsUsersInfos.flatMap((aui) => {
                if (aui.accreditationSlot.date) {
                    return [aui.accreditationSlot.date!.startOf('day')];
                } else {
                    return [];
                }
            }),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    );
    const volunteerDays = sortBy(
        uniqBy(
            vr.positionsSlotsUsersInfos.map((psui) =>
                psui.positionSlot.range.start!.startOf('day')
            ),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    );
    const days = (isVolunteer ? volunteerDays : accreditationDays).filter((d) =>
        [29, 30, 31, 1].includes(d.day)
    );
    const cateringMidi = vr.accreditationsUsersInfos.flatMap((aui) =>
        aui.accreditation.id === 3229 ? [aui.accreditationSlot.name] : []
    );
    const cateringSoir = vr.accreditationsUsersInfos.flatMap((aui) =>
        aui.accreditation.id === 3230 ? [aui.accreditationSlot.name] : []
    );
    const _catering29 = compact([
        cateringMidi.includes('29') ? 'Midi' : null,
        cateringSoir.includes('29') ? 'Soir' : null
    ]).join(' & ');
    const _catering30 = compact([
        cateringMidi.includes('30') ? 'Midi' : null,
        cateringSoir.includes('30') ? 'Soir' : null
    ]).join(' & ');
    const _catering31 = compact([
        cateringMidi.includes('31') ? 'Midi' : null,
        cateringSoir.includes('31') ? 'Soir' : null
    ]).join(' & ');
    const _catering1 = compact([
        cateringMidi.includes('1') ? 'Midi' : null,
        cateringSoir.includes('1') ? 'Soir' : null
    ]).join(' & ');
    const volunteerAgoraF = vr.accreditationsUsersInfos.flatMap((aui) =>
        aui.accreditation.id === 3436 ? [aui.accreditationSlot.name] : []
    );
    const volunteerAllAccess = vr.accreditationsUsersInfos.flatMap((aui) =>
        aui.accreditation.id === 3439 ? [aui.accreditationSlot.name] : []
    );
    const volunteerBackstage = vr.accreditationsUsersInfos.flatMap((aui) =>
        aui.accreditation.id === 3437 ? [aui.accreditationSlot.name] : []
    );
    const volunteerVIP = vr.accreditationsUsersInfos.flatMap((aui) =>
        aui.accreditation.id === 3438 ? [aui.accreditationSlot.name] : []
    );

    return (
        <>
            <Flex
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
                css={{
                    background: `url(${getPage1Background(bracelet, isVolunteer)}) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$neueMontreal',
                    position: 'relative'
                }}
            >
                {!isVolunteer && (
                    <Box
                        width={292}
                        textAlign="center"
                        css={{
                            color: '#ee78ac',
                            fontFamily: '$helveticaBlackCondensed',
                            fontSize: '22px',
                            fontWeight: '900',
                            left: '251px',
                            position: 'absolute',
                            textTransform: 'uppercase',
                            top: '130px'
                        }}
                    >
                        {ui.population}
                    </Box>
                )}

                {!isVolunteer && (
                    <Box
                        width={244}
                        textAlign="center"
                        css={{
                            color: 'black',
                            fontSize: '18px',
                            fontWeight: '700',
                            left: '299px',
                            position: 'absolute',
                            top: '167px'
                        }}
                    >
                        Bracelet {bracelet}
                    </Box>
                )}

                <Box
                    css={{
                        color: 'black',
                        fontSize: '24px',
                        fontWeight: '700',
                        lineHeight: '28px',
                        left: '95px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '237px'
                    }}
                >
                    {ui.firstName}
                    <br />
                    {ui.lastName}
                </Box>

                {!isVolunteer && (
                    <Box
                        css={{
                            color: '#ee78ac',
                            fontSize: '18px',
                            fontWeight: '700',
                            left: '95px',
                            position: 'absolute',
                            top: '295px'
                        }}
                    >
                        {vr.delegation?.name}
                    </Box>
                )}

                <Box
                    height={90}
                    width={90}
                    css={{
                        position: 'absolute',
                        right: '95px',
                        top: '232px'
                    }}
                >
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=90&width=90`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>

                <Flex
                    css={{
                        gap: '6px',
                        left: '95px',
                        position: 'absolute',
                        top: '430px'
                    }}
                >
                    {days.map((day, index) => (
                        <Flex
                            key={index}
                            direction="column"
                            align="center"
                            justify="center"
                            css={{
                                border: '1px solid black',
                                borderRadius: '4px',
                                padding: '4px 6px'
                            }}
                        >
                            <Box
                                css={{
                                    color: 'black',
                                    fontFamily: 'Helvetica',
                                    fontSize: '16px',
                                    fontWeight: '700'
                                }}
                            >
                                {day.toFormat('dd')}
                            </Box>

                            <Box
                                css={{
                                    color: 'black',
                                    fontSize: '10px',
                                    fontWeight: '500',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {day.toFormat('MMMM', { locale: 'fr' })}
                            </Box>
                        </Flex>
                    ))}
                </Flex>

                {volunteerAgoraF.includes('29') && <VolunteerBracelet left={289} top={803} />}
                {volunteerAgoraF.includes('30') && <VolunteerBracelet left={402} top={803} />}
                {volunteerAgoraF.includes('31') && <VolunteerBracelet left={515} top={803} />}
                {volunteerAgoraF.includes('1') && <VolunteerBracelet left={628} top={803} />}

                {volunteerAllAccess.includes('29') && <VolunteerBracelet left={289} top={839} />}
                {volunteerAllAccess.includes('30') && <VolunteerBracelet left={402} top={839} />}
                {volunteerAllAccess.includes('31') && <VolunteerBracelet left={515} top={839} />}
                {volunteerAllAccess.includes('1') && <VolunteerBracelet left={628} top={839} />}

                {volunteerBackstage.includes('29') && <VolunteerBracelet left={289} top={875} />}
                {volunteerBackstage.includes('30') && <VolunteerBracelet left={402} top={875} />}
                {volunteerBackstage.includes('31') && <VolunteerBracelet left={515} top={875} />}
                {volunteerBackstage.includes('1') && <VolunteerBracelet left={628} top={875} />}

                {volunteerVIP.includes('29') && <VolunteerBracelet left={289} top={911} />}
                {volunteerVIP.includes('30') && <VolunteerBracelet left={402} top={911} />}
                {volunteerVIP.includes('31') && <VolunteerBracelet left={515} top={911} />}
                {volunteerVIP.includes('1') && <VolunteerBracelet left={628} top={911} />}
            </Flex>

            {bracelet !== 'Stand & Food & Bar' && (
                <Flex
                    height={A4_SIZES['96dpi'].height}
                    width={A4_SIZES['96dpi'].width}
                    css={{
                        background: `url(${getPage2Background(bracelet, isVolunteer)}) no-repeat`,
                        backgroundSize: 'cover',
                        fontFamily: '$neueMontreal',
                        pageBreakBefore: 'always',
                        position: 'relative'
                    }}
                >
                    {showCatering && (
                        <Flex
                            direction="column"
                            css={{
                                fontWeight: '700',
                                left: '95px',
                                lineHeight: '20px',
                                position: 'absolute',
                                top: '598px'
                            }}
                        >
                            {isNonEmptyString(_catering29) && <Box>29 août : {_catering29}</Box>}

                            {isNonEmptyString(_catering30) && <Box>30 août : {_catering30}</Box>}

                            {isNonEmptyString(_catering31) && <Box>31 août : {_catering31}</Box>}

                            {isNonEmptyString(_catering1) && <Box>1 septembre : {_catering1}</Box>}
                        </Flex>
                    )}
                </Flex>
            )}

            <Flex
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
                css={{
                    background: `url(${getPage3Background(bracelet, isVolunteer)}) no-repeat`,
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
            />

            {!isVolunteer && bracelet !== 'Agora' && (
                <Flex
                    height={A4_SIZES['96dpi'].height}
                    width={A4_SIZES['96dpi'].width}
                    css={{
                        background:
                            'url(https://assets.recrewteer.com/badges/rose/v3/page4.jpg) no-repeat',
                        backgroundSize: 'cover',
                        pageBreakBefore: 'always'
                    }}
                />
            )}
        </>
    );
};
