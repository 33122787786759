// /!\ THIS FILE IS AUTOMATICALLY GENERATED, DO NOT EDIT /!\
/* tslint:disable */
import { executeQuery } from 'common-front/src/components/graphql/graphql';
import {
    IUseMutationOptions,
    IUseMutationReturns,
    IUseQueryReturns
} from 'common/src/util/graphql/types';
import { useMutation, useQuery } from 'common/src/util/dependencies/dependencies';

import AccreditationAvailableVolunteersRegistrations from '../accreditations/accredit/accreditationAvailableVolunteersRegistrations.graphql';
import {
    AccreditationAvailableVolunteersRegistrationsQuery,
    AccreditationAvailableVolunteersRegistrationsQueryVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsAccredit from '../accreditations/accredit/volunteersRegistrationsAccredit.graphql';
import {
    VolunteersRegistrationsAccreditMutation,
    VolunteersRegistrationsAccreditMutationVariables
} from 'common/src/generated/types';
import AccreditationDelete from '../accreditations/accreditationDelete.graphql';
import {
    AccreditationDeleteMutation,
    AccreditationDeleteMutationVariables
} from 'common/src/generated/types';
import AccreditationCategoryCreate from '../accreditations/create/accreditationCategoryCreate.graphql';
import {
    AccreditationCategoryCreateMutation,
    AccreditationCategoryCreateMutationVariables
} from 'common/src/generated/types';
import AccreditationCreate from '../accreditations/create/accreditationCreate.graphql';
import {
    AccreditationCreateMutation,
    AccreditationCreateMutationVariables
} from 'common/src/generated/types';
import AccreditationEventInfos from '../accreditations/create/accreditationEventInfos.graphql';
import {
    AccreditationEventInfosQuery,
    AccreditationEventInfosQueryVariables
} from 'common/src/generated/types';
import AccreditationToEdit from '../accreditations/create/accreditationToEdit.graphql';
import {
    AccreditationToEditQuery,
    AccreditationToEditQueryVariables
} from 'common/src/generated/types';
import AccreditationUpdate from '../accreditations/create/accreditationUpdate.graphql';
import {
    AccreditationUpdateMutation,
    AccreditationUpdateMutationVariables
} from 'common/src/generated/types';
import AccreditationCategoryDelete from '../accreditations/list/accreditationCategoryDelete.graphql';
import {
    AccreditationCategoryDeleteMutation,
    AccreditationCategoryDeleteMutationVariables
} from 'common/src/generated/types';
import AccreditationUpdateField from '../accreditations/list/accreditationUpdateField.graphql';
import {
    AccreditationUpdateFieldMutation,
    AccreditationUpdateFieldMutationVariables
} from 'common/src/generated/types';
import Accreditations from '../accreditations/list/accreditations.graphql';
import { AccreditationsQuery, AccreditationsQueryVariables } from 'common/src/generated/types';
import AccreditationsAccreditation from '../accreditations/list/accreditationsAccreditation.graphql';
import {
    AccreditationsAccreditationQuery,
    AccreditationsAccreditationQueryVariables
} from 'common/src/generated/types';
import AccreditationsCategories from '../accreditations/list/accreditationsCategories.graphql';
import {
    AccreditationsCategoriesQuery,
    AccreditationsCategoriesQueryVariables
} from 'common/src/generated/types';
import AccreditationsMassDelete from '../accreditations/list/accreditationsMassDelete.graphql';
import {
    AccreditationsMassDeleteMutation,
    AccreditationsMassDeleteMutationVariables
} from 'common/src/generated/types';
import AccreditationsSegments from '../accreditations/list/accreditationsSegments.graphql';
import {
    AccreditationsSegmentsQuery,
    AccreditationsSegmentsQueryVariables
} from 'common/src/generated/types';
import AccreditationMap from '../accreditations/map/accreditationMap.graphql';
import { AccreditationMapQuery, AccreditationMapQueryVariables } from 'common/src/generated/types';
import Accreditation from '../accreditations/show/accreditation.graphql';
import { AccreditationQuery, AccreditationQueryVariables } from 'common/src/generated/types';
import AccreditationSlots from '../accreditations/show/accreditationSlots/accreditationSlots.graphql';
import {
    AccreditationSlotsQuery,
    AccreditationSlotsQueryVariables
} from 'common/src/generated/types';
import AccreditationUsers from '../accreditations/show/accreditationUsers/accreditationUsers.graphql';
import {
    AccreditationUsersQuery,
    AccreditationUsersQueryVariables
} from 'common/src/generated/types';
import AccreditInfos from '../accreditationsAssignments/accredit/accreditInfos.graphql';
import { AccreditInfosQuery, AccreditInfosQueryVariables } from 'common/src/generated/types';
import AccreditSlots from '../accreditationsAssignments/accredit/accreditSlots.graphql';
import { AccreditSlotsQuery, AccreditSlotsQueryVariables } from 'common/src/generated/types';
import AccreditationsAssignmentsEmails from '../accreditationsAssignments/list/accreditationsAssignmentsEmails.graphql';
import {
    AccreditationsAssignmentsEmailsQuery,
    AccreditationsAssignmentsEmailsQueryVariables
} from 'common/src/generated/types';
import AccreditationsAssignmentsKpis from '../accreditationsAssignments/list/accreditationsAssignmentsKpis.graphql';
import {
    AccreditationsAssignmentsKpisQuery,
    AccreditationsAssignmentsKpisQueryVariables
} from 'common/src/generated/types';
import AccreditationsEmailsEstimate from '../accreditationsAssignments/list/accreditationsEmailsEstimate.graphql';
import {
    AccreditationsEmailsEstimateMutation,
    AccreditationsEmailsEstimateMutationVariables
} from 'common/src/generated/types';
import SendAccreditationsEmails from '../accreditationsAssignments/list/sendAccreditationsEmails.graphql';
import {
    SendAccreditationsEmailsMutation,
    SendAccreditationsEmailsMutationVariables
} from 'common/src/generated/types';
import AccreditationCategoryUpdate from '../accreditationsCategories/create/accreditationCategoryUpdate.graphql';
import {
    AccreditationCategoryUpdateMutation,
    AccreditationCategoryUpdateMutationVariables
} from 'common/src/generated/types';
import AccreditationSlotUpdate from '../accreditationsSlots/accreditaitonSlotUpdate.graphql';
import {
    AccreditationSlotUpdateMutation,
    AccreditationSlotUpdateMutationVariables
} from 'common/src/generated/types';
import AccreditationsAccreditationSlot from '../accreditationsSlots/list/accreditationsAccreditationSlot.graphql';
import {
    AccreditationsAccreditationSlotQuery,
    AccreditationsAccreditationSlotQueryVariables
} from 'common/src/generated/types';
import AccreditationsSlotsList from '../accreditationsSlots/list/accreditationsSlotsList.graphql';
import {
    AccreditationsSlotsListQuery,
    AccreditationsSlotsListQueryVariables
} from 'common/src/generated/types';
import EventKey from '../api/eventKey.graphql';
import { EventKeyQuery, EventKeyQueryVariables } from 'common/src/generated/types';
import WeezeventConnectionInfosUpsert from '../api/integration/weezevent/connection/weezeventConnectionsInfosUpsert.graphql';
import {
    WeezeventConnectionInfosUpsertMutation,
    WeezeventConnectionInfosUpsertMutationVariables
} from 'common/src/generated/types';
import WeezeventEvents from '../api/integration/weezevent/connection/weezeventEvents.graphql';
import { WeezeventEventsQuery, WeezeventEventsQueryVariables } from 'common/src/generated/types';
import WeezeventTickets from '../api/integration/weezevent/connection/weezeventTickets.graphql';
import { WeezeventTicketsQuery, WeezeventTicketsQueryVariables } from 'common/src/generated/types';
import CreateWeezeventRuleInfos from '../api/integration/weezevent/rules/createWeezeventRuleInfos.graphql';
import {
    CreateWeezeventRuleInfosQuery,
    CreateWeezeventRuleInfosQueryVariables
} from 'common/src/generated/types';
import WeezeventRuleCreate from '../api/integration/weezevent/rules/weezeventRuleCreate.graphql';
import {
    WeezeventRuleCreateMutation,
    WeezeventRuleCreateMutationVariables
} from 'common/src/generated/types';
import WeezeventRuleDelete from '../api/integration/weezevent/rules/weezeventRuleDelete.graphql';
import {
    WeezeventRuleDeleteMutation,
    WeezeventRuleDeleteMutationVariables
} from 'common/src/generated/types';
import WeezeventRuleToEdit from '../api/integration/weezevent/rules/weezeventRuleToEdit.graphql';
import {
    WeezeventRuleToEditQuery,
    WeezeventRuleToEditQueryVariables
} from 'common/src/generated/types';
import WeezeventRuleUpdate from '../api/integration/weezevent/rules/weezeventRuleUpdate.graphql';
import {
    WeezeventRuleUpdateMutation,
    WeezeventRuleUpdateMutationVariables
} from 'common/src/generated/types';
import WeezeventConnectionInfosDelete from '../api/integration/weezevent/weezeventConnectionInfosDelete.graphql';
import {
    WeezeventConnectionInfosDeleteMutation,
    WeezeventConnectionInfosDeleteMutationVariables
} from 'common/src/generated/types';
import WeezeventRules from '../api/integration/weezevent/weezeventRules.graphql';
import { WeezeventRulesQuery, WeezeventRulesQueryVariables } from 'common/src/generated/types';
import WeezeventVolunteersSynchronize from '../api/integration/weezevent/weezeventVolunteersSynchronize.graphql';
import {
    WeezeventVolunteersSynchronizeMutation,
    WeezeventVolunteersSynchronizeMutationVariables
} from 'common/src/generated/types';
import WeezeventConnectionInfos from '../api/integration/weezeventConnectionInfos.graphql';
import {
    WeezeventConnectionInfosQuery,
    WeezeventConnectionInfosQueryVariables
} from 'common/src/generated/types';
import AssignmentInfos from '../assignments/assign/assignmentInfos.graphql';
import { AssignmentInfosQuery, AssignmentInfosQueryVariables } from 'common/src/generated/types';
import AssignmentSlots from '../assignments/assign/assignmentSlots.graphql';
import { AssignmentSlotsQuery, AssignmentSlotsQueryVariables } from 'common/src/generated/types';
import VolunteerRegistrationAssign from '../assignments/assign/volunteerRegistrationAssign.graphql';
import {
    VolunteerRegistrationAssignMutation,
    VolunteerRegistrationAssignMutationVariables
} from 'common/src/generated/types';
import VolunteerRegistrationAssignmentRefuse from '../assignments/assign/volunteerRegistrationAssignmentRefuse.graphql';
import {
    VolunteerRegistrationAssignmentRefuseMutation,
    VolunteerRegistrationAssignmentRefuseMutationVariables
} from 'common/src/generated/types';
import VolunteerRegistrationPreAssign from '../assignments/assign/volunteerRegistrationPreAssign.graphql';
import {
    VolunteerRegistrationPreAssignMutation,
    VolunteerRegistrationPreAssignMutationVariables
} from 'common/src/generated/types';
import AssignmentsEmails from '../assignments/list/assignmentsEmails.graphql';
import {
    AssignmentsEmailsQuery,
    AssignmentsEmailsQueryVariables
} from 'common/src/generated/types';
import AssignmentsEmailsEstimate from '../assignments/list/assignmentsEmailsEstimate.graphql';
import {
    AssignmentsEmailsEstimateMutation,
    AssignmentsEmailsEstimateMutationVariables
} from 'common/src/generated/types';
import AssignmentsKpis from '../assignments/list/assignmentsKpis.graphql';
import { AssignmentsKpisQuery, AssignmentsKpisQueryVariables } from 'common/src/generated/types';
import PreAssignmentsKpis from '../assignments/list/preAssignmentsKpis.graphql';
import {
    PreAssignmentsKpisQuery,
    PreAssignmentsKpisQueryVariables
} from 'common/src/generated/types';
import SendAssignmentsEmails from '../assignments/list/sendAssignmentsEmails.graphql';
import {
    SendAssignmentsEmailsMutation,
    SendAssignmentsEmailsMutationVariables
} from 'common/src/generated/types';
import SendEmailInfos from '../assignments/list/sendEmailInfos.graphql';
import { SendEmailInfosQuery, SendEmailInfosQueryVariables } from 'common/src/generated/types';
import TeamAssign from '../assignments/teamAssignment/teamAssign.graphql';
import { TeamAssignMutation, TeamAssignMutationVariables } from 'common/src/generated/types';
import TeamAssignmentInfos from '../assignments/teamAssignment/teamAssignmentInfos.graphql';
import {
    TeamAssignmentInfosQuery,
    TeamAssignmentInfosQueryVariables
} from 'common/src/generated/types';
import TeamAssignmentRefuse from '../assignments/teamAssignment/teamAssignmentRefuse.graphql';
import {
    TeamAssignmentRefuseMutation,
    TeamAssignmentRefuseMutationVariables
} from 'common/src/generated/types';
import TeamAssignmentWaitingTeams from '../assignments/teamAssignment/teamAssignmentWaitingTeams.graphql';
import {
    TeamAssignmentWaitingTeamsQuery,
    TeamAssignmentWaitingTeamsQueryVariables
} from 'common/src/generated/types';
import Tokens from '../auth/tokens.graphql';
import { TokensQuery, TokensQueryVariables } from 'common/src/generated/types';
import BackofficeOrganization from '../backoffice/backofficeOrganization.graphql';
import {
    BackofficeOrganizationQuery,
    BackofficeOrganizationQueryVariables
} from 'common/src/generated/types';
import BackofficeOrganizationUpdate from '../backoffice/backofficeOrganizationUpdate.graphql';
import {
    BackofficeOrganizationUpdateMutation,
    BackofficeOrganizationUpdateMutationVariables
} from 'common/src/generated/types';
import BackofficeOrganizationUserAdd from '../backoffice/backofficeOrganizationUserAdd.graphql';
import {
    BackofficeOrganizationUserAddMutation,
    BackofficeOrganizationUserAddMutationVariables
} from 'common/src/generated/types';
import BackofficeOrganizationUserRemove from '../backoffice/backofficeOrganizationUserRemove.graphql';
import {
    BackofficeOrganizationUserRemoveMutation,
    BackofficeOrganizationUserRemoveMutationVariables
} from 'common/src/generated/types';
import BackofficeOrganizations from '../backoffice/backofficeOrganizations.graphql';
import {
    BackofficeOrganizationsQuery,
    BackofficeOrganizationsQueryVariables
} from 'common/src/generated/types';
import BackofficeInvoiceMarkAsPaid from '../backoffice/invoices/backofficeInvoiceMarkAsPaid.graphql';
import {
    BackofficeInvoiceMarkAsPaidMutation,
    BackofficeInvoiceMarkAsPaidMutationVariables
} from 'common/src/generated/types';
import BackofficeInvoices from '../backoffice/invoices/backofficeInvoices.graphql';
import {
    BackofficeInvoicesQuery,
    BackofficeInvoicesQueryVariables
} from 'common/src/generated/types';
import QontoTransactions from '../backoffice/invoices/qontoTransactions.graphql';
import {
    QontoTransactionsQuery,
    QontoTransactionsQueryVariables
} from 'common/src/generated/types';
import CampaignCreate from '../campaigns/create/campaignCreate.graphql';
import {
    CampaignCreateMutation,
    CampaignCreateMutationVariables
} from 'common/src/generated/types';
import CampaignEstimate from '../campaigns/create/campaignEstimate.graphql';
import {
    CampaignEstimateMutation,
    CampaignEstimateMutationVariables
} from 'common/src/generated/types';
import CampaignOrganizationToEdit from '../campaigns/create/campaignOrganizationToEdit.graphql';
import {
    CampaignOrganizationToEditQuery,
    CampaignOrganizationToEditQueryVariables
} from 'common/src/generated/types';
import CampaignToEdit from '../campaigns/create/campaignToEdit.graphql';
import { CampaignToEditQuery, CampaignToEditQueryVariables } from 'common/src/generated/types';
import CampaignUpdate from '../campaigns/create/campaignUpdate.graphql';
import {
    CampaignUpdateMutation,
    CampaignUpdateMutationVariables
} from 'common/src/generated/types';
import CreateCampaignInfos from '../campaigns/create/createCampaignInfos.graphql';
import {
    CreateCampaignInfosQuery,
    CreateCampaignInfosQueryVariables
} from 'common/src/generated/types';
import CreateOrganizationCampaignInfos from '../campaigns/create/createOrganizationCampaignInfos.graphql';
import {
    CreateOrganizationCampaignInfosQuery,
    CreateOrganizationCampaignInfosQueryVariables
} from 'common/src/generated/types';
import CampaignEventAccreditationsRecipients from '../campaigns/create/recipients/campaignEventAccreditationsRecipients.graphql';
import {
    CampaignEventAccreditationsRecipientsQuery,
    CampaignEventAccreditationsRecipientsQueryVariables
} from 'common/src/generated/types';
import CampaignEventPositionsRecipients from '../campaigns/create/recipients/campaignEventPositionsRecipients.graphql';
import {
    CampaignEventPositionsRecipientsQuery,
    CampaignEventPositionsRecipientsQueryVariables
} from 'common/src/generated/types';
import CampaignEventSegmentsRecipients from '../campaigns/create/recipients/campaignEventSegmentsRecipients.graphql';
import {
    CampaignEventSegmentsRecipientsQuery,
    CampaignEventSegmentsRecipientsQueryVariables
} from 'common/src/generated/types';
import CampaignEventUsersRecipients from '../campaigns/create/recipients/campaignEventUsersRecipients.graphql';
import {
    CampaignEventUsersRecipientsQuery,
    CampaignEventUsersRecipientsQueryVariables
} from 'common/src/generated/types';
import CampaignOrganizationSegmentsRecipients from '../campaigns/create/recipients/campaignOrganizationSegmentsRecipients.graphql';
import {
    CampaignOrganizationSegmentsRecipientsQuery,
    CampaignOrganizationSegmentsRecipientsQueryVariables
} from 'common/src/generated/types';
import CampaignOrganizationUsersRecipients from '../campaigns/create/recipients/campaignOrganizationUsersRecipients.graphql';
import {
    CampaignOrganizationUsersRecipientsQuery,
    CampaignOrganizationUsersRecipientsQueryVariables
} from 'common/src/generated/types';
import CampaignDelete from '../campaigns/list/campaignDelete.graphql';
import {
    CampaignDeleteMutation,
    CampaignDeleteMutationVariables
} from 'common/src/generated/types';
import CampaignDuplicate from '../campaigns/list/campaignDuplicate.graphql';
import {
    CampaignDuplicateMutation,
    CampaignDuplicateMutationVariables
} from 'common/src/generated/types';
import CampaignSend from '../campaigns/list/campaignSend.graphql';
import { CampaignSendMutation, CampaignSendMutationVariables } from 'common/src/generated/types';
import Campaigns from '../campaigns/list/campaigns.graphql';
import { CampaignsQuery, CampaignsQueryVariables } from 'common/src/generated/types';
import Campaign from '../campaigns/show/campaign.graphql';
import { CampaignQuery, CampaignQueryVariables } from 'common/src/generated/types';
import CampaignEmails from '../campaigns/show/campaignEmails.graphql';
import { CampaignEmailsQuery, CampaignEmailsQueryVariables } from 'common/src/generated/types';
import CampaignTextMessages from '../campaigns/show/campaignTextMessages.graphql';
import {
    CampaignTextMessagesQuery,
    CampaignTextMessagesQueryVariables
} from 'common/src/generated/types';
import TextMessageReplies from '../campaigns/show/textMessageReplies/textMessageReplies.graphql';
import {
    TextMessageRepliesQuery,
    TextMessageRepliesQueryVariables
} from 'common/src/generated/types';
import TextMessageSend from '../campaigns/show/textMessageReplies/textMessageSend.graphql';
import {
    TextMessageSendMutation,
    TextMessageSendMutationVariables
} from 'common/src/generated/types';
import TextMessageRepliesRead from '../campaigns/show/textMessageRepliesRead.graphql';
import {
    TextMessageRepliesReadMutation,
    TextMessageRepliesReadMutationVariables
} from 'common/src/generated/types';
import CampaignsSegments from '../community/campaigns/list/campaignsSegments.graphql';
import {
    CampaignsSegmentsQuery,
    CampaignsSegmentsQueryVariables
} from 'common/src/generated/types';
import CommunityCampaigns from '../community/campaigns/list/communityCampaigns.graphql';
import {
    CommunityCampaignsQuery,
    CommunityCampaignsQueryVariables
} from 'common/src/generated/types';
import CommunityDashboard from '../community/dashboard/communityDashboard.graphql';
import {
    CommunityDashboardQuery,
    CommunityDashboardQueryVariables
} from 'common/src/generated/types';
import CommunityDelegationsSegments from '../community/delegations/list/communityDelegationsSegments.graphql';
import {
    CommunityDelegationsSegmentsQuery,
    CommunityDelegationsSegmentsQueryVariables
} from 'common/src/generated/types';
import CommunityUsers from '../community/users/communityUsers.graphql';
import { CommunityUsersQuery, CommunityUsersQueryVariables } from 'common/src/generated/types';
import UsersInfosMassAddEvent from '../community/users/massAddEvent/usersInfosMassAddEvent.graphql';
import {
    UsersInfosMassAddEventMutation,
    UsersInfosMassAddEventMutationVariables
} from 'common/src/generated/types';
import MergeMembers from '../community/users/mergeMembers/mergeMembers.graphql';
import { MergeMembersQuery, MergeMembersQueryVariables } from 'common/src/generated/types';
import UsersInfosMerge from '../community/users/mergeMembers/usersInfosMerge.graphql';
import {
    UsersInfosMergeMutation,
    UsersInfosMergeMutationVariables
} from 'common/src/generated/types';
import UserInfoCommunity from '../community/users/userInfoCommunity.graphql';
import {
    UserInfoCommunityQuery,
    UserInfoCommunityQueryVariables
} from 'common/src/generated/types';
import UserInfoUpdateField from '../community/users/userInfoUpdateField.graphql';
import {
    UserInfoUpdateFieldMutation,
    UserInfoUpdateFieldMutationVariables
} from 'common/src/generated/types';
import UsersActions from '../community/users/usersActions.graphql';
import { UsersActionsQuery, UsersActionsQueryVariables } from 'common/src/generated/types';
import UsersInfosMassDelete from '../community/users/usersInfosMassDelete.graphql';
import {
    UsersInfosMassDeleteMutation,
    UsersInfosMassDeleteMutationVariables
} from 'common/src/generated/types';
import UsersSegments from '../community/users/usersSegments.graphql';
import { UsersSegmentsQuery, UsersSegmentsQueryVariables } from 'common/src/generated/types';
import PossibleDuplicates from '../community/usersDuplicates/possibleDuplicates.graphql';
import {
    PossibleDuplicatesQuery,
    PossibleDuplicatesQueryVariables
} from 'common/src/generated/types';
import CustomFieldCategoryCreate from '../customFields/create/customFieldCategoryCreate.graphql';
import {
    CustomFieldCategoryCreateMutation,
    CustomFieldCategoryCreateMutationVariables
} from 'common/src/generated/types';
import CustomFieldCreate from '../customFields/create/customFieldCreate.graphql';
import {
    CustomFieldCreateMutation,
    CustomFieldCreateMutationVariables
} from 'common/src/generated/types';
import CustomFieldToEdit from '../customFields/create/customFieldToEdit.graphql';
import {
    CustomFieldToEditQuery,
    CustomFieldToEditQueryVariables
} from 'common/src/generated/types';
import CustomFieldUpdate from '../customFields/create/customFieldUpdate.graphql';
import {
    CustomFieldUpdateMutation,
    CustomFieldUpdateMutationVariables
} from 'common/src/generated/types';
import OrganizationInfos from '../customFields/create/organizationInfos.graphql';
import {
    OrganizationInfosQuery,
    OrganizationInfosQueryVariables
} from 'common/src/generated/types';
import CustomFieldCategoryDelete from '../customFields/list/customFieldCategoryDelete.graphql';
import {
    CustomFieldCategoryDeleteMutation,
    CustomFieldCategoryDeleteMutationVariables
} from 'common/src/generated/types';
import CustomFieldDelete from '../customFields/list/customFieldDelete.graphql';
import {
    CustomFieldDeleteMutation,
    CustomFieldDeleteMutationVariables
} from 'common/src/generated/types';
import CustomFieldDuplicate from '../customFields/list/customFieldDuplicate.graphql';
import {
    CustomFieldDuplicateMutation,
    CustomFieldDuplicateMutationVariables
} from 'common/src/generated/types';
import CustomFieldUsage from '../customFields/list/customFieldUsage/customFieldUsage.graphql';
import { CustomFieldUsageQuery, CustomFieldUsageQueryVariables } from 'common/src/generated/types';
import CustomFields from '../customFields/list/customFields.graphql';
import { CustomFieldsQuery, CustomFieldsQueryVariables } from 'common/src/generated/types';
import CustomFieldsSegments from '../customFields/list/customFieldsSegments.graphql';
import {
    CustomFieldsSegmentsQuery,
    CustomFieldsSegmentsQueryVariables
} from 'common/src/generated/types';
import CustomFieldCategoryUpdate from '../customFieldsCategories/create/customFieldCategoryUpdate.graphql';
import {
    CustomFieldCategoryUpdateMutation,
    CustomFieldCategoryUpdateMutationVariables
} from 'common/src/generated/types';
import Dashboard from '../dashboard/dashboard.graphql';
import { DashboardQuery, DashboardQueryVariables } from 'common/src/generated/types';
import DashboardMobile from '../dashboard/mobile/dashboardMobile.graphql';
import { DashboardMobileQuery, DashboardMobileQueryVariables } from 'common/src/generated/types';
import CreateEventDelegationLeaders from '../delegations/create/createEventDelegationLeaders.graphql';
import {
    CreateEventDelegationLeadersQuery,
    CreateEventDelegationLeadersQueryVariables
} from 'common/src/generated/types';
import CreateOrganizationDelegationLeaders from '../delegations/create/createOrganizationDelegationLeaders.graphql';
import {
    CreateOrganizationDelegationLeadersQuery,
    CreateOrganizationDelegationLeadersQueryVariables
} from 'common/src/generated/types';
import DelegationCreate from '../delegations/create/delegationCreate.graphql';
import {
    DelegationCreateMutation,
    DelegationCreateMutationVariables
} from 'common/src/generated/types';
import DelegationEventInfos from '../delegations/create/delegationEventInfos.graphql';
import {
    DelegationEventInfosQuery,
    DelegationEventInfosQueryVariables
} from 'common/src/generated/types';
import DelegationEventToEdit from '../delegations/create/delegationEventToEdit.graphql';
import {
    DelegationEventToEditQuery,
    DelegationEventToEditQueryVariables
} from 'common/src/generated/types';
import DelegationOrganizationInfos from '../delegations/create/delegationOrganizationInfos.graphql';
import {
    DelegationOrganizationInfosQuery,
    DelegationOrganizationInfosQueryVariables
} from 'common/src/generated/types';
import DelegationOrganizationToEdit from '../delegations/create/delegationOrganizationToEdit.graphql';
import {
    DelegationOrganizationToEditQuery,
    DelegationOrganizationToEditQueryVariables
} from 'common/src/generated/types';
import DelegationUpdate from '../delegations/create/delegationUpdate.graphql';
import {
    DelegationUpdateMutation,
    DelegationUpdateMutationVariables
} from 'common/src/generated/types';
import EventUsersInfos from '../delegations/create/eventUsersInfos.graphql';
import { EventUsersInfosQuery, EventUsersInfosQueryVariables } from 'common/src/generated/types';
import DelegationsImport from '../delegations/import/delegationsImport.graphql';
import {
    DelegationsImportMutation,
    DelegationsImportMutationVariables
} from 'common/src/generated/types';
import DelegationDelete from '../delegations/list/delegationDelete.graphql';
import {
    DelegationDeleteMutation,
    DelegationDeleteMutationVariables
} from 'common/src/generated/types';
import DelegationDuplicate from '../delegations/list/delegationDuplicate.graphql';
import {
    DelegationDuplicateMutation,
    DelegationDuplicateMutationVariables
} from 'common/src/generated/types';
import DelegationUpdateField from '../delegations/list/delegationUpdateField.graphql';
import {
    DelegationUpdateFieldMutation,
    DelegationUpdateFieldMutationVariables
} from 'common/src/generated/types';
import Delegations from '../delegations/list/delegations.graphql';
import { DelegationsQuery, DelegationsQueryVariables } from 'common/src/generated/types';
import DelegationsDelegation from '../delegations/list/delegationsDelegation.graphql';
import {
    DelegationsDelegationQuery,
    DelegationsDelegationQueryVariables
} from 'common/src/generated/types';
import DelegationsMassDelete from '../delegations/list/delegationsMassDelete.graphql';
import {
    DelegationsMassDeleteMutation,
    DelegationsMassDeleteMutationVariables
} from 'common/src/generated/types';
import DelegationsSegments from '../delegations/list/delegationsSegments.graphql';
import {
    DelegationsSegmentsQuery,
    DelegationsSegmentsQueryVariables
} from 'common/src/generated/types';
import ParentDelegations from '../delegations/list/parentDelegations.graphql';
import {
    ParentDelegationsQuery,
    ParentDelegationsQueryVariables
} from 'common/src/generated/types';
import DelegationsMassEdit from '../delegations/massEdit/delegationsMassEdit.graphql';
import {
    DelegationsMassEditMutation,
    DelegationsMassEditMutationVariables
} from 'common/src/generated/types';
import EventDelegationsMassEditInfos from '../delegations/massEdit/eventDelegationsMassEditInfos.graphql';
import {
    EventDelegationsMassEditInfosQuery,
    EventDelegationsMassEditInfosQueryVariables
} from 'common/src/generated/types';
import OrganizationMassEditInfos from '../delegations/massEdit/organizationDelegationsMassEditInfos.graphql';
import {
    OrganizationMassEditInfosQuery,
    OrganizationMassEditInfosQueryVariables
} from 'common/src/generated/types';
import DelegationDashboard from '../delegations/show/dashboard/delegationDashboard.graphql';
import {
    DelegationDashboardQuery,
    DelegationDashboardQueryVariables
} from 'common/src/generated/types';
import Delegation from '../delegations/show/delegation.graphql';
import { DelegationQuery, DelegationQueryVariables } from 'common/src/generated/types';
import DelegationLock from '../delegations/show/delegationLock.graphql';
import {
    DelegationLockMutation,
    DelegationLockMutationVariables
} from 'common/src/generated/types';
import DelegationUnlock from '../delegations/show/delegationUnlock.graphql';
import {
    DelegationUnlockMutation,
    DelegationUnlockMutationVariables
} from 'common/src/generated/types';
import Documents from '../documents/list/documents.graphql';
import { DocumentsQuery, DocumentsQueryVariables } from 'common/src/generated/types';
import DocumentsAssignedUsers from '../documents/show/documentsAssignedUsers.graphql';
import {
    DocumentsAssignedUsersQuery,
    DocumentsAssignedUsersQueryVariables
} from 'common/src/generated/types';
import DocumentsPositions from '../documents/show/documentsPositions.graphql';
import {
    DocumentsPositionsQuery,
    DocumentsPositionsQueryVariables
} from 'common/src/generated/types';
import EventDocumentUpdate from '../documents/show/eventDocumentUpdate.graphql';
import {
    EventDocumentUpdateMutation,
    EventDocumentUpdateMutationVariables
} from 'common/src/generated/types';
import EventDocumentsExport from '../documents/show/eventDocumentsExport.graphql';
import {
    EventDocumentsExportMutation,
    EventDocumentsExportMutationVariables
} from 'common/src/generated/types';
import PositionPlanningDocument from '../documents/show/positionPlanningDocument.graphql';
import {
    PositionPlanningDocumentQuery,
    PositionPlanningDocumentQueryVariables
} from 'common/src/generated/types';
import PositionSheetDocument from '../documents/show/positionSheetDocument.graphql';
import {
    PositionSheetDocumentQuery,
    PositionSheetDocumentQueryVariables
} from 'common/src/generated/types';
import PlanningDocument from '../documents/show/positionsPlanningDocument.graphql';
import { PlanningDocumentQuery, PlanningDocumentQueryVariables } from 'common/src/generated/types';
import PositionsVolunteersPlanningDocument from '../documents/show/positionsVolunteersPlanningDocument.graphql';
import {
    PositionsVolunteersPlanningDocumentQuery,
    PositionsVolunteersPlanningDocumentQueryVariables
} from 'common/src/generated/types';
import UserAssignmentsDocument from '../documents/show/userAssignmentsDocument.graphql';
import {
    UserAssignmentsDocumentQuery,
    UserAssignmentsDocumentQueryVariables
} from 'common/src/generated/types';
import UserBadgeDocument from '../documents/show/userBadgeDocument.graphql';
import {
    UserBadgeDocumentQuery,
    UserBadgeDocumentQueryVariables
} from 'common/src/generated/types';
import UserCertificateDocument from '../documents/show/userCertificateDocument.graphql';
import {
    UserCertificateDocumentQuery,
    UserCertificateDocumentQueryVariables
} from 'common/src/generated/types';
import UserCustomBadgeDocument from '../documents/show/userCustomBadgeDocument.graphql';
import {
    UserCustomBadgeDocumentQuery,
    UserCustomBadgeDocumentQueryVariables
} from 'common/src/generated/types';
import UserPlanningDaysDocument from '../documents/show/userPlanningDaysDocument.graphql';
import {
    UserPlanningDaysDocumentQuery,
    UserPlanningDaysDocumentQueryVariables
} from 'common/src/generated/types';
import UserPlanningDocument from '../documents/show/userPlanningDocument.graphql';
import {
    UserPlanningDocumentQuery,
    UserPlanningDocumentQueryVariables
} from 'common/src/generated/types';
import UserPlanningListDocument from '../documents/show/userPlanningListDocument.graphql';
import {
    UserPlanningListDocumentQuery,
    UserPlanningListDocumentQueryVariables
} from 'common/src/generated/types';
import UsersListingDocument from '../documents/show/usersListingDocument.graphql';
import {
    UsersListingDocumentQuery,
    UsersListingDocumentQueryVariables
} from 'common/src/generated/types';
import EmailTemplate from '../emails/templates/emailTemplate.graphql';
import { EmailTemplateQuery, EmailTemplateQueryVariables } from 'common/src/generated/types';
import EmailTemplateUpdate from '../emails/templates/emailTemplateUpdate.graphql';
import {
    EmailTemplateUpdateMutation,
    EmailTemplateUpdateMutationVariables
} from 'common/src/generated/types';
import EmailsTemplates from '../emails/templates/emailsTemplates.graphql';
import { EmailsTemplatesQuery, EmailsTemplatesQueryVariables } from 'common/src/generated/types';
import AllEvents from '../events/allEvents.graphql';
import { AllEventsQuery, AllEventsQueryVariables } from 'common/src/generated/types';
import CreateEvent from '../events/create/createEvent.graphql';
import { CreateEventMutation, CreateEventMutationVariables } from 'common/src/generated/types';
import EventCreateInfos from '../events/create/eventCreateInfos.graphql';
import { EventCreateInfosQuery, EventCreateInfosQueryVariables } from 'common/src/generated/types';
import EventOrganizationCreate from '../events/create/eventOrganizationCreate.graphql';
import {
    EventOrganizationCreateMutation,
    EventOrganizationCreateMutationVariables
} from 'common/src/generated/types';
import EventUpdate from '../events/create/eventUpdate.graphql';
import { EventUpdateMutation, EventUpdateMutationVariables } from 'common/src/generated/types';
import EventUpdateInfos from '../events/create/eventUpdateInfos.graphql';
import { EventUpdateInfosQuery, EventUpdateInfosQueryVariables } from 'common/src/generated/types';
import EventArchive from '../events/list/eventArchive.graphql';
import { EventArchiveMutation, EventArchiveMutationVariables } from 'common/src/generated/types';
import EventDuplicate from '../events/list/eventDuplicate.graphql';
import {
    EventDuplicateMutation,
    EventDuplicateMutationVariables
} from 'common/src/generated/types';
import Events from '../events/list/events.graphql';
import { EventsQuery, EventsQueryVariables } from 'common/src/generated/types';
import EventsSegments from '../events/list/eventsSegments.graphql';
import { EventsSegmentsQuery, EventsSegmentsQueryVariables } from 'common/src/generated/types';
import EventsMobile from '../events/mobile/eventsMobile.graphql';
import { EventsMobileQuery, EventsMobileQueryVariables } from 'common/src/generated/types';
import Event from '../events/show/event.graphql';
import { EventQuery, EventQueryVariables } from 'common/src/generated/types';
import FilesImport from '../files/import/filesImport.graphql';
import { FilesImportMutation, FilesImportMutationVariables } from 'common/src/generated/types';
import FilesImportCustomFields from '../files/import/filesImportCustomFields.graphql';
import {
    FilesImportCustomFieldsQuery,
    FilesImportCustomFieldsQueryVariables
} from 'common/src/generated/types';
import FormCreate from '../forms/create/formCreate.graphql';
import { FormCreateMutation, FormCreateMutationVariables } from 'common/src/generated/types';
import EventForms from '../forms/list/eventForms.graphql';
import { EventFormsQuery, EventFormsQueryVariables } from 'common/src/generated/types';
import FormDelete from '../forms/list/formDelete.graphql';
import { FormDeleteMutation, FormDeleteMutationVariables } from 'common/src/generated/types';
import FormDuplicate from '../forms/list/formDuplicate.graphql';
import { FormDuplicateMutation, FormDuplicateMutationVariables } from 'common/src/generated/types';
import OrganizationForms from '../forms/list/organizationForms.graphql';
import {
    OrganizationFormsQuery,
    OrganizationFormsQueryVariables
} from 'common/src/generated/types';
import Form from '../forms/show/form.graphql';
import { FormQuery, FormQueryVariables } from 'common/src/generated/types';
import FormElements from '../forms/update/elements/formElements.graphql';
import { FormElementsQuery, FormElementsQueryVariables } from 'common/src/generated/types';
import FormElementsUpdate from '../forms/update/elements/formElementsUpdate.graphql';
import {
    FormElementsUpdateMutation,
    FormElementsUpdateMutationVariables
} from 'common/src/generated/types';
import FormParametersAccreditations from '../forms/update/parameters/accreditations/formParametersAccreditations.graphql';
import {
    FormParametersAccreditationsQuery,
    FormParametersAccreditationsQueryVariables
} from 'common/src/generated/types';
import FormParametersAccreditationsUpdate from '../forms/update/parameters/accreditations/formParametersAccreditationsUpdate.graphql';
import {
    FormParametersAccreditationsUpdateMutation,
    FormParametersAccreditationsUpdateMutationVariables
} from 'common/src/generated/types';
import FormParametersAccreditationsHiddenDisplayed from '../forms/update/parameters/accreditations/hiddenDisplayed/formParametersAccreditationsHiddenDisplayed.graphql';
import {
    FormParametersAccreditationsHiddenDisplayedQuery,
    FormParametersAccreditationsHiddenDisplayedQueryVariables
} from 'common/src/generated/types';
import FormParametersAvailabilities from '../forms/update/parameters/availabilities/formParametersAvailabilities.graphql';
import {
    FormParametersAvailabilitiesQuery,
    FormParametersAvailabilitiesQueryVariables
} from 'common/src/generated/types';
import FormParametersAvailabilitiesUpdate from '../forms/update/parameters/availabilities/formParametersAvailabilitiesUpdate.graphql';
import {
    FormParametersAvailabilitiesUpdateMutation,
    FormParametersAvailabilitiesUpdateMutationVariables
} from 'common/src/generated/types';
import FormParametersBroadcastEvent from '../forms/update/parameters/broadcast/formParametersBroadcastEvent.graphql';
import {
    FormParametersBroadcastEventQuery,
    FormParametersBroadcastEventQueryVariables
} from 'common/src/generated/types';
import FormParametersBroadcastOrganization from '../forms/update/parameters/broadcast/formParametersBroadcastOrganization.graphql';
import {
    FormParametersBroadcastOrganizationQuery,
    FormParametersBroadcastOrganizationQueryVariables
} from 'common/src/generated/types';
import FormParametersBroadcastUpdate from '../forms/update/parameters/broadcast/formParametersBroadcastUpdate.graphql';
import {
    FormParametersBroadcastUpdateMutation,
    FormParametersBroadcastUpdateMutationVariables
} from 'common/src/generated/types';
import FormParametersDesign from '../forms/update/parameters/design/formParametersDesign.graphql';
import {
    FormParametersDesignQuery,
    FormParametersDesignQueryVariables
} from 'common/src/generated/types';
import FormParametersDesignUpdate from '../forms/update/parameters/design/formParametersDesignUpdate.graphql';
import {
    FormParametersDesignUpdateMutation,
    FormParametersDesignUpdateMutationVariables
} from 'common/src/generated/types';
import FormParametersGeneral from '../forms/update/parameters/general/formParametersGeneral.graphql';
import {
    FormParametersGeneralQuery,
    FormParametersGeneralQueryVariables
} from 'common/src/generated/types';
import FormParametersGeneralUpdate from '../forms/update/parameters/general/formParametersGeneralUpdate.graphql';
import {
    FormParametersGeneralUpdateMutation,
    FormParametersGeneralUpdateMutationVariables
} from 'common/src/generated/types';
import FormParametersMissions from '../forms/update/parameters/missions/formParametersMissions.graphql';
import {
    FormParametersMissionsQuery,
    FormParametersMissionsQueryVariables
} from 'common/src/generated/types';
import FormParametersMissionsUpdate from '../forms/update/parameters/missions/formParametersMissionsUpdate.graphql';
import {
    FormParametersMissionsUpdateMutation,
    FormParametersMissionsUpdateMutationVariables
} from 'common/src/generated/types';
import FormParametersMissionsHiddenDisplayed from '../forms/update/parameters/missions/hiddenDisplayed/formParametersMissionsHiddenDisplayed.graphql';
import {
    FormParametersMissionsHiddenDisplayedQuery,
    FormParametersMissionsHiddenDisplayedQueryVariables
} from 'common/src/generated/types';
import FormParametersState from '../forms/update/parameters/state/formParametersState.graphql';
import {
    FormParametersStateQuery,
    FormParametersStateQueryVariables
} from 'common/src/generated/types';
import FormParametersStateUpdate from '../forms/update/parameters/state/formParametersStateUpdate.graphql';
import {
    FormParametersStateUpdateMutation,
    FormParametersStateUpdateMutationVariables
} from 'common/src/generated/types';
import HomeOrganizations from '../home/homeOrganizations.graphql';
import {
    HomeOrganizationsQuery,
    HomeOrganizationsQueryVariables
} from 'common/src/generated/types';
import EventDesign from '../integration/eventDesign.graphql';
import { EventDesignQuery, EventDesignQueryVariables } from 'common/src/generated/types';
import EventDesignUpdate from '../integration/eventDesignUpdate.graphql';
import {
    EventDesignUpdateMutation,
    EventDesignUpdateMutationVariables
} from 'common/src/generated/types';
import EventLinks from '../integration/eventLinks.graphql';
import { EventLinksQuery, EventLinksQueryVariables } from 'common/src/generated/types';
import EventLinksUpdate from '../integration/eventLinksUpdate.graphql';
import {
    EventLinksUpdateMutation,
    EventLinksUpdateMutationVariables
} from 'common/src/generated/types';
import EventsFieldsUpdate from '../integration/eventsFieldsUpdate.graphql';
import {
    EventsFieldsUpdateMutation,
    EventsFieldsUpdateMutationVariables
} from 'common/src/generated/types';
import Fields from '../integration/fields.graphql';
import { FieldsQuery, FieldsQueryVariables } from 'common/src/generated/types';
import EventParameters from '../integration/parameters/eventParameters.graphql';
import { EventParametersQuery, EventParametersQueryVariables } from 'common/src/generated/types';
import EventParametersUpdate from '../integration/parameters/eventParametersUpdate.graphql';
import {
    EventParametersUpdateMutation,
    EventParametersUpdateMutationVariables
} from 'common/src/generated/types';
import CollaboratorsVolunteers from '../leaders/assign/collaboratorsVolunteers.graphql';
import {
    CollaboratorsVolunteersQuery,
    CollaboratorsVolunteersQueryVariables
} from 'common/src/generated/types';
import DelegationsImportResult from '../notifications/delegationsImportResult.graphql';
import {
    DelegationsImportResultMutation,
    DelegationsImportResultMutationVariables
} from 'common/src/generated/types';
import EventDocumentsExportResult from '../notifications/eventDocumentsExportResult.graphql';
import {
    EventDocumentsExportResultMutation,
    EventDocumentsExportResultMutationVariables
} from 'common/src/generated/types';
import ExportResult from '../notifications/exportResult.graphql';
import { ExportResultMutation, ExportResultMutationVariables } from 'common/src/generated/types';
import FilesImportResult from '../notifications/filesImportResult.graphql';
import {
    FilesImportResultMutation,
    FilesImportResultMutationVariables
} from 'common/src/generated/types';
import VolunteersImportResult from '../notifications/volunteersImportResult.graphql';
import {
    VolunteersImportResultMutation,
    VolunteersImportResultMutationVariables
} from 'common/src/generated/types';
import OrganizationCreate from '../organization/create/organizationCreate.graphql';
import {
    OrganizationCreateMutation,
    OrganizationCreateMutationVariables
} from 'common/src/generated/types';
import Organizations from '../organization/menu/organizations.graphql';
import { OrganizationsQuery, OrganizationsQueryVariables } from 'common/src/generated/types';
import Organization from '../organization/organization.graphql';
import { OrganizationQuery, OrganizationQueryVariables } from 'common/src/generated/types';
import OrganizationArchive from '../organization/organizatonArchive.graphql';
import {
    OrganizationArchiveMutation,
    OrganizationArchiveMutationVariables
} from 'common/src/generated/types';
import UserInfo from '../organization/terms/userInfo.graphql';
import { UserInfoQuery, UserInfoQueryVariables } from 'common/src/generated/types';
import UserInfoAndTermsUpdate from '../organization/terms/userInfoAndTermsUpdate.graphql';
import {
    UserInfoAndTermsUpdateMutation,
    UserInfoAndTermsUpdateMutationVariables
} from 'common/src/generated/types';
import Activities from '../organizationParameters/activities/activities.graphql';
import { ActivitiesQuery, ActivitiesQueryVariables } from 'common/src/generated/types';
import OrganizationCategoriesAccreditations from '../organizationParameters/categories/organizationCategoriesAccreditations.graphql';
import {
    OrganizationCategoriesAccreditationsQuery,
    OrganizationCategoriesAccreditationsQueryVariables
} from 'common/src/generated/types';
import OrganizationCategoriesCustomFields from '../organizationParameters/categories/organizationCategoriesCustomFields.graphql';
import {
    OrganizationCategoriesCustomFieldsQuery,
    OrganizationCategoriesCustomFieldsQueryVariables
} from 'common/src/generated/types';
import OrganizationCategoriesPositions from '../organizationParameters/categories/organizationCategoriesPositions.graphql';
import {
    OrganizationCategoriesPositionsQuery,
    OrganizationCategoriesPositionsQueryVariables
} from 'common/src/generated/types';
import OrganizationDelegationSpaceSettings from '../organizationParameters/delegationsSpace/organizationDelegationSpaceSettings.graphql';
import {
    OrganizationDelegationSpaceSettingsQuery,
    OrganizationDelegationSpaceSettingsQueryVariables
} from 'common/src/generated/types';
import OrganizationDelegationSpaceSettingsUpdate from '../organizationParameters/delegationsSpace/organizationDelegationSpaceSettingsUpdate.graphql';
import {
    OrganizationDelegationSpaceSettingsUpdateMutation,
    OrganizationDelegationSpaceSettingsUpdateMutationVariables
} from 'common/src/generated/types';
import OrganizationDelegationsSpaceDesign from '../organizationParameters/delegationsSpace/organizationDelegationsSpaceDesign.graphql';
import {
    OrganizationDelegationsSpaceDesignQuery,
    OrganizationDelegationsSpaceDesignQueryVariables
} from 'common/src/generated/types';
import OrganizationDelegationsSpaceDesignUpdate from '../organizationParameters/delegationsSpace/organizationDelegationsSpaceDesignUpdate.graphql';
import {
    OrganizationDelegationsSpaceDesignUpdateMutation,
    OrganizationDelegationsSpaceDesignUpdateMutationVariables
} from 'common/src/generated/types';
import DomainDkimVerificationRetry from '../organizationParameters/emails/dns/domainDkimVerificationRetry.graphql';
import {
    DomainDkimVerificationRetryMutation,
    DomainDkimVerificationRetryMutationVariables
} from 'common/src/generated/types';
import OrganizationDomains from '../organizationParameters/emails/dns/organizationDomains.graphql';
import {
    OrganizationDomainsQuery,
    OrganizationDomainsQueryVariables
} from 'common/src/generated/types';
import EmailSenderCreate from '../organizationParameters/emails/senders/create/emailSenderCreate.graphql';
import {
    EmailSenderCreateMutation,
    EmailSenderCreateMutationVariables
} from 'common/src/generated/types';
import EmailSenderToEdit from '../organizationParameters/emails/senders/create/emailSenderToEdit.graphql';
import {
    EmailSenderToEditQuery,
    EmailSenderToEditQueryVariables
} from 'common/src/generated/types';
import EmailSenderUpdate from '../organizationParameters/emails/senders/create/emailSenderUpdate.graphql';
import {
    EmailSenderUpdateMutation,
    EmailSenderUpdateMutationVariables
} from 'common/src/generated/types';
import EmailSenderDelete from '../organizationParameters/emails/senders/list/emailSenderDelete.graphql';
import {
    EmailSenderDeleteMutation,
    EmailSenderDeleteMutationVariables
} from 'common/src/generated/types';
import EmailSenderDuplicate from '../organizationParameters/emails/senders/list/emailSenderDuplicate.graphql';
import {
    EmailSenderDuplicateMutation,
    EmailSenderDuplicateMutationVariables
} from 'common/src/generated/types';
import EmailSenderVerificationResend from '../organizationParameters/emails/senders/list/emailSenderVerificationResend.graphql';
import {
    EmailSenderVerificationResendMutation,
    EmailSenderVerificationResendMutationVariables
} from 'common/src/generated/types';
import EmailsSenders from '../organizationParameters/emails/senders/list/emailsSenders.graphql';
import { EmailsSendersQuery, EmailsSendersQueryVariables } from 'common/src/generated/types';
import EmailSenderPreview from '../organizationParameters/emails/senders/preview/emailSenderPreview.graphql';
import {
    EmailSenderPreviewQuery,
    EmailSenderPreviewQueryVariables
} from 'common/src/generated/types';
import OrganizationParametersInfos from '../organizationParameters/infos/organizationInfos.graphql';
import {
    OrganizationParametersInfosQuery,
    OrganizationParametersInfosQueryVariables
} from 'common/src/generated/types';
import OrganizationUpdateInfos from '../organizationParameters/infos/organizationUpdateInfos.graphql';
import {
    OrganizationUpdateInfosMutation,
    OrganizationUpdateInfosMutationVariables
} from 'common/src/generated/types';
import Invoices from '../organizationParameters/invoices/invoices.graphql';
import { InvoicesQuery, InvoicesQueryVariables } from 'common/src/generated/types';
import OrganizationMembersSpaceDesign from '../organizationParameters/membersSpace/organizationMembersSpaceDesign.graphql';
import {
    OrganizationMembersSpaceDesignQuery,
    OrganizationMembersSpaceDesignQueryVariables
} from 'common/src/generated/types';
import OrganizationMembersSpaceDesignUpdate from '../organizationParameters/membersSpace/organizationMembersSpaceDesignUpdate.graphql';
import {
    OrganizationMembersSpaceDesignUpdateMutation,
    OrganizationMembersSpaceDesignUpdateMutationVariables
} from 'common/src/generated/types';
import OrganizationMembersSpaceSettings from '../organizationParameters/membersSpace/organizationMembersSpaceSettings.graphql';
import {
    OrganizationMembersSpaceSettingsQuery,
    OrganizationMembersSpaceSettingsQueryVariables
} from 'common/src/generated/types';
import OrganizationMembersSpaceSettingsUpdate from '../organizationParameters/membersSpace/organizationMembersSpaceSettingsUpdate.graphql';
import {
    OrganizationMembersSpaceSettingsUpdateMutation,
    OrganizationMembersSpaceSettingsUpdateMutationVariables
} from 'common/src/generated/types';
import Profile from '../organizationParameters/profile/profile.graphql';
import { ProfileQuery, ProfileQueryVariables } from 'common/src/generated/types';
import UserDelete from '../organizationParameters/profile/userDelete.graphql';
import { UserDeleteMutation, UserDeleteMutationVariables } from 'common/src/generated/types';
import UserProfileUpdate from '../organizationParameters/profile/userProfileUpdate.graphql';
import {
    UserProfileUpdateMutation,
    UserProfileUpdateMutationVariables
} from 'common/src/generated/types';
import SkidataConnectionInfoSeaonEventAdd from '../organizationParameters/skidata/skidataConnectionInfoSeaonEventAdd.graphql';
import {
    SkidataConnectionInfoSeaonEventAddMutation,
    SkidataConnectionInfoSeaonEventAddMutationVariables
} from 'common/src/generated/types';
import SkidataConnectionInfoSeaonEventDelete from '../organizationParameters/skidata/skidataConnectionInfoSeaonEventDelete.graphql';
import {
    SkidataConnectionInfoSeaonEventDeleteMutation,
    SkidataConnectionInfoSeaonEventDeleteMutationVariables
} from 'common/src/generated/types';
import SkidataConnectionInfoSynchronizedEventAdd from '../organizationParameters/skidata/skidataConnectionInfoSynchronizedEventAdd.graphql';
import {
    SkidataConnectionInfoSynchronizedEventAddMutation,
    SkidataConnectionInfoSynchronizedEventAddMutationVariables
} from 'common/src/generated/types';
import SkidataConnectionInfoSynchronizedEventDelete from '../organizationParameters/skidata/skidataConnectionInfoSynchronizedEventDelete.graphql';
import {
    SkidataConnectionInfoSynchronizedEventDeleteMutation,
    SkidataConnectionInfoSynchronizedEventDeleteMutationVariables
} from 'common/src/generated/types';
import SkidataConnectionInfos from '../organizationParameters/skidata/skidataConnectionInfos.graphql';
import {
    SkidataConnectionInfosQuery,
    SkidataConnectionInfosQueryVariables
} from 'common/src/generated/types';
import SkidataConnectionInfosDelete from '../organizationParameters/skidata/skidataConnectionInfosDelete.graphql';
import {
    SkidataConnectionInfosDeleteMutation,
    SkidataConnectionInfosDeleteMutationVariables
} from 'common/src/generated/types';
import SkidataConnectionInfosUpsert from '../organizationParameters/skidata/skidataConnectionInfosUpsert.graphql';
import {
    SkidataConnectionInfosUpsertMutation,
    SkidataConnectionInfosUpsertMutationVariables
} from 'common/src/generated/types';
import SkidataSynchronize from '../organizationParameters/skidata/skidataSynchronize.graphql';
import {
    SkidataSynchronizeMutation,
    SkidataSynchronizeMutationVariables
} from 'common/src/generated/types';
import OrganizationTags from '../organizationParameters/tags/organizationTags.graphql';
import { OrganizationTagsQuery, OrganizationTagsQueryVariables } from 'common/src/generated/types';
import TagCreate from '../organizationParameters/tags/tagCreate.graphql';
import { TagCreateMutation, TagCreateMutationVariables } from 'common/src/generated/types';
import TagDelete from '../organizationParameters/tags/tagDelete.graphql';
import { TagDeleteMutation, TagDeleteMutationVariables } from 'common/src/generated/types';
import TagUpdateName from '../organizationParameters/tags/tagUpdateName.graphql';
import { TagUpdateNameMutation, TagUpdateNameMutationVariables } from 'common/src/generated/types';
import EmailEventInviteResend from '../organizationParameters/team/emailEventInviteResend.graphql';
import {
    EmailEventInviteResendMutation,
    EmailEventInviteResendMutationVariables
} from 'common/src/generated/types';
import EmailOrganizationInviteResend from '../organizationParameters/team/emailOrganizationInviteResend.graphql';
import {
    EmailOrganizationInviteResendMutation,
    EmailOrganizationInviteResendMutationVariables
} from 'common/src/generated/types';
import EventAdminsLeaders from '../organizationParameters/team/eventAdminsLeaders.graphql';
import {
    EventAdminsLeadersQuery,
    EventAdminsLeadersQueryVariables
} from 'common/src/generated/types';
import EventInvite from '../organizationParameters/team/eventInvite.graphql';
import { EventInviteMutation, EventInviteMutationVariables } from 'common/src/generated/types';
import OrganizationInvite from '../organizationParameters/team/organizationInvite.graphql';
import {
    OrganizationInviteMutation,
    OrganizationInviteMutationVariables
} from 'common/src/generated/types';
import Team from '../organizationParameters/team/team.graphql';
import { TeamQuery, TeamQueryVariables } from 'common/src/generated/types';
import UserEventDelete from '../organizationParameters/team/userEventDelete.graphql';
import {
    UserEventDeleteMutation,
    UserEventDeleteMutationVariables
} from 'common/src/generated/types';
import UserLeaderDelete from '../organizationParameters/team/userLeaderDelete.graphql';
import {
    UserLeaderDeleteMutation,
    UserLeaderDeleteMutationVariables
} from 'common/src/generated/types';
import UserOrganizationDelete from '../organizationParameters/team/userOrganizationDelete.graphql';
import {
    UserOrganizationDeleteMutation,
    UserOrganizationDeleteMutationVariables
} from 'common/src/generated/types';
import UserOrganizationUpdate from '../organizationParameters/team/userOrganizationUpdate.graphql';
import {
    UserOrganizationUpdateMutation,
    UserOrganizationUpdateMutationVariables
} from 'common/src/generated/types';
import UserPositionCategoryUpdate from '../organizationParameters/team/userPositionCategoryUpdate.graphql';
import {
    UserPositionCategoryUpdateMutation,
    UserPositionCategoryUpdateMutationVariables
} from 'common/src/generated/types';
import UserPositionUpdate from '../organizationParameters/team/userPositionUpdate.graphql';
import {
    UserPositionUpdateMutation,
    UserPositionUpdateMutationVariables
} from 'common/src/generated/types';
import OrganizationTranslations from '../organizationParameters/translations/organizationTranslations.graphql';
import {
    OrganizationTranslationsQuery,
    OrganizationTranslationsQueryVariables
} from 'common/src/generated/types';
import OrganizationTranslationsStats from '../organizationParameters/translations/organizationTranslationsStats.graphql';
import {
    OrganizationTranslationsStatsQuery,
    OrganizationTranslationsStatsQueryVariables
} from 'common/src/generated/types';
import TranslationUpdate from '../organizationParameters/translations/translationUpdate.graphql';
import {
    TranslationUpdateMutation,
    TranslationUpdateMutationVariables
} from 'common/src/generated/types';
import PositionCreate from '../positions/create/positionCreate.graphql';
import {
    PositionCreateMutation,
    PositionCreateMutationVariables
} from 'common/src/generated/types';
import PositionEventInfos from '../positions/create/positionEventInfos.graphql';
import {
    PositionEventInfosQuery,
    PositionEventInfosQueryVariables
} from 'common/src/generated/types';
import PositionToEdit from '../positions/create/positionToEdit.graphql';
import { PositionToEditQuery, PositionToEditQueryVariables } from 'common/src/generated/types';
import PositionUpdate from '../positions/create/positionUpdate.graphql';
import {
    PositionUpdateMutation,
    PositionUpdateMutationVariables
} from 'common/src/generated/types';
import KmlEventInfos from '../positions/kml/kmlEventInfos.graphql';
import { KmlEventInfosQuery, KmlEventInfosQueryVariables } from 'common/src/generated/types';
import PositionsCreate from '../positions/kml/positionsCreate.graphql';
import {
    PositionsCreateMutation,
    PositionsCreateMutationVariables
} from 'common/src/generated/types';
import PositionsCalendar from '../positions/list/calendar/positionsCalendar.graphql';
import {
    PositionsCalendarQuery,
    PositionsCalendarQueryVariables
} from 'common/src/generated/types';
import PositionUpdateField from '../positions/list/list/positionUpdateField.graphql';
import {
    PositionUpdateFieldMutation,
    PositionUpdateFieldMutationVariables
} from 'common/src/generated/types';
import PositionsCategories from '../positions/list/list/positionsCategories.graphql';
import {
    PositionsCategoriesQuery,
    PositionsCategoriesQueryVariables
} from 'common/src/generated/types';
import PositionsMassDelete from '../positions/list/list/positionsMassDelete.graphql';
import {
    PositionsMassDeleteMutation,
    PositionsMassDeleteMutationVariables
} from 'common/src/generated/types';
import PositionsPosition from '../positions/list/list/positionsPosition.graphql';
import {
    PositionsPositionQuery,
    PositionsPositionQueryVariables
} from 'common/src/generated/types';
import EventLayerUpdate from '../positions/list/map/eventLayerUpdate.graphql';
import {
    EventLayerUpdateMutation,
    EventLayerUpdateMutationVariables
} from 'common/src/generated/types';
import TraceCreate from '../positions/list/map/traceCreate.graphql';
import { TraceCreateMutation, TraceCreateMutationVariables } from 'common/src/generated/types';
import TraceDelete from '../positions/list/map/traceDelete.graphql';
import { TraceDeleteMutation, TraceDeleteMutationVariables } from 'common/src/generated/types';
import TraceUpdate from '../positions/list/map/traceUpdate.graphql';
import { TraceUpdateMutation, TraceUpdateMutationVariables } from 'common/src/generated/types';
import Traces from '../positions/list/map/traces.graphql';
import { TracesQuery, TracesQueryVariables } from 'common/src/generated/types';
import PositionCategoryDelete from '../positions/list/positionCategoryDelete.graphql';
import {
    PositionCategoryDeleteMutation,
    PositionCategoryDeleteMutationVariables
} from 'common/src/generated/types';
import PositionCategoryLeaderDelete from '../positions/list/positionCategoryLeaderDelete.graphql';
import {
    PositionCategoryLeaderDeleteMutation,
    PositionCategoryLeaderDeleteMutationVariables
} from 'common/src/generated/types';
import Positions from '../positions/list/positions.graphql';
import { PositionsQuery, PositionsQueryVariables } from 'common/src/generated/types';
import PositionsHeader from '../positions/list/positionsHeader.graphql';
import { PositionsHeaderQuery, PositionsHeaderQueryVariables } from 'common/src/generated/types';
import PositionsSegments from '../positions/list/positionsSegments.graphql';
import {
    PositionsSegmentsQuery,
    PositionsSegmentsQueryVariables
} from 'common/src/generated/types';
import PositionMap from '../positions/map/positionMap.graphql';
import { PositionMapQuery, PositionMapQueryVariables } from 'common/src/generated/types';
import MassConditionsCustomFields from '../positions/massConditions/massConditionsCustomFields.graphql';
import {
    MassConditionsCustomFieldsQuery,
    MassConditionsCustomFieldsQueryVariables
} from 'common/src/generated/types';
import PositionsMassConditions from '../positions/massConditions/positionsMassConditions.graphql';
import {
    PositionsMassConditionsMutation,
    PositionsMassConditionsMutationVariables
} from 'common/src/generated/types';
import AccreditationsMassEdit from '../positions/massEdit/accreditationsMassEdit.graphql';
import {
    AccreditationsMassEditMutation,
    AccreditationsMassEditMutationVariables
} from 'common/src/generated/types';
import PositionsMassEdit from '../positions/massEdit/positionsMassEdit.graphql';
import {
    PositionsMassEditMutation,
    PositionsMassEditMutationVariables
} from 'common/src/generated/types';
import PositionsMassEditInfos from '../positions/massEdit/positionsMassEditInfos.graphql';
import {
    PositionsMassEditInfosQuery,
    PositionsMassEditInfosQueryVariables
} from 'common/src/generated/types';
import PositionsMobile from '../positions/mobile/list/positionsMobile.graphql';
import { PositionsMobileQuery, PositionsMobileQueryVariables } from 'common/src/generated/types';
import PositionsSegmentsMobile from '../positions/mobile/list/positionsSegmentsMobile.graphql';
import {
    PositionsSegmentsMobileQuery,
    PositionsSegmentsMobileQueryVariables
} from 'common/src/generated/types';
import RecentPositionsSearchesMobile from '../positions/mobile/search/recentPositionsSearchesMobile.graphql';
import {
    RecentPositionsSearchesMobileQuery,
    RecentPositionsSearchesMobileQueryVariables
} from 'common/src/generated/types';
import PositionMobile from '../positions/mobile/show/positionMobile.graphql';
import { PositionMobileQuery, PositionMobileQueryVariables } from 'common/src/generated/types';
import PositionSlotMembersMobile from '../positions/mobile/show/positionSlotMembers/positionSlotMembersMobile.graphql';
import {
    PositionSlotMembersMobileQuery,
    PositionSlotMembersMobileQueryVariables
} from 'common/src/generated/types';
import PositionsSlotsUsersInfosCheckInMobile from '../positions/mobile/show/positionSlotMembers/positionsSlotsUsersInfosCheckInMobile.graphql';
import {
    PositionsSlotsUsersInfosCheckInMobileMutation,
    PositionsSlotsUsersInfosCheckInMobileMutationVariables
} from 'common/src/generated/types';
import PositionsSlotsUsersInfosCheckOutMobile from '../positions/mobile/show/positionSlotMembers/positionsSlotsUsersInfosCheckOutMobile.graphql';
import {
    PositionsSlotsUsersInfosCheckOutMobileMutation,
    PositionsSlotsUsersInfosCheckOutMobileMutationVariables
} from 'common/src/generated/types';
import PositionsSlotsUsersInfosResetCheckMobile from '../positions/mobile/show/positionSlotMembers/positionsSlotsUsersInfosResetCheckMobile.graphql';
import {
    PositionsSlotsUsersInfosResetCheckMobileMutation,
    PositionsSlotsUsersInfosResetCheckMobileMutationVariables
} from 'common/src/generated/types';
import PositionSlotsMobile from '../positions/mobile/show/positionSlots/positionSlotsMobile.graphql';
import {
    PositionSlotsMobileQuery,
    PositionSlotsMobileQueryVariables
} from 'common/src/generated/types';
import PositionDelete from '../positions/positionDelete.graphql';
import {
    PositionDeleteMutation,
    PositionDeleteMutationVariables
} from 'common/src/generated/types';
import Position from '../positions/show/position.graphql';
import { PositionQuery, PositionQueryVariables } from 'common/src/generated/types';
import PositionLeaderDelete from '../positions/show/positionLeaderDelete.graphql';
import {
    PositionLeaderDeleteMutation,
    PositionLeaderDeleteMutationVariables
} from 'common/src/generated/types';
import PositionLeadersAsign from '../positions/show/positionLeadersAssign.graphql';
import {
    PositionLeadersAsignMutation,
    PositionLeadersAsignMutationVariables
} from 'common/src/generated/types';
import PositionSlotUsersInfosCheckIn from '../positions/show/positionSlotUsersInfosCheckIn.graphql';
import {
    PositionSlotUsersInfosCheckInMutation,
    PositionSlotUsersInfosCheckInMutationVariables
} from 'common/src/generated/types';
import PositionSlotUsersInfosCheckOut from '../positions/show/positionSlotUsersInfosCheckOut.graphql';
import {
    PositionSlotUsersInfosCheckOutMutation,
    PositionSlotUsersInfosCheckOutMutationVariables
} from 'common/src/generated/types';
import PositionSlotDelete from '../positions/show/positionSlots/positionSlotDelete.graphql';
import {
    PositionSlotDeleteMutation,
    PositionSlotDeleteMutationVariables
} from 'common/src/generated/types';
import PositionSlotUpdate from '../positions/show/positionSlots/positionSlotUpdate.graphql';
import {
    PositionSlotUpdateMutation,
    PositionSlotUpdateMutationVariables
} from 'common/src/generated/types';
import PositionSlotVolunteers from '../positions/show/positionSlots/positionSlotVolunteers.graphql';
import {
    PositionSlotVolunteersQuery,
    PositionSlotVolunteersQueryVariables
} from 'common/src/generated/types';
import PositionSlots from '../positions/show/positionSlots/positionSlots.graphql';
import { PositionSlotsQuery, PositionSlotsQueryVariables } from 'common/src/generated/types';
import VolunteerRegistrationSlotUnassign from '../positions/show/positionSlots/volunteerRegistrationSlotUnassign.graphql';
import {
    VolunteerRegistrationSlotUnassignMutation,
    VolunteerRegistrationSlotUnassignMutationVariables
} from 'common/src/generated/types';
import PositionUpdateColor from '../positions/show/positionUpdateColor.graphql';
import {
    PositionUpdateColorMutation,
    PositionUpdateColorMutationVariables
} from 'common/src/generated/types';
import PositionUpdateIsVisible from '../positions/show/positionUpdateIsVisible.graphql';
import {
    PositionUpdateIsVisibleMutation,
    PositionUpdateIsVisibleMutationVariables
} from 'common/src/generated/types';
import PositionCategoryCreate from '../positionsCategories/create/positionCategoryCreate.graphql';
import {
    PositionCategoryCreateMutation,
    PositionCategoryCreateMutationVariables
} from 'common/src/generated/types';
import PositionCategoryUpdate from '../positionsCategories/create/positionCategoryUpdate.graphql';
import {
    PositionCategoryUpdateMutation,
    PositionCategoryUpdateMutationVariables
} from 'common/src/generated/types';
import PositionCategory from '../positionsCategories/show/positionCategory.graphql';
import { PositionCategoryQuery, PositionCategoryQueryVariables } from 'common/src/generated/types';
import PositionCategoryDescriptionUpdate from '../positionsCategories/show/positionCategoryDescriptionUpdate.graphql';
import {
    PositionCategoryDescriptionUpdateMutation,
    PositionCategoryDescriptionUpdateMutationVariables
} from 'common/src/generated/types';
import PositionCategoryPositionVolunteers from '../positionsCategories/show/positions/positionCategoryPositionVolunteers.graphql';
import {
    PositionCategoryPositionVolunteersQuery,
    PositionCategoryPositionVolunteersQueryVariables
} from 'common/src/generated/types';
import PositionCategoryPositions from '../positionsCategories/show/positions/positionCategoryPositions.graphql';
import {
    PositionCategoryPositionsQuery,
    PositionCategoryPositionsQueryVariables
} from 'common/src/generated/types';
import PositionsCategoryLeadersAssign from '../positionsCategories/show/positionsCategoryLeadersAssign.graphql';
import {
    PositionsCategoryLeadersAssignMutation,
    PositionsCategoryLeadersAssignMutationVariables
} from 'common/src/generated/types';
import AvailableVolunteersRegistrations from '../positionsSlots/assignVolunteers/availableVolunteersRegistrations.graphql';
import {
    AvailableVolunteersRegistrationsQuery,
    AvailableVolunteersRegistrationsQueryVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsAssign from '../positionsSlots/assignVolunteers/volunteersRegistrationsAssign.graphql';
import {
    VolunteersRegistrationsAssignMutation,
    VolunteersRegistrationsAssignMutationVariables
} from 'common/src/generated/types';
import PositionsPositionSlot from '../positionsSlots/list/positionsPositionSlot.graphql';
import {
    PositionsPositionSlotQuery,
    PositionsPositionSlotQueryVariables
} from 'common/src/generated/types';
import PositionsSlots from '../positionsSlots/list/positionsSlots.graphql';
import { PositionsSlotsQuery, PositionsSlotsQueryVariables } from 'common/src/generated/types';
import TagsAccreditationAssociate from '../tags/associate/tagsAccreditationAssociate.graphql';
import {
    TagsAccreditationAssociateMutation,
    TagsAccreditationAssociateMutationVariables
} from 'common/src/generated/types';
import TagsDelegationAssociate from '../tags/associate/tagsDelegationAssociate.graphql';
import {
    TagsDelegationAssociateMutation,
    TagsDelegationAssociateMutationVariables
} from 'common/src/generated/types';
import TagsPositionAssociate from '../tags/associate/tagsPositionAssociate.graphql';
import {
    TagsPositionAssociateMutation,
    TagsPositionAssociateMutationVariables
} from 'common/src/generated/types';
import UserFieldUpdate from '../userFieldUpdate.graphql';
import { UserFieldUpdateQuery, UserFieldUpdateQueryVariables } from 'common/src/generated/types';
import UserInfoMobile from '../users/mobile/show/userSheetMobile.graphql';
import { UserInfoMobileQuery, UserInfoMobileQueryVariables } from 'common/src/generated/types';
import UserOverlayAccreditations from '../users/overlay/accreditations/userOverlayAccreditations.graphql';
import {
    UserOverlayAccreditationsQuery,
    UserOverlayAccreditationsQueryVariables
} from 'common/src/generated/types';
import UserInfoAdminDelete from '../users/overlay/actions/userInfoAdminDelete.graphql';
import {
    UserInfoAdminDeleteMutation,
    UserInfoAdminDeleteMutationVariables
} from 'common/src/generated/types';
import UserOverlayCampaigns from '../users/overlay/campaigns/userOverlayCampaigns.graphql';
import {
    UserOverlayCampaignsQuery,
    UserOverlayCampaignsQueryVariables
} from 'common/src/generated/types';
import UserOverlayEvents from '../users/overlay/events/userOverlayEvents.graphql';
import {
    UserOverlayEventsQuery,
    UserOverlayEventsQueryVariables
} from 'common/src/generated/types';
import UserOverlayInformationsEvent from '../users/overlay/informations/userOverlayInformationsEvent.graphql';
import {
    UserOverlayInformationsEventQuery,
    UserOverlayInformationsEventQueryVariables
} from 'common/src/generated/types';
import UserOverlayInformationsOrganization from '../users/overlay/informations/userOverlayInformationsOrganization.graphql';
import {
    UserOverlayInformationsOrganizationQuery,
    UserOverlayInformationsOrganizationQueryVariables
} from 'common/src/generated/types';
import UserOverlayIntegrations from '../users/overlay/integrations/userOverlayIntegrations.graphql';
import {
    UserOverlayIntegrationsQuery,
    UserOverlayIntegrationsQueryVariables
} from 'common/src/generated/types';
import UserOverlayAvailablePositionsSlots from '../users/overlay/positions/userOverlayAvailablePositionsSlots.graphql';
import {
    UserOverlayAvailablePositionsSlotsQuery,
    UserOverlayAvailablePositionsSlotsQueryVariables
} from 'common/src/generated/types';
import UserOverlayPositions from '../users/overlay/positions/userOverlayPositions.graphql';
import {
    UserOverlayPositionsQuery,
    UserOverlayPositionsQueryVariables
} from 'common/src/generated/types';
import VolunteerRegistrationSlotAssign from '../users/overlay/positions/volunteerRegistrationSlotAssign.graphql';
import {
    VolunteerRegistrationSlotAssignMutation,
    VolunteerRegistrationSlotAssignMutationVariables
} from 'common/src/generated/types';
import UserOverlaySlots from '../users/overlay/slots/userOverlaySlots.graphql';
import { UserOverlaySlotsQuery, UserOverlaySlotsQueryVariables } from 'common/src/generated/types';
import UserOverlayEvent from '../users/overlay/userOverlayEvent.graphql';
import { UserOverlayEventQuery, UserOverlayEventQueryVariables } from 'common/src/generated/types';
import UserOverlayOrganization from '../users/overlay/userOverlayOrganization.graphql';
import {
    UserOverlayOrganizationQuery,
    UserOverlayOrganizationQueryVariables
} from 'common/src/generated/types';
import UserProfileAccreditations from '../users/profile/accreditations/userProfileAccreditations.graphql';
import {
    UserProfileAccreditationsQuery,
    UserProfileAccreditationsQueryVariables
} from 'common/src/generated/types';
import EventUserProfileActions from '../users/profile/actions/eventUserProfileActions.graphql';
import {
    EventUserProfileActionsQuery,
    EventUserProfileActionsQueryVariables
} from 'common/src/generated/types';
import OrganizationUserProfileActions from '../users/profile/actions/organizationUserProfileActions.graphql';
import {
    OrganizationUserProfileActionsQuery,
    OrganizationUserProfileActionsQueryVariables
} from 'common/src/generated/types';
import EventUserProfileTabs from '../users/profile/eventUserProfileTabs.graphql';
import {
    EventUserProfileTabsQuery,
    EventUserProfileTabsQueryVariables
} from 'common/src/generated/types';
import UserProfileHistoryCampaigns from '../users/profile/history/userProfileHistoryCampaigns.graphql';
import {
    UserProfileHistoryCampaignsQuery,
    UserProfileHistoryCampaignsQueryVariables
} from 'common/src/generated/types';
import UserProfileHistoryEvents from '../users/profile/history/userProfileHistoryEvents.graphql';
import {
    UserProfileHistoryEventsQuery,
    UserProfileHistoryEventsQueryVariables
} from 'common/src/generated/types';
import UserProfileInformationsEvent from '../users/profile/informations/userProfileInformationsEvent.graphql';
import {
    UserProfileInformationsEventQuery,
    UserProfileInformationsEventQueryVariables
} from 'common/src/generated/types';
import UserProfileInformationsOrganization from '../users/profile/informations/userProfileInformationsOrganization.graphql';
import {
    UserProfileInformationsOrganizationQuery,
    UserProfileInformationsOrganizationQueryVariables
} from 'common/src/generated/types';
import OrganizationUserProfileTabs from '../users/profile/organizationUserProfileTabs.graphql';
import {
    OrganizationUserProfileTabsQuery,
    OrganizationUserProfileTabsQueryVariables
} from 'common/src/generated/types';
import EventProfilePrivateFieldsUpdate from '../users/profile/privateFields/eventProfilePrivateFieldsUpdate.graphql';
import {
    EventProfilePrivateFieldsUpdateMutation,
    EventProfilePrivateFieldsUpdateMutationVariables
} from 'common/src/generated/types';
import EventUserProfilePrivateFields from '../users/profile/privateFields/eventUserProfilePrivateFields.graphql';
import {
    EventUserProfilePrivateFieldsQuery,
    EventUserProfilePrivateFieldsQueryVariables
} from 'common/src/generated/types';
import OrganizationProfilePrivateFieldsUpdate from '../users/profile/privateFields/organizationProfilePrivateFieldsUpdate.graphql';
import {
    OrganizationProfilePrivateFieldsUpdateMutation,
    OrganizationProfilePrivateFieldsUpdateMutationVariables
} from 'common/src/generated/types';
import OrganizationUserProfilePrivateFields from '../users/profile/privateFields/organizationUserProfilePrivateFields.graphql';
import {
    OrganizationUserProfilePrivateFieldsQuery,
    OrganizationUserProfilePrivateFieldsQueryVariables
} from 'common/src/generated/types';
import EmailResend from '../users/show/actions/emailResend.graphql';
import { EmailResendMutation, EmailResendMutationVariables } from 'common/src/generated/types';
import VolunteerRegistrationAccreditationStateUpdate from '../users/show/actions/volunteerRegistrationAccreditationStateUpdate.graphql';
import {
    VolunteerRegistrationAccreditationStateUpdateMutation,
    VolunteerRegistrationAccreditationStateUpdateMutationVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassWeezeventSynchronize from '../users/show/actions/volunteersRegistrationsMassWeezeventSynchronize.graphql';
import {
    VolunteersRegistrationsMassWeezeventSynchronizeMutation,
    VolunteersRegistrationsMassWeezeventSynchronizeMutationVariables
} from 'common/src/generated/types';
import CommentCreate from '../users/show/comments/commentCreate.graphql';
import { CommentCreateMutation, CommentCreateMutationVariables } from 'common/src/generated/types';
import CommentDelete from '../users/show/comments/commentDelete.graphql';
import { CommentDeleteMutation, CommentDeleteMutationVariables } from 'common/src/generated/types';
import Comments from '../users/show/comments/comments.graphql';
import { CommentsQuery, CommentsQueryVariables } from 'common/src/generated/types';
import PositionSlotUserInfoCheckIn from '../users/show/registration/positionSlotUserInfoCheckIn.graphql';
import {
    PositionSlotUserInfoCheckInMutation,
    PositionSlotUserInfoCheckInMutationVariables
} from 'common/src/generated/types';
import PositionSlotUserInfoCheckInReset from '../users/show/registration/positionSlotUserInfoCheckInReset.graphql';
import {
    PositionSlotUserInfoCheckInResetMutation,
    PositionSlotUserInfoCheckInResetMutationVariables
} from 'common/src/generated/types';
import PositionSlotUserInfoCheckOut from '../users/show/registration/positionSlotUserInfoCheckOut.graphql';
import {
    PositionSlotUserInfoCheckOutMutation,
    PositionSlotUserInfoCheckOutMutationVariables
} from 'common/src/generated/types';
import UserPanel from '../users/show/userPanel.graphql';
import { UserPanelQuery, UserPanelQueryVariables } from 'common/src/generated/types';
import UpdateUserInfosEvent from '../users/update/updateUserInfosEvent.graphql';
import {
    UpdateUserInfosEventQuery,
    UpdateUserInfosEventQueryVariables
} from 'common/src/generated/types';
import UpdateUserInfosOrganization from '../users/update/updateUserInfosOrganization.graphql';
import {
    UpdateUserInfosOrganizationQuery,
    UpdateUserInfosOrganizationQueryVariables
} from 'common/src/generated/types';
import OrganizationUsersInfos from '../volunteers/create/organizationUsersInfos.graphql';
import {
    OrganizationUsersInfosQuery,
    OrganizationUsersInfosQueryVariables
} from 'common/src/generated/types';
import UserInfoAdminCreate from '../volunteers/create/userInfoAdminCreate.graphql';
import {
    UserInfoAdminCreateMutation,
    UserInfoAdminCreateMutationVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsAdminAdd from '../volunteers/create/volunteersRegistrationsAdminAdd.graphql';
import {
    VolunteersRegistrationsAdminAddMutation,
    VolunteersRegistrationsAdminAddMutationVariables
} from 'common/src/generated/types';
import UsersInfosExport from '../volunteers/export/usersInfosExport.graphql';
import {
    UsersInfosExportMutation,
    UsersInfosExportMutationVariables
} from 'common/src/generated/types';
import VolunteersExport from '../volunteers/export/volunteersExport.graphql';
import {
    VolunteersExportMutation,
    VolunteersExportMutationVariables
} from 'common/src/generated/types';
import OrganizationCustomFieldsFront from '../volunteers/import/organizationCustomFieldsFront.graphql';
import {
    OrganizationCustomFieldsFrontQuery,
    OrganizationCustomFieldsFrontQueryVariables
} from 'common/src/generated/types';
import VolunteersImport from '../volunteers/import/volunteersImport.graphql';
import {
    VolunteersImportMutation,
    VolunteersImportMutationVariables
} from 'common/src/generated/types';
import VolunteersCalendar from '../volunteers/list/calendar/volunteersCalendar.graphql';
import {
    VolunteersCalendarQuery,
    VolunteersCalendarQueryVariables
} from 'common/src/generated/types';
import UpdateCellAccreditationsSlots from '../volunteers/list/list/updateCellAccreditationsSlots.graphql';
import {
    UpdateCellAccreditationsSlotsQuery,
    UpdateCellAccreditationsSlotsQueryVariables
} from 'common/src/generated/types';
import UpdateCellDelegations from '../volunteers/list/list/updateCellDelegations.graphql';
import {
    UpdateCellDelegationsQuery,
    UpdateCellDelegationsQueryVariables
} from 'common/src/generated/types';
import UpdateCellPositionsSlots from '../volunteers/list/list/updateCellPositionsSlots.graphql';
import {
    UpdateCellPositionsSlotsQuery,
    UpdateCellPositionsSlotsQueryVariables
} from 'common/src/generated/types';
import UpdateState from '../volunteers/list/list/updateState.graphql';
import { UpdateStateMutation, UpdateStateMutationVariables } from 'common/src/generated/types';
import UserInfoDelegationUpdate from '../volunteers/list/list/userInfoDelegationUpdate.graphql';
import {
    UserInfoDelegationUpdateMutation,
    UserInfoDelegationUpdateMutationVariables
} from 'common/src/generated/types';
import Volunteer from '../volunteers/list/list/volunteer.graphql';
import { VolunteerQuery, VolunteerQueryVariables } from 'common/src/generated/types';
import VolunteerRegistrationUpdateField from '../volunteers/list/list/volunteerRegistrationUpdateField.graphql';
import {
    VolunteerRegistrationUpdateFieldMutation,
    VolunteerRegistrationUpdateFieldMutationVariables
} from 'common/src/generated/types';
import Volunteers from '../volunteers/list/list/volunteers.graphql';
import { VolunteersQuery, VolunteersQueryVariables } from 'common/src/generated/types';
import VolunteerActions from '../volunteers/list/volunteerActions.graphql';
import { VolunteerActionsQuery, VolunteerActionsQueryVariables } from 'common/src/generated/types';
import VolunteersHeader from '../volunteers/list/volunteersHeader.graphql';
import { VolunteersHeaderQuery, VolunteersHeaderQueryVariables } from 'common/src/generated/types';
import VolunteersRegistrationsMassDelete from '../volunteers/list/volunteersRegistrationsMassDelete.graphql';
import {
    VolunteersRegistrationsMassDeleteMutation,
    VolunteersRegistrationsMassDeleteMutationVariables
} from 'common/src/generated/types';
import VolunteersSegments from '../volunteers/list/volunteersSegments.graphql';
import {
    VolunteersSegmentsQuery,
    VolunteersSegmentsQueryVariables
} from 'common/src/generated/types';
import MassAccreditAccreditations from '../volunteers/massAccredit/massAccreditAccreditations.graphql';
import {
    MassAccreditAccreditationsQuery,
    MassAccreditAccreditationsQueryVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassAccredit from '../volunteers/massAccredit/volunteersRegistrationsMassAccredit.graphql';
import {
    VolunteersRegistrationsMassAccreditMutation,
    VolunteersRegistrationsMassAccreditMutationVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassAccreditationStateUpdate from '../volunteers/massAccreditationStateUpdate/volunteersRegistrationsMassAccreditationStateUpdate.graphql';
import {
    VolunteersRegistrationsMassAccreditationStateUpdateMutation,
    VolunteersRegistrationsMassAccreditationStateUpdateMutationVariables
} from 'common/src/generated/types';
import MassAssignSlots from '../volunteers/massAssign/massAssignSlots.graphql';
import { MassAssignSlotsQuery, MassAssignSlotsQueryVariables } from 'common/src/generated/types';
import VolunteersRegistrationsMassAssign from '../volunteers/massAssign/volunteersRegistrationsMassAssign.graphql';
import {
    VolunteersRegistrationsMassAssignMutation,
    VolunteersRegistrationsMassAssignMutationVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassPreAssign from '../volunteers/massAssign/volunteersRegistrationsMassPreAssign.graphql';
import {
    VolunteersRegistrationsMassPreAssignMutation,
    VolunteersRegistrationsMassPreAssignMutationVariables
} from 'common/src/generated/types';
import MassCampaignSendEventCampaigns from '../volunteers/massCampaignSend/massCampaignSendEventCampaigns.graphql';
import {
    MassCampaignSendEventCampaignsQuery,
    MassCampaignSendEventCampaignsQueryVariables
} from 'common/src/generated/types';
import MassCampaignSendOrganizationCampaigns from '../volunteers/massCampaignSend/massCampaignSendOrganizationCampaigns.graphql';
import {
    MassCampaignSendOrganizationCampaignsQuery,
    MassCampaignSendOrganizationCampaignsQueryVariables
} from 'common/src/generated/types';
import UsersInfosMassCampaignSend from '../volunteers/massCampaignSend/usersInfosMassCampaignSend.graphql';
import {
    UsersInfosMassCampaignSendMutation,
    UsersInfosMassCampaignSendMutationVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassCampaignSend from '../volunteers/massCampaignSend/volunteersRegistrationsMassCampaignSend.graphql';
import {
    VolunteersRegistrationsMassCampaignSendMutation,
    VolunteersRegistrationsMassCampaignSendMutationVariables
} from 'common/src/generated/types';
import MassDelegationAddOrganizationDelegations from '../volunteers/massDelegationAdd/massDelegationAddOrganizationDelegations.graphql';
import {
    MassDelegationAddOrganizationDelegationsQuery,
    MassDelegationAddOrganizationDelegationsQueryVariables
} from 'common/src/generated/types';
import UsersInfosMassDelegationAdd from '../volunteers/massDelegationAdd/usersInfosMassDelegationAdd.graphql';
import {
    UsersInfosMassDelegationAddMutation,
    UsersInfosMassDelegationAddMutationVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassDelegationAdd from '../volunteers/massDelegationAdd/volunteersRegistrationsMassDelegationAdd.graphql';
import {
    VolunteersRegistrationsMassDelegationAddMutation,
    VolunteersRegistrationsMassDelegationAddMutationVariables
} from 'common/src/generated/types';
import MassEditFields from '../volunteers/massEdit/massEditFields.graphql';
import { MassEditFieldsQuery, MassEditFieldsQueryVariables } from 'common/src/generated/types';
import OrganizationUsersInfosMassEdit from '../volunteers/massEdit/organizationUsersInfosMassEdit.graphql';
import {
    OrganizationUsersInfosMassEditMutation,
    OrganizationUsersInfosMassEditMutationVariables
} from 'common/src/generated/types';
import UsersInfosMassEdit from '../volunteers/massEdit/usersInfosMassEdit.graphql';
import {
    UsersInfosMassEditMutation,
    UsersInfosMassEditMutationVariables
} from 'common/src/generated/types';
import MassFormAddEventForms from '../volunteers/massFormAdd/massFormAddEventForms.graphql';
import {
    MassFormAddEventFormsQuery,
    MassFormAddEventFormsQueryVariables
} from 'common/src/generated/types';
import MassFormAddOrganizationForms from '../volunteers/massFormAdd/massFormAddOrganizationForms.graphql';
import {
    MassFormAddOrganizationFormsQuery,
    MassFormAddOrganizationFormsQueryVariables
} from 'common/src/generated/types';
import UsersInfosMassFormAdd from '../volunteers/massFormAdd/usersInfosMassFormAdd.graphql';
import {
    UsersInfosMassFormAddMutation,
    UsersInfosMassFormAddMutationVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassFormAdd from '../volunteers/massFormAdd/volunteersRegistrationsMassFormAdd.graphql';
import {
    VolunteersRegistrationsMassFormAddMutation,
    VolunteersRegistrationsMassFormAddMutationVariables
} from 'common/src/generated/types';
import UsersInfosMassFormRemove from '../volunteers/massFormRemove/usersInfosMassFormRemove.graphql';
import {
    UsersInfosMassFormRemoveMutation,
    UsersInfosMassFormRemoveMutationVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassFormRemove from '../volunteers/massFormRemove/volunteersRegistrationsMassFormRemove.graphql';
import {
    VolunteersRegistrationsMassFormRemoveMutation,
    VolunteersRegistrationsMassFormRemoveMutationVariables
} from 'common/src/generated/types';
import MassPreAssignCategories from '../volunteers/massPreAssign/massPreAssignCategories.graphql';
import {
    MassPreAssignCategoriesQuery,
    MassPreAssignCategoriesQueryVariables
} from 'common/src/generated/types';
import MassPreAssignPositions from '../volunteers/massPreAssign/massPreAssignPositions.graphql';
import {
    MassPreAssignPositionsQuery,
    MassPreAssignPositionsQueryVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassStateUpdate from '../volunteers/massStateUpdate/volunteersRegistrationsMassStateUpdate.graphql';
import {
    VolunteersRegistrationsMassStateUpdateMutation,
    VolunteersRegistrationsMassStateUpdateMutationVariables
} from 'common/src/generated/types';
import VolunteersMobile from '../volunteers/mobile/list/volunteersMobile.graphql';
import { VolunteersMobileQuery, VolunteersMobileQueryVariables } from 'common/src/generated/types';
import VolunteersSegmentsMobile from '../volunteers/mobile/list/volunteersSegmentsMobile.graphql';
import {
    VolunteersSegmentsMobileQuery,
    VolunteersSegmentsMobileQueryVariables
} from 'common/src/generated/types';
import RecentVolunteersSearchesMobile from '../volunteers/mobile/search/recentVolunteersSearchesMobile.graphql';
import {
    RecentVolunteersSearchesMobileQuery,
    RecentVolunteersSearchesMobileQueryVariables
} from 'common/src/generated/types';
import VolunteerInfo from '../volunteers/update/volunteerInfos.graphql';
import { VolunteerInfoQuery, VolunteerInfoQueryVariables } from 'common/src/generated/types';

import { Fragments } from 'common/src/generated/fragments';
import { IFragment } from 'common/src/generated/fragments';

export interface IQueryFragments {
    query: string;
    fragments: IFragment[];
}

export const Queries = {
    AccreditationAvailableVolunteersRegistrations,
    AccreditationEventInfos,
    AccreditationToEdit,
    Accreditations,
    AccreditationsAccreditation,
    AccreditationsCategories,
    AccreditationsSegments,
    AccreditationMap,
    Accreditation,
    AccreditationSlots,
    AccreditationUsers,
    AccreditInfos,
    AccreditSlots,
    AccreditationsAssignmentsEmails,
    AccreditationsAssignmentsKpis,
    AccreditationsAccreditationSlot,
    AccreditationsSlotsList,
    EventKey,
    WeezeventEvents,
    WeezeventTickets,
    CreateWeezeventRuleInfos,
    WeezeventRuleToEdit,
    WeezeventRules,
    WeezeventConnectionInfos,
    AssignmentInfos,
    AssignmentSlots,
    AssignmentsEmails,
    AssignmentsKpis,
    PreAssignmentsKpis,
    SendEmailInfos,
    TeamAssignmentInfos,
    TeamAssignmentWaitingTeams,
    Tokens,
    BackofficeOrganization,
    BackofficeOrganizations,
    BackofficeInvoices,
    QontoTransactions,
    CampaignOrganizationToEdit,
    CampaignToEdit,
    CreateCampaignInfos,
    CreateOrganizationCampaignInfos,
    CampaignEventAccreditationsRecipients,
    CampaignEventPositionsRecipients,
    CampaignEventSegmentsRecipients,
    CampaignEventUsersRecipients,
    CampaignOrganizationSegmentsRecipients,
    CampaignOrganizationUsersRecipients,
    Campaigns,
    Campaign,
    CampaignEmails,
    CampaignTextMessages,
    TextMessageReplies,
    CampaignsSegments,
    CommunityCampaigns,
    CommunityDashboard,
    CommunityDelegationsSegments,
    CommunityUsers,
    MergeMembers,
    UserInfoCommunity,
    UsersActions,
    UsersSegments,
    PossibleDuplicates,
    CustomFieldToEdit,
    OrganizationInfos,
    CustomFieldUsage,
    CustomFields,
    CustomFieldsSegments,
    Dashboard,
    DashboardMobile,
    CreateEventDelegationLeaders,
    CreateOrganizationDelegationLeaders,
    DelegationEventInfos,
    DelegationEventToEdit,
    DelegationOrganizationInfos,
    DelegationOrganizationToEdit,
    EventUsersInfos,
    Delegations,
    DelegationsDelegation,
    DelegationsSegments,
    ParentDelegations,
    EventDelegationsMassEditInfos,
    OrganizationMassEditInfos,
    DelegationDashboard,
    Delegation,
    Documents,
    DocumentsAssignedUsers,
    DocumentsPositions,
    PositionPlanningDocument,
    PositionSheetDocument,
    PlanningDocument,
    PositionsVolunteersPlanningDocument,
    UserAssignmentsDocument,
    UserBadgeDocument,
    UserCertificateDocument,
    UserCustomBadgeDocument,
    UserPlanningDaysDocument,
    UserPlanningDocument,
    UserPlanningListDocument,
    UsersListingDocument,
    EmailTemplate,
    EmailsTemplates,
    AllEvents,
    EventCreateInfos,
    EventUpdateInfos,
    Events,
    EventsSegments,
    EventsMobile,
    Event,
    FilesImportCustomFields,
    EventForms,
    OrganizationForms,
    Form,
    FormElements,
    FormParametersAccreditations,
    FormParametersAccreditationsHiddenDisplayed,
    FormParametersAvailabilities,
    FormParametersBroadcastEvent,
    FormParametersBroadcastOrganization,
    FormParametersDesign,
    FormParametersGeneral,
    FormParametersMissions,
    FormParametersMissionsHiddenDisplayed,
    FormParametersState,
    HomeOrganizations,
    EventDesign,
    EventLinks,
    Fields,
    EventParameters,
    CollaboratorsVolunteers,
    Organizations,
    Organization,
    UserInfo,
    Activities,
    OrganizationCategoriesAccreditations,
    OrganizationCategoriesCustomFields,
    OrganizationCategoriesPositions,
    OrganizationDelegationSpaceSettings,
    OrganizationDelegationsSpaceDesign,
    OrganizationDomains,
    EmailSenderToEdit,
    EmailsSenders,
    EmailSenderPreview,
    OrganizationParametersInfos,
    Invoices,
    OrganizationMembersSpaceDesign,
    OrganizationMembersSpaceSettings,
    Profile,
    SkidataConnectionInfos,
    OrganizationTags,
    EventAdminsLeaders,
    Team,
    OrganizationTranslations,
    OrganizationTranslationsStats,
    PositionEventInfos,
    PositionToEdit,
    KmlEventInfos,
    PositionsCalendar,
    PositionsCategories,
    PositionsPosition,
    Traces,
    Positions,
    PositionsHeader,
    PositionsSegments,
    PositionMap,
    MassConditionsCustomFields,
    PositionsMassEditInfos,
    PositionsMobile,
    PositionsSegmentsMobile,
    RecentPositionsSearchesMobile,
    PositionMobile,
    PositionSlotMembersMobile,
    PositionSlotsMobile,
    Position,
    PositionSlotVolunteers,
    PositionSlots,
    PositionCategory,
    PositionCategoryPositionVolunteers,
    PositionCategoryPositions,
    AvailableVolunteersRegistrations,
    PositionsPositionSlot,
    PositionsSlots,
    UserFieldUpdate,
    UserInfoMobile,
    UserOverlayAccreditations,
    UserOverlayCampaigns,
    UserOverlayEvents,
    UserOverlayInformationsEvent,
    UserOverlayInformationsOrganization,
    UserOverlayIntegrations,
    UserOverlayAvailablePositionsSlots,
    UserOverlayPositions,
    UserOverlaySlots,
    UserOverlayEvent,
    UserOverlayOrganization,
    UserProfileAccreditations,
    EventUserProfileActions,
    OrganizationUserProfileActions,
    EventUserProfileTabs,
    UserProfileHistoryCampaigns,
    UserProfileHistoryEvents,
    UserProfileInformationsEvent,
    UserProfileInformationsOrganization,
    OrganizationUserProfileTabs,
    EventUserProfilePrivateFields,
    OrganizationUserProfilePrivateFields,
    Comments,
    UserPanel,
    UpdateUserInfosEvent,
    UpdateUserInfosOrganization,
    OrganizationUsersInfos,
    OrganizationCustomFieldsFront,
    VolunteersCalendar,
    UpdateCellAccreditationsSlots,
    UpdateCellDelegations,
    UpdateCellPositionsSlots,
    Volunteer,
    Volunteers,
    VolunteerActions,
    VolunteersHeader,
    VolunteersSegments,
    MassAccreditAccreditations,
    MassAssignSlots,
    MassCampaignSendEventCampaigns,
    MassCampaignSendOrganizationCampaigns,
    MassDelegationAddOrganizationDelegations,
    MassEditFields,
    MassFormAddEventForms,
    MassFormAddOrganizationForms,
    MassPreAssignCategories,
    MassPreAssignPositions,
    VolunteersMobile,
    VolunteersSegmentsMobile,
    RecentVolunteersSearchesMobile,
    VolunteerInfo
};

export const Mutations = {
    VolunteersRegistrationsAccredit,
    AccreditationDelete,
    AccreditationCategoryCreate,
    AccreditationCreate,
    AccreditationUpdate,
    AccreditationCategoryDelete,
    AccreditationUpdateField,
    AccreditationsMassDelete,
    AccreditationsEmailsEstimate,
    SendAccreditationsEmails,
    AccreditationCategoryUpdate,
    AccreditationSlotUpdate,
    WeezeventConnectionInfosUpsert,
    WeezeventRuleCreate,
    WeezeventRuleDelete,
    WeezeventRuleUpdate,
    WeezeventConnectionInfosDelete,
    WeezeventVolunteersSynchronize,
    VolunteerRegistrationAssign,
    VolunteerRegistrationAssignmentRefuse,
    VolunteerRegistrationPreAssign,
    AssignmentsEmailsEstimate,
    SendAssignmentsEmails,
    TeamAssign,
    TeamAssignmentRefuse,
    BackofficeOrganizationUpdate,
    BackofficeOrganizationUserAdd,
    BackofficeOrganizationUserRemove,
    BackofficeInvoiceMarkAsPaid,
    CampaignCreate,
    CampaignEstimate,
    CampaignUpdate,
    CampaignDelete,
    CampaignDuplicate,
    CampaignSend,
    TextMessageSend,
    TextMessageRepliesRead,
    UsersInfosMassAddEvent,
    UsersInfosMerge,
    UserInfoUpdateField,
    UsersInfosMassDelete,
    CustomFieldCategoryCreate,
    CustomFieldCreate,
    CustomFieldUpdate,
    CustomFieldCategoryDelete,
    CustomFieldDelete,
    CustomFieldDuplicate,
    CustomFieldCategoryUpdate,
    DelegationCreate,
    DelegationUpdate,
    DelegationsImport,
    DelegationDelete,
    DelegationDuplicate,
    DelegationUpdateField,
    DelegationsMassDelete,
    DelegationsMassEdit,
    DelegationLock,
    DelegationUnlock,
    EventDocumentUpdate,
    EventDocumentsExport,
    EmailTemplateUpdate,
    CreateEvent,
    EventOrganizationCreate,
    EventUpdate,
    EventArchive,
    EventDuplicate,
    FilesImport,
    FormCreate,
    FormDelete,
    FormDuplicate,
    FormElementsUpdate,
    FormParametersAccreditationsUpdate,
    FormParametersAvailabilitiesUpdate,
    FormParametersBroadcastUpdate,
    FormParametersDesignUpdate,
    FormParametersGeneralUpdate,
    FormParametersMissionsUpdate,
    FormParametersStateUpdate,
    EventDesignUpdate,
    EventLinksUpdate,
    EventsFieldsUpdate,
    EventParametersUpdate,
    DelegationsImportResult,
    EventDocumentsExportResult,
    ExportResult,
    FilesImportResult,
    VolunteersImportResult,
    OrganizationCreate,
    OrganizationArchive,
    UserInfoAndTermsUpdate,
    OrganizationDelegationSpaceSettingsUpdate,
    OrganizationDelegationsSpaceDesignUpdate,
    DomainDkimVerificationRetry,
    EmailSenderCreate,
    EmailSenderUpdate,
    EmailSenderDelete,
    EmailSenderDuplicate,
    EmailSenderVerificationResend,
    OrganizationUpdateInfos,
    OrganizationMembersSpaceDesignUpdate,
    OrganizationMembersSpaceSettingsUpdate,
    UserDelete,
    UserProfileUpdate,
    SkidataConnectionInfoSeaonEventAdd,
    SkidataConnectionInfoSeaonEventDelete,
    SkidataConnectionInfoSynchronizedEventAdd,
    SkidataConnectionInfoSynchronizedEventDelete,
    SkidataConnectionInfosDelete,
    SkidataConnectionInfosUpsert,
    SkidataSynchronize,
    TagCreate,
    TagDelete,
    TagUpdateName,
    EmailEventInviteResend,
    EmailOrganizationInviteResend,
    EventInvite,
    OrganizationInvite,
    UserEventDelete,
    UserLeaderDelete,
    UserOrganizationDelete,
    UserOrganizationUpdate,
    UserPositionCategoryUpdate,
    UserPositionUpdate,
    TranslationUpdate,
    PositionCreate,
    PositionUpdate,
    PositionsCreate,
    PositionUpdateField,
    PositionsMassDelete,
    EventLayerUpdate,
    TraceCreate,
    TraceDelete,
    TraceUpdate,
    PositionCategoryDelete,
    PositionCategoryLeaderDelete,
    PositionsMassConditions,
    AccreditationsMassEdit,
    PositionsMassEdit,
    PositionsSlotsUsersInfosCheckInMobile,
    PositionsSlotsUsersInfosCheckOutMobile,
    PositionsSlotsUsersInfosResetCheckMobile,
    PositionDelete,
    PositionLeaderDelete,
    PositionLeadersAsign,
    PositionSlotUsersInfosCheckIn,
    PositionSlotUsersInfosCheckOut,
    PositionSlotDelete,
    PositionSlotUpdate,
    VolunteerRegistrationSlotUnassign,
    PositionUpdateColor,
    PositionUpdateIsVisible,
    PositionCategoryCreate,
    PositionCategoryUpdate,
    PositionCategoryDescriptionUpdate,
    PositionsCategoryLeadersAssign,
    VolunteersRegistrationsAssign,
    TagsAccreditationAssociate,
    TagsDelegationAssociate,
    TagsPositionAssociate,
    UserInfoAdminDelete,
    VolunteerRegistrationSlotAssign,
    EventProfilePrivateFieldsUpdate,
    OrganizationProfilePrivateFieldsUpdate,
    EmailResend,
    VolunteerRegistrationAccreditationStateUpdate,
    VolunteersRegistrationsMassWeezeventSynchronize,
    CommentCreate,
    CommentDelete,
    PositionSlotUserInfoCheckIn,
    PositionSlotUserInfoCheckInReset,
    PositionSlotUserInfoCheckOut,
    UserInfoAdminCreate,
    VolunteersRegistrationsAdminAdd,
    UsersInfosExport,
    VolunteersExport,
    VolunteersImport,
    UpdateState,
    UserInfoDelegationUpdate,
    VolunteerRegistrationUpdateField,
    VolunteersRegistrationsMassDelete,
    VolunteersRegistrationsMassAccredit,
    VolunteersRegistrationsMassAccreditationStateUpdate,
    VolunteersRegistrationsMassAssign,
    VolunteersRegistrationsMassPreAssign,
    UsersInfosMassCampaignSend,
    VolunteersRegistrationsMassCampaignSend,
    UsersInfosMassDelegationAdd,
    VolunteersRegistrationsMassDelegationAdd,
    OrganizationUsersInfosMassEdit,
    UsersInfosMassEdit,
    UsersInfosMassFormAdd,
    VolunteersRegistrationsMassFormAdd,
    UsersInfosMassFormRemove,
    VolunteersRegistrationsMassFormRemove,
    VolunteersRegistrationsMassStateUpdate
};

export function useAccreditationAvailableVolunteersRegistrationsQuery(
    args: AccreditationAvailableVolunteersRegistrationsQueryVariables
): IUseQueryReturns<AccreditationAvailableVolunteersRegistrationsQuery> {
    return useQuery(Queries.AccreditationAvailableVolunteersRegistrations, args, []);
}

export function executeAccreditationAvailableVolunteersRegistrationsQuery(
    args: AccreditationAvailableVolunteersRegistrationsQueryVariables,
    token: string | null | undefined
): Promise<AccreditationAvailableVolunteersRegistrationsQuery> {
    return executeQuery<AccreditationAvailableVolunteersRegistrationsQuery>(
        Queries.AccreditationAvailableVolunteersRegistrations,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersRegistrationsAccreditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsAccreditMutation,
    VolunteersRegistrationsAccreditMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsAccredit, args);
}

export function useAccreditationDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<AccreditationDeleteMutation, AccreditationDeleteMutationVariables> {
    return useMutation(Mutations.AccreditationDelete, args);
}

export function useAccreditationCategoryCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    AccreditationCategoryCreateMutation,
    AccreditationCategoryCreateMutationVariables
> {
    return useMutation(Mutations.AccreditationCategoryCreate, args);
}

export function useAccreditationCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<AccreditationCreateMutation, AccreditationCreateMutationVariables> {
    return useMutation(Mutations.AccreditationCreate, args);
}

export function useAccreditationEventInfosQuery(
    args: AccreditationEventInfosQueryVariables
): IUseQueryReturns<AccreditationEventInfosQuery> {
    return useQuery(Queries.AccreditationEventInfos, args, [
        Fragments.CreatePositionCustomFieldsFragment,
        Fragments.CreatePositionTagsFragment,
        Fragments.CreateAccreditationEventFragment
    ]);
}

export function executeAccreditationEventInfosQuery(
    args: AccreditationEventInfosQueryVariables,
    token: string | null | undefined
): Promise<AccreditationEventInfosQuery> {
    return executeQuery<AccreditationEventInfosQuery>(Queries.AccreditationEventInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CreatePositionCustomFieldsFragment,
            Fragments.CreatePositionTagsFragment,
            Fragments.CreateAccreditationEventFragment
        ]
    });
}

export function useAccreditationToEditQuery(
    args: AccreditationToEditQueryVariables
): IUseQueryReturns<AccreditationToEditQuery> {
    return useQuery(Queries.AccreditationToEdit, args, [
        Fragments.CreatePositionCustomFieldsFragment,
        Fragments.CreatePositionTagsFragment,
        Fragments.CreateAccreditationEventFragment
    ]);
}

export function executeAccreditationToEditQuery(
    args: AccreditationToEditQueryVariables,
    token: string | null | undefined
): Promise<AccreditationToEditQuery> {
    return executeQuery<AccreditationToEditQuery>(Queries.AccreditationToEdit, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CreatePositionCustomFieldsFragment,
            Fragments.CreatePositionTagsFragment,
            Fragments.CreateAccreditationEventFragment
        ]
    });
}

export function useAccreditationUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<AccreditationUpdateMutation, AccreditationUpdateMutationVariables> {
    return useMutation(Mutations.AccreditationUpdate, args);
}

export function useAccreditationCategoryDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    AccreditationCategoryDeleteMutation,
    AccreditationCategoryDeleteMutationVariables
> {
    return useMutation(Mutations.AccreditationCategoryDelete, args);
}

export function useAccreditationUpdateFieldMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    AccreditationUpdateFieldMutation,
    AccreditationUpdateFieldMutationVariables
> {
    return useMutation(Mutations.AccreditationUpdateField, args);
}

export function useAccreditationsQuery(
    args: AccreditationsQueryVariables
): IUseQueryReturns<AccreditationsQuery> {
    return useQuery(Queries.Accreditations, args, [Fragments.AccreditationsAccreditationFragment]);
}

export function executeAccreditationsQuery(
    args: AccreditationsQueryVariables,
    token: string | null | undefined
): Promise<AccreditationsQuery> {
    return executeQuery<AccreditationsQuery>(Queries.Accreditations, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.AccreditationsAccreditationFragment]
    });
}

export function useAccreditationsAccreditationQuery(
    args: AccreditationsAccreditationQueryVariables
): IUseQueryReturns<AccreditationsAccreditationQuery> {
    return useQuery(Queries.AccreditationsAccreditation, args, [
        Fragments.AccreditationsAccreditationFragment
    ]);
}

export function executeAccreditationsAccreditationQuery(
    args: AccreditationsAccreditationQueryVariables,
    token: string | null | undefined
): Promise<AccreditationsAccreditationQuery> {
    return executeQuery<AccreditationsAccreditationQuery>(Queries.AccreditationsAccreditation, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.AccreditationsAccreditationFragment]
    });
}

export function useAccreditationsCategoriesQuery(
    args: AccreditationsCategoriesQueryVariables
): IUseQueryReturns<AccreditationsCategoriesQuery> {
    return useQuery(Queries.AccreditationsCategories, args, []);
}

export function executeAccreditationsCategoriesQuery(
    args: AccreditationsCategoriesQueryVariables,
    token: string | null | undefined
): Promise<AccreditationsCategoriesQuery> {
    return executeQuery<AccreditationsCategoriesQuery>(Queries.AccreditationsCategories, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useAccreditationsMassDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    AccreditationsMassDeleteMutation,
    AccreditationsMassDeleteMutationVariables
> {
    return useMutation(Mutations.AccreditationsMassDelete, args);
}

export function useAccreditationsSegmentsQuery(
    args: AccreditationsSegmentsQueryVariables
): IUseQueryReturns<AccreditationsSegmentsQuery> {
    return useQuery(Queries.AccreditationsSegments, args, [
        Fragments.SegmentsFoldersSegmentsFragment
    ]);
}

export function executeAccreditationsSegmentsQuery(
    args: AccreditationsSegmentsQueryVariables,
    token: string | null | undefined
): Promise<AccreditationsSegmentsQuery> {
    return executeQuery<AccreditationsSegmentsQuery>(Queries.AccreditationsSegments, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentsFoldersSegmentsFragment]
    });
}

export function useAccreditationMapQuery(
    args: AccreditationMapQueryVariables
): IUseQueryReturns<AccreditationMapQuery> {
    return useQuery(Queries.AccreditationMap, args, []);
}

export function executeAccreditationMapQuery(
    args: AccreditationMapQueryVariables,
    token: string | null | undefined
): Promise<AccreditationMapQuery> {
    return executeQuery<AccreditationMapQuery>(Queries.AccreditationMap, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useAccreditationQuery(
    args: AccreditationQueryVariables
): IUseQueryReturns<AccreditationQuery> {
    return useQuery(Queries.Accreditation, args, []);
}

export function executeAccreditationQuery(
    args: AccreditationQueryVariables,
    token: string | null | undefined
): Promise<AccreditationQuery> {
    return executeQuery<AccreditationQuery>(Queries.Accreditation, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useAccreditationSlotsQuery(
    args: AccreditationSlotsQueryVariables
): IUseQueryReturns<AccreditationSlotsQuery> {
    return useQuery(Queries.AccreditationSlots, args, []);
}

export function executeAccreditationSlotsQuery(
    args: AccreditationSlotsQueryVariables,
    token: string | null | undefined
): Promise<AccreditationSlotsQuery> {
    return executeQuery<AccreditationSlotsQuery>(Queries.AccreditationSlots, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useAccreditationUsersQuery(
    args: AccreditationUsersQueryVariables
): IUseQueryReturns<AccreditationUsersQuery> {
    return useQuery(Queries.AccreditationUsers, args, [Fragments.VolunteerInfosFragment]);
}

export function executeAccreditationUsersQuery(
    args: AccreditationUsersQueryVariables,
    token: string | null | undefined
): Promise<AccreditationUsersQuery> {
    return executeQuery<AccreditationUsersQuery>(Queries.AccreditationUsers, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.VolunteerInfosFragment]
    });
}

export function useAccreditInfosQuery(
    args: AccreditInfosQueryVariables
): IUseQueryReturns<AccreditInfosQuery> {
    return useQuery(Queries.AccreditInfos, args, [
        Fragments.FormsUsersInfosWishedFragment,
        Fragments.CustomFieldWithConditionFragment,
        Fragments.DelegationAccreditationsFragment
    ]);
}

export function executeAccreditInfosQuery(
    args: AccreditInfosQueryVariables,
    token: string | null | undefined
): Promise<AccreditInfosQuery> {
    return executeQuery<AccreditInfosQuery>(Queries.AccreditInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.FormsUsersInfosWishedFragment,
            Fragments.CustomFieldWithConditionFragment,
            Fragments.DelegationAccreditationsFragment
        ]
    });
}

export function useAccreditSlotsQuery(
    args: AccreditSlotsQueryVariables
): IUseQueryReturns<AccreditSlotsQuery> {
    return useQuery(Queries.AccreditSlots, args, [Fragments.AccreditationsSlotFragment]);
}

export function executeAccreditSlotsQuery(
    args: AccreditSlotsQueryVariables,
    token: string | null | undefined
): Promise<AccreditSlotsQuery> {
    return executeQuery<AccreditSlotsQuery>(Queries.AccreditSlots, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.AccreditationsSlotFragment]
    });
}

export function useAccreditationsAssignmentsEmailsQuery(
    args: AccreditationsAssignmentsEmailsQueryVariables
): IUseQueryReturns<AccreditationsAssignmentsEmailsQuery> {
    return useQuery(Queries.AccreditationsAssignmentsEmails, args, [
        Fragments.EmailsRecipientsFragment
    ]);
}

export function executeAccreditationsAssignmentsEmailsQuery(
    args: AccreditationsAssignmentsEmailsQueryVariables,
    token: string | null | undefined
): Promise<AccreditationsAssignmentsEmailsQuery> {
    return executeQuery<AccreditationsAssignmentsEmailsQuery>(
        Queries.AccreditationsAssignmentsEmails,
        {
            variables: args,
            token: token || undefined,
            fragments: [Fragments.EmailsRecipientsFragment]
        }
    );
}

export function useAccreditationsAssignmentsKpisQuery(
    args: AccreditationsAssignmentsKpisQueryVariables
): IUseQueryReturns<AccreditationsAssignmentsKpisQuery> {
    return useQuery(Queries.AccreditationsAssignmentsKpis, args, []);
}

export function executeAccreditationsAssignmentsKpisQuery(
    args: AccreditationsAssignmentsKpisQueryVariables,
    token: string | null | undefined
): Promise<AccreditationsAssignmentsKpisQuery> {
    return executeQuery<AccreditationsAssignmentsKpisQuery>(Queries.AccreditationsAssignmentsKpis, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useAccreditationsEmailsEstimateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    AccreditationsEmailsEstimateMutation,
    AccreditationsEmailsEstimateMutationVariables
> {
    return useMutation(Mutations.AccreditationsEmailsEstimate, args);
}

export function useSendAccreditationsEmailsMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    SendAccreditationsEmailsMutation,
    SendAccreditationsEmailsMutationVariables
> {
    return useMutation(Mutations.SendAccreditationsEmails, args);
}

export function useAccreditationCategoryUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    AccreditationCategoryUpdateMutation,
    AccreditationCategoryUpdateMutationVariables
> {
    return useMutation(Mutations.AccreditationCategoryUpdate, args);
}

export function useAccreditationSlotUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<AccreditationSlotUpdateMutation, AccreditationSlotUpdateMutationVariables> {
    return useMutation(Mutations.AccreditationSlotUpdate, args);
}

export function useAccreditationsAccreditationSlotQuery(
    args: AccreditationsAccreditationSlotQueryVariables
): IUseQueryReturns<AccreditationsAccreditationSlotQuery> {
    return useQuery(Queries.AccreditationsAccreditationSlot, args, [
        Fragments.AccreditationsAccreditationSlotFragment
    ]);
}

export function executeAccreditationsAccreditationSlotQuery(
    args: AccreditationsAccreditationSlotQueryVariables,
    token: string | null | undefined
): Promise<AccreditationsAccreditationSlotQuery> {
    return executeQuery<AccreditationsAccreditationSlotQuery>(
        Queries.AccreditationsAccreditationSlot,
        {
            variables: args,
            token: token || undefined,
            fragments: [Fragments.AccreditationsAccreditationSlotFragment]
        }
    );
}

export function useAccreditationsSlotsListQuery(
    args: AccreditationsSlotsListQueryVariables
): IUseQueryReturns<AccreditationsSlotsListQuery> {
    return useQuery(Queries.AccreditationsSlotsList, args, [
        Fragments.AccreditationsAccreditationSlotFragment
    ]);
}

export function executeAccreditationsSlotsListQuery(
    args: AccreditationsSlotsListQueryVariables,
    token: string | null | undefined
): Promise<AccreditationsSlotsListQuery> {
    return executeQuery<AccreditationsSlotsListQuery>(Queries.AccreditationsSlotsList, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.AccreditationsAccreditationSlotFragment]
    });
}

export function useEventKeyQuery(args: EventKeyQueryVariables): IUseQueryReturns<EventKeyQuery> {
    return useQuery(Queries.EventKey, args, []);
}

export function executeEventKeyQuery(
    args: EventKeyQueryVariables,
    token: string | null | undefined
): Promise<EventKeyQuery> {
    return executeQuery<EventKeyQuery>(Queries.EventKey, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useWeezeventConnectionInfosUpsertMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    WeezeventConnectionInfosUpsertMutation,
    WeezeventConnectionInfosUpsertMutationVariables
> {
    return useMutation(Mutations.WeezeventConnectionInfosUpsert, args);
}

export function useWeezeventEventsQuery(
    args: WeezeventEventsQueryVariables
): IUseQueryReturns<WeezeventEventsQuery> {
    return useQuery(Queries.WeezeventEvents, args, []);
}

export function executeWeezeventEventsQuery(
    args: WeezeventEventsQueryVariables,
    token: string | null | undefined
): Promise<WeezeventEventsQuery> {
    return executeQuery<WeezeventEventsQuery>(Queries.WeezeventEvents, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useWeezeventTicketsQuery(
    args: WeezeventTicketsQueryVariables
): IUseQueryReturns<WeezeventTicketsQuery> {
    return useQuery(Queries.WeezeventTickets, args, []);
}

export function executeWeezeventTicketsQuery(
    args: WeezeventTicketsQueryVariables,
    token: string | null | undefined
): Promise<WeezeventTicketsQuery> {
    return executeQuery<WeezeventTicketsQuery>(Queries.WeezeventTickets, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useCreateWeezeventRuleInfosQuery(
    args: CreateWeezeventRuleInfosQueryVariables
): IUseQueryReturns<CreateWeezeventRuleInfosQuery> {
    return useQuery(Queries.CreateWeezeventRuleInfos, args, [
        Fragments.CreateWeezeventRuleFragment
    ]);
}

export function executeCreateWeezeventRuleInfosQuery(
    args: CreateWeezeventRuleInfosQueryVariables,
    token: string | null | undefined
): Promise<CreateWeezeventRuleInfosQuery> {
    return executeQuery<CreateWeezeventRuleInfosQuery>(Queries.CreateWeezeventRuleInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CreateWeezeventRuleFragment]
    });
}

export function useWeezeventRuleCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<WeezeventRuleCreateMutation, WeezeventRuleCreateMutationVariables> {
    return useMutation(Mutations.WeezeventRuleCreate, args);
}

export function useWeezeventRuleDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<WeezeventRuleDeleteMutation, WeezeventRuleDeleteMutationVariables> {
    return useMutation(Mutations.WeezeventRuleDelete, args);
}

export function useWeezeventRuleToEditQuery(
    args: WeezeventRuleToEditQueryVariables
): IUseQueryReturns<WeezeventRuleToEditQuery> {
    return useQuery(Queries.WeezeventRuleToEdit, args, [Fragments.CreateWeezeventRuleFragment]);
}

export function executeWeezeventRuleToEditQuery(
    args: WeezeventRuleToEditQueryVariables,
    token: string | null | undefined
): Promise<WeezeventRuleToEditQuery> {
    return executeQuery<WeezeventRuleToEditQuery>(Queries.WeezeventRuleToEdit, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CreateWeezeventRuleFragment]
    });
}

export function useWeezeventRuleUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<WeezeventRuleUpdateMutation, WeezeventRuleUpdateMutationVariables> {
    return useMutation(Mutations.WeezeventRuleUpdate, args);
}

export function useWeezeventConnectionInfosDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    WeezeventConnectionInfosDeleteMutation,
    WeezeventConnectionInfosDeleteMutationVariables
> {
    return useMutation(Mutations.WeezeventConnectionInfosDelete, args);
}

export function useWeezeventRulesQuery(
    args: WeezeventRulesQueryVariables
): IUseQueryReturns<WeezeventRulesQuery> {
    return useQuery(Queries.WeezeventRules, args, []);
}

export function executeWeezeventRulesQuery(
    args: WeezeventRulesQueryVariables,
    token: string | null | undefined
): Promise<WeezeventRulesQuery> {
    return executeQuery<WeezeventRulesQuery>(Queries.WeezeventRules, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useWeezeventVolunteersSynchronizeMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    WeezeventVolunteersSynchronizeMutation,
    WeezeventVolunteersSynchronizeMutationVariables
> {
    return useMutation(Mutations.WeezeventVolunteersSynchronize, args);
}

export function useWeezeventConnectionInfosQuery(
    args: WeezeventConnectionInfosQueryVariables
): IUseQueryReturns<WeezeventConnectionInfosQuery> {
    return useQuery(Queries.WeezeventConnectionInfos, args, []);
}

export function executeWeezeventConnectionInfosQuery(
    args: WeezeventConnectionInfosQueryVariables,
    token: string | null | undefined
): Promise<WeezeventConnectionInfosQuery> {
    return executeQuery<WeezeventConnectionInfosQuery>(Queries.WeezeventConnectionInfos, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useAssignmentInfosQuery(
    args: AssignmentInfosQueryVariables
): IUseQueryReturns<AssignmentInfosQuery> {
    return useQuery(Queries.AssignmentInfos, args, [
        Fragments.FormsUsersInfosWishedFragment,
        Fragments.CustomFieldWithConditionFragment
    ]);
}

export function executeAssignmentInfosQuery(
    args: AssignmentInfosQueryVariables,
    token: string | null | undefined
): Promise<AssignmentInfosQuery> {
    return executeQuery<AssignmentInfosQuery>(Queries.AssignmentInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.FormsUsersInfosWishedFragment,
            Fragments.CustomFieldWithConditionFragment
        ]
    });
}

export function useAssignmentSlotsQuery(
    args: AssignmentSlotsQueryVariables
): IUseQueryReturns<AssignmentSlotsQuery> {
    return useQuery(Queries.AssignmentSlots, args, [
        Fragments.PositionPositionsCustomFieldsFragment
    ]);
}

export function executeAssignmentSlotsQuery(
    args: AssignmentSlotsQueryVariables,
    token: string | null | undefined
): Promise<AssignmentSlotsQuery> {
    return executeQuery<AssignmentSlotsQuery>(Queries.AssignmentSlots, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.PositionPositionsCustomFieldsFragment]
    });
}

export function useVolunteerRegistrationAssignMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteerRegistrationAssignMutation,
    VolunteerRegistrationAssignMutationVariables
> {
    return useMutation(Mutations.VolunteerRegistrationAssign, args);
}

export function useVolunteerRegistrationAssignmentRefuseMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteerRegistrationAssignmentRefuseMutation,
    VolunteerRegistrationAssignmentRefuseMutationVariables
> {
    return useMutation(Mutations.VolunteerRegistrationAssignmentRefuse, args);
}

export function useVolunteerRegistrationPreAssignMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteerRegistrationPreAssignMutation,
    VolunteerRegistrationPreAssignMutationVariables
> {
    return useMutation(Mutations.VolunteerRegistrationPreAssign, args);
}

export function useAssignmentsEmailsQuery(
    args: AssignmentsEmailsQueryVariables
): IUseQueryReturns<AssignmentsEmailsQuery> {
    return useQuery(Queries.AssignmentsEmails, args, [Fragments.EmailsRecipientsFragment]);
}

export function executeAssignmentsEmailsQuery(
    args: AssignmentsEmailsQueryVariables,
    token: string | null | undefined
): Promise<AssignmentsEmailsQuery> {
    return executeQuery<AssignmentsEmailsQuery>(Queries.AssignmentsEmails, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.EmailsRecipientsFragment]
    });
}

export function useAssignmentsEmailsEstimateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    AssignmentsEmailsEstimateMutation,
    AssignmentsEmailsEstimateMutationVariables
> {
    return useMutation(Mutations.AssignmentsEmailsEstimate, args);
}

export function useAssignmentsKpisQuery(
    args: AssignmentsKpisQueryVariables
): IUseQueryReturns<AssignmentsKpisQuery> {
    return useQuery(Queries.AssignmentsKpis, args, []);
}

export function executeAssignmentsKpisQuery(
    args: AssignmentsKpisQueryVariables,
    token: string | null | undefined
): Promise<AssignmentsKpisQuery> {
    return executeQuery<AssignmentsKpisQuery>(Queries.AssignmentsKpis, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function usePreAssignmentsKpisQuery(
    args: PreAssignmentsKpisQueryVariables
): IUseQueryReturns<PreAssignmentsKpisQuery> {
    return useQuery(Queries.PreAssignmentsKpis, args, []);
}

export function executePreAssignmentsKpisQuery(
    args: PreAssignmentsKpisQueryVariables,
    token: string | null | undefined
): Promise<PreAssignmentsKpisQuery> {
    return executeQuery<PreAssignmentsKpisQuery>(Queries.PreAssignmentsKpis, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useSendAssignmentsEmailsMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SendAssignmentsEmailsMutation, SendAssignmentsEmailsMutationVariables> {
    return useMutation(Mutations.SendAssignmentsEmails, args);
}

export function useSendEmailInfosQuery(
    args: SendEmailInfosQueryVariables
): IUseQueryReturns<SendEmailInfosQuery> {
    return useQuery(Queries.SendEmailInfos, args, []);
}

export function executeSendEmailInfosQuery(
    args: SendEmailInfosQueryVariables,
    token: string | null | undefined
): Promise<SendEmailInfosQuery> {
    return executeQuery<SendEmailInfosQuery>(Queries.SendEmailInfos, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useTeamAssignMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<TeamAssignMutation, TeamAssignMutationVariables> {
    return useMutation(Mutations.TeamAssign, args);
}

export function useTeamAssignmentInfosQuery(
    args: TeamAssignmentInfosQueryVariables
): IUseQueryReturns<TeamAssignmentInfosQuery> {
    return useQuery(Queries.TeamAssignmentInfos, args, [
        Fragments.CustomFieldWithConditionFragment,
        Fragments.FormsUsersInfosWishedFragment
    ]);
}

export function executeTeamAssignmentInfosQuery(
    args: TeamAssignmentInfosQueryVariables,
    token: string | null | undefined
): Promise<TeamAssignmentInfosQuery> {
    return executeQuery<TeamAssignmentInfosQuery>(Queries.TeamAssignmentInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CustomFieldWithConditionFragment,
            Fragments.FormsUsersInfosWishedFragment
        ]
    });
}

export function useTeamAssignmentRefuseMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<TeamAssignmentRefuseMutation, TeamAssignmentRefuseMutationVariables> {
    return useMutation(Mutations.TeamAssignmentRefuse, args);
}

export function useTeamAssignmentWaitingTeamsQuery(
    args: TeamAssignmentWaitingTeamsQueryVariables
): IUseQueryReturns<TeamAssignmentWaitingTeamsQuery> {
    return useQuery(Queries.TeamAssignmentWaitingTeams, args, [
        Fragments.VolunteerRegistrationWaitingAssignmentFragment
    ]);
}

export function executeTeamAssignmentWaitingTeamsQuery(
    args: TeamAssignmentWaitingTeamsQueryVariables,
    token: string | null | undefined
): Promise<TeamAssignmentWaitingTeamsQuery> {
    return executeQuery<TeamAssignmentWaitingTeamsQuery>(Queries.TeamAssignmentWaitingTeams, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.VolunteerRegistrationWaitingAssignmentFragment]
    });
}

export function useTokensQuery(args: TokensQueryVariables): IUseQueryReturns<TokensQuery> {
    return useQuery(Queries.Tokens, args, []);
}

export function executeTokensQuery(
    args: TokensQueryVariables,
    token: string | null | undefined
): Promise<TokensQuery> {
    return executeQuery<TokensQuery>(Queries.Tokens, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useBackofficeOrganizationQuery(
    args: BackofficeOrganizationQueryVariables
): IUseQueryReturns<BackofficeOrganizationQuery> {
    return useQuery(Queries.BackofficeOrganization, args, []);
}

export function executeBackofficeOrganizationQuery(
    args: BackofficeOrganizationQueryVariables,
    token: string | null | undefined
): Promise<BackofficeOrganizationQuery> {
    return executeQuery<BackofficeOrganizationQuery>(Queries.BackofficeOrganization, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useBackofficeOrganizationUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    BackofficeOrganizationUpdateMutation,
    BackofficeOrganizationUpdateMutationVariables
> {
    return useMutation(Mutations.BackofficeOrganizationUpdate, args);
}

export function useBackofficeOrganizationUserAddMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    BackofficeOrganizationUserAddMutation,
    BackofficeOrganizationUserAddMutationVariables
> {
    return useMutation(Mutations.BackofficeOrganizationUserAdd, args);
}

export function useBackofficeOrganizationUserRemoveMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    BackofficeOrganizationUserRemoveMutation,
    BackofficeOrganizationUserRemoveMutationVariables
> {
    return useMutation(Mutations.BackofficeOrganizationUserRemove, args);
}

export function useBackofficeOrganizationsQuery(
    args: BackofficeOrganizationsQueryVariables
): IUseQueryReturns<BackofficeOrganizationsQuery> {
    return useQuery(Queries.BackofficeOrganizations, args, []);
}

export function executeBackofficeOrganizationsQuery(
    args: BackofficeOrganizationsQueryVariables,
    token: string | null | undefined
): Promise<BackofficeOrganizationsQuery> {
    return executeQuery<BackofficeOrganizationsQuery>(Queries.BackofficeOrganizations, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useBackofficeInvoiceMarkAsPaidMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    BackofficeInvoiceMarkAsPaidMutation,
    BackofficeInvoiceMarkAsPaidMutationVariables
> {
    return useMutation(Mutations.BackofficeInvoiceMarkAsPaid, args);
}

export function useBackofficeInvoicesQuery(
    args: BackofficeInvoicesQueryVariables
): IUseQueryReturns<BackofficeInvoicesQuery> {
    return useQuery(Queries.BackofficeInvoices, args, []);
}

export function executeBackofficeInvoicesQuery(
    args: BackofficeInvoicesQueryVariables,
    token: string | null | undefined
): Promise<BackofficeInvoicesQuery> {
    return executeQuery<BackofficeInvoicesQuery>(Queries.BackofficeInvoices, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useQontoTransactionsQuery(
    args: QontoTransactionsQueryVariables
): IUseQueryReturns<QontoTransactionsQuery> {
    return useQuery(Queries.QontoTransactions, args, []);
}

export function executeQontoTransactionsQuery(
    args: QontoTransactionsQueryVariables,
    token: string | null | undefined
): Promise<QontoTransactionsQuery> {
    return executeQuery<QontoTransactionsQuery>(Queries.QontoTransactions, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useCampaignCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<CampaignCreateMutation, CampaignCreateMutationVariables> {
    return useMutation(Mutations.CampaignCreate, args);
}

export function useCampaignEstimateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<CampaignEstimateMutation, CampaignEstimateMutationVariables> {
    return useMutation(Mutations.CampaignEstimate, args);
}

export function useCampaignOrganizationToEditQuery(
    args: CampaignOrganizationToEditQueryVariables
): IUseQueryReturns<CampaignOrganizationToEditQuery> {
    return useQuery(Queries.CampaignOrganizationToEdit, args, [
        Fragments.CampaignEditFragment,
        Fragments.CreateCampaignEmailSenderFragment
    ]);
}

export function executeCampaignOrganizationToEditQuery(
    args: CampaignOrganizationToEditQueryVariables,
    token: string | null | undefined
): Promise<CampaignOrganizationToEditQuery> {
    return executeQuery<CampaignOrganizationToEditQuery>(Queries.CampaignOrganizationToEdit, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CampaignEditFragment, Fragments.CreateCampaignEmailSenderFragment]
    });
}

export function useCampaignToEditQuery(
    args: CampaignToEditQueryVariables
): IUseQueryReturns<CampaignToEditQuery> {
    return useQuery(Queries.CampaignToEdit, args, [
        Fragments.CreateCampaignEmailSenderFragment,
        Fragments.CampaignEditFragment
    ]);
}

export function executeCampaignToEditQuery(
    args: CampaignToEditQueryVariables,
    token: string | null | undefined
): Promise<CampaignToEditQuery> {
    return executeQuery<CampaignToEditQuery>(Queries.CampaignToEdit, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CreateCampaignEmailSenderFragment, Fragments.CampaignEditFragment]
    });
}

export function useCampaignUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<CampaignUpdateMutation, CampaignUpdateMutationVariables> {
    return useMutation(Mutations.CampaignUpdate, args);
}

export function useCreateCampaignInfosQuery(
    args: CreateCampaignInfosQueryVariables
): IUseQueryReturns<CreateCampaignInfosQuery> {
    return useQuery(Queries.CreateCampaignInfos, args, [
        Fragments.CreateCampaignEmailSenderFragment
    ]);
}

export function executeCreateCampaignInfosQuery(
    args: CreateCampaignInfosQueryVariables,
    token: string | null | undefined
): Promise<CreateCampaignInfosQuery> {
    return executeQuery<CreateCampaignInfosQuery>(Queries.CreateCampaignInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CreateCampaignEmailSenderFragment]
    });
}

export function useCreateOrganizationCampaignInfosQuery(
    args: CreateOrganizationCampaignInfosQueryVariables
): IUseQueryReturns<CreateOrganizationCampaignInfosQuery> {
    return useQuery(Queries.CreateOrganizationCampaignInfos, args, [
        Fragments.CreateCampaignEmailSenderFragment
    ]);
}

export function executeCreateOrganizationCampaignInfosQuery(
    args: CreateOrganizationCampaignInfosQueryVariables,
    token: string | null | undefined
): Promise<CreateOrganizationCampaignInfosQuery> {
    return executeQuery<CreateOrganizationCampaignInfosQuery>(
        Queries.CreateOrganizationCampaignInfos,
        {
            variables: args,
            token: token || undefined,
            fragments: [Fragments.CreateCampaignEmailSenderFragment]
        }
    );
}

export function useCampaignEventAccreditationsRecipientsQuery(
    args: CampaignEventAccreditationsRecipientsQueryVariables
): IUseQueryReturns<CampaignEventAccreditationsRecipientsQuery> {
    return useQuery(Queries.CampaignEventAccreditationsRecipients, args, []);
}

export function executeCampaignEventAccreditationsRecipientsQuery(
    args: CampaignEventAccreditationsRecipientsQueryVariables,
    token: string | null | undefined
): Promise<CampaignEventAccreditationsRecipientsQuery> {
    return executeQuery<CampaignEventAccreditationsRecipientsQuery>(
        Queries.CampaignEventAccreditationsRecipients,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useCampaignEventPositionsRecipientsQuery(
    args: CampaignEventPositionsRecipientsQueryVariables
): IUseQueryReturns<CampaignEventPositionsRecipientsQuery> {
    return useQuery(Queries.CampaignEventPositionsRecipients, args, []);
}

export function executeCampaignEventPositionsRecipientsQuery(
    args: CampaignEventPositionsRecipientsQueryVariables,
    token: string | null | undefined
): Promise<CampaignEventPositionsRecipientsQuery> {
    return executeQuery<CampaignEventPositionsRecipientsQuery>(
        Queries.CampaignEventPositionsRecipients,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useCampaignEventSegmentsRecipientsQuery(
    args: CampaignEventSegmentsRecipientsQueryVariables
): IUseQueryReturns<CampaignEventSegmentsRecipientsQuery> {
    return useQuery(Queries.CampaignEventSegmentsRecipients, args, []);
}

export function executeCampaignEventSegmentsRecipientsQuery(
    args: CampaignEventSegmentsRecipientsQueryVariables,
    token: string | null | undefined
): Promise<CampaignEventSegmentsRecipientsQuery> {
    return executeQuery<CampaignEventSegmentsRecipientsQuery>(
        Queries.CampaignEventSegmentsRecipients,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useCampaignEventUsersRecipientsQuery(
    args: CampaignEventUsersRecipientsQueryVariables
): IUseQueryReturns<CampaignEventUsersRecipientsQuery> {
    return useQuery(Queries.CampaignEventUsersRecipients, args, []);
}

export function executeCampaignEventUsersRecipientsQuery(
    args: CampaignEventUsersRecipientsQueryVariables,
    token: string | null | undefined
): Promise<CampaignEventUsersRecipientsQuery> {
    return executeQuery<CampaignEventUsersRecipientsQuery>(Queries.CampaignEventUsersRecipients, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useCampaignOrganizationSegmentsRecipientsQuery(
    args: CampaignOrganizationSegmentsRecipientsQueryVariables
): IUseQueryReturns<CampaignOrganizationSegmentsRecipientsQuery> {
    return useQuery(Queries.CampaignOrganizationSegmentsRecipients, args, []);
}

export function executeCampaignOrganizationSegmentsRecipientsQuery(
    args: CampaignOrganizationSegmentsRecipientsQueryVariables,
    token: string | null | undefined
): Promise<CampaignOrganizationSegmentsRecipientsQuery> {
    return executeQuery<CampaignOrganizationSegmentsRecipientsQuery>(
        Queries.CampaignOrganizationSegmentsRecipients,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useCampaignOrganizationUsersRecipientsQuery(
    args: CampaignOrganizationUsersRecipientsQueryVariables
): IUseQueryReturns<CampaignOrganizationUsersRecipientsQuery> {
    return useQuery(Queries.CampaignOrganizationUsersRecipients, args, []);
}

export function executeCampaignOrganizationUsersRecipientsQuery(
    args: CampaignOrganizationUsersRecipientsQueryVariables,
    token: string | null | undefined
): Promise<CampaignOrganizationUsersRecipientsQuery> {
    return executeQuery<CampaignOrganizationUsersRecipientsQuery>(
        Queries.CampaignOrganizationUsersRecipients,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useCampaignDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<CampaignDeleteMutation, CampaignDeleteMutationVariables> {
    return useMutation(Mutations.CampaignDelete, args);
}

export function useCampaignDuplicateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<CampaignDuplicateMutation, CampaignDuplicateMutationVariables> {
    return useMutation(Mutations.CampaignDuplicate, args);
}

export function useCampaignSendMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<CampaignSendMutation, CampaignSendMutationVariables> {
    return useMutation(Mutations.CampaignSend, args);
}

export function useCampaignsQuery(args: CampaignsQueryVariables): IUseQueryReturns<CampaignsQuery> {
    return useQuery(Queries.Campaigns, args, [Fragments.CampaignListFragment]);
}

export function executeCampaignsQuery(
    args: CampaignsQueryVariables,
    token: string | null | undefined
): Promise<CampaignsQuery> {
    return executeQuery<CampaignsQuery>(Queries.Campaigns, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CampaignListFragment]
    });
}

export function useCampaignQuery(args: CampaignQueryVariables): IUseQueryReturns<CampaignQuery> {
    return useQuery(Queries.Campaign, args, [Fragments.DocumentEmailPreviewFragment]);
}

export function executeCampaignQuery(
    args: CampaignQueryVariables,
    token: string | null | undefined
): Promise<CampaignQuery> {
    return executeQuery<CampaignQuery>(Queries.Campaign, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DocumentEmailPreviewFragment]
    });
}

export function useCampaignEmailsQuery(
    args: CampaignEmailsQueryVariables
): IUseQueryReturns<CampaignEmailsQuery> {
    return useQuery(Queries.CampaignEmails, args, [Fragments.EmailsRecipientsFragment]);
}

export function executeCampaignEmailsQuery(
    args: CampaignEmailsQueryVariables,
    token: string | null | undefined
): Promise<CampaignEmailsQuery> {
    return executeQuery<CampaignEmailsQuery>(Queries.CampaignEmails, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.EmailsRecipientsFragment]
    });
}

export function useCampaignTextMessagesQuery(
    args: CampaignTextMessagesQueryVariables
): IUseQueryReturns<CampaignTextMessagesQuery> {
    return useQuery(Queries.CampaignTextMessages, args, [
        Fragments.CreatedByFragment,
        Fragments.CampaignUserInfoFragment
    ]);
}

export function executeCampaignTextMessagesQuery(
    args: CampaignTextMessagesQueryVariables,
    token: string | null | undefined
): Promise<CampaignTextMessagesQuery> {
    return executeQuery<CampaignTextMessagesQuery>(Queries.CampaignTextMessages, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CreatedByFragment, Fragments.CampaignUserInfoFragment]
    });
}

export function useTextMessageRepliesQuery(
    args: TextMessageRepliesQueryVariables
): IUseQueryReturns<TextMessageRepliesQuery> {
    return useQuery(Queries.TextMessageReplies, args, [Fragments.CreatedByFragment]);
}

export function executeTextMessageRepliesQuery(
    args: TextMessageRepliesQueryVariables,
    token: string | null | undefined
): Promise<TextMessageRepliesQuery> {
    return executeQuery<TextMessageRepliesQuery>(Queries.TextMessageReplies, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CreatedByFragment]
    });
}

export function useTextMessageSendMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<TextMessageSendMutation, TextMessageSendMutationVariables> {
    return useMutation(Mutations.TextMessageSend, args);
}

export function useTextMessageRepliesReadMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<TextMessageRepliesReadMutation, TextMessageRepliesReadMutationVariables> {
    return useMutation(Mutations.TextMessageRepliesRead, args);
}

export function useCampaignsSegmentsQuery(
    args: CampaignsSegmentsQueryVariables
): IUseQueryReturns<CampaignsSegmentsQuery> {
    return useQuery(Queries.CampaignsSegments, args, [Fragments.SegmentsFoldersSegmentsFragment]);
}

export function executeCampaignsSegmentsQuery(
    args: CampaignsSegmentsQueryVariables,
    token: string | null | undefined
): Promise<CampaignsSegmentsQuery> {
    return executeQuery<CampaignsSegmentsQuery>(Queries.CampaignsSegments, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentsFoldersSegmentsFragment]
    });
}

export function useCommunityCampaignsQuery(
    args: CommunityCampaignsQueryVariables
): IUseQueryReturns<CommunityCampaignsQuery> {
    return useQuery(Queries.CommunityCampaigns, args, [Fragments.CampaignListFragment]);
}

export function executeCommunityCampaignsQuery(
    args: CommunityCampaignsQueryVariables,
    token: string | null | undefined
): Promise<CommunityCampaignsQuery> {
    return executeQuery<CommunityCampaignsQuery>(Queries.CommunityCampaigns, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CampaignListFragment]
    });
}

export function useCommunityDashboardQuery(
    args: CommunityDashboardQueryVariables
): IUseQueryReturns<CommunityDashboardQuery> {
    return useQuery(Queries.CommunityDashboard, args, []);
}

export function executeCommunityDashboardQuery(
    args: CommunityDashboardQueryVariables,
    token: string | null | undefined
): Promise<CommunityDashboardQuery> {
    return executeQuery<CommunityDashboardQuery>(Queries.CommunityDashboard, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useCommunityDelegationsSegmentsQuery(
    args: CommunityDelegationsSegmentsQueryVariables
): IUseQueryReturns<CommunityDelegationsSegmentsQuery> {
    return useQuery(Queries.CommunityDelegationsSegments, args, [
        Fragments.SegmentsFoldersSegmentsFragment
    ]);
}

export function executeCommunityDelegationsSegmentsQuery(
    args: CommunityDelegationsSegmentsQueryVariables,
    token: string | null | undefined
): Promise<CommunityDelegationsSegmentsQuery> {
    return executeQuery<CommunityDelegationsSegmentsQuery>(Queries.CommunityDelegationsSegments, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentsFoldersSegmentsFragment]
    });
}

export function useCommunityUsersQuery(
    args: CommunityUsersQueryVariables
): IUseQueryReturns<CommunityUsersQuery> {
    return useQuery(Queries.CommunityUsers, args, [Fragments.UserInfoCommunityFragment]);
}

export function executeCommunityUsersQuery(
    args: CommunityUsersQueryVariables,
    token: string | null | undefined
): Promise<CommunityUsersQuery> {
    return executeQuery<CommunityUsersQuery>(Queries.CommunityUsers, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.UserInfoCommunityFragment]
    });
}

export function useUsersInfosMassAddEventMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UsersInfosMassAddEventMutation, UsersInfosMassAddEventMutationVariables> {
    return useMutation(Mutations.UsersInfosMassAddEvent, args);
}

export function useMergeMembersQuery(
    args: MergeMembersQueryVariables
): IUseQueryReturns<MergeMembersQuery> {
    return useQuery(Queries.MergeMembers, args, []);
}

export function executeMergeMembersQuery(
    args: MergeMembersQueryVariables,
    token: string | null | undefined
): Promise<MergeMembersQuery> {
    return executeQuery<MergeMembersQuery>(Queries.MergeMembers, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUsersInfosMergeMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UsersInfosMergeMutation, UsersInfosMergeMutationVariables> {
    return useMutation(Mutations.UsersInfosMerge, args);
}

export function useUserInfoCommunityQuery(
    args: UserInfoCommunityQueryVariables
): IUseQueryReturns<UserInfoCommunityQuery> {
    return useQuery(Queries.UserInfoCommunity, args, [Fragments.UserInfoCommunityFragment]);
}

export function executeUserInfoCommunityQuery(
    args: UserInfoCommunityQueryVariables,
    token: string | null | undefined
): Promise<UserInfoCommunityQuery> {
    return executeQuery<UserInfoCommunityQuery>(Queries.UserInfoCommunity, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.UserInfoCommunityFragment]
    });
}

export function useUserInfoUpdateFieldMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserInfoUpdateFieldMutation, UserInfoUpdateFieldMutationVariables> {
    return useMutation(Mutations.UserInfoUpdateField, args);
}

export function useUsersActionsQuery(
    args: UsersActionsQueryVariables
): IUseQueryReturns<UsersActionsQuery> {
    return useQuery(Queries.UsersActions, args, []);
}

export function executeUsersActionsQuery(
    args: UsersActionsQueryVariables,
    token: string | null | undefined
): Promise<UsersActionsQuery> {
    return executeQuery<UsersActionsQuery>(Queries.UsersActions, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUsersInfosMassDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UsersInfosMassDeleteMutation, UsersInfosMassDeleteMutationVariables> {
    return useMutation(Mutations.UsersInfosMassDelete, args);
}

export function useUsersSegmentsQuery(
    args: UsersSegmentsQueryVariables
): IUseQueryReturns<UsersSegmentsQuery> {
    return useQuery(Queries.UsersSegments, args, [
        Fragments.SegmentCustomFieldFragment,
        Fragments.SegmentsFoldersSegmentsFragment
    ]);
}

export function executeUsersSegmentsQuery(
    args: UsersSegmentsQueryVariables,
    token: string | null | undefined
): Promise<UsersSegmentsQuery> {
    return executeQuery<UsersSegmentsQuery>(Queries.UsersSegments, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentCustomFieldFragment, Fragments.SegmentsFoldersSegmentsFragment]
    });
}

export function usePossibleDuplicatesQuery(
    args: PossibleDuplicatesQueryVariables
): IUseQueryReturns<PossibleDuplicatesQuery> {
    return useQuery(Queries.PossibleDuplicates, args, [Fragments.CustomFieldWithConditionFragment]);
}

export function executePossibleDuplicatesQuery(
    args: PossibleDuplicatesQueryVariables,
    token: string | null | undefined
): Promise<PossibleDuplicatesQuery> {
    return executeQuery<PossibleDuplicatesQuery>(Queries.PossibleDuplicates, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CustomFieldWithConditionFragment]
    });
}

export function useCustomFieldCategoryCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    CustomFieldCategoryCreateMutation,
    CustomFieldCategoryCreateMutationVariables
> {
    return useMutation(Mutations.CustomFieldCategoryCreate, args);
}

export function useCustomFieldCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<CustomFieldCreateMutation, CustomFieldCreateMutationVariables> {
    return useMutation(Mutations.CustomFieldCreate, args);
}

export function useCustomFieldToEditQuery(
    args: CustomFieldToEditQueryVariables
): IUseQueryReturns<CustomFieldToEditQuery> {
    return useQuery(Queries.CustomFieldToEdit, args, [Fragments.CreateCustomFieldInfosFragment]);
}

export function executeCustomFieldToEditQuery(
    args: CustomFieldToEditQueryVariables,
    token: string | null | undefined
): Promise<CustomFieldToEditQuery> {
    return executeQuery<CustomFieldToEditQuery>(Queries.CustomFieldToEdit, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CreateCustomFieldInfosFragment]
    });
}

export function useCustomFieldUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<CustomFieldUpdateMutation, CustomFieldUpdateMutationVariables> {
    return useMutation(Mutations.CustomFieldUpdate, args);
}

export function useOrganizationInfosQuery(
    args: OrganizationInfosQueryVariables
): IUseQueryReturns<OrganizationInfosQuery> {
    return useQuery(Queries.OrganizationInfos, args, [Fragments.CreateCustomFieldInfosFragment]);
}

export function executeOrganizationInfosQuery(
    args: OrganizationInfosQueryVariables,
    token: string | null | undefined
): Promise<OrganizationInfosQuery> {
    return executeQuery<OrganizationInfosQuery>(Queries.OrganizationInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CreateCustomFieldInfosFragment]
    });
}

export function useCustomFieldCategoryDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    CustomFieldCategoryDeleteMutation,
    CustomFieldCategoryDeleteMutationVariables
> {
    return useMutation(Mutations.CustomFieldCategoryDelete, args);
}

export function useCustomFieldDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<CustomFieldDeleteMutation, CustomFieldDeleteMutationVariables> {
    return useMutation(Mutations.CustomFieldDelete, args);
}

export function useCustomFieldDuplicateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<CustomFieldDuplicateMutation, CustomFieldDuplicateMutationVariables> {
    return useMutation(Mutations.CustomFieldDuplicate, args);
}

export function useCustomFieldUsageQuery(
    args: CustomFieldUsageQueryVariables
): IUseQueryReturns<CustomFieldUsageQuery> {
    return useQuery(Queries.CustomFieldUsage, args, []);
}

export function executeCustomFieldUsageQuery(
    args: CustomFieldUsageQueryVariables,
    token: string | null | undefined
): Promise<CustomFieldUsageQuery> {
    return executeQuery<CustomFieldUsageQuery>(Queries.CustomFieldUsage, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useCustomFieldsQuery(
    args: CustomFieldsQueryVariables
): IUseQueryReturns<CustomFieldsQuery> {
    return useQuery(Queries.CustomFields, args, []);
}

export function executeCustomFieldsQuery(
    args: CustomFieldsQueryVariables,
    token: string | null | undefined
): Promise<CustomFieldsQuery> {
    return executeQuery<CustomFieldsQuery>(Queries.CustomFields, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useCustomFieldsSegmentsQuery(
    args: CustomFieldsSegmentsQueryVariables
): IUseQueryReturns<CustomFieldsSegmentsQuery> {
    return useQuery(Queries.CustomFieldsSegments, args, [
        Fragments.SegmentsFoldersSegmentsFragment
    ]);
}

export function executeCustomFieldsSegmentsQuery(
    args: CustomFieldsSegmentsQueryVariables,
    token: string | null | undefined
): Promise<CustomFieldsSegmentsQuery> {
    return executeQuery<CustomFieldsSegmentsQuery>(Queries.CustomFieldsSegments, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentsFoldersSegmentsFragment]
    });
}

export function useCustomFieldCategoryUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    CustomFieldCategoryUpdateMutation,
    CustomFieldCategoryUpdateMutationVariables
> {
    return useMutation(Mutations.CustomFieldCategoryUpdate, args);
}

export function useDashboardQuery(args: DashboardQueryVariables): IUseQueryReturns<DashboardQuery> {
    return useQuery(Queries.Dashboard, args, []);
}

export function executeDashboardQuery(
    args: DashboardQueryVariables,
    token: string | null | undefined
): Promise<DashboardQuery> {
    return executeQuery<DashboardQuery>(Queries.Dashboard, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useDashboardMobileQuery(
    args: DashboardMobileQueryVariables
): IUseQueryReturns<DashboardMobileQuery> {
    return useQuery(Queries.DashboardMobile, args, []);
}

export function executeDashboardMobileQuery(
    args: DashboardMobileQueryVariables,
    token: string | null | undefined
): Promise<DashboardMobileQuery> {
    return executeQuery<DashboardMobileQuery>(Queries.DashboardMobile, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useCreateEventDelegationLeadersQuery(
    args: CreateEventDelegationLeadersQueryVariables
): IUseQueryReturns<CreateEventDelegationLeadersQuery> {
    return useQuery(Queries.CreateEventDelegationLeaders, args, []);
}

export function executeCreateEventDelegationLeadersQuery(
    args: CreateEventDelegationLeadersQueryVariables,
    token: string | null | undefined
): Promise<CreateEventDelegationLeadersQuery> {
    return executeQuery<CreateEventDelegationLeadersQuery>(Queries.CreateEventDelegationLeaders, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useCreateOrganizationDelegationLeadersQuery(
    args: CreateOrganizationDelegationLeadersQueryVariables
): IUseQueryReturns<CreateOrganizationDelegationLeadersQuery> {
    return useQuery(Queries.CreateOrganizationDelegationLeaders, args, []);
}

export function executeCreateOrganizationDelegationLeadersQuery(
    args: CreateOrganizationDelegationLeadersQueryVariables,
    token: string | null | undefined
): Promise<CreateOrganizationDelegationLeadersQuery> {
    return executeQuery<CreateOrganizationDelegationLeadersQuery>(
        Queries.CreateOrganizationDelegationLeaders,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useDelegationCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<DelegationCreateMutation, DelegationCreateMutationVariables> {
    return useMutation(Mutations.DelegationCreate, args);
}

export function useDelegationEventInfosQuery(
    args: DelegationEventInfosQueryVariables
): IUseQueryReturns<DelegationEventInfosQuery> {
    return useQuery(Queries.DelegationEventInfos, args, [
        Fragments.CreatePositionTagsFragment,
        Fragments.CreateDelegationCustomFieldsFragment,
        Fragments.CreateDelegationEventFragment
    ]);
}

export function executeDelegationEventInfosQuery(
    args: DelegationEventInfosQueryVariables,
    token: string | null | undefined
): Promise<DelegationEventInfosQuery> {
    return executeQuery<DelegationEventInfosQuery>(Queries.DelegationEventInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CreatePositionTagsFragment,
            Fragments.CreateDelegationCustomFieldsFragment,
            Fragments.CreateDelegationEventFragment
        ]
    });
}

export function useDelegationEventToEditQuery(
    args: DelegationEventToEditQueryVariables
): IUseQueryReturns<DelegationEventToEditQuery> {
    return useQuery(Queries.DelegationEventToEdit, args, [
        Fragments.CreatePositionTagsFragment,
        Fragments.CreateDelegationCustomFieldsFragment,
        Fragments.CreateDelegationEventFragment,
        Fragments.DelegationAccreditationsFragment
    ]);
}

export function executeDelegationEventToEditQuery(
    args: DelegationEventToEditQueryVariables,
    token: string | null | undefined
): Promise<DelegationEventToEditQuery> {
    return executeQuery<DelegationEventToEditQuery>(Queries.DelegationEventToEdit, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CreatePositionTagsFragment,
            Fragments.CreateDelegationCustomFieldsFragment,
            Fragments.CreateDelegationEventFragment,
            Fragments.DelegationAccreditationsFragment
        ]
    });
}

export function useDelegationOrganizationInfosQuery(
    args: DelegationOrganizationInfosQueryVariables
): IUseQueryReturns<DelegationOrganizationInfosQuery> {
    return useQuery(Queries.DelegationOrganizationInfos, args, [
        Fragments.CreatePositionTagsFragment,
        Fragments.CreateDelegationCustomFieldsFragment,
        Fragments.CreateDelegationFormFragment
    ]);
}

export function executeDelegationOrganizationInfosQuery(
    args: DelegationOrganizationInfosQueryVariables,
    token: string | null | undefined
): Promise<DelegationOrganizationInfosQuery> {
    return executeQuery<DelegationOrganizationInfosQuery>(Queries.DelegationOrganizationInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CreatePositionTagsFragment,
            Fragments.CreateDelegationCustomFieldsFragment,
            Fragments.CreateDelegationFormFragment
        ]
    });
}

export function useDelegationOrganizationToEditQuery(
    args: DelegationOrganizationToEditQueryVariables
): IUseQueryReturns<DelegationOrganizationToEditQuery> {
    return useQuery(Queries.DelegationOrganizationToEdit, args, [
        Fragments.CreatePositionTagsFragment,
        Fragments.CreateDelegationCustomFieldsFragment,
        Fragments.CreateDelegationFormFragment
    ]);
}

export function executeDelegationOrganizationToEditQuery(
    args: DelegationOrganizationToEditQueryVariables,
    token: string | null | undefined
): Promise<DelegationOrganizationToEditQuery> {
    return executeQuery<DelegationOrganizationToEditQuery>(Queries.DelegationOrganizationToEdit, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CreatePositionTagsFragment,
            Fragments.CreateDelegationCustomFieldsFragment,
            Fragments.CreateDelegationFormFragment
        ]
    });
}

export function useDelegationUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<DelegationUpdateMutation, DelegationUpdateMutationVariables> {
    return useMutation(Mutations.DelegationUpdate, args);
}

export function useEventUsersInfosQuery(
    args: EventUsersInfosQueryVariables
): IUseQueryReturns<EventUsersInfosQuery> {
    return useQuery(Queries.EventUsersInfos, args, [Fragments.ExistingVolunteerFragment]);
}

export function executeEventUsersInfosQuery(
    args: EventUsersInfosQueryVariables,
    token: string | null | undefined
): Promise<EventUsersInfosQuery> {
    return executeQuery<EventUsersInfosQuery>(Queries.EventUsersInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.ExistingVolunteerFragment]
    });
}

export function useDelegationsImportMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<DelegationsImportMutation, DelegationsImportMutationVariables> {
    return useMutation(Mutations.DelegationsImport, args);
}

export function useDelegationDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<DelegationDeleteMutation, DelegationDeleteMutationVariables> {
    return useMutation(Mutations.DelegationDelete, args);
}

export function useDelegationDuplicateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<DelegationDuplicateMutation, DelegationDuplicateMutationVariables> {
    return useMutation(Mutations.DelegationDuplicate, args);
}

export function useDelegationUpdateFieldMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<DelegationUpdateFieldMutation, DelegationUpdateFieldMutationVariables> {
    return useMutation(Mutations.DelegationUpdateField, args);
}

export function useDelegationsQuery(
    args: DelegationsQueryVariables
): IUseQueryReturns<DelegationsQuery> {
    return useQuery(Queries.Delegations, args, [Fragments.DelegationsDelegationFragment]);
}

export function executeDelegationsQuery(
    args: DelegationsQueryVariables,
    token: string | null | undefined
): Promise<DelegationsQuery> {
    return executeQuery<DelegationsQuery>(Queries.Delegations, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DelegationsDelegationFragment]
    });
}

export function useDelegationsDelegationQuery(
    args: DelegationsDelegationQueryVariables
): IUseQueryReturns<DelegationsDelegationQuery> {
    return useQuery(Queries.DelegationsDelegation, args, [Fragments.DelegationsDelegationFragment]);
}

export function executeDelegationsDelegationQuery(
    args: DelegationsDelegationQueryVariables,
    token: string | null | undefined
): Promise<DelegationsDelegationQuery> {
    return executeQuery<DelegationsDelegationQuery>(Queries.DelegationsDelegation, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DelegationsDelegationFragment]
    });
}

export function useDelegationsMassDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<DelegationsMassDeleteMutation, DelegationsMassDeleteMutationVariables> {
    return useMutation(Mutations.DelegationsMassDelete, args);
}

export function useDelegationsSegmentsQuery(
    args: DelegationsSegmentsQueryVariables
): IUseQueryReturns<DelegationsSegmentsQuery> {
    return useQuery(Queries.DelegationsSegments, args, [Fragments.SegmentsFoldersSegmentsFragment]);
}

export function executeDelegationsSegmentsQuery(
    args: DelegationsSegmentsQueryVariables,
    token: string | null | undefined
): Promise<DelegationsSegmentsQuery> {
    return executeQuery<DelegationsSegmentsQuery>(Queries.DelegationsSegments, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentsFoldersSegmentsFragment]
    });
}

export function useParentDelegationsQuery(
    args: ParentDelegationsQueryVariables
): IUseQueryReturns<ParentDelegationsQuery> {
    return useQuery(Queries.ParentDelegations, args, []);
}

export function executeParentDelegationsQuery(
    args: ParentDelegationsQueryVariables,
    token: string | null | undefined
): Promise<ParentDelegationsQuery> {
    return executeQuery<ParentDelegationsQuery>(Queries.ParentDelegations, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useDelegationsMassEditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<DelegationsMassEditMutation, DelegationsMassEditMutationVariables> {
    return useMutation(Mutations.DelegationsMassEdit, args);
}

export function useEventDelegationsMassEditInfosQuery(
    args: EventDelegationsMassEditInfosQueryVariables
): IUseQueryReturns<EventDelegationsMassEditInfosQuery> {
    return useQuery(Queries.EventDelegationsMassEditInfos, args, []);
}

export function executeEventDelegationsMassEditInfosQuery(
    args: EventDelegationsMassEditInfosQueryVariables,
    token: string | null | undefined
): Promise<EventDelegationsMassEditInfosQuery> {
    return executeQuery<EventDelegationsMassEditInfosQuery>(Queries.EventDelegationsMassEditInfos, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useOrganizationMassEditInfosQuery(
    args: OrganizationMassEditInfosQueryVariables
): IUseQueryReturns<OrganizationMassEditInfosQuery> {
    return useQuery(Queries.OrganizationMassEditInfos, args, []);
}

export function executeOrganizationMassEditInfosQuery(
    args: OrganizationMassEditInfosQueryVariables,
    token: string | null | undefined
): Promise<OrganizationMassEditInfosQuery> {
    return executeQuery<OrganizationMassEditInfosQuery>(Queries.OrganizationMassEditInfos, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useDelegationDashboardQuery(
    args: DelegationDashboardQueryVariables
): IUseQueryReturns<DelegationDashboardQuery> {
    return useQuery(Queries.DelegationDashboard, args, []);
}

export function executeDelegationDashboardQuery(
    args: DelegationDashboardQueryVariables,
    token: string | null | undefined
): Promise<DelegationDashboardQuery> {
    return executeQuery<DelegationDashboardQuery>(Queries.DelegationDashboard, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useDelegationQuery(
    args: DelegationQueryVariables
): IUseQueryReturns<DelegationQuery> {
    return useQuery(Queries.Delegation, args, [Fragments.CreateDelegationCustomFieldsFragment]);
}

export function executeDelegationQuery(
    args: DelegationQueryVariables,
    token: string | null | undefined
): Promise<DelegationQuery> {
    return executeQuery<DelegationQuery>(Queries.Delegation, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CreateDelegationCustomFieldsFragment]
    });
}

export function useDelegationLockMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<DelegationLockMutation, DelegationLockMutationVariables> {
    return useMutation(Mutations.DelegationLock, args);
}

export function useDelegationUnlockMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<DelegationUnlockMutation, DelegationUnlockMutationVariables> {
    return useMutation(Mutations.DelegationUnlock, args);
}

export function useDocumentsQuery(args: DocumentsQueryVariables): IUseQueryReturns<DocumentsQuery> {
    return useQuery(Queries.Documents, args, []);
}

export function executeDocumentsQuery(
    args: DocumentsQueryVariables,
    token: string | null | undefined
): Promise<DocumentsQuery> {
    return executeQuery<DocumentsQuery>(Queries.Documents, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useDocumentsAssignedUsersQuery(
    args: DocumentsAssignedUsersQueryVariables
): IUseQueryReturns<DocumentsAssignedUsersQuery> {
    return useQuery(Queries.DocumentsAssignedUsers, args, []);
}

export function executeDocumentsAssignedUsersQuery(
    args: DocumentsAssignedUsersQueryVariables,
    token: string | null | undefined
): Promise<DocumentsAssignedUsersQuery> {
    return executeQuery<DocumentsAssignedUsersQuery>(Queries.DocumentsAssignedUsers, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useDocumentsPositionsQuery(
    args: DocumentsPositionsQueryVariables
): IUseQueryReturns<DocumentsPositionsQuery> {
    return useQuery(Queries.DocumentsPositions, args, []);
}

export function executeDocumentsPositionsQuery(
    args: DocumentsPositionsQueryVariables,
    token: string | null | undefined
): Promise<DocumentsPositionsQuery> {
    return executeQuery<DocumentsPositionsQuery>(Queries.DocumentsPositions, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useEventDocumentUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EventDocumentUpdateMutation, EventDocumentUpdateMutationVariables> {
    return useMutation(Mutations.EventDocumentUpdate, args);
}

export function useEventDocumentsExportMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EventDocumentsExportMutation, EventDocumentsExportMutationVariables> {
    return useMutation(Mutations.EventDocumentsExport, args);
}

export function usePositionPlanningDocumentQuery(
    args: PositionPlanningDocumentQueryVariables
): IUseQueryReturns<PositionPlanningDocumentQuery> {
    return useQuery(Queries.PositionPlanningDocument, args, [
        Fragments.DocumentPositionPlanningFragment
    ]);
}

export function executePositionPlanningDocumentQuery(
    args: PositionPlanningDocumentQueryVariables,
    token: string | null | undefined
): Promise<PositionPlanningDocumentQuery> {
    return executeQuery<PositionPlanningDocumentQuery>(Queries.PositionPlanningDocument, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DocumentPositionPlanningFragment]
    });
}

export function usePositionSheetDocumentQuery(
    args: PositionSheetDocumentQueryVariables
): IUseQueryReturns<PositionSheetDocumentQuery> {
    return useQuery(Queries.PositionSheetDocument, args, [Fragments.DocumentPositionSheetFragment]);
}

export function executePositionSheetDocumentQuery(
    args: PositionSheetDocumentQueryVariables,
    token: string | null | undefined
): Promise<PositionSheetDocumentQuery> {
    return executeQuery<PositionSheetDocumentQuery>(Queries.PositionSheetDocument, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DocumentPositionSheetFragment]
    });
}

export function usePlanningDocumentQuery(
    args: PlanningDocumentQueryVariables
): IUseQueryReturns<PlanningDocumentQuery> {
    return useQuery(Queries.PlanningDocument, args, [Fragments.DocumentPositionsPlanningFragment]);
}

export function executePlanningDocumentQuery(
    args: PlanningDocumentQueryVariables,
    token: string | null | undefined
): Promise<PlanningDocumentQuery> {
    return executeQuery<PlanningDocumentQuery>(Queries.PlanningDocument, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DocumentPositionsPlanningFragment]
    });
}

export function usePositionsVolunteersPlanningDocumentQuery(
    args: PositionsVolunteersPlanningDocumentQueryVariables
): IUseQueryReturns<PositionsVolunteersPlanningDocumentQuery> {
    return useQuery(Queries.PositionsVolunteersPlanningDocument, args, [
        Fragments.DocumentPositionsVolunteersPlanningFragment
    ]);
}

export function executePositionsVolunteersPlanningDocumentQuery(
    args: PositionsVolunteersPlanningDocumentQueryVariables,
    token: string | null | undefined
): Promise<PositionsVolunteersPlanningDocumentQuery> {
    return executeQuery<PositionsVolunteersPlanningDocumentQuery>(
        Queries.PositionsVolunteersPlanningDocument,
        {
            variables: args,
            token: token || undefined,
            fragments: [Fragments.DocumentPositionsVolunteersPlanningFragment]
        }
    );
}

export function useUserAssignmentsDocumentQuery(
    args: UserAssignmentsDocumentQueryVariables
): IUseQueryReturns<UserAssignmentsDocumentQuery> {
    return useQuery(Queries.UserAssignmentsDocument, args, [
        Fragments.DocumentUserAssignmentsFragment
    ]);
}

export function executeUserAssignmentsDocumentQuery(
    args: UserAssignmentsDocumentQueryVariables,
    token: string | null | undefined
): Promise<UserAssignmentsDocumentQuery> {
    return executeQuery<UserAssignmentsDocumentQuery>(Queries.UserAssignmentsDocument, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DocumentUserAssignmentsFragment]
    });
}

export function useUserBadgeDocumentQuery(
    args: UserBadgeDocumentQueryVariables
): IUseQueryReturns<UserBadgeDocumentQuery> {
    return useQuery(Queries.UserBadgeDocument, args, [Fragments.DocumentUserBadgeFragment]);
}

export function executeUserBadgeDocumentQuery(
    args: UserBadgeDocumentQueryVariables,
    token: string | null | undefined
): Promise<UserBadgeDocumentQuery> {
    return executeQuery<UserBadgeDocumentQuery>(Queries.UserBadgeDocument, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DocumentUserBadgeFragment]
    });
}

export function useUserCertificateDocumentQuery(
    args: UserCertificateDocumentQueryVariables
): IUseQueryReturns<UserCertificateDocumentQuery> {
    return useQuery(Queries.UserCertificateDocument, args, [
        Fragments.OrganizationCertificateDefaultFragment,
        Fragments.DocumentUserCertificateFragment
    ]);
}

export function executeUserCertificateDocumentQuery(
    args: UserCertificateDocumentQueryVariables,
    token: string | null | undefined
): Promise<UserCertificateDocumentQuery> {
    return executeQuery<UserCertificateDocumentQuery>(Queries.UserCertificateDocument, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.OrganizationCertificateDefaultFragment,
            Fragments.DocumentUserCertificateFragment
        ]
    });
}

export function useUserCustomBadgeDocumentQuery(
    args: UserCustomBadgeDocumentQueryVariables
): IUseQueryReturns<UserCustomBadgeDocumentQuery> {
    return useQuery(Queries.UserCustomBadgeDocument, args, [
        Fragments.DocumentUserCustomBadgeFragment
    ]);
}

export function executeUserCustomBadgeDocumentQuery(
    args: UserCustomBadgeDocumentQueryVariables,
    token: string | null | undefined
): Promise<UserCustomBadgeDocumentQuery> {
    return executeQuery<UserCustomBadgeDocumentQuery>(Queries.UserCustomBadgeDocument, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DocumentUserCustomBadgeFragment]
    });
}

export function useUserPlanningDaysDocumentQuery(
    args: UserPlanningDaysDocumentQueryVariables
): IUseQueryReturns<UserPlanningDaysDocumentQuery> {
    return useQuery(Queries.UserPlanningDaysDocument, args, [
        Fragments.DocumentUserPlanningDaysFragment
    ]);
}

export function executeUserPlanningDaysDocumentQuery(
    args: UserPlanningDaysDocumentQueryVariables,
    token: string | null | undefined
): Promise<UserPlanningDaysDocumentQuery> {
    return executeQuery<UserPlanningDaysDocumentQuery>(Queries.UserPlanningDaysDocument, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DocumentUserPlanningDaysFragment]
    });
}

export function useUserPlanningDocumentQuery(
    args: UserPlanningDocumentQueryVariables
): IUseQueryReturns<UserPlanningDocumentQuery> {
    return useQuery(Queries.UserPlanningDocument, args, [Fragments.DocumentUserPlanningFragment]);
}

export function executeUserPlanningDocumentQuery(
    args: UserPlanningDocumentQueryVariables,
    token: string | null | undefined
): Promise<UserPlanningDocumentQuery> {
    return executeQuery<UserPlanningDocumentQuery>(Queries.UserPlanningDocument, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DocumentUserPlanningFragment]
    });
}

export function useUserPlanningListDocumentQuery(
    args: UserPlanningListDocumentQueryVariables
): IUseQueryReturns<UserPlanningListDocumentQuery> {
    return useQuery(Queries.UserPlanningListDocument, args, [
        Fragments.DocumentUserPlanningListFragment
    ]);
}

export function executeUserPlanningListDocumentQuery(
    args: UserPlanningListDocumentQueryVariables,
    token: string | null | undefined
): Promise<UserPlanningListDocumentQuery> {
    return executeQuery<UserPlanningListDocumentQuery>(Queries.UserPlanningListDocument, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DocumentUserPlanningListFragment]
    });
}

export function useUsersListingDocumentQuery(
    args: UsersListingDocumentQueryVariables
): IUseQueryReturns<UsersListingDocumentQuery> {
    return useQuery(Queries.UsersListingDocument, args, [Fragments.DocumentUsersListingFragment]);
}

export function executeUsersListingDocumentQuery(
    args: UsersListingDocumentQueryVariables,
    token: string | null | undefined
): Promise<UsersListingDocumentQuery> {
    return executeQuery<UsersListingDocumentQuery>(Queries.UsersListingDocument, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DocumentUsersListingFragment]
    });
}

export function useEmailTemplateQuery(
    args: EmailTemplateQueryVariables
): IUseQueryReturns<EmailTemplateQuery> {
    return useQuery(Queries.EmailTemplate, args, []);
}

export function executeEmailTemplateQuery(
    args: EmailTemplateQueryVariables,
    token: string | null | undefined
): Promise<EmailTemplateQuery> {
    return executeQuery<EmailTemplateQuery>(Queries.EmailTemplate, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useEmailTemplateUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EmailTemplateUpdateMutation, EmailTemplateUpdateMutationVariables> {
    return useMutation(Mutations.EmailTemplateUpdate, args);
}

export function useEmailsTemplatesQuery(
    args: EmailsTemplatesQueryVariables
): IUseQueryReturns<EmailsTemplatesQuery> {
    return useQuery(Queries.EmailsTemplates, args, []);
}

export function executeEmailsTemplatesQuery(
    args: EmailsTemplatesQueryVariables,
    token: string | null | undefined
): Promise<EmailsTemplatesQuery> {
    return executeQuery<EmailsTemplatesQuery>(Queries.EmailsTemplates, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useAllEventsQuery(args: AllEventsQueryVariables): IUseQueryReturns<AllEventsQuery> {
    return useQuery(Queries.AllEvents, args, []);
}

export function executeAllEventsQuery(
    args: AllEventsQueryVariables,
    token: string | null | undefined
): Promise<AllEventsQuery> {
    return executeQuery<AllEventsQuery>(Queries.AllEvents, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useCreateEventMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<CreateEventMutation, CreateEventMutationVariables> {
    return useMutation(Mutations.CreateEvent, args);
}

export function useEventCreateInfosQuery(
    args: EventCreateInfosQueryVariables
): IUseQueryReturns<EventCreateInfosQuery> {
    return useQuery(Queries.EventCreateInfos, args, [Fragments.CreateUpdateEventInfosFragment]);
}

export function executeEventCreateInfosQuery(
    args: EventCreateInfosQueryVariables,
    token: string | null | undefined
): Promise<EventCreateInfosQuery> {
    return executeQuery<EventCreateInfosQuery>(Queries.EventCreateInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CreateUpdateEventInfosFragment]
    });
}

export function useEventOrganizationCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EventOrganizationCreateMutation, EventOrganizationCreateMutationVariables> {
    return useMutation(Mutations.EventOrganizationCreate, args);
}

export function useEventUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EventUpdateMutation, EventUpdateMutationVariables> {
    return useMutation(Mutations.EventUpdate, args);
}

export function useEventUpdateInfosQuery(
    args: EventUpdateInfosQueryVariables
): IUseQueryReturns<EventUpdateInfosQuery> {
    return useQuery(Queries.EventUpdateInfos, args, [Fragments.CreateUpdateEventInfosFragment]);
}

export function executeEventUpdateInfosQuery(
    args: EventUpdateInfosQueryVariables,
    token: string | null | undefined
): Promise<EventUpdateInfosQuery> {
    return executeQuery<EventUpdateInfosQuery>(Queries.EventUpdateInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CreateUpdateEventInfosFragment]
    });
}

export function useEventArchiveMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EventArchiveMutation, EventArchiveMutationVariables> {
    return useMutation(Mutations.EventArchive, args);
}

export function useEventDuplicateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EventDuplicateMutation, EventDuplicateMutationVariables> {
    return useMutation(Mutations.EventDuplicate, args);
}

export function useEventsQuery(args: EventsQueryVariables): IUseQueryReturns<EventsQuery> {
    return useQuery(Queries.Events, args, []);
}

export function executeEventsQuery(
    args: EventsQueryVariables,
    token: string | null | undefined
): Promise<EventsQuery> {
    return executeQuery<EventsQuery>(Queries.Events, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useEventsSegmentsQuery(
    args: EventsSegmentsQueryVariables
): IUseQueryReturns<EventsSegmentsQuery> {
    return useQuery(Queries.EventsSegments, args, [Fragments.SegmentsFoldersSegmentsFragment]);
}

export function executeEventsSegmentsQuery(
    args: EventsSegmentsQueryVariables,
    token: string | null | undefined
): Promise<EventsSegmentsQuery> {
    return executeQuery<EventsSegmentsQuery>(Queries.EventsSegments, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentsFoldersSegmentsFragment]
    });
}

export function useEventsMobileQuery(
    args: EventsMobileQueryVariables
): IUseQueryReturns<EventsMobileQuery> {
    return useQuery(Queries.EventsMobile, args, []);
}

export function executeEventsMobileQuery(
    args: EventsMobileQueryVariables,
    token: string | null | undefined
): Promise<EventsMobileQuery> {
    return executeQuery<EventsMobileQuery>(Queries.EventsMobile, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useEventQuery(args: EventQueryVariables): IUseQueryReturns<EventQuery> {
    return useQuery(Queries.Event, args, []);
}

export function executeEventQuery(
    args: EventQueryVariables,
    token: string | null | undefined
): Promise<EventQuery> {
    return executeQuery<EventQuery>(Queries.Event, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useFilesImportMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<FilesImportMutation, FilesImportMutationVariables> {
    return useMutation(Mutations.FilesImport, args);
}

export function useFilesImportCustomFieldsQuery(
    args: FilesImportCustomFieldsQueryVariables
): IUseQueryReturns<FilesImportCustomFieldsQuery> {
    return useQuery(Queries.FilesImportCustomFields, args, []);
}

export function executeFilesImportCustomFieldsQuery(
    args: FilesImportCustomFieldsQueryVariables,
    token: string | null | undefined
): Promise<FilesImportCustomFieldsQuery> {
    return executeQuery<FilesImportCustomFieldsQuery>(Queries.FilesImportCustomFields, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useFormCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<FormCreateMutation, FormCreateMutationVariables> {
    return useMutation(Mutations.FormCreate, args);
}

export function useEventFormsQuery(
    args: EventFormsQueryVariables
): IUseQueryReturns<EventFormsQuery> {
    return useQuery(Queries.EventForms, args, [Fragments.FormListFragment]);
}

export function executeEventFormsQuery(
    args: EventFormsQueryVariables,
    token: string | null | undefined
): Promise<EventFormsQuery> {
    return executeQuery<EventFormsQuery>(Queries.EventForms, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.FormListFragment]
    });
}

export function useFormDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<FormDeleteMutation, FormDeleteMutationVariables> {
    return useMutation(Mutations.FormDelete, args);
}

export function useFormDuplicateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<FormDuplicateMutation, FormDuplicateMutationVariables> {
    return useMutation(Mutations.FormDuplicate, args);
}

export function useOrganizationFormsQuery(
    args: OrganizationFormsQueryVariables
): IUseQueryReturns<OrganizationFormsQuery> {
    return useQuery(Queries.OrganizationForms, args, [Fragments.FormListFragment]);
}

export function executeOrganizationFormsQuery(
    args: OrganizationFormsQueryVariables,
    token: string | null | undefined
): Promise<OrganizationFormsQuery> {
    return executeQuery<OrganizationFormsQuery>(Queries.OrganizationForms, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.FormListFragment]
    });
}

export function useFormQuery(args: FormQueryVariables): IUseQueryReturns<FormQuery> {
    return useQuery(Queries.Form, args, []);
}

export function executeFormQuery(
    args: FormQueryVariables,
    token: string | null | undefined
): Promise<FormQuery> {
    return executeQuery<FormQuery>(Queries.Form, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useFormElementsQuery(
    args: FormElementsQueryVariables
): IUseQueryReturns<FormElementsQuery> {
    return useQuery(Queries.FormElements, args, [Fragments.FormCustomFieldFragment]);
}

export function executeFormElementsQuery(
    args: FormElementsQueryVariables,
    token: string | null | undefined
): Promise<FormElementsQuery> {
    return executeQuery<FormElementsQuery>(Queries.FormElements, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.FormCustomFieldFragment]
    });
}

export function useFormElementsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<FormElementsUpdateMutation, FormElementsUpdateMutationVariables> {
    return useMutation(Mutations.FormElementsUpdate, args);
}

export function useFormParametersAccreditationsQuery(
    args: FormParametersAccreditationsQueryVariables
): IUseQueryReturns<FormParametersAccreditationsQuery> {
    return useQuery(Queries.FormParametersAccreditations, args, []);
}

export function executeFormParametersAccreditationsQuery(
    args: FormParametersAccreditationsQueryVariables,
    token: string | null | undefined
): Promise<FormParametersAccreditationsQuery> {
    return executeQuery<FormParametersAccreditationsQuery>(Queries.FormParametersAccreditations, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useFormParametersAccreditationsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    FormParametersAccreditationsUpdateMutation,
    FormParametersAccreditationsUpdateMutationVariables
> {
    return useMutation(Mutations.FormParametersAccreditationsUpdate, args);
}

export function useFormParametersAccreditationsHiddenDisplayedQuery(
    args: FormParametersAccreditationsHiddenDisplayedQueryVariables
): IUseQueryReturns<FormParametersAccreditationsHiddenDisplayedQuery> {
    return useQuery(Queries.FormParametersAccreditationsHiddenDisplayed, args, []);
}

export function executeFormParametersAccreditationsHiddenDisplayedQuery(
    args: FormParametersAccreditationsHiddenDisplayedQueryVariables,
    token: string | null | undefined
): Promise<FormParametersAccreditationsHiddenDisplayedQuery> {
    return executeQuery<FormParametersAccreditationsHiddenDisplayedQuery>(
        Queries.FormParametersAccreditationsHiddenDisplayed,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useFormParametersAvailabilitiesQuery(
    args: FormParametersAvailabilitiesQueryVariables
): IUseQueryReturns<FormParametersAvailabilitiesQuery> {
    return useQuery(Queries.FormParametersAvailabilities, args, [
        Fragments.FormSlotsOptionsFragment,
        Fragments.FormRegisterCustomSlotPeriodFragment
    ]);
}

export function executeFormParametersAvailabilitiesQuery(
    args: FormParametersAvailabilitiesQueryVariables,
    token: string | null | undefined
): Promise<FormParametersAvailabilitiesQuery> {
    return executeQuery<FormParametersAvailabilitiesQuery>(Queries.FormParametersAvailabilities, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.FormSlotsOptionsFragment,
            Fragments.FormRegisterCustomSlotPeriodFragment
        ]
    });
}

export function useFormParametersAvailabilitiesUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    FormParametersAvailabilitiesUpdateMutation,
    FormParametersAvailabilitiesUpdateMutationVariables
> {
    return useMutation(Mutations.FormParametersAvailabilitiesUpdate, args);
}

export function useFormParametersBroadcastEventQuery(
    args: FormParametersBroadcastEventQueryVariables
): IUseQueryReturns<FormParametersBroadcastEventQuery> {
    return useQuery(Queries.FormParametersBroadcastEvent, args, []);
}

export function executeFormParametersBroadcastEventQuery(
    args: FormParametersBroadcastEventQueryVariables,
    token: string | null | undefined
): Promise<FormParametersBroadcastEventQuery> {
    return executeQuery<FormParametersBroadcastEventQuery>(Queries.FormParametersBroadcastEvent, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useFormParametersBroadcastOrganizationQuery(
    args: FormParametersBroadcastOrganizationQueryVariables
): IUseQueryReturns<FormParametersBroadcastOrganizationQuery> {
    return useQuery(Queries.FormParametersBroadcastOrganization, args, []);
}

export function executeFormParametersBroadcastOrganizationQuery(
    args: FormParametersBroadcastOrganizationQueryVariables,
    token: string | null | undefined
): Promise<FormParametersBroadcastOrganizationQuery> {
    return executeQuery<FormParametersBroadcastOrganizationQuery>(
        Queries.FormParametersBroadcastOrganization,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useFormParametersBroadcastUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    FormParametersBroadcastUpdateMutation,
    FormParametersBroadcastUpdateMutationVariables
> {
    return useMutation(Mutations.FormParametersBroadcastUpdate, args);
}

export function useFormParametersDesignQuery(
    args: FormParametersDesignQueryVariables
): IUseQueryReturns<FormParametersDesignQuery> {
    return useQuery(Queries.FormParametersDesign, args, []);
}

export function executeFormParametersDesignQuery(
    args: FormParametersDesignQueryVariables,
    token: string | null | undefined
): Promise<FormParametersDesignQuery> {
    return executeQuery<FormParametersDesignQuery>(Queries.FormParametersDesign, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useFormParametersDesignUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    FormParametersDesignUpdateMutation,
    FormParametersDesignUpdateMutationVariables
> {
    return useMutation(Mutations.FormParametersDesignUpdate, args);
}

export function useFormParametersGeneralQuery(
    args: FormParametersGeneralQueryVariables
): IUseQueryReturns<FormParametersGeneralQuery> {
    return useQuery(Queries.FormParametersGeneral, args, [Fragments.MassEditCustomFieldFragment]);
}

export function executeFormParametersGeneralQuery(
    args: FormParametersGeneralQueryVariables,
    token: string | null | undefined
): Promise<FormParametersGeneralQuery> {
    return executeQuery<FormParametersGeneralQuery>(Queries.FormParametersGeneral, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.MassEditCustomFieldFragment]
    });
}

export function useFormParametersGeneralUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    FormParametersGeneralUpdateMutation,
    FormParametersGeneralUpdateMutationVariables
> {
    return useMutation(Mutations.FormParametersGeneralUpdate, args);
}

export function useFormParametersMissionsQuery(
    args: FormParametersMissionsQueryVariables
): IUseQueryReturns<FormParametersMissionsQuery> {
    return useQuery(Queries.FormParametersMissions, args, []);
}

export function executeFormParametersMissionsQuery(
    args: FormParametersMissionsQueryVariables,
    token: string | null | undefined
): Promise<FormParametersMissionsQuery> {
    return executeQuery<FormParametersMissionsQuery>(Queries.FormParametersMissions, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useFormParametersMissionsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    FormParametersMissionsUpdateMutation,
    FormParametersMissionsUpdateMutationVariables
> {
    return useMutation(Mutations.FormParametersMissionsUpdate, args);
}

export function useFormParametersMissionsHiddenDisplayedQuery(
    args: FormParametersMissionsHiddenDisplayedQueryVariables
): IUseQueryReturns<FormParametersMissionsHiddenDisplayedQuery> {
    return useQuery(Queries.FormParametersMissionsHiddenDisplayed, args, []);
}

export function executeFormParametersMissionsHiddenDisplayedQuery(
    args: FormParametersMissionsHiddenDisplayedQueryVariables,
    token: string | null | undefined
): Promise<FormParametersMissionsHiddenDisplayedQuery> {
    return executeQuery<FormParametersMissionsHiddenDisplayedQuery>(
        Queries.FormParametersMissionsHiddenDisplayed,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useFormParametersStateQuery(
    args: FormParametersStateQueryVariables
): IUseQueryReturns<FormParametersStateQuery> {
    return useQuery(Queries.FormParametersState, args, []);
}

export function executeFormParametersStateQuery(
    args: FormParametersStateQueryVariables,
    token: string | null | undefined
): Promise<FormParametersStateQuery> {
    return executeQuery<FormParametersStateQuery>(Queries.FormParametersState, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useFormParametersStateUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    FormParametersStateUpdateMutation,
    FormParametersStateUpdateMutationVariables
> {
    return useMutation(Mutations.FormParametersStateUpdate, args);
}

export function useHomeOrganizationsQuery(
    args: HomeOrganizationsQueryVariables
): IUseQueryReturns<HomeOrganizationsQuery> {
    return useQuery(Queries.HomeOrganizations, args, [Fragments.OrganizationPermissionsFragment]);
}

export function executeHomeOrganizationsQuery(
    args: HomeOrganizationsQueryVariables,
    token: string | null | undefined
): Promise<HomeOrganizationsQuery> {
    return executeQuery<HomeOrganizationsQuery>(Queries.HomeOrganizations, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.OrganizationPermissionsFragment]
    });
}

export function useEventDesignQuery(
    args: EventDesignQueryVariables
): IUseQueryReturns<EventDesignQuery> {
    return useQuery(Queries.EventDesign, args, []);
}

export function executeEventDesignQuery(
    args: EventDesignQueryVariables,
    token: string | null | undefined
): Promise<EventDesignQuery> {
    return executeQuery<EventDesignQuery>(Queries.EventDesign, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useEventDesignUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EventDesignUpdateMutation, EventDesignUpdateMutationVariables> {
    return useMutation(Mutations.EventDesignUpdate, args);
}

export function useEventLinksQuery(
    args: EventLinksQueryVariables
): IUseQueryReturns<EventLinksQuery> {
    return useQuery(Queries.EventLinks, args, []);
}

export function executeEventLinksQuery(
    args: EventLinksQueryVariables,
    token: string | null | undefined
): Promise<EventLinksQuery> {
    return executeQuery<EventLinksQuery>(Queries.EventLinks, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useEventLinksUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EventLinksUpdateMutation, EventLinksUpdateMutationVariables> {
    return useMutation(Mutations.EventLinksUpdate, args);
}

export function useEventsFieldsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EventsFieldsUpdateMutation, EventsFieldsUpdateMutationVariables> {
    return useMutation(Mutations.EventsFieldsUpdate, args);
}

export function useFieldsQuery(args: FieldsQueryVariables): IUseQueryReturns<FieldsQuery> {
    return useQuery(Queries.Fields, args, [Fragments.EventFieldCustomFieldFragment]);
}

export function executeFieldsQuery(
    args: FieldsQueryVariables,
    token: string | null | undefined
): Promise<FieldsQuery> {
    return executeQuery<FieldsQuery>(Queries.Fields, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.EventFieldCustomFieldFragment]
    });
}

export function useEventParametersQuery(
    args: EventParametersQueryVariables
): IUseQueryReturns<EventParametersQuery> {
    return useQuery(Queries.EventParameters, args, []);
}

export function executeEventParametersQuery(
    args: EventParametersQueryVariables,
    token: string | null | undefined
): Promise<EventParametersQuery> {
    return executeQuery<EventParametersQuery>(Queries.EventParameters, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useEventParametersUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EventParametersUpdateMutation, EventParametersUpdateMutationVariables> {
    return useMutation(Mutations.EventParametersUpdate, args);
}

export function useCollaboratorsVolunteersQuery(
    args: CollaboratorsVolunteersQueryVariables
): IUseQueryReturns<CollaboratorsVolunteersQuery> {
    return useQuery(Queries.CollaboratorsVolunteers, args, [Fragments.LeaderUserInfoFragment]);
}

export function executeCollaboratorsVolunteersQuery(
    args: CollaboratorsVolunteersQueryVariables,
    token: string | null | undefined
): Promise<CollaboratorsVolunteersQuery> {
    return executeQuery<CollaboratorsVolunteersQuery>(Queries.CollaboratorsVolunteers, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.LeaderUserInfoFragment]
    });
}

export function useDelegationsImportResultMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<DelegationsImportResultMutation, DelegationsImportResultMutationVariables> {
    return useMutation(Mutations.DelegationsImportResult, args);
}

export function useEventDocumentsExportResultMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    EventDocumentsExportResultMutation,
    EventDocumentsExportResultMutationVariables
> {
    return useMutation(Mutations.EventDocumentsExportResult, args);
}

export function useExportResultMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<ExportResultMutation, ExportResultMutationVariables> {
    return useMutation(Mutations.ExportResult, args);
}

export function useFilesImportResultMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<FilesImportResultMutation, FilesImportResultMutationVariables> {
    return useMutation(Mutations.FilesImportResult, args);
}

export function useVolunteersImportResultMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<VolunteersImportResultMutation, VolunteersImportResultMutationVariables> {
    return useMutation(Mutations.VolunteersImportResult, args);
}

export function useOrganizationCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<OrganizationCreateMutation, OrganizationCreateMutationVariables> {
    return useMutation(Mutations.OrganizationCreate, args);
}

export function useOrganizationsQuery(
    args: OrganizationsQueryVariables
): IUseQueryReturns<OrganizationsQuery> {
    return useQuery(Queries.Organizations, args, [Fragments.OrganizationPermissionsFragment]);
}

export function executeOrganizationsQuery(
    args: OrganizationsQueryVariables,
    token: string | null | undefined
): Promise<OrganizationsQuery> {
    return executeQuery<OrganizationsQuery>(Queries.Organizations, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.OrganizationPermissionsFragment]
    });
}

export function useOrganizationQuery(
    args: OrganizationQueryVariables
): IUseQueryReturns<OrganizationQuery> {
    return useQuery(Queries.Organization, args, [Fragments.OrganizationPermissionsFragment]);
}

export function executeOrganizationQuery(
    args: OrganizationQueryVariables,
    token: string | null | undefined
): Promise<OrganizationQuery> {
    return executeQuery<OrganizationQuery>(Queries.Organization, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.OrganizationPermissionsFragment]
    });
}

export function useOrganizationArchiveMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<OrganizationArchiveMutation, OrganizationArchiveMutationVariables> {
    return useMutation(Mutations.OrganizationArchive, args);
}

export function useUserInfoQuery(args: UserInfoQueryVariables): IUseQueryReturns<UserInfoQuery> {
    return useQuery(Queries.UserInfo, args, []);
}

export function executeUserInfoQuery(
    args: UserInfoQueryVariables,
    token: string | null | undefined
): Promise<UserInfoQuery> {
    return executeQuery<UserInfoQuery>(Queries.UserInfo, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUserInfoAndTermsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserInfoAndTermsUpdateMutation, UserInfoAndTermsUpdateMutationVariables> {
    return useMutation(Mutations.UserInfoAndTermsUpdate, args);
}

export function useActivitiesQuery(
    args: ActivitiesQueryVariables
): IUseQueryReturns<ActivitiesQuery> {
    return useQuery(Queries.Activities, args, []);
}

export function executeActivitiesQuery(
    args: ActivitiesQueryVariables,
    token: string | null | undefined
): Promise<ActivitiesQuery> {
    return executeQuery<ActivitiesQuery>(Queries.Activities, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useOrganizationCategoriesAccreditationsQuery(
    args: OrganizationCategoriesAccreditationsQueryVariables
): IUseQueryReturns<OrganizationCategoriesAccreditationsQuery> {
    return useQuery(Queries.OrganizationCategoriesAccreditations, args, []);
}

export function executeOrganizationCategoriesAccreditationsQuery(
    args: OrganizationCategoriesAccreditationsQueryVariables,
    token: string | null | undefined
): Promise<OrganizationCategoriesAccreditationsQuery> {
    return executeQuery<OrganizationCategoriesAccreditationsQuery>(
        Queries.OrganizationCategoriesAccreditations,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useOrganizationCategoriesCustomFieldsQuery(
    args: OrganizationCategoriesCustomFieldsQueryVariables
): IUseQueryReturns<OrganizationCategoriesCustomFieldsQuery> {
    return useQuery(Queries.OrganizationCategoriesCustomFields, args, []);
}

export function executeOrganizationCategoriesCustomFieldsQuery(
    args: OrganizationCategoriesCustomFieldsQueryVariables,
    token: string | null | undefined
): Promise<OrganizationCategoriesCustomFieldsQuery> {
    return executeQuery<OrganizationCategoriesCustomFieldsQuery>(
        Queries.OrganizationCategoriesCustomFields,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useOrganizationCategoriesPositionsQuery(
    args: OrganizationCategoriesPositionsQueryVariables
): IUseQueryReturns<OrganizationCategoriesPositionsQuery> {
    return useQuery(Queries.OrganizationCategoriesPositions, args, []);
}

export function executeOrganizationCategoriesPositionsQuery(
    args: OrganizationCategoriesPositionsQueryVariables,
    token: string | null | undefined
): Promise<OrganizationCategoriesPositionsQuery> {
    return executeQuery<OrganizationCategoriesPositionsQuery>(
        Queries.OrganizationCategoriesPositions,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useOrganizationDelegationSpaceSettingsQuery(
    args: OrganizationDelegationSpaceSettingsQueryVariables
): IUseQueryReturns<OrganizationDelegationSpaceSettingsQuery> {
    return useQuery(Queries.OrganizationDelegationSpaceSettings, args, []);
}

export function executeOrganizationDelegationSpaceSettingsQuery(
    args: OrganizationDelegationSpaceSettingsQueryVariables,
    token: string | null | undefined
): Promise<OrganizationDelegationSpaceSettingsQuery> {
    return executeQuery<OrganizationDelegationSpaceSettingsQuery>(
        Queries.OrganizationDelegationSpaceSettings,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useOrganizationDelegationSpaceSettingsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    OrganizationDelegationSpaceSettingsUpdateMutation,
    OrganizationDelegationSpaceSettingsUpdateMutationVariables
> {
    return useMutation(Mutations.OrganizationDelegationSpaceSettingsUpdate, args);
}

export function useOrganizationDelegationsSpaceDesignQuery(
    args: OrganizationDelegationsSpaceDesignQueryVariables
): IUseQueryReturns<OrganizationDelegationsSpaceDesignQuery> {
    return useQuery(Queries.OrganizationDelegationsSpaceDesign, args, []);
}

export function executeOrganizationDelegationsSpaceDesignQuery(
    args: OrganizationDelegationsSpaceDesignQueryVariables,
    token: string | null | undefined
): Promise<OrganizationDelegationsSpaceDesignQuery> {
    return executeQuery<OrganizationDelegationsSpaceDesignQuery>(
        Queries.OrganizationDelegationsSpaceDesign,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useOrganizationDelegationsSpaceDesignUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    OrganizationDelegationsSpaceDesignUpdateMutation,
    OrganizationDelegationsSpaceDesignUpdateMutationVariables
> {
    return useMutation(Mutations.OrganizationDelegationsSpaceDesignUpdate, args);
}

export function useDomainDkimVerificationRetryMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    DomainDkimVerificationRetryMutation,
    DomainDkimVerificationRetryMutationVariables
> {
    return useMutation(Mutations.DomainDkimVerificationRetry, args);
}

export function useOrganizationDomainsQuery(
    args: OrganizationDomainsQueryVariables
): IUseQueryReturns<OrganizationDomainsQuery> {
    return useQuery(Queries.OrganizationDomains, args, []);
}

export function executeOrganizationDomainsQuery(
    args: OrganizationDomainsQueryVariables,
    token: string | null | undefined
): Promise<OrganizationDomainsQuery> {
    return executeQuery<OrganizationDomainsQuery>(Queries.OrganizationDomains, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useEmailSenderCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EmailSenderCreateMutation, EmailSenderCreateMutationVariables> {
    return useMutation(Mutations.EmailSenderCreate, args);
}

export function useEmailSenderToEditQuery(
    args: EmailSenderToEditQueryVariables
): IUseQueryReturns<EmailSenderToEditQuery> {
    return useQuery(Queries.EmailSenderToEdit, args, []);
}

export function executeEmailSenderToEditQuery(
    args: EmailSenderToEditQueryVariables,
    token: string | null | undefined
): Promise<EmailSenderToEditQuery> {
    return executeQuery<EmailSenderToEditQuery>(Queries.EmailSenderToEdit, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useEmailSenderUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EmailSenderUpdateMutation, EmailSenderUpdateMutationVariables> {
    return useMutation(Mutations.EmailSenderUpdate, args);
}

export function useEmailSenderDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EmailSenderDeleteMutation, EmailSenderDeleteMutationVariables> {
    return useMutation(Mutations.EmailSenderDelete, args);
}

export function useEmailSenderDuplicateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EmailSenderDuplicateMutation, EmailSenderDuplicateMutationVariables> {
    return useMutation(Mutations.EmailSenderDuplicate, args);
}

export function useEmailSenderVerificationResendMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    EmailSenderVerificationResendMutation,
    EmailSenderVerificationResendMutationVariables
> {
    return useMutation(Mutations.EmailSenderVerificationResend, args);
}

export function useEmailsSendersQuery(
    args: EmailsSendersQueryVariables
): IUseQueryReturns<EmailsSendersQuery> {
    return useQuery(Queries.EmailsSenders, args, []);
}

export function executeEmailsSendersQuery(
    args: EmailsSendersQueryVariables,
    token: string | null | undefined
): Promise<EmailsSendersQuery> {
    return executeQuery<EmailsSendersQuery>(Queries.EmailsSenders, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useEmailSenderPreviewQuery(
    args: EmailSenderPreviewQueryVariables
): IUseQueryReturns<EmailSenderPreviewQuery> {
    return useQuery(Queries.EmailSenderPreview, args, []);
}

export function executeEmailSenderPreviewQuery(
    args: EmailSenderPreviewQueryVariables,
    token: string | null | undefined
): Promise<EmailSenderPreviewQuery> {
    return executeQuery<EmailSenderPreviewQuery>(Queries.EmailSenderPreview, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useOrganizationParametersInfosQuery(
    args: OrganizationParametersInfosQueryVariables
): IUseQueryReturns<OrganizationParametersInfosQuery> {
    return useQuery(Queries.OrganizationParametersInfos, args, [
        Fragments.OrganizationAdminInfosFragment
    ]);
}

export function executeOrganizationParametersInfosQuery(
    args: OrganizationParametersInfosQueryVariables,
    token: string | null | undefined
): Promise<OrganizationParametersInfosQuery> {
    return executeQuery<OrganizationParametersInfosQuery>(Queries.OrganizationParametersInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.OrganizationAdminInfosFragment]
    });
}

export function useOrganizationUpdateInfosMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<OrganizationUpdateInfosMutation, OrganizationUpdateInfosMutationVariables> {
    return useMutation(Mutations.OrganizationUpdateInfos, args);
}

export function useInvoicesQuery(args: InvoicesQueryVariables): IUseQueryReturns<InvoicesQuery> {
    return useQuery(Queries.Invoices, args, []);
}

export function executeInvoicesQuery(
    args: InvoicesQueryVariables,
    token: string | null | undefined
): Promise<InvoicesQuery> {
    return executeQuery<InvoicesQuery>(Queries.Invoices, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useOrganizationMembersSpaceDesignQuery(
    args: OrganizationMembersSpaceDesignQueryVariables
): IUseQueryReturns<OrganizationMembersSpaceDesignQuery> {
    return useQuery(Queries.OrganizationMembersSpaceDesign, args, []);
}

export function executeOrganizationMembersSpaceDesignQuery(
    args: OrganizationMembersSpaceDesignQueryVariables,
    token: string | null | undefined
): Promise<OrganizationMembersSpaceDesignQuery> {
    return executeQuery<OrganizationMembersSpaceDesignQuery>(
        Queries.OrganizationMembersSpaceDesign,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useOrganizationMembersSpaceDesignUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    OrganizationMembersSpaceDesignUpdateMutation,
    OrganizationMembersSpaceDesignUpdateMutationVariables
> {
    return useMutation(Mutations.OrganizationMembersSpaceDesignUpdate, args);
}

export function useOrganizationMembersSpaceSettingsQuery(
    args: OrganizationMembersSpaceSettingsQueryVariables
): IUseQueryReturns<OrganizationMembersSpaceSettingsQuery> {
    return useQuery(Queries.OrganizationMembersSpaceSettings, args, []);
}

export function executeOrganizationMembersSpaceSettingsQuery(
    args: OrganizationMembersSpaceSettingsQueryVariables,
    token: string | null | undefined
): Promise<OrganizationMembersSpaceSettingsQuery> {
    return executeQuery<OrganizationMembersSpaceSettingsQuery>(
        Queries.OrganizationMembersSpaceSettings,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useOrganizationMembersSpaceSettingsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    OrganizationMembersSpaceSettingsUpdateMutation,
    OrganizationMembersSpaceSettingsUpdateMutationVariables
> {
    return useMutation(Mutations.OrganizationMembersSpaceSettingsUpdate, args);
}

export function useProfileQuery(args: ProfileQueryVariables): IUseQueryReturns<ProfileQuery> {
    return useQuery(Queries.Profile, args, []);
}

export function executeProfileQuery(
    args: ProfileQueryVariables,
    token: string | null | undefined
): Promise<ProfileQuery> {
    return executeQuery<ProfileQuery>(Queries.Profile, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUserDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserDeleteMutation, UserDeleteMutationVariables> {
    return useMutation(Mutations.UserDelete, args);
}

export function useUserProfileUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserProfileUpdateMutation, UserProfileUpdateMutationVariables> {
    return useMutation(Mutations.UserProfileUpdate, args);
}

export function useSkidataConnectionInfoSeaonEventAddMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    SkidataConnectionInfoSeaonEventAddMutation,
    SkidataConnectionInfoSeaonEventAddMutationVariables
> {
    return useMutation(Mutations.SkidataConnectionInfoSeaonEventAdd, args);
}

export function useSkidataConnectionInfoSeaonEventDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    SkidataConnectionInfoSeaonEventDeleteMutation,
    SkidataConnectionInfoSeaonEventDeleteMutationVariables
> {
    return useMutation(Mutations.SkidataConnectionInfoSeaonEventDelete, args);
}

export function useSkidataConnectionInfoSynchronizedEventAddMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    SkidataConnectionInfoSynchronizedEventAddMutation,
    SkidataConnectionInfoSynchronizedEventAddMutationVariables
> {
    return useMutation(Mutations.SkidataConnectionInfoSynchronizedEventAdd, args);
}

export function useSkidataConnectionInfoSynchronizedEventDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    SkidataConnectionInfoSynchronizedEventDeleteMutation,
    SkidataConnectionInfoSynchronizedEventDeleteMutationVariables
> {
    return useMutation(Mutations.SkidataConnectionInfoSynchronizedEventDelete, args);
}

export function useSkidataConnectionInfosQuery(
    args: SkidataConnectionInfosQueryVariables
): IUseQueryReturns<SkidataConnectionInfosQuery> {
    return useQuery(Queries.SkidataConnectionInfos, args, []);
}

export function executeSkidataConnectionInfosQuery(
    args: SkidataConnectionInfosQueryVariables,
    token: string | null | undefined
): Promise<SkidataConnectionInfosQuery> {
    return executeQuery<SkidataConnectionInfosQuery>(Queries.SkidataConnectionInfos, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useSkidataConnectionInfosDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    SkidataConnectionInfosDeleteMutation,
    SkidataConnectionInfosDeleteMutationVariables
> {
    return useMutation(Mutations.SkidataConnectionInfosDelete, args);
}

export function useSkidataConnectionInfosUpsertMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    SkidataConnectionInfosUpsertMutation,
    SkidataConnectionInfosUpsertMutationVariables
> {
    return useMutation(Mutations.SkidataConnectionInfosUpsert, args);
}

export function useSkidataSynchronizeMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SkidataSynchronizeMutation, SkidataSynchronizeMutationVariables> {
    return useMutation(Mutations.SkidataSynchronize, args);
}

export function useOrganizationTagsQuery(
    args: OrganizationTagsQueryVariables
): IUseQueryReturns<OrganizationTagsQuery> {
    return useQuery(Queries.OrganizationTags, args, []);
}

export function executeOrganizationTagsQuery(
    args: OrganizationTagsQueryVariables,
    token: string | null | undefined
): Promise<OrganizationTagsQuery> {
    return executeQuery<OrganizationTagsQuery>(Queries.OrganizationTags, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useTagCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<TagCreateMutation, TagCreateMutationVariables> {
    return useMutation(Mutations.TagCreate, args);
}

export function useTagDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<TagDeleteMutation, TagDeleteMutationVariables> {
    return useMutation(Mutations.TagDelete, args);
}

export function useTagUpdateNameMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<TagUpdateNameMutation, TagUpdateNameMutationVariables> {
    return useMutation(Mutations.TagUpdateName, args);
}

export function useEmailEventInviteResendMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EmailEventInviteResendMutation, EmailEventInviteResendMutationVariables> {
    return useMutation(Mutations.EmailEventInviteResend, args);
}

export function useEmailOrganizationInviteResendMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    EmailOrganizationInviteResendMutation,
    EmailOrganizationInviteResendMutationVariables
> {
    return useMutation(Mutations.EmailOrganizationInviteResend, args);
}

export function useEventAdminsLeadersQuery(
    args: EventAdminsLeadersQueryVariables
): IUseQueryReturns<EventAdminsLeadersQuery> {
    return useQuery(Queries.EventAdminsLeaders, args, []);
}

export function executeEventAdminsLeadersQuery(
    args: EventAdminsLeadersQueryVariables,
    token: string | null | undefined
): Promise<EventAdminsLeadersQuery> {
    return executeQuery<EventAdminsLeadersQuery>(Queries.EventAdminsLeaders, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useEventInviteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EventInviteMutation, EventInviteMutationVariables> {
    return useMutation(Mutations.EventInvite, args);
}

export function useOrganizationInviteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<OrganizationInviteMutation, OrganizationInviteMutationVariables> {
    return useMutation(Mutations.OrganizationInvite, args);
}

export function useTeamQuery(args: TeamQueryVariables): IUseQueryReturns<TeamQuery> {
    return useQuery(Queries.Team, args, []);
}

export function executeTeamQuery(
    args: TeamQueryVariables,
    token: string | null | undefined
): Promise<TeamQuery> {
    return executeQuery<TeamQuery>(Queries.Team, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUserEventDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserEventDeleteMutation, UserEventDeleteMutationVariables> {
    return useMutation(Mutations.UserEventDelete, args);
}

export function useUserLeaderDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserLeaderDeleteMutation, UserLeaderDeleteMutationVariables> {
    return useMutation(Mutations.UserLeaderDelete, args);
}

export function useUserOrganizationDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserOrganizationDeleteMutation, UserOrganizationDeleteMutationVariables> {
    return useMutation(Mutations.UserOrganizationDelete, args);
}

export function useUserOrganizationUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserOrganizationUpdateMutation, UserOrganizationUpdateMutationVariables> {
    return useMutation(Mutations.UserOrganizationUpdate, args);
}

export function useUserPositionCategoryUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    UserPositionCategoryUpdateMutation,
    UserPositionCategoryUpdateMutationVariables
> {
    return useMutation(Mutations.UserPositionCategoryUpdate, args);
}

export function useUserPositionUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserPositionUpdateMutation, UserPositionUpdateMutationVariables> {
    return useMutation(Mutations.UserPositionUpdate, args);
}

export function useOrganizationTranslationsQuery(
    args: OrganizationTranslationsQueryVariables
): IUseQueryReturns<OrganizationTranslationsQuery> {
    return useQuery(Queries.OrganizationTranslations, args, [Fragments.TranslationFragment]);
}

export function executeOrganizationTranslationsQuery(
    args: OrganizationTranslationsQueryVariables,
    token: string | null | undefined
): Promise<OrganizationTranslationsQuery> {
    return executeQuery<OrganizationTranslationsQuery>(Queries.OrganizationTranslations, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.TranslationFragment]
    });
}

export function useOrganizationTranslationsStatsQuery(
    args: OrganizationTranslationsStatsQueryVariables
): IUseQueryReturns<OrganizationTranslationsStatsQuery> {
    return useQuery(Queries.OrganizationTranslationsStats, args, []);
}

export function executeOrganizationTranslationsStatsQuery(
    args: OrganizationTranslationsStatsQueryVariables,
    token: string | null | undefined
): Promise<OrganizationTranslationsStatsQuery> {
    return executeQuery<OrganizationTranslationsStatsQuery>(Queries.OrganizationTranslationsStats, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useTranslationUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<TranslationUpdateMutation, TranslationUpdateMutationVariables> {
    return useMutation(Mutations.TranslationUpdate, args);
}

export function usePositionCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionCreateMutation, PositionCreateMutationVariables> {
    return useMutation(Mutations.PositionCreate, args);
}

export function usePositionEventInfosQuery(
    args: PositionEventInfosQueryVariables
): IUseQueryReturns<PositionEventInfosQuery> {
    return useQuery(Queries.PositionEventInfos, args, [
        Fragments.CreatePositionCustomFieldsFragment,
        Fragments.CreatePositionTagsFragment,
        Fragments.CreatePositionEventFragment
    ]);
}

export function executePositionEventInfosQuery(
    args: PositionEventInfosQueryVariables,
    token: string | null | undefined
): Promise<PositionEventInfosQuery> {
    return executeQuery<PositionEventInfosQuery>(Queries.PositionEventInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CreatePositionCustomFieldsFragment,
            Fragments.CreatePositionTagsFragment,
            Fragments.CreatePositionEventFragment
        ]
    });
}

export function usePositionToEditQuery(
    args: PositionToEditQueryVariables
): IUseQueryReturns<PositionToEditQuery> {
    return useQuery(Queries.PositionToEdit, args, [
        Fragments.CreatePositionCustomFieldsFragment,
        Fragments.CreatePositionTagsFragment,
        Fragments.CreatePositionEventFragment
    ]);
}

export function executePositionToEditQuery(
    args: PositionToEditQueryVariables,
    token: string | null | undefined
): Promise<PositionToEditQuery> {
    return executeQuery<PositionToEditQuery>(Queries.PositionToEdit, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CreatePositionCustomFieldsFragment,
            Fragments.CreatePositionTagsFragment,
            Fragments.CreatePositionEventFragment
        ]
    });
}

export function usePositionUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionUpdateMutation, PositionUpdateMutationVariables> {
    return useMutation(Mutations.PositionUpdate, args);
}

export function useKmlEventInfosQuery(
    args: KmlEventInfosQueryVariables
): IUseQueryReturns<KmlEventInfosQuery> {
    return useQuery(Queries.KmlEventInfos, args, []);
}

export function executeKmlEventInfosQuery(
    args: KmlEventInfosQueryVariables,
    token: string | null | undefined
): Promise<KmlEventInfosQuery> {
    return executeQuery<KmlEventInfosQuery>(Queries.KmlEventInfos, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function usePositionsCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionsCreateMutation, PositionsCreateMutationVariables> {
    return useMutation(Mutations.PositionsCreate, args);
}

export function usePositionsCalendarQuery(
    args: PositionsCalendarQueryVariables
): IUseQueryReturns<PositionsCalendarQuery> {
    return useQuery(Queries.PositionsCalendar, args, []);
}

export function executePositionsCalendarQuery(
    args: PositionsCalendarQueryVariables,
    token: string | null | undefined
): Promise<PositionsCalendarQuery> {
    return executeQuery<PositionsCalendarQuery>(Queries.PositionsCalendar, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function usePositionUpdateFieldMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionUpdateFieldMutation, PositionUpdateFieldMutationVariables> {
    return useMutation(Mutations.PositionUpdateField, args);
}

export function usePositionsCategoriesQuery(
    args: PositionsCategoriesQueryVariables
): IUseQueryReturns<PositionsCategoriesQuery> {
    return useQuery(Queries.PositionsCategories, args, []);
}

export function executePositionsCategoriesQuery(
    args: PositionsCategoriesQueryVariables,
    token: string | null | undefined
): Promise<PositionsCategoriesQuery> {
    return executeQuery<PositionsCategoriesQuery>(Queries.PositionsCategories, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function usePositionsMassDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionsMassDeleteMutation, PositionsMassDeleteMutationVariables> {
    return useMutation(Mutations.PositionsMassDelete, args);
}

export function usePositionsPositionQuery(
    args: PositionsPositionQueryVariables
): IUseQueryReturns<PositionsPositionQuery> {
    return useQuery(Queries.PositionsPosition, args, [Fragments.PositionsPositionFragment]);
}

export function executePositionsPositionQuery(
    args: PositionsPositionQueryVariables,
    token: string | null | undefined
): Promise<PositionsPositionQuery> {
    return executeQuery<PositionsPositionQuery>(Queries.PositionsPosition, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.PositionsPositionFragment]
    });
}

export function useEventLayerUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EventLayerUpdateMutation, EventLayerUpdateMutationVariables> {
    return useMutation(Mutations.EventLayerUpdate, args);
}

export function useTraceCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<TraceCreateMutation, TraceCreateMutationVariables> {
    return useMutation(Mutations.TraceCreate, args);
}

export function useTraceDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<TraceDeleteMutation, TraceDeleteMutationVariables> {
    return useMutation(Mutations.TraceDelete, args);
}

export function useTraceUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<TraceUpdateMutation, TraceUpdateMutationVariables> {
    return useMutation(Mutations.TraceUpdate, args);
}

export function useTracesQuery(args: TracesQueryVariables): IUseQueryReturns<TracesQuery> {
    return useQuery(Queries.Traces, args, []);
}

export function executeTracesQuery(
    args: TracesQueryVariables,
    token: string | null | undefined
): Promise<TracesQuery> {
    return executeQuery<TracesQuery>(Queries.Traces, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function usePositionCategoryDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionCategoryDeleteMutation, PositionCategoryDeleteMutationVariables> {
    return useMutation(Mutations.PositionCategoryDelete, args);
}

export function usePositionCategoryLeaderDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    PositionCategoryLeaderDeleteMutation,
    PositionCategoryLeaderDeleteMutationVariables
> {
    return useMutation(Mutations.PositionCategoryLeaderDelete, args);
}

export function usePositionsQuery(args: PositionsQueryVariables): IUseQueryReturns<PositionsQuery> {
    return useQuery(Queries.Positions, args, [
        Fragments.PositionsPositionFragment,
        Fragments.TraceFragment
    ]);
}

export function executePositionsQuery(
    args: PositionsQueryVariables,
    token: string | null | undefined
): Promise<PositionsQuery> {
    return executeQuery<PositionsQuery>(Queries.Positions, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.PositionsPositionFragment, Fragments.TraceFragment]
    });
}

export function usePositionsHeaderQuery(
    args: PositionsHeaderQueryVariables
): IUseQueryReturns<PositionsHeaderQuery> {
    return useQuery(Queries.PositionsHeader, args, []);
}

export function executePositionsHeaderQuery(
    args: PositionsHeaderQueryVariables,
    token: string | null | undefined
): Promise<PositionsHeaderQuery> {
    return executeQuery<PositionsHeaderQuery>(Queries.PositionsHeader, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function usePositionsSegmentsQuery(
    args: PositionsSegmentsQueryVariables
): IUseQueryReturns<PositionsSegmentsQuery> {
    return useQuery(Queries.PositionsSegments, args, [
        Fragments.SegmentCustomFieldFragment,
        Fragments.LeaderInfosFragment,
        Fragments.SegmentsFoldersSegmentsFragment
    ]);
}

export function executePositionsSegmentsQuery(
    args: PositionsSegmentsQueryVariables,
    token: string | null | undefined
): Promise<PositionsSegmentsQuery> {
    return executeQuery<PositionsSegmentsQuery>(Queries.PositionsSegments, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.SegmentCustomFieldFragment,
            Fragments.LeaderInfosFragment,
            Fragments.SegmentsFoldersSegmentsFragment
        ]
    });
}

export function usePositionMapQuery(
    args: PositionMapQueryVariables
): IUseQueryReturns<PositionMapQuery> {
    return useQuery(Queries.PositionMap, args, [Fragments.TraceFragment]);
}

export function executePositionMapQuery(
    args: PositionMapQueryVariables,
    token: string | null | undefined
): Promise<PositionMapQuery> {
    return executeQuery<PositionMapQuery>(Queries.PositionMap, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.TraceFragment]
    });
}

export function useMassConditionsCustomFieldsQuery(
    args: MassConditionsCustomFieldsQueryVariables
): IUseQueryReturns<MassConditionsCustomFieldsQuery> {
    return useQuery(Queries.MassConditionsCustomFields, args, [
        Fragments.CreatePositionCustomFieldsFragment
    ]);
}

export function executeMassConditionsCustomFieldsQuery(
    args: MassConditionsCustomFieldsQueryVariables,
    token: string | null | undefined
): Promise<MassConditionsCustomFieldsQuery> {
    return executeQuery<MassConditionsCustomFieldsQuery>(Queries.MassConditionsCustomFields, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CreatePositionCustomFieldsFragment]
    });
}

export function usePositionsMassConditionsMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionsMassConditionsMutation, PositionsMassConditionsMutationVariables> {
    return useMutation(Mutations.PositionsMassConditions, args);
}

export function useAccreditationsMassEditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<AccreditationsMassEditMutation, AccreditationsMassEditMutationVariables> {
    return useMutation(Mutations.AccreditationsMassEdit, args);
}

export function usePositionsMassEditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionsMassEditMutation, PositionsMassEditMutationVariables> {
    return useMutation(Mutations.PositionsMassEdit, args);
}

export function usePositionsMassEditInfosQuery(
    args: PositionsMassEditInfosQueryVariables
): IUseQueryReturns<PositionsMassEditInfosQuery> {
    return useQuery(Queries.PositionsMassEditInfos, args, []);
}

export function executePositionsMassEditInfosQuery(
    args: PositionsMassEditInfosQueryVariables,
    token: string | null | undefined
): Promise<PositionsMassEditInfosQuery> {
    return executeQuery<PositionsMassEditInfosQuery>(Queries.PositionsMassEditInfos, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function usePositionsMobileQuery(
    args: PositionsMobileQueryVariables
): IUseQueryReturns<PositionsMobileQuery> {
    return useQuery(Queries.PositionsMobile, args, [
        Fragments.LeaderInfosFragment,
        Fragments.FullPageInfoFragment
    ]);
}

export function executePositionsMobileQuery(
    args: PositionsMobileQueryVariables,
    token: string | null | undefined
): Promise<PositionsMobileQuery> {
    return executeQuery<PositionsMobileQuery>(Queries.PositionsMobile, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.LeaderInfosFragment, Fragments.FullPageInfoFragment]
    });
}

export function usePositionsSegmentsMobileQuery(
    args: PositionsSegmentsMobileQueryVariables
): IUseQueryReturns<PositionsSegmentsMobileQuery> {
    return useQuery(Queries.PositionsSegmentsMobile, args, []);
}

export function executePositionsSegmentsMobileQuery(
    args: PositionsSegmentsMobileQueryVariables,
    token: string | null | undefined
): Promise<PositionsSegmentsMobileQuery> {
    return executeQuery<PositionsSegmentsMobileQuery>(Queries.PositionsSegmentsMobile, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useRecentPositionsSearchesMobileQuery(
    args: RecentPositionsSearchesMobileQueryVariables
): IUseQueryReturns<RecentPositionsSearchesMobileQuery> {
    return useQuery(Queries.RecentPositionsSearchesMobile, args, []);
}

export function executeRecentPositionsSearchesMobileQuery(
    args: RecentPositionsSearchesMobileQueryVariables,
    token: string | null | undefined
): Promise<RecentPositionsSearchesMobileQuery> {
    return executeQuery<RecentPositionsSearchesMobileQuery>(Queries.RecentPositionsSearchesMobile, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function usePositionMobileQuery(
    args: PositionMobileQueryVariables
): IUseQueryReturns<PositionMobileQuery> {
    return useQuery(Queries.PositionMobile, args, [
        Fragments.TraceFragment,
        Fragments.LeaderInfosFragment
    ]);
}

export function executePositionMobileQuery(
    args: PositionMobileQueryVariables,
    token: string | null | undefined
): Promise<PositionMobileQuery> {
    return executeQuery<PositionMobileQuery>(Queries.PositionMobile, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.TraceFragment, Fragments.LeaderInfosFragment]
    });
}

export function usePositionSlotMembersMobileQuery(
    args: PositionSlotMembersMobileQueryVariables
): IUseQueryReturns<PositionSlotMembersMobileQuery> {
    return useQuery(Queries.PositionSlotMembersMobile, args, [Fragments.FullPageInfoFragment]);
}

export function executePositionSlotMembersMobileQuery(
    args: PositionSlotMembersMobileQueryVariables,
    token: string | null | undefined
): Promise<PositionSlotMembersMobileQuery> {
    return executeQuery<PositionSlotMembersMobileQuery>(Queries.PositionSlotMembersMobile, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.FullPageInfoFragment]
    });
}

export function usePositionsSlotsUsersInfosCheckInMobileMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    PositionsSlotsUsersInfosCheckInMobileMutation,
    PositionsSlotsUsersInfosCheckInMobileMutationVariables
> {
    return useMutation(Mutations.PositionsSlotsUsersInfosCheckInMobile, args);
}

export function usePositionsSlotsUsersInfosCheckOutMobileMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    PositionsSlotsUsersInfosCheckOutMobileMutation,
    PositionsSlotsUsersInfosCheckOutMobileMutationVariables
> {
    return useMutation(Mutations.PositionsSlotsUsersInfosCheckOutMobile, args);
}

export function usePositionsSlotsUsersInfosResetCheckMobileMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    PositionsSlotsUsersInfosResetCheckMobileMutation,
    PositionsSlotsUsersInfosResetCheckMobileMutationVariables
> {
    return useMutation(Mutations.PositionsSlotsUsersInfosResetCheckMobile, args);
}

export function usePositionSlotsMobileQuery(
    args: PositionSlotsMobileQueryVariables
): IUseQueryReturns<PositionSlotsMobileQuery> {
    return useQuery(Queries.PositionSlotsMobile, args, [
        Fragments.PositionPositionSlotFragment,
        Fragments.FullPageInfoFragment
    ]);
}

export function executePositionSlotsMobileQuery(
    args: PositionSlotsMobileQueryVariables,
    token: string | null | undefined
): Promise<PositionSlotsMobileQuery> {
    return executeQuery<PositionSlotsMobileQuery>(Queries.PositionSlotsMobile, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.PositionPositionSlotFragment, Fragments.FullPageInfoFragment]
    });
}

export function usePositionDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionDeleteMutation, PositionDeleteMutationVariables> {
    return useMutation(Mutations.PositionDelete, args);
}

export function usePositionQuery(args: PositionQueryVariables): IUseQueryReturns<PositionQuery> {
    return useQuery(Queries.Position, args, [
        Fragments.TraceFragment,
        Fragments.LeaderInfosFragment
    ]);
}

export function executePositionQuery(
    args: PositionQueryVariables,
    token: string | null | undefined
): Promise<PositionQuery> {
    return executeQuery<PositionQuery>(Queries.Position, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.TraceFragment, Fragments.LeaderInfosFragment]
    });
}

export function usePositionLeaderDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionLeaderDeleteMutation, PositionLeaderDeleteMutationVariables> {
    return useMutation(Mutations.PositionLeaderDelete, args);
}

export function usePositionLeadersAsignMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionLeadersAsignMutation, PositionLeadersAsignMutationVariables> {
    return useMutation(Mutations.PositionLeadersAsign, args);
}

export function usePositionSlotUsersInfosCheckInMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    PositionSlotUsersInfosCheckInMutation,
    PositionSlotUsersInfosCheckInMutationVariables
> {
    return useMutation(Mutations.PositionSlotUsersInfosCheckIn, args);
}

export function usePositionSlotUsersInfosCheckOutMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    PositionSlotUsersInfosCheckOutMutation,
    PositionSlotUsersInfosCheckOutMutationVariables
> {
    return useMutation(Mutations.PositionSlotUsersInfosCheckOut, args);
}

export function usePositionSlotDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionSlotDeleteMutation, PositionSlotDeleteMutationVariables> {
    return useMutation(Mutations.PositionSlotDelete, args);
}

export function usePositionSlotUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionSlotUpdateMutation, PositionSlotUpdateMutationVariables> {
    return useMutation(Mutations.PositionSlotUpdate, args);
}

export function usePositionSlotVolunteersQuery(
    args: PositionSlotVolunteersQueryVariables
): IUseQueryReturns<PositionSlotVolunteersQuery> {
    return useQuery(Queries.PositionSlotVolunteers, args, []);
}

export function executePositionSlotVolunteersQuery(
    args: PositionSlotVolunteersQueryVariables,
    token: string | null | undefined
): Promise<PositionSlotVolunteersQuery> {
    return executeQuery<PositionSlotVolunteersQuery>(Queries.PositionSlotVolunteers, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function usePositionSlotsQuery(
    args: PositionSlotsQueryVariables
): IUseQueryReturns<PositionSlotsQuery> {
    return useQuery(Queries.PositionSlots, args, [Fragments.PositionPositionSlotFragment]);
}

export function executePositionSlotsQuery(
    args: PositionSlotsQueryVariables,
    token: string | null | undefined
): Promise<PositionSlotsQuery> {
    return executeQuery<PositionSlotsQuery>(Queries.PositionSlots, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.PositionPositionSlotFragment]
    });
}

export function useVolunteerRegistrationSlotUnassignMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteerRegistrationSlotUnassignMutation,
    VolunteerRegistrationSlotUnassignMutationVariables
> {
    return useMutation(Mutations.VolunteerRegistrationSlotUnassign, args);
}

export function usePositionUpdateColorMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionUpdateColorMutation, PositionUpdateColorMutationVariables> {
    return useMutation(Mutations.PositionUpdateColor, args);
}

export function usePositionUpdateIsVisibleMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionUpdateIsVisibleMutation, PositionUpdateIsVisibleMutationVariables> {
    return useMutation(Mutations.PositionUpdateIsVisible, args);
}

export function usePositionCategoryCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionCategoryCreateMutation, PositionCategoryCreateMutationVariables> {
    return useMutation(Mutations.PositionCategoryCreate, args);
}

export function usePositionCategoryUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionCategoryUpdateMutation, PositionCategoryUpdateMutationVariables> {
    return useMutation(Mutations.PositionCategoryUpdate, args);
}

export function usePositionCategoryQuery(
    args: PositionCategoryQueryVariables
): IUseQueryReturns<PositionCategoryQuery> {
    return useQuery(Queries.PositionCategory, args, [Fragments.LeaderInfosFragment]);
}

export function executePositionCategoryQuery(
    args: PositionCategoryQueryVariables,
    token: string | null | undefined
): Promise<PositionCategoryQuery> {
    return executeQuery<PositionCategoryQuery>(Queries.PositionCategory, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.LeaderInfosFragment]
    });
}

export function usePositionCategoryDescriptionUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    PositionCategoryDescriptionUpdateMutation,
    PositionCategoryDescriptionUpdateMutationVariables
> {
    return useMutation(Mutations.PositionCategoryDescriptionUpdate, args);
}

export function usePositionCategoryPositionVolunteersQuery(
    args: PositionCategoryPositionVolunteersQueryVariables
): IUseQueryReturns<PositionCategoryPositionVolunteersQuery> {
    return useQuery(Queries.PositionCategoryPositionVolunteers, args, []);
}

export function executePositionCategoryPositionVolunteersQuery(
    args: PositionCategoryPositionVolunteersQueryVariables,
    token: string | null | undefined
): Promise<PositionCategoryPositionVolunteersQuery> {
    return executeQuery<PositionCategoryPositionVolunteersQuery>(
        Queries.PositionCategoryPositionVolunteers,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function usePositionCategoryPositionsQuery(
    args: PositionCategoryPositionsQueryVariables
): IUseQueryReturns<PositionCategoryPositionsQuery> {
    return useQuery(Queries.PositionCategoryPositions, args, [
        Fragments.PositionCategoryPositionFragment
    ]);
}

export function executePositionCategoryPositionsQuery(
    args: PositionCategoryPositionsQueryVariables,
    token: string | null | undefined
): Promise<PositionCategoryPositionsQuery> {
    return executeQuery<PositionCategoryPositionsQuery>(Queries.PositionCategoryPositions, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.PositionCategoryPositionFragment]
    });
}

export function usePositionsCategoryLeadersAssignMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    PositionsCategoryLeadersAssignMutation,
    PositionsCategoryLeadersAssignMutationVariables
> {
    return useMutation(Mutations.PositionsCategoryLeadersAssign, args);
}

export function useAvailableVolunteersRegistrationsQuery(
    args: AvailableVolunteersRegistrationsQueryVariables
): IUseQueryReturns<AvailableVolunteersRegistrationsQuery> {
    return useQuery(Queries.AvailableVolunteersRegistrations, args, []);
}

export function executeAvailableVolunteersRegistrationsQuery(
    args: AvailableVolunteersRegistrationsQueryVariables,
    token: string | null | undefined
): Promise<AvailableVolunteersRegistrationsQuery> {
    return executeQuery<AvailableVolunteersRegistrationsQuery>(
        Queries.AvailableVolunteersRegistrations,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersRegistrationsAssignMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsAssignMutation,
    VolunteersRegistrationsAssignMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsAssign, args);
}

export function usePositionsPositionSlotQuery(
    args: PositionsPositionSlotQueryVariables
): IUseQueryReturns<PositionsPositionSlotQuery> {
    return useQuery(Queries.PositionsPositionSlot, args, [Fragments.PositionsPositionSlotFragment]);
}

export function executePositionsPositionSlotQuery(
    args: PositionsPositionSlotQueryVariables,
    token: string | null | undefined
): Promise<PositionsPositionSlotQuery> {
    return executeQuery<PositionsPositionSlotQuery>(Queries.PositionsPositionSlot, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.PositionsPositionSlotFragment]
    });
}

export function usePositionsSlotsQuery(
    args: PositionsSlotsQueryVariables
): IUseQueryReturns<PositionsSlotsQuery> {
    return useQuery(Queries.PositionsSlots, args, [Fragments.PositionsPositionSlotFragment]);
}

export function executePositionsSlotsQuery(
    args: PositionsSlotsQueryVariables,
    token: string | null | undefined
): Promise<PositionsSlotsQuery> {
    return executeQuery<PositionsSlotsQuery>(Queries.PositionsSlots, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.PositionsPositionSlotFragment]
    });
}

export function useTagsAccreditationAssociateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    TagsAccreditationAssociateMutation,
    TagsAccreditationAssociateMutationVariables
> {
    return useMutation(Mutations.TagsAccreditationAssociate, args);
}

export function useTagsDelegationAssociateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<TagsDelegationAssociateMutation, TagsDelegationAssociateMutationVariables> {
    return useMutation(Mutations.TagsDelegationAssociate, args);
}

export function useTagsPositionAssociateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<TagsPositionAssociateMutation, TagsPositionAssociateMutationVariables> {
    return useMutation(Mutations.TagsPositionAssociate, args);
}

export function useUserFieldUpdateQuery(
    args: UserFieldUpdateQueryVariables
): IUseQueryReturns<UserFieldUpdateQuery> {
    return useQuery(Queries.UserFieldUpdate, args, []);
}

export function executeUserFieldUpdateQuery(
    args: UserFieldUpdateQueryVariables,
    token: string | null | undefined
): Promise<UserFieldUpdateQuery> {
    return executeQuery<UserFieldUpdateQuery>(Queries.UserFieldUpdate, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUserInfoMobileQuery(
    args: UserInfoMobileQueryVariables
): IUseQueryReturns<UserInfoMobileQuery> {
    return useQuery(Queries.UserInfoMobile, args, [
        Fragments.CustomFieldWithConditionFragment,
        Fragments.UserInfoOverlayFragment,
        Fragments.UserPanelPositionSlotUserInfoFragment
    ]);
}

export function executeUserInfoMobileQuery(
    args: UserInfoMobileQueryVariables,
    token: string | null | undefined
): Promise<UserInfoMobileQuery> {
    return executeQuery<UserInfoMobileQuery>(Queries.UserInfoMobile, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CustomFieldWithConditionFragment,
            Fragments.UserInfoOverlayFragment,
            Fragments.UserPanelPositionSlotUserInfoFragment
        ]
    });
}

export function useUserOverlayAccreditationsQuery(
    args: UserOverlayAccreditationsQueryVariables
): IUseQueryReturns<UserOverlayAccreditationsQuery> {
    return useQuery(Queries.UserOverlayAccreditations, args, [
        Fragments.UserPanelAccreditationSlotUserInfoFragment
    ]);
}

export function executeUserOverlayAccreditationsQuery(
    args: UserOverlayAccreditationsQueryVariables,
    token: string | null | undefined
): Promise<UserOverlayAccreditationsQuery> {
    return executeQuery<UserOverlayAccreditationsQuery>(Queries.UserOverlayAccreditations, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.UserPanelAccreditationSlotUserInfoFragment]
    });
}

export function useUserInfoAdminDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserInfoAdminDeleteMutation, UserInfoAdminDeleteMutationVariables> {
    return useMutation(Mutations.UserInfoAdminDelete, args);
}

export function useUserOverlayCampaignsQuery(
    args: UserOverlayCampaignsQueryVariables
): IUseQueryReturns<UserOverlayCampaignsQuery> {
    return useQuery(Queries.UserOverlayCampaigns, args, []);
}

export function executeUserOverlayCampaignsQuery(
    args: UserOverlayCampaignsQueryVariables,
    token: string | null | undefined
): Promise<UserOverlayCampaignsQuery> {
    return executeQuery<UserOverlayCampaignsQuery>(Queries.UserOverlayCampaigns, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUserOverlayEventsQuery(
    args: UserOverlayEventsQueryVariables
): IUseQueryReturns<UserOverlayEventsQuery> {
    return useQuery(Queries.UserOverlayEvents, args, []);
}

export function executeUserOverlayEventsQuery(
    args: UserOverlayEventsQueryVariables,
    token: string | null | undefined
): Promise<UserOverlayEventsQuery> {
    return executeQuery<UserOverlayEventsQuery>(Queries.UserOverlayEvents, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUserOverlayInformationsEventQuery(
    args: UserOverlayInformationsEventQueryVariables
): IUseQueryReturns<UserOverlayInformationsEventQuery> {
    return useQuery(Queries.UserOverlayInformationsEvent, args, [
        Fragments.CustomFieldWithConditionFragment,
        Fragments.FormUserInfoUserProfileFragment,
        Fragments.FormUserOverlayFragment,
        Fragments.DelegationUserProfileFragment
    ]);
}

export function executeUserOverlayInformationsEventQuery(
    args: UserOverlayInformationsEventQueryVariables,
    token: string | null | undefined
): Promise<UserOverlayInformationsEventQuery> {
    return executeQuery<UserOverlayInformationsEventQuery>(Queries.UserOverlayInformationsEvent, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CustomFieldWithConditionFragment,
            Fragments.FormUserInfoUserProfileFragment,
            Fragments.FormUserOverlayFragment,
            Fragments.DelegationUserProfileFragment
        ]
    });
}

export function useUserOverlayInformationsOrganizationQuery(
    args: UserOverlayInformationsOrganizationQueryVariables
): IUseQueryReturns<UserOverlayInformationsOrganizationQuery> {
    return useQuery(Queries.UserOverlayInformationsOrganization, args, [
        Fragments.CustomFieldWithConditionFragment,
        Fragments.FormUserOverlayFragment,
        Fragments.DelegationUserProfileFragment,
        Fragments.FormUserInfoUserProfileFragment
    ]);
}

export function executeUserOverlayInformationsOrganizationQuery(
    args: UserOverlayInformationsOrganizationQueryVariables,
    token: string | null | undefined
): Promise<UserOverlayInformationsOrganizationQuery> {
    return executeQuery<UserOverlayInformationsOrganizationQuery>(
        Queries.UserOverlayInformationsOrganization,
        {
            variables: args,
            token: token || undefined,
            fragments: [
                Fragments.CustomFieldWithConditionFragment,
                Fragments.FormUserOverlayFragment,
                Fragments.DelegationUserProfileFragment,
                Fragments.FormUserInfoUserProfileFragment
            ]
        }
    );
}

export function useUserOverlayIntegrationsQuery(
    args: UserOverlayIntegrationsQueryVariables
): IUseQueryReturns<UserOverlayIntegrationsQuery> {
    return useQuery(Queries.UserOverlayIntegrations, args, []);
}

export function executeUserOverlayIntegrationsQuery(
    args: UserOverlayIntegrationsQueryVariables,
    token: string | null | undefined
): Promise<UserOverlayIntegrationsQuery> {
    return executeQuery<UserOverlayIntegrationsQuery>(Queries.UserOverlayIntegrations, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUserOverlayAvailablePositionsSlotsQuery(
    args: UserOverlayAvailablePositionsSlotsQueryVariables
): IUseQueryReturns<UserOverlayAvailablePositionsSlotsQuery> {
    return useQuery(Queries.UserOverlayAvailablePositionsSlots, args, []);
}

export function executeUserOverlayAvailablePositionsSlotsQuery(
    args: UserOverlayAvailablePositionsSlotsQueryVariables,
    token: string | null | undefined
): Promise<UserOverlayAvailablePositionsSlotsQuery> {
    return executeQuery<UserOverlayAvailablePositionsSlotsQuery>(
        Queries.UserOverlayAvailablePositionsSlots,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useUserOverlayPositionsQuery(
    args: UserOverlayPositionsQueryVariables
): IUseQueryReturns<UserOverlayPositionsQuery> {
    return useQuery(Queries.UserOverlayPositions, args, [
        Fragments.FormsUsersInfosWishedFragment,
        Fragments.UserPanelPositionSlotUserInfoFragment
    ]);
}

export function executeUserOverlayPositionsQuery(
    args: UserOverlayPositionsQueryVariables,
    token: string | null | undefined
): Promise<UserOverlayPositionsQuery> {
    return executeQuery<UserOverlayPositionsQuery>(Queries.UserOverlayPositions, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.FormsUsersInfosWishedFragment,
            Fragments.UserPanelPositionSlotUserInfoFragment
        ]
    });
}

export function useVolunteerRegistrationSlotAssignMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteerRegistrationSlotAssignMutation,
    VolunteerRegistrationSlotAssignMutationVariables
> {
    return useMutation(Mutations.VolunteerRegistrationSlotAssign, args);
}

export function useUserOverlaySlotsQuery(
    args: UserOverlaySlotsQueryVariables
): IUseQueryReturns<UserOverlaySlotsQuery> {
    return useQuery(Queries.UserOverlaySlots, args, [Fragments.FormsUsersInfosWishedFragment]);
}

export function executeUserOverlaySlotsQuery(
    args: UserOverlaySlotsQueryVariables,
    token: string | null | undefined
): Promise<UserOverlaySlotsQuery> {
    return executeQuery<UserOverlaySlotsQuery>(Queries.UserOverlaySlots, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.FormsUsersInfosWishedFragment]
    });
}

export function useUserOverlayEventQuery(
    args: UserOverlayEventQueryVariables
): IUseQueryReturns<UserOverlayEventQuery> {
    return useQuery(Queries.UserOverlayEvent, args, [Fragments.UserInfoOverlayFragment]);
}

export function executeUserOverlayEventQuery(
    args: UserOverlayEventQueryVariables,
    token: string | null | undefined
): Promise<UserOverlayEventQuery> {
    return executeQuery<UserOverlayEventQuery>(Queries.UserOverlayEvent, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.UserInfoOverlayFragment]
    });
}

export function useUserOverlayOrganizationQuery(
    args: UserOverlayOrganizationQueryVariables
): IUseQueryReturns<UserOverlayOrganizationQuery> {
    return useQuery(Queries.UserOverlayOrganization, args, [Fragments.UserInfoOverlayFragment]);
}

export function executeUserOverlayOrganizationQuery(
    args: UserOverlayOrganizationQueryVariables,
    token: string | null | undefined
): Promise<UserOverlayOrganizationQuery> {
    return executeQuery<UserOverlayOrganizationQuery>(Queries.UserOverlayOrganization, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.UserInfoOverlayFragment]
    });
}

export function useUserProfileAccreditationsQuery(
    args: UserProfileAccreditationsQueryVariables
): IUseQueryReturns<UserProfileAccreditationsQuery> {
    return useQuery(Queries.UserProfileAccreditations, args, []);
}

export function executeUserProfileAccreditationsQuery(
    args: UserProfileAccreditationsQueryVariables,
    token: string | null | undefined
): Promise<UserProfileAccreditationsQuery> {
    return executeQuery<UserProfileAccreditationsQuery>(Queries.UserProfileAccreditations, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useEventUserProfileActionsQuery(
    args: EventUserProfileActionsQueryVariables
): IUseQueryReturns<EventUserProfileActionsQuery> {
    return useQuery(Queries.EventUserProfileActions, args, []);
}

export function executeEventUserProfileActionsQuery(
    args: EventUserProfileActionsQueryVariables,
    token: string | null | undefined
): Promise<EventUserProfileActionsQuery> {
    return executeQuery<EventUserProfileActionsQuery>(Queries.EventUserProfileActions, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useOrganizationUserProfileActionsQuery(
    args: OrganizationUserProfileActionsQueryVariables
): IUseQueryReturns<OrganizationUserProfileActionsQuery> {
    return useQuery(Queries.OrganizationUserProfileActions, args, []);
}

export function executeOrganizationUserProfileActionsQuery(
    args: OrganizationUserProfileActionsQueryVariables,
    token: string | null | undefined
): Promise<OrganizationUserProfileActionsQuery> {
    return executeQuery<OrganizationUserProfileActionsQuery>(
        Queries.OrganizationUserProfileActions,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useEventUserProfileTabsQuery(
    args: EventUserProfileTabsQueryVariables
): IUseQueryReturns<EventUserProfileTabsQuery> {
    return useQuery(Queries.EventUserProfileTabs, args, []);
}

export function executeEventUserProfileTabsQuery(
    args: EventUserProfileTabsQueryVariables,
    token: string | null | undefined
): Promise<EventUserProfileTabsQuery> {
    return executeQuery<EventUserProfileTabsQuery>(Queries.EventUserProfileTabs, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUserProfileHistoryCampaignsQuery(
    args: UserProfileHistoryCampaignsQueryVariables
): IUseQueryReturns<UserProfileHistoryCampaignsQuery> {
    return useQuery(Queries.UserProfileHistoryCampaigns, args, []);
}

export function executeUserProfileHistoryCampaignsQuery(
    args: UserProfileHistoryCampaignsQueryVariables,
    token: string | null | undefined
): Promise<UserProfileHistoryCampaignsQuery> {
    return executeQuery<UserProfileHistoryCampaignsQuery>(Queries.UserProfileHistoryCampaigns, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUserProfileHistoryEventsQuery(
    args: UserProfileHistoryEventsQueryVariables
): IUseQueryReturns<UserProfileHistoryEventsQuery> {
    return useQuery(Queries.UserProfileHistoryEvents, args, []);
}

export function executeUserProfileHistoryEventsQuery(
    args: UserProfileHistoryEventsQueryVariables,
    token: string | null | undefined
): Promise<UserProfileHistoryEventsQuery> {
    return executeQuery<UserProfileHistoryEventsQuery>(Queries.UserProfileHistoryEvents, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUserProfileInformationsEventQuery(
    args: UserProfileInformationsEventQueryVariables
): IUseQueryReturns<UserProfileInformationsEventQuery> {
    return useQuery(Queries.UserProfileInformationsEvent, args, [
        Fragments.UserInfoOverlayFragment,
        Fragments.FormUserOverlayFragment
    ]);
}

export function executeUserProfileInformationsEventQuery(
    args: UserProfileInformationsEventQueryVariables,
    token: string | null | undefined
): Promise<UserProfileInformationsEventQuery> {
    return executeQuery<UserProfileInformationsEventQuery>(Queries.UserProfileInformationsEvent, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.UserInfoOverlayFragment, Fragments.FormUserOverlayFragment]
    });
}

export function useUserProfileInformationsOrganizationQuery(
    args: UserProfileInformationsOrganizationQueryVariables
): IUseQueryReturns<UserProfileInformationsOrganizationQuery> {
    return useQuery(Queries.UserProfileInformationsOrganization, args, [
        Fragments.CustomFieldWithConditionFragment,
        Fragments.FormUserOverlayFragment,
        Fragments.UserInfoOverlayFragment
    ]);
}

export function executeUserProfileInformationsOrganizationQuery(
    args: UserProfileInformationsOrganizationQueryVariables,
    token: string | null | undefined
): Promise<UserProfileInformationsOrganizationQuery> {
    return executeQuery<UserProfileInformationsOrganizationQuery>(
        Queries.UserProfileInformationsOrganization,
        {
            variables: args,
            token: token || undefined,
            fragments: [
                Fragments.CustomFieldWithConditionFragment,
                Fragments.FormUserOverlayFragment,
                Fragments.UserInfoOverlayFragment
            ]
        }
    );
}

export function useOrganizationUserProfileTabsQuery(
    args: OrganizationUserProfileTabsQueryVariables
): IUseQueryReturns<OrganizationUserProfileTabsQuery> {
    return useQuery(Queries.OrganizationUserProfileTabs, args, []);
}

export function executeOrganizationUserProfileTabsQuery(
    args: OrganizationUserProfileTabsQueryVariables,
    token: string | null | undefined
): Promise<OrganizationUserProfileTabsQuery> {
    return executeQuery<OrganizationUserProfileTabsQuery>(Queries.OrganizationUserProfileTabs, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useEventProfilePrivateFieldsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    EventProfilePrivateFieldsUpdateMutation,
    EventProfilePrivateFieldsUpdateMutationVariables
> {
    return useMutation(Mutations.EventProfilePrivateFieldsUpdate, args);
}

export function useEventUserProfilePrivateFieldsQuery(
    args: EventUserProfilePrivateFieldsQueryVariables
): IUseQueryReturns<EventUserProfilePrivateFieldsQuery> {
    return useQuery(Queries.EventUserProfilePrivateFields, args, [
        Fragments.CustomFieldWithConditionFragment,
        Fragments.UserInfoOverlayFragment,
        Fragments.UserInfoTeamMemberFragment,
        Fragments.DelegationUserProfileFragment
    ]);
}

export function executeEventUserProfilePrivateFieldsQuery(
    args: EventUserProfilePrivateFieldsQueryVariables,
    token: string | null | undefined
): Promise<EventUserProfilePrivateFieldsQuery> {
    return executeQuery<EventUserProfilePrivateFieldsQuery>(Queries.EventUserProfilePrivateFields, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CustomFieldWithConditionFragment,
            Fragments.UserInfoOverlayFragment,
            Fragments.UserInfoTeamMemberFragment,
            Fragments.DelegationUserProfileFragment
        ]
    });
}

export function useOrganizationProfilePrivateFieldsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    OrganizationProfilePrivateFieldsUpdateMutation,
    OrganizationProfilePrivateFieldsUpdateMutationVariables
> {
    return useMutation(Mutations.OrganizationProfilePrivateFieldsUpdate, args);
}

export function useOrganizationUserProfilePrivateFieldsQuery(
    args: OrganizationUserProfilePrivateFieldsQueryVariables
): IUseQueryReturns<OrganizationUserProfilePrivateFieldsQuery> {
    return useQuery(Queries.OrganizationUserProfilePrivateFields, args, [
        Fragments.CustomFieldWithConditionFragment,
        Fragments.UserInfoOverlayFragment,
        Fragments.DelegationUserProfileFragment
    ]);
}

export function executeOrganizationUserProfilePrivateFieldsQuery(
    args: OrganizationUserProfilePrivateFieldsQueryVariables,
    token: string | null | undefined
): Promise<OrganizationUserProfilePrivateFieldsQuery> {
    return executeQuery<OrganizationUserProfilePrivateFieldsQuery>(
        Queries.OrganizationUserProfilePrivateFields,
        {
            variables: args,
            token: token || undefined,
            fragments: [
                Fragments.CustomFieldWithConditionFragment,
                Fragments.UserInfoOverlayFragment,
                Fragments.DelegationUserProfileFragment
            ]
        }
    );
}

export function useEmailResendMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EmailResendMutation, EmailResendMutationVariables> {
    return useMutation(Mutations.EmailResend, args);
}

export function useVolunteerRegistrationAccreditationStateUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteerRegistrationAccreditationStateUpdateMutation,
    VolunteerRegistrationAccreditationStateUpdateMutationVariables
> {
    return useMutation(Mutations.VolunteerRegistrationAccreditationStateUpdate, args);
}

export function useVolunteersRegistrationsMassWeezeventSynchronizeMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassWeezeventSynchronizeMutation,
    VolunteersRegistrationsMassWeezeventSynchronizeMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassWeezeventSynchronize, args);
}

export function useCommentCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<CommentCreateMutation, CommentCreateMutationVariables> {
    return useMutation(Mutations.CommentCreate, args);
}

export function useCommentDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<CommentDeleteMutation, CommentDeleteMutationVariables> {
    return useMutation(Mutations.CommentDelete, args);
}

export function useCommentsQuery(args: CommentsQueryVariables): IUseQueryReturns<CommentsQuery> {
    return useQuery(Queries.Comments, args, []);
}

export function executeCommentsQuery(
    args: CommentsQueryVariables,
    token: string | null | undefined
): Promise<CommentsQuery> {
    return executeQuery<CommentsQuery>(Queries.Comments, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function usePositionSlotUserInfoCheckInMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    PositionSlotUserInfoCheckInMutation,
    PositionSlotUserInfoCheckInMutationVariables
> {
    return useMutation(Mutations.PositionSlotUserInfoCheckIn, args);
}

export function usePositionSlotUserInfoCheckInResetMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    PositionSlotUserInfoCheckInResetMutation,
    PositionSlotUserInfoCheckInResetMutationVariables
> {
    return useMutation(Mutations.PositionSlotUserInfoCheckInReset, args);
}

export function usePositionSlotUserInfoCheckOutMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    PositionSlotUserInfoCheckOutMutation,
    PositionSlotUserInfoCheckOutMutationVariables
> {
    return useMutation(Mutations.PositionSlotUserInfoCheckOut, args);
}

export function useUserPanelQuery(args: UserPanelQueryVariables): IUseQueryReturns<UserPanelQuery> {
    return useQuery(Queries.UserPanel, args, [
        Fragments.CustomFieldWithConditionFragment,
        Fragments.UserInfoOverlayFragment,
        Fragments.UserPanelAccreditationSlotUserInfoFragment,
        Fragments.UserPanelPositionSlotUserInfoFragment
    ]);
}

export function executeUserPanelQuery(
    args: UserPanelQueryVariables,
    token: string | null | undefined
): Promise<UserPanelQuery> {
    return executeQuery<UserPanelQuery>(Queries.UserPanel, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CustomFieldWithConditionFragment,
            Fragments.UserInfoOverlayFragment,
            Fragments.UserPanelAccreditationSlotUserInfoFragment,
            Fragments.UserPanelPositionSlotUserInfoFragment
        ]
    });
}

export function useUpdateUserInfosEventQuery(
    args: UpdateUserInfosEventQueryVariables
): IUseQueryReturns<UpdateUserInfosEventQuery> {
    return useQuery(Queries.UpdateUserInfosEvent, args, [
        Fragments.CustomFieldWithConditionFragment,
        Fragments.UpdateUserFormFragment
    ]);
}

export function executeUpdateUserInfosEventQuery(
    args: UpdateUserInfosEventQueryVariables,
    token: string | null | undefined
): Promise<UpdateUserInfosEventQuery> {
    return executeQuery<UpdateUserInfosEventQuery>(Queries.UpdateUserInfosEvent, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CustomFieldWithConditionFragment, Fragments.UpdateUserFormFragment]
    });
}

export function useUpdateUserInfosOrganizationQuery(
    args: UpdateUserInfosOrganizationQueryVariables
): IUseQueryReturns<UpdateUserInfosOrganizationQuery> {
    return useQuery(Queries.UpdateUserInfosOrganization, args, [
        Fragments.CustomFieldWithConditionFragment,
        Fragments.UpdateUserFormFragment
    ]);
}

export function executeUpdateUserInfosOrganizationQuery(
    args: UpdateUserInfosOrganizationQueryVariables,
    token: string | null | undefined
): Promise<UpdateUserInfosOrganizationQuery> {
    return executeQuery<UpdateUserInfosOrganizationQuery>(Queries.UpdateUserInfosOrganization, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.CustomFieldWithConditionFragment, Fragments.UpdateUserFormFragment]
    });
}

export function useOrganizationUsersInfosQuery(
    args: OrganizationUsersInfosQueryVariables
): IUseQueryReturns<OrganizationUsersInfosQuery> {
    return useQuery(Queries.OrganizationUsersInfos, args, [Fragments.ExistingVolunteerFragment]);
}

export function executeOrganizationUsersInfosQuery(
    args: OrganizationUsersInfosQueryVariables,
    token: string | null | undefined
): Promise<OrganizationUsersInfosQuery> {
    return executeQuery<OrganizationUsersInfosQuery>(Queries.OrganizationUsersInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.ExistingVolunteerFragment]
    });
}

export function useUserInfoAdminCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserInfoAdminCreateMutation, UserInfoAdminCreateMutationVariables> {
    return useMutation(Mutations.UserInfoAdminCreate, args);
}

export function useVolunteersRegistrationsAdminAddMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsAdminAddMutation,
    VolunteersRegistrationsAdminAddMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsAdminAdd, args);
}

export function useUsersInfosExportMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UsersInfosExportMutation, UsersInfosExportMutationVariables> {
    return useMutation(Mutations.UsersInfosExport, args);
}

export function useVolunteersExportMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<VolunteersExportMutation, VolunteersExportMutationVariables> {
    return useMutation(Mutations.VolunteersExport, args);
}

export function useOrganizationCustomFieldsFrontQuery(
    args: OrganizationCustomFieldsFrontQueryVariables
): IUseQueryReturns<OrganizationCustomFieldsFrontQuery> {
    return useQuery(Queries.OrganizationCustomFieldsFront, args, []);
}

export function executeOrganizationCustomFieldsFrontQuery(
    args: OrganizationCustomFieldsFrontQueryVariables,
    token: string | null | undefined
): Promise<OrganizationCustomFieldsFrontQuery> {
    return executeQuery<OrganizationCustomFieldsFrontQuery>(Queries.OrganizationCustomFieldsFront, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useVolunteersImportMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<VolunteersImportMutation, VolunteersImportMutationVariables> {
    return useMutation(Mutations.VolunteersImport, args);
}

export function useVolunteersCalendarQuery(
    args: VolunteersCalendarQueryVariables
): IUseQueryReturns<VolunteersCalendarQuery> {
    return useQuery(Queries.VolunteersCalendar, args, [Fragments.DocumentUsersPlanningFragment]);
}

export function executeVolunteersCalendarQuery(
    args: VolunteersCalendarQueryVariables,
    token: string | null | undefined
): Promise<VolunteersCalendarQuery> {
    return executeQuery<VolunteersCalendarQuery>(Queries.VolunteersCalendar, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DocumentUsersPlanningFragment]
    });
}

export function useUpdateCellAccreditationsSlotsQuery(
    args: UpdateCellAccreditationsSlotsQueryVariables
): IUseQueryReturns<UpdateCellAccreditationsSlotsQuery> {
    return useQuery(Queries.UpdateCellAccreditationsSlots, args, []);
}

export function executeUpdateCellAccreditationsSlotsQuery(
    args: UpdateCellAccreditationsSlotsQueryVariables,
    token: string | null | undefined
): Promise<UpdateCellAccreditationsSlotsQuery> {
    return executeQuery<UpdateCellAccreditationsSlotsQuery>(Queries.UpdateCellAccreditationsSlots, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUpdateCellDelegationsQuery(
    args: UpdateCellDelegationsQueryVariables
): IUseQueryReturns<UpdateCellDelegationsQuery> {
    return useQuery(Queries.UpdateCellDelegations, args, []);
}

export function executeUpdateCellDelegationsQuery(
    args: UpdateCellDelegationsQueryVariables,
    token: string | null | undefined
): Promise<UpdateCellDelegationsQuery> {
    return executeQuery<UpdateCellDelegationsQuery>(Queries.UpdateCellDelegations, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUpdateCellPositionsSlotsQuery(
    args: UpdateCellPositionsSlotsQueryVariables
): IUseQueryReturns<UpdateCellPositionsSlotsQuery> {
    return useQuery(Queries.UpdateCellPositionsSlots, args, []);
}

export function executeUpdateCellPositionsSlotsQuery(
    args: UpdateCellPositionsSlotsQueryVariables,
    token: string | null | undefined
): Promise<UpdateCellPositionsSlotsQuery> {
    return executeQuery<UpdateCellPositionsSlotsQuery>(Queries.UpdateCellPositionsSlots, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUpdateStateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UpdateStateMutation, UpdateStateMutationVariables> {
    return useMutation(Mutations.UpdateState, args);
}

export function useUserInfoDelegationUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    UserInfoDelegationUpdateMutation,
    UserInfoDelegationUpdateMutationVariables
> {
    return useMutation(Mutations.UserInfoDelegationUpdate, args);
}

export function useVolunteerQuery(args: VolunteerQueryVariables): IUseQueryReturns<VolunteerQuery> {
    return useQuery(Queries.Volunteer, args, [Fragments.VolunteerRegistrationFragment]);
}

export function executeVolunteerQuery(
    args: VolunteerQueryVariables,
    token: string | null | undefined
): Promise<VolunteerQuery> {
    return executeQuery<VolunteerQuery>(Queries.Volunteer, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.VolunteerRegistrationFragment]
    });
}

export function useVolunteerRegistrationUpdateFieldMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteerRegistrationUpdateFieldMutation,
    VolunteerRegistrationUpdateFieldMutationVariables
> {
    return useMutation(Mutations.VolunteerRegistrationUpdateField, args);
}

export function useVolunteersQuery(
    args: VolunteersQueryVariables
): IUseQueryReturns<VolunteersQuery> {
    return useQuery(Queries.Volunteers, args, [Fragments.VolunteerRegistrationFragment]);
}

export function executeVolunteersQuery(
    args: VolunteersQueryVariables,
    token: string | null | undefined
): Promise<VolunteersQuery> {
    return executeQuery<VolunteersQuery>(Queries.Volunteers, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.VolunteerRegistrationFragment]
    });
}

export function useVolunteerActionsQuery(
    args: VolunteerActionsQueryVariables
): IUseQueryReturns<VolunteerActionsQuery> {
    return useQuery(Queries.VolunteerActions, args, []);
}

export function executeVolunteerActionsQuery(
    args: VolunteerActionsQueryVariables,
    token: string | null | undefined
): Promise<VolunteerActionsQuery> {
    return executeQuery<VolunteerActionsQuery>(Queries.VolunteerActions, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useVolunteersHeaderQuery(
    args: VolunteersHeaderQueryVariables
): IUseQueryReturns<VolunteersHeaderQuery> {
    return useQuery(Queries.VolunteersHeader, args, []);
}

export function executeVolunteersHeaderQuery(
    args: VolunteersHeaderQueryVariables,
    token: string | null | undefined
): Promise<VolunteersHeaderQuery> {
    return executeQuery<VolunteersHeaderQuery>(Queries.VolunteersHeader, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useVolunteersRegistrationsMassDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassDeleteMutation,
    VolunteersRegistrationsMassDeleteMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassDelete, args);
}

export function useVolunteersSegmentsQuery(
    args: VolunteersSegmentsQueryVariables
): IUseQueryReturns<VolunteersSegmentsQuery> {
    return useQuery(Queries.VolunteersSegments, args, [
        Fragments.SegmentCustomFieldFragment,
        Fragments.SegmentsFoldersSegmentsFragment
    ]);
}

export function executeVolunteersSegmentsQuery(
    args: VolunteersSegmentsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsQuery> {
    return executeQuery<VolunteersSegmentsQuery>(Queries.VolunteersSegments, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentCustomFieldFragment, Fragments.SegmentsFoldersSegmentsFragment]
    });
}

export function useMassAccreditAccreditationsQuery(
    args: MassAccreditAccreditationsQueryVariables
): IUseQueryReturns<MassAccreditAccreditationsQuery> {
    return useQuery(Queries.MassAccreditAccreditations, args, []);
}

export function executeMassAccreditAccreditationsQuery(
    args: MassAccreditAccreditationsQueryVariables,
    token: string | null | undefined
): Promise<MassAccreditAccreditationsQuery> {
    return executeQuery<MassAccreditAccreditationsQuery>(Queries.MassAccreditAccreditations, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useVolunteersRegistrationsMassAccreditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassAccreditMutation,
    VolunteersRegistrationsMassAccreditMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassAccredit, args);
}

export function useVolunteersRegistrationsMassAccreditationStateUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassAccreditationStateUpdateMutation,
    VolunteersRegistrationsMassAccreditationStateUpdateMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassAccreditationStateUpdate, args);
}

export function useMassAssignSlotsQuery(
    args: MassAssignSlotsQueryVariables
): IUseQueryReturns<MassAssignSlotsQuery> {
    return useQuery(Queries.MassAssignSlots, args, []);
}

export function executeMassAssignSlotsQuery(
    args: MassAssignSlotsQueryVariables,
    token: string | null | undefined
): Promise<MassAssignSlotsQuery> {
    return executeQuery<MassAssignSlotsQuery>(Queries.MassAssignSlots, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useVolunteersRegistrationsMassAssignMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassAssignMutation,
    VolunteersRegistrationsMassAssignMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassAssign, args);
}

export function useVolunteersRegistrationsMassPreAssignMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassPreAssignMutation,
    VolunteersRegistrationsMassPreAssignMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassPreAssign, args);
}

export function useMassCampaignSendEventCampaignsQuery(
    args: MassCampaignSendEventCampaignsQueryVariables
): IUseQueryReturns<MassCampaignSendEventCampaignsQuery> {
    return useQuery(Queries.MassCampaignSendEventCampaigns, args, []);
}

export function executeMassCampaignSendEventCampaignsQuery(
    args: MassCampaignSendEventCampaignsQueryVariables,
    token: string | null | undefined
): Promise<MassCampaignSendEventCampaignsQuery> {
    return executeQuery<MassCampaignSendEventCampaignsQuery>(
        Queries.MassCampaignSendEventCampaigns,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useMassCampaignSendOrganizationCampaignsQuery(
    args: MassCampaignSendOrganizationCampaignsQueryVariables
): IUseQueryReturns<MassCampaignSendOrganizationCampaignsQuery> {
    return useQuery(Queries.MassCampaignSendOrganizationCampaigns, args, []);
}

export function executeMassCampaignSendOrganizationCampaignsQuery(
    args: MassCampaignSendOrganizationCampaignsQueryVariables,
    token: string | null | undefined
): Promise<MassCampaignSendOrganizationCampaignsQuery> {
    return executeQuery<MassCampaignSendOrganizationCampaignsQuery>(
        Queries.MassCampaignSendOrganizationCampaigns,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useUsersInfosMassCampaignSendMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    UsersInfosMassCampaignSendMutation,
    UsersInfosMassCampaignSendMutationVariables
> {
    return useMutation(Mutations.UsersInfosMassCampaignSend, args);
}

export function useVolunteersRegistrationsMassCampaignSendMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassCampaignSendMutation,
    VolunteersRegistrationsMassCampaignSendMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassCampaignSend, args);
}

export function useMassDelegationAddOrganizationDelegationsQuery(
    args: MassDelegationAddOrganizationDelegationsQueryVariables
): IUseQueryReturns<MassDelegationAddOrganizationDelegationsQuery> {
    return useQuery(Queries.MassDelegationAddOrganizationDelegations, args, []);
}

export function executeMassDelegationAddOrganizationDelegationsQuery(
    args: MassDelegationAddOrganizationDelegationsQueryVariables,
    token: string | null | undefined
): Promise<MassDelegationAddOrganizationDelegationsQuery> {
    return executeQuery<MassDelegationAddOrganizationDelegationsQuery>(
        Queries.MassDelegationAddOrganizationDelegations,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useUsersInfosMassDelegationAddMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    UsersInfosMassDelegationAddMutation,
    UsersInfosMassDelegationAddMutationVariables
> {
    return useMutation(Mutations.UsersInfosMassDelegationAdd, args);
}

export function useVolunteersRegistrationsMassDelegationAddMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassDelegationAddMutation,
    VolunteersRegistrationsMassDelegationAddMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassDelegationAdd, args);
}

export function useMassEditFieldsQuery(
    args: MassEditFieldsQueryVariables
): IUseQueryReturns<MassEditFieldsQuery> {
    return useQuery(Queries.MassEditFields, args, [Fragments.MassEditCustomFieldFragment]);
}

export function executeMassEditFieldsQuery(
    args: MassEditFieldsQueryVariables,
    token: string | null | undefined
): Promise<MassEditFieldsQuery> {
    return executeQuery<MassEditFieldsQuery>(Queries.MassEditFields, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.MassEditCustomFieldFragment]
    });
}

export function useOrganizationUsersInfosMassEditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    OrganizationUsersInfosMassEditMutation,
    OrganizationUsersInfosMassEditMutationVariables
> {
    return useMutation(Mutations.OrganizationUsersInfosMassEdit, args);
}

export function useUsersInfosMassEditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UsersInfosMassEditMutation, UsersInfosMassEditMutationVariables> {
    return useMutation(Mutations.UsersInfosMassEdit, args);
}

export function useMassFormAddEventFormsQuery(
    args: MassFormAddEventFormsQueryVariables
): IUseQueryReturns<MassFormAddEventFormsQuery> {
    return useQuery(Queries.MassFormAddEventForms, args, []);
}

export function executeMassFormAddEventFormsQuery(
    args: MassFormAddEventFormsQueryVariables,
    token: string | null | undefined
): Promise<MassFormAddEventFormsQuery> {
    return executeQuery<MassFormAddEventFormsQuery>(Queries.MassFormAddEventForms, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useMassFormAddOrganizationFormsQuery(
    args: MassFormAddOrganizationFormsQueryVariables
): IUseQueryReturns<MassFormAddOrganizationFormsQuery> {
    return useQuery(Queries.MassFormAddOrganizationForms, args, []);
}

export function executeMassFormAddOrganizationFormsQuery(
    args: MassFormAddOrganizationFormsQueryVariables,
    token: string | null | undefined
): Promise<MassFormAddOrganizationFormsQuery> {
    return executeQuery<MassFormAddOrganizationFormsQuery>(Queries.MassFormAddOrganizationForms, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUsersInfosMassFormAddMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UsersInfosMassFormAddMutation, UsersInfosMassFormAddMutationVariables> {
    return useMutation(Mutations.UsersInfosMassFormAdd, args);
}

export function useVolunteersRegistrationsMassFormAddMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassFormAddMutation,
    VolunteersRegistrationsMassFormAddMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassFormAdd, args);
}

export function useUsersInfosMassFormRemoveMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    UsersInfosMassFormRemoveMutation,
    UsersInfosMassFormRemoveMutationVariables
> {
    return useMutation(Mutations.UsersInfosMassFormRemove, args);
}

export function useVolunteersRegistrationsMassFormRemoveMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassFormRemoveMutation,
    VolunteersRegistrationsMassFormRemoveMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassFormRemove, args);
}

export function useMassPreAssignCategoriesQuery(
    args: MassPreAssignCategoriesQueryVariables
): IUseQueryReturns<MassPreAssignCategoriesQuery> {
    return useQuery(Queries.MassPreAssignCategories, args, []);
}

export function executeMassPreAssignCategoriesQuery(
    args: MassPreAssignCategoriesQueryVariables,
    token: string | null | undefined
): Promise<MassPreAssignCategoriesQuery> {
    return executeQuery<MassPreAssignCategoriesQuery>(Queries.MassPreAssignCategories, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useMassPreAssignPositionsQuery(
    args: MassPreAssignPositionsQueryVariables
): IUseQueryReturns<MassPreAssignPositionsQuery> {
    return useQuery(Queries.MassPreAssignPositions, args, []);
}

export function executeMassPreAssignPositionsQuery(
    args: MassPreAssignPositionsQueryVariables,
    token: string | null | undefined
): Promise<MassPreAssignPositionsQuery> {
    return executeQuery<MassPreAssignPositionsQuery>(Queries.MassPreAssignPositions, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useVolunteersRegistrationsMassStateUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassStateUpdateMutation,
    VolunteersRegistrationsMassStateUpdateMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassStateUpdate, args);
}

export function useVolunteersMobileQuery(
    args: VolunteersMobileQueryVariables
): IUseQueryReturns<VolunteersMobileQuery> {
    return useQuery(Queries.VolunteersMobile, args, [Fragments.FullPageInfoFragment]);
}

export function executeVolunteersMobileQuery(
    args: VolunteersMobileQueryVariables,
    token: string | null | undefined
): Promise<VolunteersMobileQuery> {
    return executeQuery<VolunteersMobileQuery>(Queries.VolunteersMobile, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.FullPageInfoFragment]
    });
}

export function useVolunteersSegmentsMobileQuery(
    args: VolunteersSegmentsMobileQueryVariables
): IUseQueryReturns<VolunteersSegmentsMobileQuery> {
    return useQuery(Queries.VolunteersSegmentsMobile, args, []);
}

export function executeVolunteersSegmentsMobileQuery(
    args: VolunteersSegmentsMobileQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsMobileQuery> {
    return executeQuery<VolunteersSegmentsMobileQuery>(Queries.VolunteersSegmentsMobile, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useRecentVolunteersSearchesMobileQuery(
    args: RecentVolunteersSearchesMobileQueryVariables
): IUseQueryReturns<RecentVolunteersSearchesMobileQuery> {
    return useQuery(Queries.RecentVolunteersSearchesMobile, args, []);
}

export function executeRecentVolunteersSearchesMobileQuery(
    args: RecentVolunteersSearchesMobileQueryVariables,
    token: string | null | undefined
): Promise<RecentVolunteersSearchesMobileQuery> {
    return executeQuery<RecentVolunteersSearchesMobileQuery>(
        Queries.RecentVolunteersSearchesMobile,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteerInfoQuery(
    args: VolunteerInfoQueryVariables
): IUseQueryReturns<VolunteerInfoQuery> {
    return useQuery(Queries.VolunteerInfo, args, [
        Fragments.CustomFieldWithConditionFragment,
        Fragments.EventRegisterSlotsPositionsFragment,
        Fragments.EditVolunteerRegistrationSlotFragment
    ]);
}

export function executeVolunteerInfoQuery(
    args: VolunteerInfoQueryVariables,
    token: string | null | undefined
): Promise<VolunteerInfoQuery> {
    return executeQuery<VolunteerInfoQuery>(Queries.VolunteerInfo, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.CustomFieldWithConditionFragment,
            Fragments.EventRegisterSlotsPositionsFragment,
            Fragments.EditVolunteerRegistrationSlotFragment
        ]
    });
}
