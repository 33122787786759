import { injectable } from 'inversify';
import { CommonEnvVars } from '../envVars';
import {
    CustomFieldWithConditionFragment,
    Event as EventVo,
    Organization as OrganizationVo,
    UsersInfo as UsersInfoVo,
    VolunteerRegistrationEmailRendererFragment
} from '../generated/types';
import { getDocumentFullPath } from '../util/documentsPaths';
import { Emptyable } from '../util/emptyable';
import { flattenObject } from '../util/object';
import { FieldService } from '../vo/field';
import { getName } from '../vo/userInfo';

type Organization = Pick<OrganizationVo, 'id' | 'name' | 'exportDatetimeFormat'>;
type Event = Pick<EventVo, 'id' | 'organizationId' | 'name' | 'startAt' | 'endAt'>;
type UsersInfo = Pick<UsersInfoVo, 'id' | 'email' | 'fields' | 'links' | 'registrationDate'>;

/* eslint-disable-next-line no-useless-escape */
const VARIABLE_REGEX = /(\{([\w\.]+)\})/g;

@injectable()
export class TemplateService {
    constructor(private fieldService: FieldService) {}

    renderText(
        text: string,
        userInfo: UsersInfo,
        organization: Emptyable<Organization>,
        event: Emptyable<Event>,
        customsFields: CustomFieldWithConditionFragment[],
        volunteerRegistration: Emptyable<VolunteerRegistrationEmailRendererFragment>,
        extraInfos: any = {}
    ): string {
        const fields = Object.fromEntries(
            customsFields.map((customField) => [
                customField.slug,
                this.fieldService.getValueString(customField, userInfo)
            ])
        );
        const userAssignmentsPath = userInfo.links
            ? getDocumentFullPath(userInfo.links.userAssignmentsLink, 'pdf')
            : '';
        const userPlanningPath = userInfo.links
            ? getDocumentFullPath(userInfo.links.userPlanningLink, 'pdf')
            : '';
        const userPlanningListPath = userInfo.links
            ? getDocumentFullPath(userInfo.links.userPlanningListLink, 'pdf')
            : '';
        const userPlanningDaysPath = userInfo.links
            ? getDocumentFullPath(userInfo.links.userPlanningDaysLink, 'pdf')
            : '';
        const documents = userInfo.links
            ? {
                  assignments: userAssignmentsPath,
                  badge: getDocumentFullPath(userInfo.links.userBadgeLink, 'pdf'),
                  certificate: getDocumentFullPath(userInfo.links.userCertificateLink, 'pdf'),
                  planning: userPlanningPath,
                  planningList: userPlanningListPath,
                  planningDays: userPlanningDaysPath,
                  ...Object.fromEntries(
                      Object.entries(userInfo.links.userCustomBadgeLinks).map(
                          ([customBadge, link]) => [
                              customBadge.toLowerCase(),
                              getDocumentFullPath(link as string, 'pdf')
                          ]
                      )
                  )
              }
            : {};
        const variables = {
            organization: organization || undefined,
            event: event || undefined,
            user: {
                email: userInfo.email,
                ...fields,
                firstName: userInfo.fields.firstName || '',
                lastName: userInfo.fields.lastName || '',
                name: getName({
                    firstName: userInfo.fields.firstName,
                    lastName: userInfo.fields.lastName
                }),
                registrationDate:
                    userInfo.registrationDate?.toFormat(
                        organization?.exportDatetimeFormat ?? 'yyyy-MM-dd HH:mm'
                    ) ?? '',
                assignmentSheet: event ? userAssignmentsPath : '',
                documents,
                id: userInfo.id,
                delegation: {
                    name: volunteerRegistration?.delegation?.name ?? ''
                },
                weezevent: {
                    barcode: volunteerRegistration?.weezeventParticipantInfo?.idBarcode ?? '',
                    qrcode: `<div style="height: 100px; margin: auto; text-align: center; width: 100px;">
                        <img
                            src="https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                volunteerRegistration?.weezeventParticipantInfo?.idBarcode ?? ''
                            )}&color=000000&height=100&width=100"
                            height="100%"
                            width="100%"
                        />
                    </div>`
                }
            },
            ...extraInfos
        };

        const variablesFlattened = flattenObject(variables);

        return Object.entries(variablesFlattened)
            .reduce(
                (currentText: string, [key, value]: [string, any]) =>
                    currentText.replaceAll(`{${key}}`, value),
                text
            )
            .replace(VARIABLE_REGEX, (_match: string, _group1: string, group2: string) => {
                const [_u, _d, _n, date] = group2.split('.');
                const query: any = {};

                if (date?.length === 8) {
                    query.date = date;
                } else if (date?.length === 16) {
                    query.date = date.substring(0, 8);
                    query.endDate = date.substring(8);
                }

                if (group2.includes('planningDays') && userInfo.links) {
                    return getDocumentFullPath(userInfo.links.userPlanningDaysLink, 'pdf', query);
                } else if (group2.includes('planningList') && userInfo.links) {
                    return getDocumentFullPath(userInfo.links.userPlanningListLink, 'pdf', query);
                } else if (group2.includes('planning') && userInfo.links) {
                    return getDocumentFullPath(userInfo.links.userPlanningLink, 'pdf', query);
                } else if (group2.includes('assignments') && userInfo.links) {
                    return getDocumentFullPath(userInfo.links.userAssignmentsLink, 'pdf', query);
                } else {
                    return '';
                }
            });
    }
}
