import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    EventId,
    VolunteerRegistrationState,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { PossibleColumn } from 'common/src/vo/segment';
import { getStateBadgeColorIcon } from 'common/src/vo/volunteerRegistration';
import * as React from 'react';
import { useVolunteerRegistrationUpdateFieldMutation } from '../../../generated/graphqlHooks';

interface IDropdownCellProps {
    eventId: EventId;
    state: VolunteerRegistrationState;
    volunteerRegistrationId: VolunteersRegistrationId;

    close(): void;
    reload(): void;
}

const DropdownCell = (props: IDropdownCellProps) => {
    const translate = useTranslate();
    const { mutate } = useVolunteerRegistrationUpdateFieldMutation();

    return (
        <Flex
            css={{
                cursor: 'pointer',
                padding: '$3',
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onClick={async () => {
                await mutate({
                    eventId: props.eventId,
                    volunteerRegistrationId: props.volunteerRegistrationId,
                    slug: 'volunteer_registration_state',
                    value: props.state
                });

                props.reload();
                props.close();
            }}
        >
            <Badge cursor="pointer" {...getStateBadgeColorIcon(props.state)}>
                {translate(props.state)}
            </Badge>
        </Flex>
    );
};

interface IVolunteerRegistrationStateCellProps {
    eventId: EventId;
    field: PossibleColumn;
    state: VolunteerRegistrationState;
    volunteerRegistrationId: VolunteersRegistrationId;

    reload(): void;
}

export const VolunteerRegistrationStateCell = (props: IVolunteerRegistrationStateCellProps) => {
    const translate = useTranslate();
    const { isEditMode } = useSegmentsContext();
    const [isOpen, _setIsOpen] = React.useState(false);
    const setIsOpen = React.useCallback(
        (newIsOpen: boolean) => {
            if (isEditMode) {
                _setIsOpen(newIsOpen);
            }
        },
        [isEditMode, _setIsOpen]
    );
    const close = React.useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    if (isEditMode) {
        return (
            <Cell
                width={230}
                css={{
                    border: '2px solid transparent',
                    cursor: 'text',
                    '&:hover': {
                        border: '2px solid $primary700'
                    }
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }}
            >
                <Dropdown isOpen={isOpen} onStateChange={setIsOpen}>
                    <Trigger>
                        <Flex align="center" justify="between" gap="3" height={1} width={1}>
                            <Badge {...getStateBadgeColorIcon(props.state)} cursor="text">
                                <Box width={1} css={{ ellipsis: true }}>
                                    {translate(props.state)}
                                </Box>
                            </Badge>

                            {isOpen && (
                                <Box>
                                    <I icon="chevron-down" />
                                </Box>
                            )}
                        </Flex>
                    </Trigger>

                    <Menu placement="bottom" width={230}>
                        {props.state !== VolunteerRegistrationState.WaitingAssignment && (
                            <DropdownCell
                                eventId={props.eventId}
                                state={VolunteerRegistrationState.WaitingAssignment}
                                volunteerRegistrationId={props.volunteerRegistrationId}
                                close={close}
                                reload={props.reload}
                            />
                        )}

                        {props.state !== VolunteerRegistrationState.Refused && (
                            <DropdownCell
                                eventId={props.eventId}
                                state={VolunteerRegistrationState.Refused}
                                volunteerRegistrationId={props.volunteerRegistrationId}
                                close={close}
                                reload={props.reload}
                            />
                        )}

                        {props.state !== VolunteerRegistrationState.NotApplicable && (
                            <DropdownCell
                                eventId={props.eventId}
                                state={VolunteerRegistrationState.NotApplicable}
                                volunteerRegistrationId={props.volunteerRegistrationId}
                                close={close}
                                reload={props.reload}
                            />
                        )}
                    </Menu>
                </Dropdown>
            </Cell>
        );
    } else {
        return (
            <Cell
                width={230}
                css={{
                    border: '2px solid transparent'
                }}
            >
                <Badge {...getStateBadgeColorIcon(props.state)}>{translate(props.state)}</Badge>
            </Cell>
        );
    }
};
