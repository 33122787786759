import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { AccreditationPositionProperty, Position, Tag } from 'common/src/generated/types';
import * as React from 'react';
import { PositionsTagsCellInput } from './positionsTagsCellInput';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsTagsCellProps {
    position: Pick<Position, 'id'> & { tags: Array<Pick<Tag, 'id' | 'name'>> };

    reload(): void;
}

export const PositionsTagsCell = (props: IPositionsTagsCellProps) => {
    const tags = React.useMemo(() => props.position.tags || [], [props.position]);
    const badges = React.useMemo(
        () =>
            tags.map((tag) => ({
                id: tag.id,
                color: 'gray',
                icon: null,
                text: tag.name
            })),
        [tags]
    );

    return (
        <PositionsUpdateCell
            initialValue={tags.map((t) => t.id)}
            positionId={props.position.id}
            property={AccreditationPositionProperty.Tag}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <PositionsTagsCellInput value={value} setValue={setValue} />
            )}
        >
            <BadgesPlus badges={badges} />
        </PositionsUpdateCell>
    );
};
