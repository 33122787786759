import { groupBy, sortBy } from 'lodash-es';
import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { Paris20KmAssignmentPage } from './paris20kmAssignmentPage';

interface IParis20KmAssignmentProps {
    event: DocumentUserCustomBadgeFragment;
}

export const Paris20KmAssignment = (props: IParis20KmAssignmentProps) => {
    const psuis = props.event.volunteerRegistration.positionsSlotsUsersInfos.filter(
        ({ position }) => position.tags.some((t) => t.name === 'Fiche personnalisée')
    );
    const grouped = sortBy(
        Object.entries(groupBy(psuis, ({ position }) => position.id)),
        ([positionName]) => positionName
    );

    return (
        <>
            {grouped.map(([_positionName, psuis], index) => (
                <Paris20KmAssignmentPage
                    key={index}
                    eventId={props.event.id}
                    psuis={psuis}
                    userInfo={props.event.volunteerRegistration.userInfo}
                />
            ))}
        </>
    );
};
