import { compact, uniq } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyArray } from '../../../util/array';
import { capitalizeName, isNonEmptyString } from '../../../util/string';

const getBackgroundUrl = (population: string) => {
    switch (population) {
        case 'STAFF':
            return 'https://assets.recrewteer.com/badges/clubFrance/background_staff.png';
        case 'MÉDIA':
            return 'https://assets.recrewteer.com/badges/clubFrance/background_media.png';
        default:
            return '';
    }
};

const getTitle1 = (ui: DocumentUserCustomBadgeFragment['volunteerRegistration']['userInfo']) => {
    switch (ui.clubFrancePopulation) {
        case 'ÉQUIPE DE FRANCE':
            return '';
        case 'PARTIE PRENANTE':
            if (
                ui.clubFranceSousPopulation === 'Ministère de la Justice' ||
                ui.clubFranceSousPopulation ===
                    "Ministère de l'Enseignement Supérieur & de la Recherche" ||
                ui.clubFranceSousPopulation ===
                    'Ministère de l’Europe et des Affaires Étrangères' ||
                ui.clubFranceSousPopulation === 'Ministère du Sport & JOP' ||
                ui.clubFranceSousPopulation === "Ministère de l'Intérieur et des Outre-mer" ||
                ui.clubFranceSousPopulation === 'Paris 2024' ||
                ui.clubFranceSousPopulation === 'MSJOP' ||
                ui.clubFranceSousPopulation === 'Agence Nationale Du Sport' ||
                ui.clubFranceSousPopulation === 'DIJOP' ||
                ui.clubFranceSousPopulation === 'Ville de Paris' ||
                ui.clubFranceSousPopulation === 'Région Ile de France' ||
                ui.clubFranceSousPopulation === 'INSEP'
            ) {
                return ui.fields.cf33056;
            } else {
                return ui.fields.cf24975;
            }
        case 'PARTENAIRE':
            return ui.fields.cf33056;
        case 'MÉDIA':
            if (ui.clubFranceSousPopulation === 'Attaché presse des fédérations') {
                return ui.fields.cf30882;
            } else {
                return '';
            }
        case 'FÉDÉRATION SPORTIVE':
            return ui.fields.cf30882;
        case 'VOLONTAIRE':
            return '';
        case 'SÉCURITÉ':
            return '';
        case 'ORGANISATION':
            if (ui.clubFranceSousPopulation === 'MCI France') {
                return ui.clubFranceEquipeMci;
            } else if (
                ui.clubFranceSousPopulation === 'CNOSF' ||
                ui.clubFranceSousPopulation === 'CPSF Core Team'
            ) {
                return ui.fields.cf33056;
            } else {
                return '';
            }
        case 'STAFF':
            return '';
        case 'CNOSF':
            if (ui.clubFranceSousPopulation === 'Consultant Olympien') {
                return ui.fields.cf33706;
            } else if (ui.clubFranceSousPopulation === 'Dirigeant') {
                return ui.fields.cf24975;
            } else {
                return '';
            }
        case 'CPSF':
            if (ui.clubFranceSousPopulation === 'Consultant Paralympique') {
                return '';
            } else {
                return ui.fields.cf24975;
            }
        case 'ARTISTE':
            return ui.clubFranceSociete;
        default:
            return '';
    }
};

const getTitle2 = (ui: DocumentUserCustomBadgeFragment['volunteerRegistration']['userInfo']) => {
    switch (ui.clubFrancePopulation) {
        case 'ÉQUIPE DE FRANCE':
            return ui.clubFranceSousPopulation;
        case 'PARTIE PRENANTE':
            return ui.clubFranceSousPopulation;
        case 'PARTENAIRE':
            return ui.clubFranceSousPopulation;
        case 'MÉDIA':
            if (
                ui.clubFranceSousPopulation === 'Détenteur de Droits' ||
                ui.clubFranceSousPopulation === 'Non détenteur de Droits' ||
                ui.clubFranceSousPopulation === 'Volants'
            ) {
                return ui.fields.cf33706;
            } else {
                return ui.clubFranceSousPopulation;
            }
        case 'FÉDÉRATION SPORTIVE':
            if (
                ui.clubFranceSousPopulation === 'Animation sportive' ||
                ui.clubFranceSousPopulation === 'Animation Archi-Folies' ||
                ui.clubFranceSousPopulation === 'Staff Fédération'
            ) {
                return '';
            } else {
                return ui.clubFranceSousPopulation;
            }
        case 'VOLONTAIRE':
            if (ui.clubFranceSousPopulation === 'Ville de Paris') {
                return ui.clubFranceSousPopulation;
            } else {
                return '';
            }
        case 'SÉCURITÉ':
            return '';
        case 'ORGANISATION':
            return ui.clubFranceSousPopulation;
        case 'STAFF':
            return ui.clubFranceSociete;
        case 'CNOSF':
            if (ui.clubFranceSousPopulation === 'Dirigeant') {
                return '';
            } else {
                return ui.clubFranceSousPopulation;
            }
        case 'CPSF':
            return ui.clubFranceSousPopulation;
        case 'ARTISTE':
            return ui.clubFranceSousPopulation;
        default:
            return '';
    }
};

const Accreditation = ({ acronym, visible }: { acronym: number; visible: boolean }) => (
    <Flex
        align="center"
        justify="center"
        height={20}
        width={20}
        css={{
            border: '1px solid black',
            fontSize: '12px',
            fontWeight: '500',
            visibility: visible ? 'visible' : 'hidden'
        }}
    >
        <Box
            css={{
                position: 'relative',
                top: '1px'
            }}
        >
            {acronym}
        </Box>
    </Flex>
);

interface IBadgeClubFranceProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeClubFrance = (props: IBadgeClubFranceProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const acronyms = uniq(
        compact(vr.accreditationsUsersInfos.map(({ accreditation }) => accreditation.acronym))
    );
    const AAAcronyms = acronyms.filter((a) => ['AA', 'AA+', 'AAA', 'AAA+'].includes(a));
    const hasAA = isNonEmptyArray(AAAcronyms);

    return (
        <>
            <Flex
                height={325}
                width={204}
                css={{
                    background: `url(${getBackgroundUrl(ui.clubFrancePopulation)}) no-repeat`,
                    backgroundSize: 'cover',
                    color: 'black',
                    fontFamily: '$roboto',
                    position: 'relative'
                }}
            >
                <Box
                    height={97}
                    width={75}
                    css={{
                        left: '19px',
                        position: 'absolute',
                        top: '38px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img src={ui.picture!.url} height="100%" width="100%" />
                    )}
                </Box>

                <Box
                    css={{
                        fontWeight: '600',
                        left: '19px',
                        position: 'absolute',
                        top: '147px'
                    }}
                >
                    {capitalizeName(ui.fields.cf27122 || ui.firstName)}
                </Box>

                <Box
                    css={{
                        fontWeight: '600',
                        left: '19px',
                        position: 'absolute',
                        top: '163px'
                    }}
                >
                    {capitalizeName(ui.fields.cf27123 || ui.lastName)}
                </Box>

                <Box
                    css={{
                        fontSize: '10px',
                        left: '19px',
                        position: 'absolute',
                        top: '181px'
                    }}
                >
                    {getTitle1(ui)}
                </Box>

                <Box
                    width={1}
                    css={{
                        fontSize: '8px',
                        fontWeight: '500',
                        position: 'absolute',
                        px: '18px',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        top: '299px'
                    }}
                >
                    {getTitle2(ui)}
                </Box>

                {!hasAA && (
                    <Flex
                        wrap="wrap"
                        css={{
                            gap: '6px 4px',
                            position: 'absolute',
                            px: '53px',
                            top: '244px'
                        }}
                    >
                        <Accreditation acronym={1} visible={acronyms.includes('1')} />
                        <Accreditation acronym={2} visible={acronyms.includes('2')} />
                        <Accreditation acronym={3} visible={acronyms.includes('3')} />
                        <Accreditation acronym={4} visible={acronyms.includes('4')} />
                        <Accreditation acronym={5} visible={acronyms.includes('5')} />
                        <Accreditation acronym={6} visible={acronyms.includes('6')} />
                        <Accreditation acronym={7} visible={acronyms.includes('7')} />
                        <Accreditation acronym={8} visible={acronyms.includes('8')} />
                    </Flex>
                )}

                {hasAA && (
                    <Flex
                        justify="center"
                        width={1}
                        css={{
                            position: 'absolute',
                            top: '246px'
                        }}
                    >
                        <Box
                            css={{
                                border: '1px solid black',
                                fontSize: '12px',
                                fontWeight: '500',
                                padding: '10px'
                            }}
                        >
                            {AAAcronyms.join(' ')}
                        </Box>
                    </Flex>
                )}
            </Flex>
            {['STAFF', 'MÉDIA'].includes(ui.clubFrancePopulation) && (
                <Flex
                    height={325}
                    width={204}
                    css={{
                        background: `url(https://assets.recrewteer.com/badges/clubFrance/verso.jpg) no-repeat`,
                        backgroundSize: 'cover',
                        position: 'relative'
                    }}
                ></Flex>
            )}
        </>
    );
};
