import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UpdateUserForm } from 'common-front/src/users/update/updateUserForm';
import { useReferrerContext } from 'common-front/src/util/referrerContext';
import { isNonEmptyArray } from 'common/src/util/array';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import {
    useUpdateUserInfosEventQuery,
    useUpdateUserInfosOrganizationQuery
} from '../../generated/graphqlHooks';

export const UpdateUserLoaderEvent = () => {
    const {
        history,
        params: { organizationId, eventId, delegationId, userInfoId }
    } = useHeavent();
    const { getReferrerPath } = useReferrerContext();
    const { data, loader } = useUpdateUserInfosEventQuery({ organizationId, eventId, userInfoId });
    const formIdToInsertedAt = React.useMemo(
        () =>
            Object.fromEntries(
                (data.organization?.userInfo.formsUsersInfos ?? []).map((fui) => [
                    fui.formId,
                    fui.insertedAt
                ])
            ),
        [data.organization]
    );
    const formIdToTeamCode = React.useMemo(
        () =>
            Object.fromEntries(
                (data.organization?.userInfo.formsUsersInfos ?? []).map((fui) => [
                    fui.formId,
                    fui.teamCode
                ])
            ),
        [data.organization]
    );

    if (loader) {
        return loader;
    } else {
        return (
            <Switch>
                <Route
                    path={HeaventPaths.EDIT_USER_FORM(
                        ':organizationId',
                        ':eventId',
                        ':userInfoId',
                        ':formId'
                    )}
                    children={
                        <UpdateUserForm
                            closePath={HeaventPaths.VOLUNTEERS(organizationId, eventId)}
                            customFields={data.organization.customFields.nodes}
                            country={data.event.country}
                            eventId={eventId}
                            formIdToInsertedAt={formIdToInsertedAt}
                            formIdToTeamCode={formIdToTeamCode}
                            forms={data.event.forms}
                            organizationId={organizationId}
                            origin="event"
                            showAllAndPrivate={true}
                            showIsFilled={true}
                            userInfo={data.organization.userInfo}
                            getEditUserFormPath={(formId) =>
                                HeaventPaths.EDIT_USER_FORM(
                                    organizationId,
                                    eventId,
                                    userInfoId,
                                    formId
                                )
                            }
                            onSuccess={() => {
                                history.push(
                                    getReferrerPath() ??
                                        HeaventPaths.VOLUNTEERS(organizationId, eventId)
                                );
                            }}
                        />
                    }
                />

                <Route
                    path={HeaventPaths.EDIT_USER_DELEGATION_FORM(
                        ':organizationId',
                        ':eventId',
                        ':delegationId',
                        ':userInfoId',
                        ':formId'
                    )}
                    children={
                        <UpdateUserForm
                            closePath={HeaventPaths.DELEGATION(
                                organizationId,
                                eventId,
                                delegationId
                            )}
                            customFields={data.organization.customFields.nodes}
                            country={data.event.country}
                            eventId={eventId}
                            formIdToInsertedAt={formIdToInsertedAt}
                            formIdToTeamCode={formIdToTeamCode}
                            forms={data.event.forms.filter(
                                (form) =>
                                    isNonEmptyArray(form.delegationsIds) &&
                                    form.delegationsIds.includes(delegationId)
                            )}
                            organizationId={organizationId}
                            origin="event"
                            showAllAndPrivate={true}
                            showIsFilled={true}
                            userInfo={data.organization.userInfo}
                            getEditUserFormPath={(formId) =>
                                HeaventPaths.EDIT_USER_DELEGATION_FORM(
                                    organizationId,
                                    eventId,
                                    delegationId,
                                    userInfoId,
                                    formId
                                )
                            }
                            onSuccess={() => {
                                history.push(
                                    HeaventPaths.DELEGATION(organizationId, eventId, delegationId)
                                );
                            }}
                        />
                    }
                />

                <Route
                    children={
                        <Empty
                            path={
                                delegationId
                                    ? HeaventPaths.EDIT_USER_DELEGATION_FORM(
                                          organizationId,
                                          eventId,
                                          delegationId,
                                          userInfoId,
                                          data.event.forms.length > 0
                                              ? data.event.forms[0].id
                                              : 'all'
                                      )
                                    : HeaventPaths.EDIT_USER_FORM(
                                          organizationId,
                                          eventId,
                                          userInfoId,
                                          data.event.forms.length > 0
                                              ? data.event.forms[0].id
                                              : 'all'
                                      )
                            }
                            replace={true}
                        />
                    }
                />
            </Switch>
        );
    }
};

export const UpdateUserLoaderOrganization = () => {
    const {
        history,
        params: { organizationId, delegationId, userInfoId }
    } = useHeavent();
    const { getReferrerPath } = useReferrerContext();
    const { data, loader } = useUpdateUserInfosOrganizationQuery({ organizationId, userInfoId });
    const formIdToInsertedAt = React.useMemo(
        () =>
            Object.fromEntries(
                (data.organization?.userInfo.formsUsersInfos ?? []).map((fui) => [
                    fui.formId,
                    fui.insertedAt
                ])
            ),
        [data.organization]
    );

    if (loader) {
        return loader;
    } else {
        return (
            <Switch>
                <Route
                    path={HeaventPaths.COMMUNITY_EDIT_USER_FORM(
                        ':organizationId',
                        ':userInfoId',
                        ':formId'
                    )}
                    children={
                        <UpdateUserForm
                            closePath={CommunityPaths.COMMUNITY_USERS(organizationId)}
                            customFields={data.organization.customFields.nodes}
                            country={data.organization.country}
                            eventId={null}
                            formIdToInsertedAt={formIdToInsertedAt}
                            formIdToTeamCode={{}}
                            forms={data.organization.forms}
                            organizationId={organizationId}
                            origin="organization"
                            showAllAndPrivate={true}
                            showIsFilled={true}
                            userInfo={data.organization.userInfo}
                            getEditUserFormPath={(formId) =>
                                HeaventPaths.COMMUNITY_EDIT_USER_FORM(
                                    organizationId,
                                    userInfoId,
                                    formId
                                )
                            }
                            onSuccess={() => {
                                history.push(
                                    getReferrerPath() ??
                                        CommunityPaths.COMMUNITY_USERS(organizationId)
                                );
                            }}
                        />
                    }
                />

                <Route
                    path={HeaventPaths.COMMUNITY_EDIT_USER_DELEGATION_FORM(
                        ':organizationId',
                        ':delegationId',
                        ':userInfoId',
                        ':formId'
                    )}
                    children={
                        <UpdateUserForm
                            closePath={HeaventPaths.COMMUNITY_DELEGATION(
                                organizationId,
                                delegationId
                            )}
                            customFields={data.organization.customFields.nodes}
                            country={data.organization.country}
                            eventId={null}
                            formIdToInsertedAt={formIdToInsertedAt}
                            formIdToTeamCode={{}}
                            forms={data.organization.forms.filter(
                                (form) =>
                                    isNonEmptyArray(form.delegationsIds) &&
                                    form.delegationsIds.includes(delegationId)
                            )}
                            organizationId={organizationId}
                            origin="organization"
                            showAllAndPrivate={true}
                            showIsFilled={true}
                            userInfo={data.organization.userInfo}
                            getEditUserFormPath={(formId) =>
                                HeaventPaths.COMMUNITY_EDIT_USER_DELEGATION_FORM(
                                    organizationId,
                                    delegationId,
                                    userInfoId,
                                    formId
                                )
                            }
                            onSuccess={() => {
                                history.push(
                                    HeaventPaths.COMMUNITY_DELEGATION(organizationId, delegationId)
                                );
                            }}
                        />
                    }
                />

                <Route
                    children={
                        <Empty
                            path={
                                delegationId
                                    ? HeaventPaths.COMMUNITY_EDIT_USER_DELEGATION_FORM(
                                          organizationId,
                                          delegationId,
                                          userInfoId,
                                          data.organization.forms.length > 0
                                              ? data.organization.forms[0].id
                                              : 'all'
                                      )
                                    : HeaventPaths.COMMUNITY_EDIT_USER_FORM(
                                          organizationId,
                                          userInfoId,
                                          data.organization.forms.length > 0
                                              ? data.organization.forms[0].id
                                              : 'all'
                                      )
                            }
                            replace={true}
                        />
                    }
                />
            </Switch>
        );
    }
};
