import { chunk } from 'lodash-es';
import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgesFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { BadgeFcg } from './badgeFcg';

interface IBadgesFcgProps {
    event: DocumentUserCustomBadgesFragment;
}

export const BadgesFcg = (props: IBadgesFcgProps) => (
    <>
        {chunk(props.event.volunteersRegistrations.nodes, 4).map(
            (volunteersRegistrations, index) => (
                <Flex
                    key={`page-${index}`}
                    wrap="wrap"
                    justify="between"
                    width={A4_SIZES['96dpi'].width}
                    css={{
                        pageBreakBefore: index !== 0 ? 'always' : undefined
                    }}
                >
                    {volunteersRegistrations.map((volunteerRegistration) => (
                        <BadgeFcg
                            key={volunteerRegistration.userInfo.id}
                            event={{
                                ...props.event,
                                volunteerRegistration
                            }}
                        />
                    ))}
                </Flex>
            )
        )}
    </>
);
