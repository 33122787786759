import { range } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

interface IRestaurationProps {
    children: React.ReactNode;
    visible: boolean;
}

const Restauration = (props: IRestaurationProps) => (
    <Flex
        direction="column"
        align="center"
        gap="1"
        css={{
            visibility: props.visible ? 'visible' : 'hidden'
        }}
    >
        <Box
            height={24}
            width={24}
            css={{
                border: '1px solid white',
                borderRadius: '6px'
            }}
        />

        <Box css={{ color: 'white', fontSize: '8px', fontWeight: '500' }}>{props.children}</Box>
    </Flex>
);

const statutToBackground = (statut: string) => {
    switch (statut) {
        case 'BENEVOLES':
            return '#f49538';
        case 'PARTENAIRES':
            return '#fecc16';
        case 'EXPOSANTS':
            return '#17bd6d';
        case 'MEDIAS':
            return '#fd5e97';
        case 'FOURNISSEURS':
            return '#da43c2';
        case 'MEDICAL':
            return '#ff0000';
        case 'PRESTATAIRES':
            return '#da43c2';
        case 'ORGANISATION':
            return '#36b3e9';
        case 'PHOTOGRAPHE':
            return '#fd5e97';
        case 'VIDÉASTE':
            return '#fd5e97';
        case 'OFFICIEL':
            return '#da43c2';
        default:
            return '';
    }
};

interface IBagdeVanneProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BagdeVanne = (props: IBagdeVanneProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const statut = ui.vannesStatut || '';
    const acronyms = vr.accreditationsUsersInfos.map((aui) => aui.accreditation.acronym || '');

    return (
        <>
            <Flex
                height={454}
                width={378}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/vannes/2024/recto.png) no-repeat`,
                    backgroundColor: statutToBackground(statut),
                    backgroundSize: 'cover',
                    fontFamily: '$montserrat',
                    position: 'relative'
                }}
            >
                <Box
                    css={{
                        fontSize: '20px',
                        fontWeight: '700',
                        left: '39px',
                        position: 'absolute',
                        top: '167px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    css={{
                        fontSize: '20px',
                        fontWeight: '700',
                        left: '39px',
                        position: 'absolute',
                        top: '195px'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Box
                    css={{
                        fontSize: '20px',
                        fontWeight: '500',
                        left: '39px',
                        position: 'absolute',
                        textTransform: 'capitalize',
                        top: '241px'
                    }}
                >
                    {statut.toLowerCase()}
                </Box>

                <Flex
                    gap="2"
                    css={{
                        left: '41px',
                        position: 'absolute',
                        top: '347px'
                    }}
                >
                    {range(1, 8).map((i) => (
                        <Flex
                            key={i}
                            align="center"
                            justify="center"
                            height={32}
                            width={32}
                            css={{
                                background: '#00e5ff',
                                borderRadius: '32px',
                                fontSize: '18px',
                                fontWeight: '700',
                                visibility: acronyms.includes(i.toString(10)) ? 'visible' : 'hidden'
                            }}
                        >
                            {i}
                        </Flex>
                    ))}
                </Flex>
            </Flex>

            <Flex
                height={454}
                width={378}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/vannes/2024/verso_v2.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$montserrat',
                    position: 'relative'
                }}
            >
                <Flex
                    css={{
                        gap: '32px',
                        left: '38px',
                        position: 'absolute',
                        top: '374px'
                    }}
                >
                    <Restauration visible={acronyms.includes('ven midi')}>Ven. Midi</Restauration>
                    <Restauration visible={acronyms.includes('sam midi')}>Sam. Midi</Restauration>
                    <Restauration visible={acronyms.includes('sam soir')}>Sam. Soir</Restauration>
                    <Restauration visible={acronyms.includes('dim')}>Dim.</Restauration>
                    <Restauration visible={acronyms.includes('cocktail')}>Cocktail</Restauration>
                </Flex>
            </Flex>
        </>
    );
};
