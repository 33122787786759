import { Button } from 'common-front/src/designSystem/components/button';
import { ToggleText } from 'common-front/src/designSystem/components/toggle';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { FormCustomFieldFragment, FormsElementId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { UpdateCustomFieldWithParams } from '../../../customFields/create/createUpdateCustomField';
import { useFormContext } from '../../show/formContext';

interface IFormFieldProps {
    elementId: FormsElementId;
    field: FormCustomFieldFragment;
    index: number;
    isMandatory: boolean;

    onDelete(index: number): void;
    toggleIsMandatory(index: number, isMandatory: boolean): void;
    updateCustomField(customField: FormCustomFieldFragment): void;
}

export const FormField = (props: IFormFieldProps) => {
    const translate = useTranslate();
    const { organizationId } = useFormContext();
    const [isEditOpen, setIsEditOpen] = React.useState(false);

    return (
        <>
            <Draggable draggableId={props.elementId} index={props.index}>
                {(draggableProvided: DraggableProvided) => (
                        <Flex
                            align="center"
                            gap="5"
                            css={{
                                bd: true,
                                padding: '$4 $6'
                            }}
                            {...draggableProvided.draggableProps}
                            ref={draggableProvided.innerRef}
                        >
                            <Box
                                color="gray300"
                                fontSize="textMd"
                                css={{ cursor: 'grab' }}
                                {...draggableProvided.dragHandleProps}
                            >
                                <I icon="grip-vertical" iconStyle="solid" />
                            </Box>

                            <Flex direction="column" gap="1" css={{ flex: '1' }}>
                                <Flex gap="2">
                                    <Box color="gray800" fontWeight="semiBold">
                                        {props.field.name}
                                    </Box>

                                    {props.field.conditionCustomField && (
                                        <Tooltip>
                                            <Trigger>
                                                <Box
                                                    color="gray800"
                                                    fontWeight="semiBold"
                                                    css={{ cursor: 'pointer' }}
                                                >
                                                    <I icon="split" />
                                                </Box>
                                            </Trigger>

                                            <Content placement="top">
                                                {translate(
                                                    'ce_champ_d_pend_31209',
                                                    props.field.conditionCustomField?.name
                                                )}
                                            </Content>
                                        </Tooltip>
                                    )}
                                </Flex>

                                <Box color="gray500">{translate(props.field.fieldType)}</Box>
                            </Flex>

                            <ToggleText
                                value={props.isMandatory}
                                onChange={(value) => {
                                    props.toggleIsMandatory(props.index, value);
                                }}
                            >
                                {translate('obligatoire_35326')}
                            </ToggleText>

                            <Flex gap="2">
                                <Tooltip>
                                    <Trigger>
                                        <Box>
                                            <Button
                                                color="white"
                                                leftIcon="trash-can"
                                                size="sm"
                                                onClick={() => {
                                                    props.onDelete(props.index);
                                                }}
                                            />
                                        </Box>
                                    </Trigger>

                                    <Content placement="top">
                                        {translate('supprimer_le_ch_99740')}
                                    </Content>
                                </Tooltip>

                                <Tooltip>
                                    <Trigger>
                                        <Box>
                                            <Button
                                                color="white"
                                                leftIcon="pen"
                                                size="sm"
                                                onClick={() => {
                                                    setIsEditOpen(true);
                                                }}
                                            />
                                        </Box>
                                    </Trigger>

                                    <Content placement="top">
                                        {translate('_diter_le_champ_78018')}
                                    </Content>
                                </Tooltip>
                            </Flex>
                        </Flex>
                    )}
            </Draggable>

            {isEditOpen && (
                <UpdateCustomFieldWithParams
                    organizationId={organizationId}
                    customFieldId={props.field.id}
                    onClose={() => {
                        setIsEditOpen(false);
                    }}
                    onSuccess={props.updateCustomField}
                />
            )}
        </>
    );
};
