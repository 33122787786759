import { SearchRow } from 'common-front/src/segments/searchRow';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { SegmentTableHeader } from 'common-front/src/segments/segmentTableHeader';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { CommunityCampaignsHeader } from './communityCampaignsHeader';
import { CommunityCampaignsList } from './communityCampaignsList';

export const CommunityCampaignsContent = () => {
    const translate = useTranslate();
    const { segment } = useSegmentsContext();

    return (
        <>
            <CommunityCampaignsHeader segment={segment} />

            <Spacer height="7" />

            <SegmentTableHeader
                searchRow={
                    <SearchRow
                        searchPlaceholder={translate('rechercher_une_69554')}
                        showEditColumns={false}
                        showEditMode={false}
                        showSearchColumns={false}
                    />
                }
            />

            <CommunityCampaignsList />
        </>
    );
};
