import { DelegationProperty, DelegationsDelegationFragment } from 'common/src/generated/types';
import * as React from 'react';
import { DelegationsFormCellInput } from './delegationsFormCellInput';
import { DelegationsUpdateCell } from './delegationsUpdateCell';

interface IDelegationsFormCellProps {
    delegation: DelegationsDelegationFragment;

    reload(): void;
}

export const DelegationsFormCell = (props: IDelegationsFormCellProps) => (
    <DelegationsUpdateCell
        delegation={props.delegation}
        initialValue={props.delegation.form.id}
        property={DelegationProperty.Form}
        reload={props.reload}
        renderInput={(value, setValue) => (
            <DelegationsFormCellInput value={value} setValue={setValue} />
        )}
    >
        {props.delegation.form.name}
    </DelegationsUpdateCell>
);
