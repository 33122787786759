import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A6_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeElephantsFrontProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeElephantsFront = (props: IBadgeElephantsFrontProps) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;
    const accreditations = vr.accreditationsUsersInfos.map((aui) => aui.accreditation);
    const vestiairesAccred = accreditations.find((a) => a.acronym === 'VEST');
    const tribunesAccred = accreditations.find((a) => a.acronym === 'TRI');
    const presseAccred = accreditations.find((a) => a.acronym === 'ZP');
    const immersionAccred = accreditations.find((a) => a.acronym === 'IMM');
    const fanAccred = accreditations.find((a) => a.acronym === 'FAN');
    const glaceAccred = accreditations.find((a) => a.acronym === 'GLA');

    return (
        <Flex
            height={A6_SIZES['96dpi'].height}
            width={A6_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/chambery/front.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$orbitron',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    height: '207px',
                    left: '49px',
                    position: 'absolute',
                    top: '113px',
                    width: '163px'
                }}
            >
                {isNonEmptyString(userInfo.picture?.url) && (
                    <img src={userInfo.picture!.url} height="100%" width="100%" />
                )}
            </Box>

            <Box
                css={{
                    color: '#feca36',
                    fontSize: '18px',
                    left: '37px',
                    position: 'absolute',
                    top: '350px'
                }}
            >
                {userInfo.firstName}
            </Box>

            <Box
                css={{
                    color: '#feca36',
                    fontSize: '18px',
                    left: '37px',
                    position: 'absolute',
                    top: '374px'
                }}
            >
                {userInfo.lastName}
            </Box>

            <Box
                css={{
                    color: '#feca36',
                    fontSize: '11px',
                    fontWeight: '700',
                    left: '41px',
                    position: 'absolute',
                    top: '419px'
                }}
            >
                {userInfo.fields.cf21877 || 'Staff'}
            </Box>

            {tribunesAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '6px',
                        left: '37px',
                        position: 'absolute',
                        top: '455px'
                    }}
                >
                    <Flex
                        align="center"
                        justify="center"
                        height={22}
                        width={38}
                        css={{
                            background: tribunesAccred?.color,
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: '700'
                        }}
                    >
                        1
                    </Flex>

                    <Box css={{ color: '#feca36', fontSize: '11px', textTransform: 'uppercase' }}>
                        Tribune
                    </Box>
                </Flex>
            )}

            {presseAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '6px',
                        left: '37px',
                        position: 'absolute',
                        top: '484px'
                    }}
                >
                    <Flex
                        align="center"
                        justify="center"
                        height={22}
                        width={38}
                        css={{
                            background: presseAccred?.color,
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: '700'
                        }}
                    >
                        2
                    </Flex>

                    <Box css={{ color: '#feca36', fontSize: '11px', textTransform: 'uppercase' }}>
                        Zone Presse
                    </Box>
                </Flex>
            )}

            {immersionAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '6px',
                        left: '37px',
                        position: 'absolute',
                        top: '515px'
                    }}
                >
                    <Flex
                        align="center"
                        justify="center"
                        height={22}
                        width={38}
                        css={{
                            background: vestiairesAccred?.color,
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: '700'
                        }}
                    >
                        3
                    </Flex>

                    <Box css={{ color: '#feca36', fontSize: '11px', textTransform: 'uppercase' }}>
                        VIP Immersion
                    </Box>
                </Flex>
            )}

            {vestiairesAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '6px',
                        left: '186px',
                        position: 'absolute',
                        top: '455px'
                    }}
                >
                    <Flex
                        align="center"
                        justify="center"
                        height={22}
                        width={38}
                        css={{
                            background: vestiairesAccred?.color,
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: '700'
                        }}
                    >
                        4
                    </Flex>

                    <Box css={{ color: '#feca36', fontSize: '11px', textTransform: 'uppercase' }}>
                        Vestiaires
                    </Box>
                </Flex>
            )}

            {fanAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '6px',
                        left: '186px',
                        position: 'absolute',
                        top: '484px'
                    }}
                >
                    <Flex
                        align="center"
                        justify="center"
                        height={22}
                        width={38}
                        css={{
                            background: fanAccred?.color,
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: '700'
                        }}
                    >
                        3
                    </Flex>

                    <Box css={{ color: '#feca36', fontSize: '11px', textTransform: 'uppercase' }}>
                        Players Expérience
                    </Box>
                </Flex>
            )}

            {glaceAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '6px',
                        left: '186px',
                        position: 'absolute',
                        top: '513px'
                    }}
                >
                    <Flex
                        align="center"
                        justify="center"
                        height={22}
                        width={38}
                        css={{
                            background: glaceAccred?.color,
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: '700'
                        }}
                    >
                        6
                    </Flex>

                    <Box css={{ color: '#feca36', fontSize: '11px', textTransform: 'uppercase' }}>
                        Bord de Glace
                    </Box>
                </Flex>
            )}
        </Flex>
    );
};
