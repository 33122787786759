import { injectable } from 'inversify';
import { AccreditationsSegmentsQuery, ALL_ICON, FilterType } from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { isNonEmptyArray } from '../../util/array';
import { Filter, PossibleColumn, SegmentService } from '../segment';

export enum AccreditationDefaultColumns {
    Acronym = 'acronym',
    Address = 'address',
    Category = 'category',
    Color = 'color',
    Conditions = 'conditions',
    CreatedBy = 'createdBy',
    FillingRate = 'fillingRate',
    Icon = 'icon',
    Id = 'id',
    Latitude = 'latitude',
    Longitude = 'longitude',
    Name = 'name',
    Resources = 'resources',
    SlotDate = 'slotDate',
    SlotName = 'slotName',
    Tags = 'tags'
}

@injectable()
export class AccreditationsSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getAccreditationsFilters(eventInfos: AccreditationsSegmentsQuery['event']): Filter[] {
        const filters: Filter[] = [
            {
                color: 'warning',
                slug: 'name',
                name: this.t('nom_de_l_accr_d_11908'),
                fieldType: FilterType.Text,
                hideEmpty: true
            }
        ];

        if (isNonEmptyArray(eventInfos.accreditationsCategories)) {
            filters.push({
                color: 'warning',
                slug: 'accreditationCategory',
                name: this.t('nom_de_la_cat_g_03860'),
                fieldType: FilterType.Select,
                values: eventInfos.accreditationsCategories,
                hideEmpty: true
            });
        }

        filters.push(
            {
                color: 'warning',
                slug: 'slotName',
                name: this.t('nom_du_cr_neau_54351'),
                fieldType: FilterType.Text
            },
            {
                color: 'warning',
                slug: 'slotDate',
                name: this.t('Date'),
                fieldType: FilterType.Date
            }
        );

        filters.push(
            {
                color: 'warning',
                slug: 'tag',
                name: 'Tag',
                fieldType: FilterType.Select,
                needLoading: true,
                organizationId: eventInfos.organizationId
            },
            {
                color: 'warning',
                slug: 'accreditationCustomField',
                name: this.t('condition_61066'),
                fieldType: FilterType.Select,
                needLoading: true,
                organizationId: eventInfos.organizationId
            }
        );

        filters.push(
            {
                color: 'warning',
                slug: 'acronym',
                name: this.t('Acronym'),
                fieldType: FilterType.Text
            },
            {
                color: 'warning',
                slug: 'color',
                name: this.t('Color'),
                fieldType: FilterType.Text,
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: 'icon',
                name: this.t('ic_ne_55554'),
                fieldType: FilterType.Select,
                values: ALL_ICON.map((icon) => ({
                    id: icon,
                    name: this.t(icon)
                })),
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: 'address',
                name: this.t('Address'),
                fieldType: FilterType.Text
            },
            {
                color: 'warning',
                slug: 'fillingRate',
                name: this.t('taux_de_remplis_11913'),
                fieldType: FilterType.Number,
                hideEmpty: true
            }
        );

        return filters;
    }

    getAccreditationsPossibleColumns(): PossibleColumn[] {
        return [
            {
                slug: AccreditationDefaultColumns.Id,
                name: this.t('id_51738'),
                isSearchable: true
            },
            {
                slug: AccreditationDefaultColumns.Name,
                name: this.t('nom_de_l_accr_d_11908'),
                isSearchable: true
            },
            {
                slug: AccreditationDefaultColumns.Category,
                name: this.t('nom_de_la_cat_g_03860'),
                isSearchable: true
            },
            {
                slug: AccreditationDefaultColumns.SlotName,
                name: this.t('nom_du_cr_neau_54351'),
                isSearchable: true
            },
            {
                slug: AccreditationDefaultColumns.SlotDate,
                name: this.t('Date')
            },
            {
                slug: AccreditationDefaultColumns.Resources,
                name: this.t('membres_11310')
            },
            {
                slug: AccreditationDefaultColumns.Tags,
                name: this.t('tags_79499'),
                isSearchable: true
            },
            {
                slug: AccreditationDefaultColumns.Conditions,
                name: this.t('conditions_77756')
            },
            {
                slug: AccreditationDefaultColumns.Address,
                name: this.t('Address'),
                isSearchable: true
            },
            {
                slug: AccreditationDefaultColumns.Latitude,
                name: this.t('latitude_46176'),
                isSearchable: true
            },
            {
                slug: AccreditationDefaultColumns.Longitude,
                name: this.t('longitude_01695'),
                isSearchable: true
            },
            {
                slug: AccreditationDefaultColumns.Acronym,
                name: this.t('Acronym'),
                isSearchable: true
            },
            {
                slug: AccreditationDefaultColumns.Color,
                name: this.t('Color'),
                isSearchable: true
            },
            {
                slug: AccreditationDefaultColumns.Icon,
                name: this.t('ic_ne_55554')
            },
            {
                slug: AccreditationDefaultColumns.FillingRate,
                name: this.t('taux_de_remplis_11913')
            },
            {
                slug: AccreditationDefaultColumns.CreatedBy,
                name: this.t('cr_e_par_30622'),
                isSearchable: true
            }
        ];
    }
}
