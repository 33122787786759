import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Position, PositionsPositionCustomFieldFragment } from 'common/src/generated/types';
import { displayConditionValue } from 'common/src/vo/positionCustomField';
import * as React from 'react';

interface IPositionsConditionsCellProps {
    position: Pick<Position, 'id'> & {
        positionsCustomFields: PositionsPositionCustomFieldFragment[];
    };
}

export const PositionsConditionsCell = (props: IPositionsConditionsCellProps) => {
    const { translate } = useHeavent();
    const positionCustomFields = React.useMemo(
        () => props.position.positionsCustomFields || [],
        [props.position]
    );
    const badges = React.useMemo(
        () =>
            positionCustomFields.map((positionCustomField, index) => ({
                id: index,
                color: 'gray',
                icon: null,
                text: `${positionCustomField.customField.name} : ${displayConditionValue(
                    translate,
                    positionCustomField.customField,
                    positionCustomField.conditionValue
                )}`
            })),
        [positionCustomFields]
    );

    return (
        <Cell>
            <BadgesPlus badges={badges} />
        </Cell>
    );
};
