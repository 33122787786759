import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { styled } from 'common/src/designSystem/components/stitches';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { StyledButton } from '../../designSystem/components/button';
import { IconBackground } from '../../designSystem/components/iconBackground';
import { Modal } from '../../designSystem/components/modal';

interface IErrorProps {
    content?: string[];

    onClose(): void;
}

const Content = styled(Flex, {
    gap: '$1',
    overflowY: 'auto'
});

export const ErrorModal = (props: IErrorProps) => {
    const translate = useTranslate();
    let content = [translate('une_erreur_est_92333')];

    if (isNonEmptyArray(props.content)) {
        content = props.content!;
    }

    return (
        <Modal size="sm" onClose={props.onClose} role="alertdialog">
            <Flex justify="center">
                <IconBackground color="error" icon="face-frown" />
            </Flex>

            <Spacer height="7" />

            <Box color="gray800" fontSize="textLg" fontWeight="medium" textAlign="center">
                {translate('ooops_on_a_un_p_56888')}
            </Box>

            <Spacer height="6" />

            <Content direction="column">
                {content.map((c, index) => (
                        <Box key={index} color="gray900">
                            {c}
                        </Box>
                    ))}
            </Content>

            <Spacer height="7" />

            <Flex justify="end" gap="3">
                <StyledButton
                    onClick={() => {
                        location.reload();
                    }}
                >
                    {translate('recharger_34496')}
                </StyledButton>

                <StyledButton color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </StyledButton>
            </Flex>
        </Modal>
    );
};
