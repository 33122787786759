import { Textarea } from 'common-front/src/designSystem/components/textarea';
import {
    EventId,
    SegmentCustomFieldFragment,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { VolunteerRegistrationUpdateCell } from './volunteerRegistrationUpdateCell';

interface IVolunteerRegistrationTextareaCellProps {
    customField: SegmentCustomFieldFragment;
    eventId: EventId;
    field: PossibleColumn;
    volunteerRegistration: VolunteerRegistrationFragment;

    reload(): void;
}

export const VolunteerRegistrationTextareaCell = (
    props: IVolunteerRegistrationTextareaCellProps
) => {
    const fieldService = useService(FieldService);
    const value = fieldService.getValueString(
        props.customField,
        props.volunteerRegistration.userInfo
    );

    return (
        <VolunteerRegistrationUpdateCell
            customField={props.customField}
            eventId={props.eventId}
            field={props.field}
            initialValue={value}
            valueString={value}
            volunteerRegistration={props.volunteerRegistration}
            reload={props.reload}
        >
            {(updatedValue, setUpdatedValue) => (
                <Textarea
                    label={props.customField.name}
                    value={updatedValue}
                    onChange={setUpdatedValue}
                />
            )}
        </VolunteerRegistrationUpdateCell>
    );
};
