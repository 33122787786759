import { sortBy } from 'lodash-es';
import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { VannesAssignmentPage } from './vannesAssignmentPage';

interface IVannesAssignmentProps {
    event: DocumentUserCustomBadgeFragment;
}

export const VannesAssignment = (props: IVannesAssignmentProps) => {
    const psuis = React.useMemo(
        () =>
            sortBy(props.event.volunteerRegistration.positionsSlotsUsersInfos, (psui) =>
                psui.positionSlot.range.start!.toMillis()
            ),
        [props.event.volunteerRegistration.positionsSlotsUsersInfos]
    );

    return (
        <>
            {psuis.map((psui) => (
                <VannesAssignmentPage
                    key={psui.positionSlot.id}
                    eventId={props.event.id}
                    positionSlotUserInfo={psui}
                    userInfo={props.event.volunteerRegistration.userInfo}
                />
            ))}
        </>
    );
};
