import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationPositionProperty,
    AccreditationsPositionsMassEditInput,
    ALL_ACCREDITATIONPOSITIONPROPERTY,
    EventId,
    MassAssignStrategy,
    OrganizationId,
    SegmentId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { isEqual } from 'lodash-es';
import * as React from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { usePositionsMassEditInfosQuery } from '../../generated/graphqlHooks';
import { MassEditStrategies } from '../../volunteers/massEdit/massEditStrategies';
import { MassEditFieldInput } from './massEditFieldInput';

interface IMassEditValues {
    massEdit: AccreditationsPositionsMassEditInput;
}

interface IMassEditModalProps {
    eventId: EventId;
    ids: number[];
    organizationId: OrganizationId;
    segmentId: Emptyable<SegmentId>;
    type: 'accreditations' | 'positions';

    onClose(): void;
    onSuccess(): void;
    mutate(massEdit: AccreditationsPositionsMassEditInput): Promise<any>;
}

export const MassEditModal = (props: IMassEditModalProps) => {
    const translate = useTranslate();
    const { data } = usePositionsMassEditInfosQuery({
        organizationId: props.organizationId,
        eventId: props.eventId
    });
    const properties = React.useMemo(
        () =>
            ALL_ACCREDITATIONPOSITIONPROPERTY.filter((p) => {
                if (p === AccreditationPositionProperty.Name) {
                    return false;
                } else if (
                    props.type === 'accreditations' &&
                    [AccreditationPositionProperty.Traces].includes(p)
                ) {
                    return false;
                } else {
                    return true;
                }
            }),
        []
    );
    const categories = React.useMemo(
        () =>
            props.type === 'accreditations'
                ? data.event?.accreditationsCategories ?? []
                : data.event?.positionsCategories ?? [],
        [props.type, data.event]
    );

    return (
        <Modal size="md" onClose={props.onClose}>
            <Form
                initialValues={{
                    massEdit: {
                        ids: props.ids,
                        property: '' as any,
                        reset: false,
                        segmentId: props.segmentId,
                        value: '',
                        strategy: MassAssignStrategy.Replace
                    }
                }}
                initialValuesEqual={isEqual}
                onSubmit={async (values: IMassEditValues) => {
                    await props.mutate(values.massEdit);

                    props.onSuccess();
                    props.onClose();
                }}
                render={({ form, handleSubmit, submitting, values }) => {
                    const { property, reset, strategy } = values.massEdit;
                    const massEditFieldInput = isNonEmptyString(property) ? (
                        <>
                            <Spacer height="1" />

                            <Box css={{ paddingLeft: '$6' }}>
                                <MassEditFieldInput
                                    categories={categories}
                                    property={property as AccreditationPositionProperty}
                                    tags={data.organization?.tags.nodes ?? []}
                                    traces={data.event?.traces ?? []}
                                    value={values.massEdit.value}
                                />
                            </Box>
                        </>
                    ) : null;
                    const isAddDeleteVisible = property === AccreditationPositionProperty.Tag;
                    const isResetVisible = property !== AccreditationPositionProperty.Category;

                    return (
                        <>
                            <Flex justify="center">
                                <IconBackground color="primary" icon="sliders" />
                            </Flex>

                            <Spacer height="4" />

                            <Box
                                color="gray900"
                                fontSize="textLg"
                                fontWeight="medium"
                                textAlign="center"
                            >
                                {translate('_dition_de_la_v_65696')}
                            </Box>

                            <Spacer height="7" />

                            <OnChange name="massEdit.property">
                                {(property: AccreditationPositionProperty) => {
                                    if (property === AccreditationPositionProperty.Tag) {
                                        form.change('massEdit.value', []);
                                    } else {
                                        form.change('massEdit.value', '');
                                    }
                                }}
                            </OnChange>

                            <RichSelect
                                isSearchVisible={true}
                                placeholder={translate('s_lectionnez_un_08888')}
                                name="massEdit.property"
                                label={translate('quelle_est_la_p_49128')}
                            >
                                {properties.map((property) => (
                                    <option key={property} value={property}>
                                        {translate(property)}
                                    </option>
                                ))}
                            </RichSelect>

                            {isNonEmptyString(property) && (
                                <MassEditStrategies
                                    displayName={translate(property)}
                                    isAddDeleteVisible={isAddDeleteVisible}
                                    isResetVisible={isResetVisible}
                                    massEditFieldInput={massEditFieldInput}
                                    reset={reset}
                                    strategy={strategy}
                                    change={form.change}
                                />
                            )}

                            <Spacer height="7" />

                            <Flex justify="end" gap="3">
                                <Button isLoading={submitting} onClick={handleSubmit}>
                                    {translate('modifier_la_val_07602')}
                                </Button>

                                <Button color="white" onClick={props.onClose}>
                                    {translate('annuler_48254')}
                                </Button>
                            </Flex>
                        </>
                    );
                }}
            />
        </Modal>
    );
};
