import { Span } from 'common/src/designSystem/components/span';
import * as React from 'react';
import { showDeprecationWarning } from '../../util/deprecationWarning';

export enum Color {
    Blue,
    Red
}

interface ILinkProps {
    children: React.ReactNode;
    color?: Color;
    bold?: boolean;

    onClick(): void;
}

export const Link = (props: ILinkProps) => {
    showDeprecationWarning(
        'common-front/src/components/link/link.tsx',
        'common/src/designSystem/components/link.tsx'
    );

    return (
        <Span
            css={{
                color: props.color === Color.Red ? '$oldRed' : '$oldBlue',
                cursor: 'pointer',
                fontWeight: props.bold ? '$oldBold' : 'inherit',
                textDecoration: 'underline',
                userSelect: 'none'
            }}
            onClick={props.onClick}
        >
            {props.children}
        </Span>
    );
};
