import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { BadgeJokersfrancesloveniaA6 } from './badgeJokersfrancesloveniaA6';

interface IBadgeJokersfrancesloveniaA4Props {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeJokersfrancesloveniaA4 = (props: IBadgeJokersfrancesloveniaA4Props) => (
        <Flex
            align="center"
            justify="center"
            height={A4_SIZES['96dpi'].width}
            width={A4_SIZES['96dpi'].height}
        >
            <BadgeJokersfrancesloveniaA6 event={props.event} />
        </Flex>
    );
