import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { noop } from 'lodash-es';
import * as React from 'react';
import { Checkbox, CheckboxText, ICheckboxSize, ICheckboxState } from '../components/checkbox';

export const Checkboxes = () => {
    const [size, setSize] = React.useState<ICheckboxSize>('sm');
    const [state, setState] = React.useState<ICheckboxState>('unchecked');
    const [disabled, setDisabled] = React.useState(false);
    const [text, setText] = React.useState('Remember me!');
    const [subtext, setSubtext] = React.useState('');

    return (
        <Flex direction="column" gap="6">
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Checkboxes
            </Box>

            <Flex gap="3">
                <Checkbox onClick={noop} />

                <Checkbox disabled onClick={noop} />

                <Checkbox state="checked" onClick={noop} />

                <Checkbox state="checked" disabled onClick={noop} />

                <Checkbox state="indeterminate" onClick={noop} />

                <Checkbox state="indeterminate" disabled onClick={noop} />
            </Flex>

            <Flex gap="3">
                <Checkbox size="md" onClick={noop} />

                <Checkbox size="md" disabled onClick={noop} />

                <Checkbox size="md" state="checked" onClick={noop} />

                <Checkbox size="md" state="checked" disabled onClick={noop} />

                <Checkbox size="md" state="indeterminate" onClick={noop} />

                <Checkbox size="md" state="indeterminate" disabled onClick={noop} />
            </Flex>

            <Flex gap="3">
                <CheckboxText onClick={noop}>Remember me!</CheckboxText>

                <CheckboxText disabled onClick={noop}>
                    Remember me!
                </CheckboxText>

                <CheckboxText state="checked" onClick={noop}>
                    Remember me!
                </CheckboxText>

                <CheckboxText state="checked" disabled onClick={noop}>
                    Remember me!
                </CheckboxText>

                <CheckboxText state="indeterminate" onClick={noop}>
                    Remember me!
                </CheckboxText>

                <CheckboxText state="indeterminate" disabled onClick={noop}>
                    Remember me!
                </CheckboxText>
            </Flex>

            <Flex gap="3">
                <CheckboxText size="md" onClick={noop}>
                    Remember me!
                </CheckboxText>

                <CheckboxText size="md" disabled onClick={noop}>
                    Remember me!
                </CheckboxText>

                <CheckboxText size="md" state="checked" onClick={noop}>
                    Remember me!
                </CheckboxText>

                <CheckboxText size="md" state="checked" disabled onClick={noop}>
                    Remember me!
                </CheckboxText>

                <CheckboxText size="md" state="indeterminate" onClick={noop}>
                    Remember me!
                </CheckboxText>

                <CheckboxText size="md" state="indeterminate" disabled onClick={noop}>
                    Remember me!
                </CheckboxText>
            </Flex>

            <Flex gap="3">
                <CheckboxText subtext="Save my login details for next time." onClick={noop}>
                    Remember me!
                </CheckboxText>

                <CheckboxText
                    subtext="Save my login details for next time."
                    disabled
                    onClick={noop}
                >
                    Remember me!
                </CheckboxText>

                <CheckboxText
                    subtext="Save my login details for next time."
                    state="checked"
                    onClick={noop}
                >
                    Remember me!
                </CheckboxText>

                <CheckboxText
                    subtext="Save my login details for next time."
                    state="checked"
                    disabled
                    onClick={noop}
                >
                    Remember me!
                </CheckboxText>

                <CheckboxText
                    subtext="Save my login details for next time."
                    state="indeterminate"
                    onClick={noop}
                >
                    Remember me!
                </CheckboxText>

                <CheckboxText
                    subtext="Save my login details for next time."
                    state="indeterminate"
                    disabled
                    onClick={noop}
                >
                    Remember me!
                </CheckboxText>
            </Flex>

            <Flex gap="3">
                <CheckboxText
                    size="md"
                    subtext="Save my login details for next time."
                    onClick={noop}
                >
                    Remember me!
                </CheckboxText>

                <CheckboxText
                    size="md"
                    subtext="Save my login details for next time."
                    disabled
                    onClick={noop}
                >
                    Remember me!
                </CheckboxText>

                <CheckboxText
                    size="md"
                    subtext="Save my login details for next time."
                    state="checked"
                    onClick={noop}
                >
                    Remember me!
                </CheckboxText>

                <CheckboxText
                    size="md"
                    subtext="Save my login details for next time."
                    state="checked"
                    disabled
                    onClick={noop}
                >
                    Remember me!
                </CheckboxText>

                <CheckboxText
                    size="md"
                    subtext="Save my login details for next time."
                    state="indeterminate"
                    onClick={noop}
                >
                    Remember me!
                </CheckboxText>

                <CheckboxText
                    size="md"
                    subtext="Save my login details for next time."
                    state="indeterminate"
                    disabled
                    onClick={noop}
                >
                    Remember me!
                </CheckboxText>
            </Flex>

            <Flex align="center" gap="3">
                <select
                    value={size}
                    onChange={(e) => {
                        setSize(e.target.value as ICheckboxSize);
                    }}
                >
                    <option value="sm">sm</option>
                    <option value="md">md</option>
                </select>

                <select
                    value={state}
                    onChange={(e) => {
                        setState(e.target.value as ICheckboxState);
                    }}
                >
                    <option value="unchecked">unchecked</option>
                    <option value="checked">checked</option>
                    <option value="indeterminate">indeterminate</option>
                </select>

                <label style={{ cursor: 'pointer' }}>
                    <input
                        type="checkbox"
                        checked={disabled}
                        onChange={(e) => {
                            setDisabled(e.target.checked);
                        }}
                    />{' '}
                    Disabled
                </label>

                <input
                    type="text"
                    placeholder="Texte"
                    value={text}
                    onChange={(e) => {
                        setText(e.target.value);
                    }}
                />

                <input
                    type="text"
                    placeholder="Sous-texte"
                    value={subtext}
                    onChange={(e) => {
                        setSubtext(e.target.value);
                    }}
                />
            </Flex>

            <Flex direction="column" gap="3">
                <Checkbox state={state} size={size} disabled={disabled} onClick={setState} />

                <CheckboxText
                    state={state}
                    size={size}
                    disabled={disabled}
                    subtext={subtext}
                    onClick={setState}
                >
                    {text}
                </CheckboxText>
            </Flex>
        </Flex>
    );
};
