import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CampaignId,
    EventId,
    MassCampaignSendEventCampaignsQuery,
    OrganizationId,
    SegmentId,
    UsersInfoId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { encodeBase64 } from 'common/src/util/base64';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import {
    executeMassCampaignSendEventCampaignsQuery,
    executeMassCampaignSendOrganizationCampaignsQuery,
    useUsersInfosMassCampaignSendMutation,
    useVolunteersRegistrationsMassCampaignSendMutation
} from '../../generated/graphqlHooks';

interface IMassCampaignSendModalProps {
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    segmentId: Emptyable<SegmentId>;
    usersInfosIds: UsersInfoId[];

    onClose(): void;
    onSuccess(): void;
}

export const MassCampaignSendModal = (props: IMassCampaignSendModalProps) => {
    const { history, translate } = useHeavent();
    const {
        mutate: volunteersRegistrationsMassCampaignSend,
        isLoading: isVolunteersRegistrationsMassCampaignSendLoading
    } = useVolunteersRegistrationsMassCampaignSendMutation();
    const { mutate: usersInfosMassCampaignSend, isLoading: isUsersInfosMassCampaignSendLoading } =
        useUsersInfosMassCampaignSendMutation();
    const [campaigns, setCampaigns] = React.useState<
        MassCampaignSendEventCampaignsQuery['event']['campaigns']['nodes']
    >([]);
    const [campaignsIds, setCampaignsIds] = React.useState<CampaignId[]>([]);
    const valid = isNonEmptyArray(campaignsIds);
    const onUpdate = async () => {
        if (isNonEmptyArray(campaignsIds)) {
            if (props.eventId) {
                await volunteersRegistrationsMassCampaignSend({
                    eventId: props.eventId,
                    segmentId: props.segmentId,
                    usersInfosIds: props.usersInfosIds,
                    campaignId: campaignsIds[0]
                });
            } else {
                await usersInfosMassCampaignSend({
                    organizationId: props.organizationId,
                    segmentId: props.segmentId,
                    usersInfosIds: props.usersInfosIds,
                    campaignId: campaignsIds[0]
                });
            }

            props.onSuccess();
            props.onClose();
        }
    };

    React.useEffect(() => {
        (async () => {
            if (props.eventId) {
                const eventResult = await executeMassCampaignSendEventCampaignsQuery(
                    { eventId: props.eventId },
                    await getToken()
                );

                setCampaigns(eventResult.event.campaigns.nodes);
            } else {
                const organizationResult = await executeMassCampaignSendOrganizationCampaignsQuery(
                    { organizationId: props.organizationId },
                    await getToken()
                );

                setCampaigns(organizationResult.organization.campaigns.nodes);
            }
        })();
    }, [props.organizationId, props.eventId]);

    return (
        <Modal size="md" onClose={props.onClose}>
            <Flex justify="center">
                <IconBackground color="primary" icon="people-group" />
            </Flex>

            <Spacer height="4" />

            <Box font="gray900 textLg medium" textAlign="center">
                {translate('envoyer_une_cam_94630')}
            </Box>

            <Spacer height="7" />

            <RichSelect
                isSearchVisible={true}
                label={translate('campagne_97871')}
                values={campaignsIds}
                onChange={setCampaignsIds}
            >
                {campaigns.map((campaign) => (
                    <option key={campaign.id} value={campaign.id}>
                        {campaign.name}
                    </option>
                ))}
            </RichSelect>

            <Spacer height="2" />

            <Button
                color="invisible"
                leftIcon="plus"
                onClick={() => {
                    const options = encodeBase64(
                        JSON.stringify({
                            segmentId: props.segmentId,
                            usersInfosIds: props.usersInfosIds
                        })
                    );

                    if (props.eventId) {
                        history.push(
                            HeaventPaths.CREATE_CAMPAIGN(
                                props.organizationId,
                                props.eventId,
                                options
                            )
                        );
                    } else {
                        history.push(
                            HeaventPaths.COMMUNITY_CREATE_CAMPAIGN(props.organizationId, options)
                        );
                    }
                }}
            >
                {translate('cr_er_une_nouve_86635')}
            </Button>

            <Spacer height="7" />

            <Flex justify="end" gap="3">
                <Button
                    disabled={!valid}
                    isLoading={
                        isVolunteersRegistrationsMassCampaignSendLoading ||
                        isUsersInfosMassCampaignSendLoading
                    }
                    onClick={onUpdate}
                >
                    {translate('envoyer_26609')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
