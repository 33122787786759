import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IHeaderProps {
    icon: IIcon;
    children: React.ReactNode;
    subtitle?: string;
}

export const Header = ({ icon, children, subtitle }: IHeaderProps) => (
    <Flex
        align="center"
        css={{
            padding: '$6 $7 $5 $7'
        }}
        gap="4"
    >
        <Flex
            align="center"
            css={{
                border: '1px solid $gray200',
                borderRadius: '10px',
                boxShadow: '$xs',
                color: 'gray700',
                fontSize: 'textMd',
                padding: '$3'
            }}
            height={48}
            justify="center"
            width={48}
        >
            <I icon={icon} />
        </Flex>

        <Flex direction="column">
            <Box font="gray900 textLg medium">{children}</Box>

            {isNonEmptyString(subtitle) && <Box color="gray500">{subtitle}</Box>}
        </Flex>
    </Flex>
);
