import { sortBy } from 'lodash-es';
import * as React from 'react';
import {
    AsoassignmentDocumentOptions,
    DocumentUserCustomBadgeFragment
} from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';
import { AsoAssignmentPage1 } from './asoAssignmentPage1';
import { AsoAssignmentPage2 } from './asoAssignmentPage2';

interface IAsoAssignmentProps {
    event: DocumentUserCustomBadgeFragment;
    options: AsoassignmentDocumentOptions;
}

export const AsoAssignment = (props: IAsoAssignmentProps) => {
    const psuis = React.useMemo(
        () =>
            sortBy(props.event.volunteerRegistration.positionsSlotsUsersInfos, (psui) =>
                psui.positionSlot.range.start!.toMillis()
            ),
        [props.event.volunteerRegistration.positionsSlotsUsersInfos]
    );

    return (
        <>
            {psuis.map((psui, index) => {
                const position = psui.position;

                return (
                    <React.Fragment key={index}>
                        <AsoAssignmentPage1
                            eventId={props.event.id}
                            options={props.options}
                            positionSlotUserInfo={psui}
                            userInfo={props.event.volunteerRegistration.userInfo}
                        />

                        {isNonEmptyString(position.fields.cf24781?.url) && (
                            <AsoAssignmentPage2
                                options={props.options}
                                positionSlotUserInfo={psui}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
};
