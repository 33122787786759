import { Flex, SectioningElement } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IMenuProps {
    children: React.ReactNode;
    height?: number | string;
    width?: number | string;
}

export const TopMenu = (props: IMenuProps) => (
    <Flex
        as={SectioningElement.Nav}
        justify="center"
        gap="2"
        height={props.height}
        width={props.width} 
        css={{
            borderBottom: '1px solid $gray200'
        }}
        role="menubar"
    >
        {props.children}
    </Flex>
);
