import { RadioText } from 'common-front/src/designSystem/components/radio';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { MassAssignStrategy } from 'common/src/generated/types';
import * as React from 'react';

interface IMassEditStrategiesProps {
    displayName: string;
    isAddDeleteVisible: boolean;
    isResetVisible: boolean;
    massEditFieldInput: React.ReactNode;
    reset: boolean;
    strategy: MassAssignStrategy;

    change(name: string, value: any): void;
}

export const MassEditStrategies = ({
    displayName,
    isAddDeleteVisible,
    isResetVisible,
    massEditFieldInput,
    reset,
    strategy,
    change
}: IMassEditStrategiesProps) => {
    const { translate } = useHeavent();

    return (
        <>
            <Spacer height="4" />

            <Box font="gray700 textSm medium">{translate('que_souhaitez_v_87607')}</Box>

            <Spacer height="2" />

            <RadioText
                state={!reset && strategy === MassAssignStrategy.Replace ? 'checked' : 'unchecked'}
                onClick={() => {
                    change('massEdit.reset', false);
                    change('massEdit.strategy', MassAssignStrategy.Replace);
                }}
            >
                {translate('remplacer_les_v_78885')}
            </RadioText>

            {!reset && strategy === MassAssignStrategy.Replace && massEditFieldInput}

            <Spacer height="3" />

            {isAddDeleteVisible && (
                <>
                    <RadioText
                        state={
                            !reset && strategy === MassAssignStrategy.Add ? 'checked' : 'unchecked'
                        }
                        onClick={() => {
                            change('massEdit.reset', false);
                            change('massEdit.strategy', MassAssignStrategy.Add);
                        }}
                    >
                        {translate('ajouter_des_val_80159')}
                    </RadioText>

                    {!reset && strategy === MassAssignStrategy.Add && massEditFieldInput}

                    <Spacer height="3" />

                    <RadioText
                        state={
                            !reset && strategy === MassAssignStrategy.Delete
                                ? 'checked'
                                : 'unchecked'
                        }
                        onClick={() => {
                            change('massEdit.reset', false);
                            change('massEdit.strategy', MassAssignStrategy.Delete);
                        }}
                    >
                        {translate('supprimer_des_v_96120')}
                    </RadioText>

                    {!reset && strategy === MassAssignStrategy.Delete && massEditFieldInput}

                    <Spacer height="3" />
                </>
            )}

            {isResetVisible && (
                <RadioText
                    state={reset ? 'checked' : 'unchecked'}
                    onClick={() => change('massEdit.reset', true)}
                >
                    {translate('r_initialiser_42983', displayName)}
                </RadioText>
            )}
        </>
    );
};
