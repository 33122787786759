import * as React from 'react';
import { useModalContext } from './root';

interface ITriggerProps {
    children: React.ReactNode;
}

export const Trigger = (props: ITriggerProps) => {
    const { setIsOpen } = useModalContext();
    const children = props.children as any;
    const handler = React.useCallback(
        (e: React.MouseEvent | React.KeyboardEvent) => {
            e.nativeEvent.stopImmediatePropagation();
            e.stopPropagation();

            setIsOpen(true);

            children.props.onClick?.(e);
        },
        [setIsOpen, children]
    );
    const child = React.useMemo(() => {
        return React.cloneElement(React.Children.only(children), {
            onClick: handler,
            onKeyPress: handler,
            tabIndex: 0
        });
    }, [children, handler]);

    return <>{child}</>;
};
