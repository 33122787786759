import { Flex } from 'common/src/designSystem/components/flex';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IItemProps {
    children: React.ReactNode;
    path: string;

    isActive?(pathname: string): boolean;
    onClick?(): Promise<any>;
}

export const Item = (props: IItemProps) => {
    const location = useLocation();
    const isActive = props.isActive?.(location.pathname) ?? location.pathname === props.path;

    return (
        <Flex
            align="center"
            css={{
                borderBottom: isActive ? '2px solid $primary700' : '2px solid transparent',
                borderTop: '2px solid transparent',
                color: isActive ? '$primary700' : '$gray500',
                cursor: 'pointer',
                fontWeight: '$medium',
                px: '$3'
            }}
            height={1}
            to={props.path}
            onClick={async () => {
                if (props.onClick) {
                    await props.onClick();
                }
            }}
        >
            {props.children}
        </Flex>
    );
};
