import { UpdateCell } from 'common-front/src/components/updateCell';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { PositionsPositionSlotFragment } from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import { pick } from 'lodash-es';
import * as React from 'react';
import { usePositionSlotUpdateMutation } from '../../../generated/graphqlHooks';

interface IPositionSlotNameCellProps {
    positionSlot: PositionsPositionSlotFragment;

    reload(): void;
}

export const PositionSlotNameCell = (props: IPositionSlotNameCellProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const { mutate: positionSlotUpdate } = usePositionSlotUpdateMutation();
    const name = React.useMemo(
        () => (isNonEmptyString(props.positionSlot.name) ? props.positionSlot.name : '-'),
        [props.positionSlot]
    );

    return (
        <UpdateCell
            initialValue={props.positionSlot.name}
            mutate={async (name) =>
                positionSlotUpdate({
                    eventId,
                    positionId: props.positionSlot.position.id,
                    positionSlotId: props.positionSlot.id,
                    positionSlot: {
                        ...pick(props.positionSlot, [
                            'id',
                            'startDate',
                            'startTime',
                            'endDate',
                            'endTime',
                            'resources'
                        ]),
                        name
                    }
                })
            }
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput
                    label={translate('nom_du_cr_neau_54351')}
                    value={value}
                    onChange={setValue}
                />
            )}
        >
            <Box
                font="gray900 textSm medium"
                width={1}
                css={{
                    ellipsis: true
                }}
                title={name}
            >
                {name}
            </Box>
        </UpdateCell>
    );
};
