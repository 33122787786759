import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';

interface IInfernalContremarqueProps {
    event: DocumentUserCustomBadgeFragment;
}

export const InfernalContremarque = (props: IInfernalContremarqueProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/infernal/contremarque.jpg) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Flex
                direction="column"
                css={{
                    fontSize: '24px',
                    fontWeight: '600',
                    left: '49px',
                    lineHeight: '24px',
                    position: 'absolute',
                    top: '481px'
                }}
            >
                <Box>{ui.firstName}</Box>
                <Box>{ui.lastName}</Box>
            </Flex>

            <Box
                css={{
                    color: '#ef7d00',
                    fontSize: '22px',
                    fontWeight: '600',
                    left: '49px',
                    position: 'absolute',
                    top: '537px'
                }}
            >
                {ui.infernalPopulation} | {vr.delegation?.name}
            </Box>
        </Flex>
    );
};
