import * as React from 'react';
import { Field } from 'react-final-form';
import { IToggleSize, ToggleText as ToggleTextComponent } from '../components/toggle';

interface IToggleTextProps {
    name: string;
    size?: IToggleSize;
    children: React.ReactNode;
    subtext?: string;
}

export const ToggleText = (props: IToggleTextProps) => (
    <Field
        name={props.name}
        render={(fieldProps) => (
            <ToggleTextComponent
                size={props.size}
                subtext={props.subtext}
                value={fieldProps.input.value}
                onChange={fieldProps.input.onChange}
            >
                {props.children}
            </ToggleTextComponent>
        )}
    />
);
