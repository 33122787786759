import { Flex } from 'common/src/designSystem/components/flex';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Button } from '../button';
import { useModalContext } from './root';

interface IButtonsProps {
    children: React.ReactNode;
}

export const Buttons = ({ children }: IButtonsProps) => {
    const translate = useTranslate();
    const { onClose } = useModalContext();

    return (
        <Flex
            css={{
                borderTop: '1px solid $gray200',
                padding: '$6 $7'
            }}
            gap="3"
            justify="end"
        >
            {children}

            <Button color="white" onClick={onClose}>
                {translate('annuler_48254')}
            </Button>
        </Flex>
    );
};
