import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { useEsc } from '../../hooks/useEsc';

const ClickSpace = styled('div', {
    alignSelf: 'flex-end',
    cursor: 'pointer',
    padding: '$4 $6 $3 $6',
    i: {
        color: '$gray500'
    }
});

const CloseButton = ({ onClose }: Pick<IMobileOverlayProps, 'onClose'>) => (
        <ClickSpace onClick={onClose}>
            <i className="fa-regular fa-xmark" />
        </ClickSpace>
    );

const Content = styled(Flex, {
    overflowX: 'hidden',
    overflowY: 'auto'
});

const Background = styled(Flex, {
    background: 'rgba(0, 0, 0, 0.7)',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0'
});

const Overlay = styled(Flex, {
    background: 'white',
    borderRadius: '20px 20px 0 0',
    marginTop: '44px',
    flex: '1',
    overflow: 'auto'
});

interface IMobileOverlayProps {
    children: React.ReactNode;
    onClose: () => void;
}

export const MobileOverlay = ({ children, onClose }: IMobileOverlayProps) => {
    useEsc(onClose);

    return (
        <Background direction="column" onClick={onClose}>
            <Overlay
                direction="column"
                onClick={(event: React.MouseEvent) => {
                    event.stopPropagation();
                }}
            >
                <CloseButton onClose={onClose} />
                <Content direction="column">{children}</Content>
            </Overlay>
        </Background>
    );
};
