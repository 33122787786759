import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';
import { EmptyZone, Zone } from './zone';

interface BadgeFcg {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeFcg = (props: BadgeFcg) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const isSaison = props.event.id === 4444;
    const acronyms = vr.accreditationsUsersInfos.map(({ accreditation }) => accreditation.acronym);
    const hasCat1 = acronyms.includes('CAT 1');
    const hasCat2 = acronyms.includes('CAT 2');
    const hasCat3 = acronyms.includes('CAT 3');
    const hasCat4 = acronyms.includes('CAT 4');
    const hasCat5 = acronyms.includes('CAT 5');
    const hasCat6 = acronyms.includes('CAT 6');
    const background = isSaison
        ? 'https://assets.recrewteer.com/badges/fcg/2425/bg_badge_saison.jpg'
        : 'https://assets.recrewteer.com/badges/fcg/2425/bg_badge_match.jpg';

    return (
        <Flex
            height={563}
            width={397}
            css={{
                background: `url(${background}) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$montserrat',
                position: 'relative'
            }}
        >
            <Box
                height={80}
                width={80}
                css={{
                    border: '2px solid #004994',
                    left: '38px',
                    position: 'absolute',
                    top: '38px'
                }}
            >
                {isNonEmptyString(ui.picture?.url) && (
                    <img src={ui.picture!.url} height="100%" width="100%" />
                )}
            </Box>

            <Flex
                direction="column"
                css={{
                    color: '#00384f',
                    fontSize: '12px',
                    fontWeight: '900',
                    left: '128px',
                    lineHeight: '18px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '52px'
                }}
            >
                <Box>{ui.fields.cf10106 || ''}</Box>
                <Box>{ui.fields.firstName}</Box>
                <Box>{ui.fields.lastName}</Box>
            </Flex>

            <Box
                height={80}
                width={80}
                css={{
                    border: '2px solid #004994',
                    left: '273px',
                    padding: '$1',
                    position: 'absolute',
                    top: '38px'
                }}
            >
                {isNonEmptyString(vr.utid) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(vr.utid)}&color=5d9dcb`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>

            {!isSaison && (
                <Flex
                    align="center"
                    justify="center"
                    wrap="wrap"
                    css={{
                        color: '#00384f',
                        fontSize: '30px',
                        fontWeight: '900',
                        height: '54px',
                        left: '129px',
                        lineHeight: '24px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '183px',
                        width: '230px'
                    }}
                >
                    {props.event.name}
                </Flex>
            )}

            <Flex css={{ gap: '7px', left: '38px', position: 'absolute', top: '293px' }}>
                <Flex direction="column" gap="2">
                    {hasCat1 ? (
                        <Zone backgroundColor="#8ec89a" zoneNumber={1} zoneText="Terrain" />
                    ) : (
                        <EmptyZone />
                    )}

                    {hasCat2 ? (
                        <Zone backgroundColor="#ddca00" zoneNumber={2} zoneText="Zone mixte" />
                    ) : (
                        <EmptyZone />
                    )}

                    {hasCat3 ? (
                        <Zone backgroundColor="#cd1719" zoneNumber={3} zoneText="Espaces Media" />
                    ) : (
                        <EmptyZone />
                    )}
                </Flex>

                <Flex direction="column" gap="2">
                    {hasCat4 ? (
                        <Zone backgroundColor="#009fe3" zoneNumber={4} zoneText="Tribunes" />
                    ) : (
                        <EmptyZone />
                    )}

                    {hasCat5 ? (
                        <Zone
                            backgroundColor="#d89fc7"
                            zoneNumber={5}
                            zoneText="Espaces et Salon VIP"
                        />
                    ) : (
                        <EmptyZone />
                    )}

                    {hasCat6 ? (
                        <Zone backgroundColor="#010000" zoneNumber={6} zoneText="PC Sécurité" />
                    ) : (
                        <EmptyZone />
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};
