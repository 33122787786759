import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

const getFrontBackground = (badgeType: string) => {
    if (badgeType === 'All Access Pass') {
        return 'https://assets.recrewteer.com/badges/goldenCoast/pass_front.jpg';
    } else if (badgeType === 'All Access Pass + A') {
        return 'https://assets.recrewteer.com/badges/goldenCoast/pass_plus_front.jpg';
    } else {
        return 'https://assets.recrewteer.com/badges/goldenCoast/classique_front.jpg';
    }
};

const getBackBackground = (badgeType: string) => {
    if (badgeType === 'All Access Pass' || badgeType === 'All Access Pass + A') {
        return 'https://assets.recrewteer.com/badges/goldenCoast/pass_back.jpg';
    } else {
        return 'https://assets.recrewteer.com/badges/goldenCoast/classique_back.jpg';
    }
};

interface IBadgeGoldenCoastProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeGoldenCoast = (props: IBadgeGoldenCoastProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const color =
        ui.goldenCoastBadge === 'All Access Pass' || ui.goldenCoastBadge === 'All Access Pass + A'
            ? '#faed4f'
            : '#294999';

    return (
        <>
            <Flex
                height={317}
                width={212}
                css={{
                    background: `url(${getFrontBackground(ui.goldenCoastBadge)}) no-repeat`,
                    backgroundSize: '212px 317px',
                    fontFamily: '$neueMontreal',
                    position: 'relative'
                }}
            >
                <Box
                    height={102}
                    width={102}
                    css={{
                        borderRadius: '4px',
                        left: '55px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '105px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img src={ui.picture!.url} height="100%" width="100%" />
                    )}
                </Box>

                <Box
                    width={1}
                    textAlign="center"
                    css={{
                        color,
                        fontSize: '24px',
                        fontWeight: '700',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '218px'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Box
                    width={1}
                    textAlign="center"
                    css={{
                        color,
                        fontSize: '24px',
                        fontWeight: '700',
                        position: 'absolute',
                        top: '248px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    width={1}
                    textAlign="center"
                    css={{
                        color,
                        fontWeight: '700',
                        position: 'absolute',
                        top: '286px'
                    }}
                >
                    {ui.fields.cf33258}
                </Box>
            </Flex>

            <Flex
                height={317}
                width={212}
                css={{
                    background: `url(${getBackBackground(ui.goldenCoastBadge)}) no-repeat`,
                    backgroundSize: '212px 317px',
                    pageBreakBefore: 'always'
                }}
            />
        </>
    );
};
