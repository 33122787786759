import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { AccreditationPositionProperty, Position } from 'common/src/generated/types';
import * as React from 'react';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsAcronymCellProps {
    position: Pick<Position, 'id' | 'acronym'>;

    reload(): void;
}

export const PositionsAcronymCell = (props: IPositionsAcronymCellProps) => {
    const { translate } = useHeavent();

    return (
        <PositionsUpdateCell
            initialValue={props.position.acronym || ''}
            positionId={props.position.id}
            property={AccreditationPositionProperty.Acronym}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput label={translate('Acronym')} value={value} onChange={setValue} />
            )}
        >
            {props.position.acronym}
        </PositionsUpdateCell>
    );
};
