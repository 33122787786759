import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { DateTimeService } from '../../../services/dateTimeService';
import { useService } from '../../../util/dependencies/dependencies';
import { LocaleFormats } from '../../../util/luxon';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IExplosivContremarqueProps {
    event: DocumentUserCustomBadgeFragment;
}

export const ExplosivContremarque = (props: IExplosivContremarqueProps) => {
    const dateTimeService = useService(DateTimeService);
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const dates = uniqBy(
        sortBy(
            vr.accreditationsUsersInfos.flatMap((aui) =>
                aui.accreditationSlot.date ? [aui.accreditationSlot.date!.startOf('day')] : []
            ),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    )
        .map((d) => dateTimeService.toLocaleString(d, LocaleFormats.DateOnly.MonthShort))
        .join(' | ');

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/explosiv/contremarque.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$montserrat',
                position: 'relative'
            }}
        >
            <Box
                width={440}
                textAlign="center"
                css={{
                    fontSize: '36px',
                    fontWeight: '700',
                    left: '177px',
                    position: 'absolute',
                    top: '314px'
                }}
            >
                {ui.firstName}
            </Box>

            <Box
                width={440}
                textAlign="center"
                css={{
                    fontSize: '36px',
                    fontWeight: '700',
                    left: '177px',
                    position: 'absolute',
                    top: '362px'
                }}
            >
                {ui.lastName}
            </Box>

            <Box
                width={440}
                textAlign="center"
                css={{
                    fontSize: '32px',
                    fontWeight: '600',
                    left: '177px',
                    position: 'absolute',
                    top: '418px'
                }}
            >
                {ui.population}
            </Box>

            <Box
                width={440}
                textAlign="center"
                css={{
                    fontSize: '16px',
                    fontWeight: '700',
                    left: '177px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '468px'
                }}
            >
                {dates}
            </Box>

            <Box
                height={160}
                width={160}
                css={{
                    left: '318px',
                    position: 'absolute',
                    top: '567px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=160&width=160`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
