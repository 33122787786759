import { ColorInput } from 'common-front/src/designSystem/components/colorInput';
import { FileInput, FileInputValue } from 'common-front/src/designSystem/components/file/fileInput';
import { RichEditor } from 'common-front/src/designSystem/components/richEditor/richEditor';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accept } from 'common-front/src/util/accept';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CustomBadge,
    DocumentType,
    DocumentUserCustomBadgeFragment
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { getCustomBadgeReactNode, getDisplayName } from 'common/src/vo/customBadge';
import {
    getDefaultAsoassignmentOptions,
    getDefaultBruleursDocumentOptions,
    getDefaultPecbadgeOptions,
    getDefaultT24assignmentOptions
} from 'common/src/vo/eventDocument';
import * as React from 'react';
import { useUserCustomBadgeDocumentQuery } from '../../generated/graphqlHooks';
import { DocumentHeader } from './documentHeader';
import { DocumentOptions, useDocumentOptions } from './documentOptions';
import { DocumentsAssignedUserSelector } from './documentsAssignedUserSelector';
import { DocumentShadow } from './documentShadow';

interface IBadgeDinoContentProps {
    event: DocumentUserCustomBadgeFragment;
    logoUrl: Emptyable<string>;
}

const CustomBadgeContent = (props: IBadgeDinoContentProps) => {
    const {
        translate,
        params: { organizationId, eventId, badgeType }
    } = useHeavent();
    const [options, setOptions] = useDocumentOptions(
        props.event.document.options,
        badgeType === CustomBadge.Pec
            ? getDefaultPecbadgeOptions()
            : badgeType === CustomBadge.T24assignment
            ? getDefaultT24assignmentOptions()
            : badgeType === CustomBadge.Bruleurs
            ? getDefaultBruleursDocumentOptions()
            : getDefaultAsoassignmentOptions(),
        eventId,
        props.event.document.id
    );

    return (
        <Flex gap="7" width={1} css={{ flex: '1', overflow: 'hidden' }}>
            {badgeType === CustomBadge.Asoassignment && (
                <DocumentOptions>
                    <ColorInput
                        label={translate('Color')}
                        value={options.color}
                        onChange={(color: string) => {
                            setOptions({ color });
                        }}
                    />

                    <FileInput
                        accept={Accept.Images}
                        format="base64"
                        label={translate('logo_18191')}
                        placeholder={translate('logo_18191')}
                        value={options.logo}
                        onChange={(logo: FileInputValue) => {
                            setOptions({ logo });
                        }}
                    />

                    <FileInput
                        accept={Accept.Images}
                        format="base64"
                        label={translate('image_de_fond_74511')}
                        placeholder={translate('image_de_fond_74511')}
                        value={options.background}
                        onChange={(background: FileInputValue) => {
                            setOptions({ background });
                        }}
                    />

                    <RichEditor
                        label={translate('texte_d_introdu_66076')}
                        initialValue={options.introText}
                        onChange={(introText) => {
                            setOptions({ introText });
                        }}
                    />

                    <RichEditor
                        label={translate('texte_de_conclu_27981')}
                        initialValue={options.outroText}
                        onChange={(outroText) => {
                            setOptions({ outroText });
                        }}
                    />

                    <RichEditor
                        label={translate('signature_67521')}
                        initialValue={options.signature}
                        onChange={(signature) => {
                            setOptions({ signature });
                        }}
                    />
                </DocumentOptions>
            )}

            {badgeType === CustomBadge.Pec && (
                <DocumentOptions>
                    <FileInput
                        accept={Accept.Images}
                        format="base64"
                        label={translate('banni_re_86634')}
                        placeholder={translate('banni_re_86634')}
                        value={options.header}
                        onChange={(header: FileInputValue) => {
                            setOptions({ header });
                        }}
                    />
                </DocumentOptions>
            )}

            {badgeType === CustomBadge.T24assignment && (
                <DocumentOptions>
                    <FileInput
                        accept={Accept.Images}
                        format="base64"
                        label={translate('logo_18191')}
                        placeholder={translate('logo_18191')}
                        value={options.logo}
                        onChange={(logo: FileInputValue) => {
                            setOptions({ logo });
                        }}
                    />
                </DocumentOptions>
            )}

            {badgeType === CustomBadge.Bruleurs && (
                <DocumentOptions>
                    <FileInput
                        accept={Accept.Images}
                        format="base64"
                        label={translate('logo_18191')}
                        placeholder={translate('logo_18191')}
                        value={options.logo}
                        onChange={(logo: FileInputValue) => {
                            setOptions({ logo });
                        }}
                    />
                </DocumentOptions>
            )}

            <Flex direction="column" css={{ flex: '1' }}>
                <DocumentsAssignedUserSelector
                    getPath={(newUserInfoId) =>
                        HeaventPaths.DOCUMENTS_BADGE(
                            organizationId,
                            eventId,
                            newUserInfoId,
                            badgeType
                        )
                    }
                />

                <DocumentShadow>
                    {getCustomBadgeReactNode(badgeType, props.event, props.logoUrl, options)}
                </DocumentShadow>
            </Flex>
        </Flex>
    );
};

export const UserCustomBadge = () => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId, badgeType }
    } = useHeavent();
    const { data, loader } = useUserCustomBadgeDocumentQuery({
        organizationId,
        eventId,
        userInfoId,
        customBadge: badgeType
    });

    return (
        <Flex direction="column" height={1} width={1} css={{ padding: '$7' }}>
            <DocumentHeader
                badgeType={badgeType}
                documentType={DocumentType.CustomBadge}
                eventId={eventId}
                languages={data.event?.languages ?? []}
                organizationId={organizationId}
                segments={data.event?.segments ?? []}
                showStrategy={true}
                title={translate('badge_1_89709', getDisplayName(badgeType))}
            />

            <Spacer height="7" />

            {loader || (
                <CustomBadgeContent
                    event={data.event}
                    logoUrl={data.organization.membersSpaceDesign?.logo?.url}
                />
            )}
        </Flex>
    );
};
