import { Empty } from 'common-front/src/components/empty/empty';
import { NoFeature } from 'common-front/src/components/noFeature';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Feature } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
    CreateOrganizationCampaign,
    UpdateOrganizationCampaign
} from '../campaigns/create/createUpdateCampaign';
import { OrganizationCampaign } from '../campaigns/show/campaign';
import {
    CreateOrganizationDelegation,
    UpdateOrganizationDelegation
} from '../delegations/create/createUpdateDelegation';
import { Delegation } from '../delegations/show/delegation';
import { FilesImport } from '../files/import/filesImport';
import { OrganizationForms } from '../forms/list/forms';
import { OrganizationForm } from '../forms/show/form';
import { OrganizationComponent } from '../organization/organizationComponent';
import { useOrganizationContext } from '../organization/organizationContext';
import { UpdateUserLoaderOrganization } from '../users/update/updateUserLoader';
import { VolunteersImport } from '../volunteers/import/volunteersImport';
import { CommunityCampaignsLoader } from './campaigns/list/communityCampaignsLoader';
import { CommunityContainer } from './communityContainer';
import { CommunityDashboard } from './dashboard/communityDashboard';
import { CommunityDelegationsHasFeature } from './delegations/list/communityDelegationsHasFeature';
import { CommunityUsersLoader } from './users/communityUsersLoader';
import { CommunityUsersDuplicates } from './usersDuplicates/communityUsersDuplicates';

export const Community = () => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const { hasFeature } = useOrganizationContext();

    return hasFeature(Feature.Community) ? (
        <Switch>
            <Route
                path={CommunityPaths.COMMUNITY_DASHBOARD(':organizationId')}
                children={
                    <CommunityContainer>
                        <CommunityDashboard />
                    </CommunityContainer>
                }
            />

            <Route
                path={HeaventPaths.COMMUNITY_USERS_DUPLICATES(':organizationId')}
                children={<CommunityUsersDuplicates />}
            />

            <Route
                path={CommunityPaths.COMMUNITY_USERS(':organizationId')}
                children={
                    <CommunityContainer>
                        <CommunityUsersLoader />
                    </CommunityContainer>
                }
            />

            <Route
                path={[
                    HeaventPaths.COMMUNITY_EDIT_USER(':organizationId', ':userInfoId'),
                    HeaventPaths.COMMUNITY_EDIT_USER_DELEGATION(
                        ':organizationId',
                        ':delegationId',
                        ':userInfoId'
                    )
                ]}
                children={
                    <OrganizationComponent>
                        <UpdateUserLoaderOrganization />
                    </OrganizationComponent>
                }
            />

            <Route
                path={HeaventPaths.COMMUNITY_CREATE_CAMPAIGN(':organizationId', ':options?')}
                children={<CreateOrganizationCampaign />}
            />

            <Route
                path={HeaventPaths.COMMUNITY_EDIT_CAMPAIGN(':organizationId', ':campaignId')}
                children={<UpdateOrganizationCampaign />}
            />

            <Route
                path={HeaventPaths.COMMUNITY_CAMPAIGNS(':organizationId')}
                children={
                    <CommunityContainer>
                        <CommunityCampaignsLoader />
                    </CommunityContainer>
                }
            />

            <Route
                path={HeaventPaths.COMMUNITY_CAMPAIGN(':organizationId', ':campaignId')}
                children={
                    <CommunityContainer>
                        <OrganizationCampaign />
                    </CommunityContainer>
                }
            />

            <Route
                path={HeaventPaths.COMMUNITY_FORMS(':organizationId')}
                children={
                    <CommunityContainer>
                        <OrganizationForms />
                    </CommunityContainer>
                }
            />

            <Route
                path={HeaventPaths.COMMUNITY_FORM(':organizationId', ':formId')}
                children={<OrganizationForm />}
            />

            <Route
                path={HeaventPaths.COMMUNITY_VOLUNTEERS_IMPORT(':organizationId')}
                children={
                    <VolunteersImport fromPath={CommunityPaths.COMMUNITY_USERS(organizationId)} />
                }
            />

            <Route
                path={HeaventPaths.COMMUNITY_FILES_IMPORT(':organizationId')}
                children={<FilesImport fromPath={CommunityPaths.COMMUNITY_USERS(organizationId)} />}
            />

            {hasFeature(Feature.Delegation) && (
                <>
                    <Route
                        path={HeaventPaths.COMMUNITY_CREATE_DELEGATION(':organizationId')}
                        children={<CreateOrganizationDelegation />}
                    />

                    <Route
                        path={HeaventPaths.COMMUNITY_EDIT_DELEGATION(
                            ':organizationId',
                            ':delegationId'
                        )}
                        children={<UpdateOrganizationDelegation />}
                    />

                    <Route
                        path={HeaventPaths.COMMUNITY_DELEGATIONS(':organizationId')}
                        children={
                            <CommunityContainer>
                                <CommunityDelegationsHasFeature />
                            </CommunityContainer>
                        }
                    />

                    <Route
                        path={HeaventPaths.COMMUNITY_DELEGATION(':organizationId', ':delegationId')}
                        children={
                            <CommunityContainer>
                                <Delegation />
                            </CommunityContainer>
                        }
                    />
                </>
            )}

            <Route
                children={
                    <Empty
                        path={CommunityPaths.COMMUNITY_DASHBOARD(organizationId)}
                        replace={true}
                    />
                }
            />
        </Switch>
    ) : (
        <CommunityContainer>
            <Flex align="center" justify="center" height={1} width={1}>
                <NoFeature
                    icon="crown"
                    subtitle={translate('contactez_nous_69860')}
                    title={translate('pour_en_profite_62789')}
                />
            </Flex>
        </CommunityContainer>
    );
};
