import * as React from 'react';
import { Flex } from '../flex';
import { CSS } from '../stitches';
import { useTableContext } from './tableContext';

interface IRowProps {
    css?: CSS;
    children: React.ReactNode;

    onClick?(): void;
    onMouseLeave?(e: React.MouseEvent<HTMLDivElement>): void;
}

export const Row = (props: IRowProps) => {
    const { setClickedRowIndex } = useTableContext();

    return (
        <Flex
            className="table-row"
            align="center"
            css={{
                background: 'white',
                borderBottom: '1px solid $gray200',
                borderLeft: '1px solid $gray200',
                borderRight: '1px solid $gray200',
                height: '64px',
                width: '100%',
                ...(props.css as any),
                '&:hover .dot-dot-dot': {
                    display: 'block'
                },
                '&:hover .h-controls': {
                    display: 'flex'
                }
            }}
            onClick={() => {
                props.onClick?.();
            }}
            onMouseLeave={(e: React.MouseEvent<any>) => {
                setClickedRowIndex(null);

                props.onMouseLeave?.(e);
            }}
        >
            {props.children}
        </Flex>
    );
};
