import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import {
    PositionId,
    PositionsPositionSlotFragment,
    PositionsSlotId,
    PositionsSlotsSortAttributes,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { isNonEmptyString } from 'common/src/util/string';
import { PositionDefaultColumns } from 'common/src/vo/segments/positionsSegmentsService';
import { compact } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import {
    executePositionsPositionSlotQuery,
    usePositionsSlotsQuery
} from '../../generated/graphqlHooks';
import { PositionSlotRow } from './positionSlotRow';

interface IPositionsSlotsListProps {
    customFields: SegmentCustomFieldFragment[];
}

export const PositionsSlotsList = (props: IPositionsSlotsListProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const { isPositionCategoryAdmin } = useEventContext();
    const {
        columns,
        columnsSearch,
        limit,
        nameDebounced,
        offset,
        possibleColumns,
        predicates,
        reloadKey,
        setLimit,
        setOffset,
        setSort,
        sort
    } = useSegmentsContext<PositionId, PositionsSlotsSortAttributes>();
    const includes = React.useMemo(
        () => ({
            includeCheckedInRate: columns.includes(PositionDefaultColumns.CheckedInRate),
            includeConditions: columns.includes(PositionDefaultColumns.Conditions),
            includeCreatedBy: columns.includes(PositionDefaultColumns.CreatedBy),
            includeTags: columns.includes(PositionDefaultColumns.Tags)
        }),
        [columns]
    );
    const { data, isLoading, reload } = usePositionsSlotsQuery({
        ...includes,
        eventId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        columnsSearch: isNonEmptyArray(columnsSearch) ? columnsSearch : null,
        predicates,
        limit,
        offset,
        sort
    });
    const selectedFields = React.useMemo(
        () => columns.flatMap((slug) => compact([possibleColumns.find((pc) => pc.slug === slug)])),
        [possibleColumns, columns]
    );
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.positionsSlots);
    const [positionsSlots, setPositionsSlots] = React.useState<PositionsPositionSlotFragment[]>([]);
    const [idToLoading, setIdToLoading] = React.useState<Record<PositionsSlotId, boolean>>({});
    const reloadPositionSlot = React.useCallback(
        async (id: PositionsSlotId) => {
            setIdToLoading((ids) => ({ ...ids, [id]: true }));

            const {
                event: { positionSlot }
            } = await executePositionsPositionSlotQuery(
                {
                    ...includes,
                    eventId,
                    id
                },
                await getToken()
            );

            setPositionsSlots((pss) => pss.map((ps) => (ps.id === id ? positionSlot : ps)));
            setIdToLoading((ids) => ({ ...ids, [id]: false }));
        },
        [includes, setIdToLoading, setPositionsSlots]
    );

    React.useEffect(() => {
        setPositionsSlots(data.event?.positionsSlots.nodes ?? []);
    }, [data.event]);

    React.useEffect(() => {
        if (reloadKey > 1) {
            reload();
        }
    }, [reloadKey]);

    return (
        <Flex width={1} css={{ flex: '1', overflow: 'hidden' }}>
            <TableGrid
                headerRow={
                    <HeaderRow>
                        {selectedFields.map((field) =>
                            field.slug === PositionDefaultColumns.Id ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    width={100}
                                    attribute={PositionsSlotsSortAttributes.Id}
                                    sort={sort}
                                    setSort={setSort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === PositionDefaultColumns.Category ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={PositionsSlotsSortAttributes.Category}
                                    sort={sort}
                                    setSort={setSort}
                                >
                                    {translate('cat_gorie_00291')}
                                </HeaderCellSort>
                            ) : field.slug === PositionDefaultColumns.Name ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={PositionsSlotsSortAttributes.Position}
                                    sort={sort}
                                    setSort={setSort}
                                >
                                    {translate('nom_de_la_missi_64605')}
                                </HeaderCellSort>
                            ) : field.slug === PositionDefaultColumns.Slot ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={PositionsSlotsSortAttributes.Range}
                                    sort={sort}
                                    setSort={setSort}
                                >
                                    {translate('cr_neau_11117')}
                                </HeaderCellSort>
                            ) : field.slug === PositionDefaultColumns.SlotName ? (
                                <HeaderCellSort
                                    key={field.name}
                                    attribute={PositionsSlotsSortAttributes.Name}
                                    sort={sort}
                                    setSort={setSort}
                                >
                                    {translate('nom_du_cr_neau_54351')}
                                </HeaderCellSort>
                            ) : field.slug === PositionDefaultColumns.Resources ? (
                                <HeaderCell key={field.slug} width={256}>
                                    {translate('membres_11310')}
                                </HeaderCell>
                            ) : field.slug === PositionDefaultColumns.Icon ? (
                                <HeaderCell key={field.slug} width={100}>
                                    {field.name}
                                </HeaderCell>
                            ) : field.slug === PositionDefaultColumns.Acronym ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={PositionsSlotsSortAttributes.Acronym}
                                    sort={sort}
                                    setSort={setSort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : (
                                <HeaderCell key={field.slug}>
                                    <Box title={field.name} css={{ ellipsis: true }}>
                                        {field.name}
                                    </Box>
                                </HeaderCell>
                            )
                        )}

                        {isPositionCategoryAdmin() && <HeaderCell width={100} />}
                    </HeaderRow>
                }
                paginationRow={
                    <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                        <PaginationCell
                            limit={limit}
                            numberOfPages={numberOfPages}
                            offset={offset}
                            showLimits={true}
                            totalCount={totalCount}
                            setLimit={setLimit}
                            setOffset={setOffset}
                        />
                    </PaginationRow>
                }
            >
                {isLoading ? (
                    <>
                        <RowSkeleton />
                        <RowSkeleton />
                        <RowSkeleton />
                    </>
                ) : (
                    positionsSlots.map((positionSlot) => {
                        if (idToLoading[positionSlot.id]) {
                            return <RowSkeleton key={positionSlot.id} />;
                        } else {
                            return (
                                <PositionSlotRow
                                    key={positionSlot.id}
                                    columns={selectedFields}
                                    customFields={props.customFields}
                                    event={data.event}
                                    positionSlot={positionSlot}
                                    reloadPositionSlot={reloadPositionSlot}
                                />
                            );
                        }
                    })
                )}
            </TableGrid>
        </Flex>
    );
};
