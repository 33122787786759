import { DateInput } from 'common-front/src/designSystem/components/date/dateInput';
import {
    OrganizationId,
    SegmentCustomFieldFragment,
    UserInfoCommunityFragment
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import { DateTime } from 'luxon';
import * as React from 'react';
import { UserInfoUpdateCell } from '../../volunteers/list/list/volunteerRegistrationUpdateCell';

interface ICommunityUserDateCellProps {
    customField: SegmentCustomFieldFragment;
    field: PossibleColumn;
    organizationId: OrganizationId;
    userInfo: UserInfoCommunityFragment;

    reload(): void;
}

export const CommunityUserDateCell = (props: ICommunityUserDateCellProps) => {
    const fieldService = useService(FieldService);
    const valueString = fieldService.getValueString(props.customField, props.userInfo);
    const initialValue = React.useMemo(() => {
        const value = fieldService.getValue<DateTime | string>(props.customField, props.userInfo);

        if (isNonEmptyString(value)) {
            return DateTime.fromISO(value, { zone: 'utc' });
        } else {
            return value;
        }
    }, []);

    return (
        <UserInfoUpdateCell
            customField={props.customField}
            field={props.field}
            initialValue={initialValue as any}
            organizationId={props.organizationId}
            valueString={valueString}
            userInfoId={props.userInfo.id}
            reload={props.reload}
        >
            {(value, setValue) => (
                <DateInput label={props.customField.name} value={value} onChange={setValue} />
            )}
        </UserInfoUpdateCell>
    );
};
