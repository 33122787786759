import { FormId, VolunteerRegistrationFragment } from '../generated/types';
import { DateTimeService } from '../services/dateTimeService';
import { LocaleFormats } from '../util/luxon';

export function getFormsInsertedAt(
    dateTimeService: DateTimeService,
    formsUsersInfos: VolunteerRegistrationFragment['userInfo']['formsUsersInfos']
): string {
    return formsUsersInfos
        .flatMap((fui) => {
            if (fui.form) {
                const name = fui.form.name;
                const insertedAt = dateTimeService.toLocaleString(
                    fui.insertedAt.toLocal(),
                    LocaleFormats.DateTime
                );

                return [`${name} : ${insertedAt}`];
            } else {
                return [];
            }
        })
        .join(', ');
}

export function getFormInsertedAt(
    dateTimeService: DateTimeService,
    slug: string,
    formsUsersInfos: VolunteerRegistrationFragment['userInfo']['formsUsersInfos']
): string {
    if (slug.startsWith('form_inserted_at')) {
        const formId = parseInt(slug.substring(17), 10) as FormId;

        if (!isNaN(formId)) {
            const fui = formsUsersInfos.find(({ form }) => form?.id === formId);

            return fui
                ? dateTimeService.toLocaleString(fui.insertedAt.toLocal(), LocaleFormats.DateTime)
                : '';
        } else {
            return '';
        }
    } else {
        return '';
    }
}
