import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IContentProps {
    children: React.ReactNode;
}

export const Content = ({ children }: IContentProps) => (
    <Flex
        css={{
            borderTop: '1px solid $gray200',
            padding: '$5 $7 $7 $7'
        }}
        direction="column"
    >
        {children}
    </Flex>
);
