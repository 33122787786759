import { Box } from 'common/src/designSystem/components/box';
import {
    AccreditationId,
    AccreditationPositionProperty,
    AccreditationsCategory
} from 'common/src/generated/types';
import * as React from 'react';
import { AccreditationsCategoryCellInput } from './accreditationsCategoryCellInput';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsCategoryCellProps {
    accreditationId: AccreditationId;
    accreditationCategory: Pick<AccreditationsCategory, 'id' | 'name'>;

    reload(): void;
}

export const AccreditationsCategoryCell = (props: IAccreditationsCategoryCellProps) => (
    <AccreditationsUpdateCell
        accreditationId={props.accreditationId}
        initialValue={props.accreditationCategory.id}
        property={AccreditationPositionProperty.Category}
        reload={props.reload}
        renderInput={(value, setValue) => (
            <AccreditationsCategoryCellInput value={value} setValue={setValue} />
        )}
    >
        <Box
            font="gray900 textSm medium"
            width={1}
            css={{
                ellipsis: true
            }}
            title={props.accreditationCategory.name}
        >
            {props.accreditationCategory.name}
        </Box>
    </AccreditationsUpdateCell>
);
