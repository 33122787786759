import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { RichText } from '../../../designSystem/components/richEditor/richText';
import { Spacer } from '../../../designSystem/components/spacer';
import { DocumentUserCustomBadgeFragment, EventId } from '../../../generated/types';
import { useEnvVars } from '../../../util/dependencies/dependencies';
import { googleMapsHref } from '../../../util/googleMaps';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IParis20KmAssignmentPageProps {
    eventId: EventId;
    psuis: DocumentUserCustomBadgeFragment['volunteerRegistration']['positionsSlotsUsersInfos'];
    userInfo: DocumentUserCustomBadgeFragment['volunteerRegistration']['userInfo'];
}

export const Paris20KmAssignmentPage = (props: IParis20KmAssignmentPageProps) => {
    const { HEAVENT_EXTERNAL_DOMAIN } = useEnvVars();
    const position = props.psuis[0].position;
    const slots = sortBy(
        props.psuis.map(({ positionSlot }) => positionSlot),
        (slot) => slot.range.start!.toMillis()
    );
    const ui = props.userInfo;
    const leader = position.leaders[0];
    const leader2 = position.leaders[1];

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/paris20km/2024/assignment.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$avenir',
                fontWeight: '500',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
        >
            <Box
                height={74}
                width={74}
                css={{
                    borderRadius: '5px',
                    left: '67px',
                    overflow: 'hidden',
                    position: 'absolute',
                    top: '212px'
                }}
            >
                {isNonEmptyString(ui.picture?.url) && (
                    <img src={ui.picture!.url} height="100%" width="100%" />
                )}
            </Box>

            <Box
                width={155}
                textAlign="center"
                css={{
                    color: 'white',
                    fontSize: '15px',
                    fontWeight: '900',
                    left: '27px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '295px'
                }}
            >
                {ui.firstName}
            </Box>

            {leader && (
                <Flex
                    direction="column"
                    align="center"
                    width={185}
                    css={{
                        color: 'white',
                        gap: '2px',
                        fontSize: '16px',
                        fontWeight: '900',
                        left: '12px',
                        position: 'absolute',
                        top: '815px'
                    }}
                >
                    <Box>{leader.userInfo.firstName}</Box>
                    <Box>{leader.userInfo.lastName}</Box>
                    <Box>{leader.userInfo.phone?.internationalFormat}</Box>
                </Flex>
            )}

            {leader2 && (
                <Flex
                    direction="column"
                    align="center"
                    width={185}
                    css={{
                        color: 'white',
                        gap: '2px',
                        fontSize: '16px',
                        fontWeight: '900',
                        left: '12px',
                        position: 'absolute',
                        top: '932px'
                    }}
                >
                    <Box>{leader2.userInfo.firstName}</Box>
                    <Box>{leader2.userInfo.lastName}</Box>
                    <Box>{leader2.userInfo.phone?.internationalFormat}</Box>
                </Flex>
            )}

            <Flex
                direction="column"
                width={584}
                css={{
                    left: '210px',
                    padding: '24px 40px 0 40px',
                    position: 'absolute',
                    top: '170px'
                }}
            >
                <Flex
                    align="center"
                    justify="center"
                    height={38}
                    width={1}
                    css={{
                        color: '#d7531e',
                        fontSize: '18px',
                        fontWeight: '900',
                        textTransform: 'uppercase'
                    }}
                >
                    {position.name}
                </Flex>

                <Spacer height="5" />

                <Box css={{ fontSize: '16px', fontWeight: '900' }}>Date de ta mission :</Box>

                <Spacer height="2" />

                <Flex direction="column" gap="1">
                    {slots.map((slot) => (
                        <Box css={{ color: '#333333' }}>
                            {slot.range.start!.toFormat('d MMMM yyyy hh:mm', { locale: 'fr' })} -{' '}
                            {slot.range.end!.toFormat('d MMMM yyyy hh:mm', { locale: 'fr' })}
                        </Box>
                    ))}
                </Flex>

                <Spacer height="6" />

                <Box css={{ fontSize: '16px', fontWeight: '900' }}>Détails de ta mission :</Box>

                <Spacer height="2" />

                {isNonEmptyString(position.description) && (
                    <>
                        <Box
                            css={{
                                color: 'black',
                                '& .h-rich-text > p': {
                                    margin: '0'
                                }
                            }}
                        >
                            <RichText text={position.description} />
                        </Box>
                    </>
                )}

                <Spacer height="6" />

                <Box css={{ fontSize: '16px', fontWeight: '900' }}>Lieu de rendez-vous :</Box>

                <Spacer height="2" />

                <Box>{position.address}</Box>

                <Spacer height="2" />

                <Box height={180} width={1}>
                    <a
                        href={googleMapsHref(position.latitude!, position.longitude!)}
                        style={{
                            background: `url(https://api.${HEAVENT_EXTERNAL_DOMAIN}/image/event/${props.eventId}/position/${position.id}.png)`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            display: 'block',
                            height: '100%',
                            width: '100%'
                        }}
                    />
                </Box>

                {isNonEmptyString(position.fields.cf38416) && (
                    <>
                        <Spacer height="6" />

                        <Box css={{ fontSize: '16px', fontWeight: '900' }}>En complément :</Box>

                        <Spacer height="2" />

                        <Box>{position.fields.cf38416}</Box>
                    </>
                )}
            </Flex>
        </Flex>
    );
};
