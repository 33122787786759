import { Button } from 'common-front/src/designSystem/components/button';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Title } from 'common-front/src/designSystem/components/dropdown/title';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger as TooltipTrigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import {
    Feature,
    UsersInfoId,
    VolunteersRegistrationsSortAttributes
} from 'common/src/generated/types';
import * as React from 'react';
import {
    useUsersInfosMassEditMutation,
    useVolunteerActionsQuery,
    useVolunteersRegistrationsMassDeleteMutation,
    useVolunteersRegistrationsMassWeezeventSynchronizeMutation
} from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { MassAccreditModal } from '../massAccredit/massAccreditModal';
import { MassAccreditationStateModal } from '../massAccreditationStateUpdate/massAccreditationStateModal';
import { MassAssignModal } from '../massAssign/massAssignModal';
import { MassCampaignSendModal } from '../massCampaignSend/massCampaignSendModal';
import { MassDelegationAddModal } from '../massDelegationAdd/massDelegationAddModal';
import { MassEditModal } from '../massEdit/massEditModal';
import { MassFormAddModal } from '../massFormAdd/massFormAddModal';
import { MassFormRemoveModal } from '../massFormRemove/massFormRemoveModal';
import { MassPreAssignModal } from '../massPreAssign/massPreAssignModal';
import { MassStateUpdateModal } from '../massStateUpdate/massStateUpdateModal';

export const VolunteersActions = () => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const { hasFeature } = useOrganizationContext();
    const {
        isSegmentSelected,
        massEditSelectedIds,
        massEditSegmentId,
        numberOfSelected,
        onMassEditSuccess,
        organizationId,
        segment
    } = useSegmentsContext<UsersInfoId, VolunteersRegistrationsSortAttributes>();
    const { mutate: usersInfosMassEdit } = useUsersInfosMassEditMutation();
    const { mutate: volunteersRegistrationsMassDelete } =
        useVolunteersRegistrationsMassDeleteMutation();
    const { mutate: volunteersRegistrationsMassWeezeventSynchronize } =
        useVolunteersRegistrationsMassWeezeventSynchronizeMutation();
    const { data } = useVolunteerActionsQuery({ eventId: eventId! });
    const [isMassEditVisible, setIsMassEditVisible] = React.useState(false);
    const [isMassStateUpdatevisible, setIsMassStateUpdatevisible] = React.useState(false);
    const [isMassAffectVisible, setIsMassAffectVisible] = React.useState(false);
    const [isMassAccreditVisible, setIsMassAccreditVisible] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [isMassPreAffectVisible, setIsMassPreAffectVisible] = React.useState(false);
    const [isMassAccreditationStateVisible, setIsMassAccreditationStateVisible] =
        React.useState(false);
    const [isMassDelegationAddVisible, setIsMassDelegationAddVisible] = React.useState(false);
    const [isMassFormAddVisible, setIsMassFormAddVisible] = React.useState(false);
    const [isMassFormRemoveVisible, setIsMassFormRemoveVisible] = React.useState(false);
    const [isMassCampaignSendVisible, setIsMassCampaignSendVisible] = React.useState(false);
    const finalNumberOfSelected = isSegmentSelected ? segment.count : numberOfSelected;

    return (
        <>
            <Button
                onClick={() => {
                    setIsMassEditVisible(true);
                }}
            >
                {translate('_diter_un_champ_64621')}
            </Button>

            <Tooltip>
                <TooltipTrigger>
                    <Box>
                        <Button
                            color="white"
                            leftIcon="wand-magic-sparkles"
                            onClick={() => {
                                setIsMassAffectVisible(true);
                            }}
                        />
                    </Box>
                </TooltipTrigger>

                <Content placement="top">
                    {translate('affecter_1_me_24704', finalNumberOfSelected)}
                </Content>
            </Tooltip>

            {hasFeature(Feature.Accreditation) && (
                <Tooltip>
                    <TooltipTrigger>
                        <Box>
                            <Button
                                color="white"
                                leftIcon="badge-check"
                                onClick={() => {
                                    setIsMassAccreditVisible(true);
                                }}
                            />
                        </Box>
                    </TooltipTrigger>

                    <Content placement="top">
                        {translate('accr_diter_1_13773', finalNumberOfSelected)}
                    </Content>
                </Tooltip>
            )}

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <Title>{translate('affectation_plural')}</Title>

                    <ItemIcon
                        icon="wand-magic-sparkles"
                        onClick={() => {
                            setIsMassStateUpdatevisible(true);
                        }}
                    >
                        {translate('changer_le_stat_39694')}
                    </ItemIcon>

                    <ItemIcon
                        icon="wand-magic-sparkles"
                        onClick={() => {
                            setIsMassPreAffectVisible(true);
                        }}
                    >
                        {translate('pr_affecter_1_62649', finalNumberOfSelected)}
                    </ItemIcon>

                    <Separator direction="horizontal" color="gray100" />

                    <Title>{translate('accr_ditations_39450')}</Title>

                    {hasFeature(Feature.Accreditation) && (
                        <ItemIcon
                            icon="badge-check"
                            onClick={() => {
                                setIsMassAccreditationStateVisible(true);
                            }}
                        >
                            {translate('changer_le_stat_27596')}
                        </ItemIcon>
                    )}

                    {hasFeature(Feature.Delegation) && (
                        <ItemIcon
                            icon="people-group"
                            isDisabled={!data.event?.hasDelegations}
                            onClick={() => {
                                setIsMassDelegationAddVisible(true);
                            }}
                        >
                            {translate('ajouter_1_mem_86218', finalNumberOfSelected)}
                        </ItemIcon>
                    )}

                    <Separator direction="horizontal" color="gray100" />

                    <Title>{translate('communication_05277')}</Title>

                    <ItemIcon
                        icon="file-lines"
                        isDisabled={!data.event?.hasForms}
                        onClick={() => {
                            setIsMassFormAddVisible(true);
                        }}
                    >
                        {translate('ajouter_1_mem_52947', finalNumberOfSelected)}
                    </ItemIcon>

                    <ItemIcon
                        icon="file-lines"
                        isDisabled={!data.event?.hasForms}
                        onClick={() => {
                            setIsMassFormRemoveVisible(true);
                        }}
                    >
                        {translate('marquer_un_form_10633')}
                    </ItemIcon>

                    <ItemIcon
                        icon="paper-plane"
                        onClick={() => {
                            setIsMassCampaignSendVisible(true);
                        }}
                    >
                        {translate('envoyer_une_cam_94630')}
                    </ItemIcon>

                    <Separator direction="horizontal" color="gray100" />

                    {hasFeature(Feature.Weezevent) && (
                        <>
                            <Title>{translate('int_gration_10662')}</Title>

                            <ItemIcon
                                icon="code-branch"
                                isDisabled={!data.event?.weezeventConnectionInfos}
                                onClick={async () => {
                                    await volunteersRegistrationsMassWeezeventSynchronize({
                                        eventId: eventId!,
                                        segmentId: massEditSegmentId,
                                        usersInfosIds: massEditSelectedIds
                                    });
                                }}
                            >
                                {translate('synchroniser_av_82451')}
                            </ItemIcon>

                            <Separator direction="horizontal" color="gray100" />
                        </>
                    )}

                    <ItemIcon
                        icon="trash-can"
                        color="red"
                        onClick={() => {
                            setIsDeleteModalOpen(true);
                        }}
                    >
                        {translate('d_sinscrire_de_06416')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isMassEditVisible && (
                <MassEditModal
                    organizationId={organizationId}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    mutate={(massEdit) =>
                        usersInfosMassEdit({
                            eventId: eventId!,
                            massEdit
                        })
                    }
                    onClose={() => {
                        setIsMassEditVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isMassStateUpdatevisible && (
                <MassStateUpdateModal
                    eventId={eventId!}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassStateUpdatevisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isMassAffectVisible && (
                <MassAssignModal
                    eventId={eventId!}
                    numberOfSelected={finalNumberOfSelected}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassAffectVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isMassAccreditVisible && (
                <MassAccreditModal
                    eventId={eventId!}
                    numberOfSelected={finalNumberOfSelected}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassAccreditVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isMassPreAffectVisible && (
                <MassPreAssignModal
                    eventId={eventId!}
                    numberOfSelected={finalNumberOfSelected}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassPreAffectVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isDeleteModalOpen && (
                <DeleteModal
                    text={translate('d_sinscription_66971', finalNumberOfSelected)}
                    subtext={[translate('_tes_vous_s_r_d_46029')]}
                    buttonText={translate('d_sinscrire_27046')}
                    onDelete={async () => {
                        await volunteersRegistrationsMassDelete({
                            eventId: eventId!,
                            segmentId: massEditSegmentId,
                            usersInfosIds: massEditSelectedIds
                        });

                        onMassEditSuccess();
                    }}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                    }}
                />
            )}

            {isMassAccreditationStateVisible && (
                <MassAccreditationStateModal
                    eventId={eventId!}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassAccreditationStateVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isMassFormAddVisible && (
                <MassFormAddModal
                    eventId={eventId!}
                    organizationId={organizationId}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassFormAddVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isMassFormRemoveVisible && (
                <MassFormRemoveModal
                    eventId={eventId!}
                    organizationId={organizationId}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassFormRemoveVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isMassDelegationAddVisible && (
                <MassDelegationAddModal
                    eventId={eventId!}
                    organizationId={organizationId}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassDelegationAddVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isMassCampaignSendVisible && (
                <MassCampaignSendModal
                    eventId={eventId}
                    organizationId={organizationId}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassCampaignSendVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}
        </>
    );
};
