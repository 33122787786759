import * as React from 'react';

interface IModalContext {
    isOpen: boolean;

    onClose(): void;
    setIsOpen(isOpen: boolean): void;
}

export const ModalContext = React.createContext<IModalContext>({} as any);

export function useModalContext() {
    return React.useContext(ModalContext);
}

interface IRootProps {
    children: React.ReactNode;
}

export const Root = ({ children }: IRootProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const onClose = React.useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <ModalContext.Provider
            value={{
                isOpen,
                onClose,
                setIsOpen
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};
