import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { FormsElementId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { AddSectionModal } from './addSectionModal';

interface IFormSectionProps {
    elementId: FormsElementId;
    index: number;
    name: string;

    onDelete(index: number): void;
    onRename(index: number, name: string): void;
}

export const FormSection = (props: IFormSectionProps) => {
    const translate = useTranslate();
    const [isRenameOpen, setIsRenameOpen] = React.useState(false);

    return (
        <>
            <Draggable draggableId={props.elementId} index={props.index}>
                {(draggableProvided: DraggableProvided) => (
                        <Flex
                            align="center"
                            gap="5"
                            css={{
                                background: '$gray900',
                                bd: 'gray900 2',
                                color: 'white',
                                marginBottom: '$2',
                                marginTop: '$5',
                                padding: '$4 $6'
                            }}
                            {...draggableProvided.draggableProps}
                            ref={draggableProvided.innerRef}
                        >
                            <Box
                                color="gray300"
                                fontSize="textMd"
                                css={{ cursor: 'grab' }}
                                {...draggableProvided.dragHandleProps}
                            >
                                <I icon="grip-vertical" iconStyle="solid" />
                            </Box>

                            <Box fontWeight="semiBold" css={{ flex: '1' }}>
                                {props.name}
                            </Box>

                            <Dropdown>
                                <Trigger>
                                    <Flex
                                        align="center"
                                        justify="center"
                                        height={20}
                                        width={20}
                                        css={{
                                            cursor: 'pointer',
                                            fontSize: '$textMd'
                                        }}
                                    >
                                        <I icon="ellipsis-vertical" />
                                    </Flex>
                                </Trigger>

                                <Menu placement="bottom-end">
                                    <ItemIcon
                                        icon="pen"
                                        onClick={() => {
                                            setIsRenameOpen(true);
                                        }}
                                    >
                                        {translate('renommer_42135')}
                                    </ItemIcon>

                                    <ItemIcon
                                        icon="trash-can"
                                        color="red"
                                        onClick={() => {
                                            props.onDelete(props.index);
                                        }}
                                    >
                                        {translate('supprimer_43083')}
                                    </ItemIcon>
                                </Menu>
                            </Dropdown>
                        </Flex>
                    )}
            </Draggable>

            {isRenameOpen && (
                <AddSectionModal
                    initialName={props.name}
                    onAdd={(name) => {
                        props.onRename(props.index, name);
                    }}
                    onClose={() => {
                        setIsRenameOpen(false);
                    }}
                />
            )}
        </>
    );
};
