import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accreditation, AccreditationPositionProperty } from 'common/src/generated/types';
import * as React from 'react';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsAcronymCellProps {
    accreditation: Pick<Accreditation, 'id' | 'acronym'>;

    reload(): void;
}

export const AccreditationsAcronymCell = (props: IAccreditationsAcronymCellProps) => {
    const { translate } = useHeavent();

    return (
        <AccreditationsUpdateCell
            accreditationId={props.accreditation.id}
            initialValue={props.accreditation.acronym || ''}
            property={AccreditationPositionProperty.Acronym}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput label={translate('Acronym')} value={value} onChange={setValue} />
            )}
        >
            {props.accreditation.acronym}
        </AccreditationsUpdateCell>
    );
};
