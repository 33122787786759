import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { Table } from 'common/src/designSystem/components/table/table';
import { noop } from 'lodash-es';
import * as React from 'react';
import { Button } from '../components/button';
import { Checkbox } from '../components/checkbox';
import sellData from './sellData.json';

export const Tables = () => {
    const [checkboxes, setCheckboxes] = React.useState<any>(
        Object.fromEntries(
            sellData.map(({ id }: any) => [id, false])
        )
    );
    const checkboxesValues = Object.values(checkboxes);
    const checkboxesSet = new Set(checkboxesValues);
    const mainCheckboxState =
        checkboxesSet.size === 1 && checkboxesValues[0] === true
            ? 'checked'
            : checkboxesSet.size === 2
            ? 'indeterminate'
            : 'unchecked';

    return (
        <Flex direction="column" gap="6" width={1} height="fit-content">
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Tables
            </Box>

            <Flex width={1}>
                <Table hideBorder={true}>
                    <HeaderRow>
                        <HeaderCell>Nom du bénévole</HeaderCell>
                        <HeaderCell>Adresse e-mail</HeaderCell>
                        <HeaderCell>Téléphone</HeaderCell>
                    </HeaderRow>

                    <Row>
                        <Cell>Matthieu</Cell>
                        <Cell>matthieu@recrewteer.com</Cell>
                        <Cell>+33 6 23 45 67 89</Cell>
                    </Row>

                    <Row>
                        <Cell>Benjamin</Cell>
                        <Cell>benjamin@recrewteer.com</Cell>
                        <Cell>+33 6 23 45 67 89</Cell>
                    </Row>

                    <Row>
                        <Cell>Jean-Charles</Cell>
                        <Cell>jeancharles@recrewteer.com</Cell>
                        <Cell>+33 6 23 45 67 89</Cell>
                    </Row>
                </Table>
            </Flex>

            <Flex width={1}>
                <Table>
                    <HeaderRow>
                        <HeaderCell>Type d&apos;e-mail</HeaderCell>
                        <HeaderCell>Description</HeaderCell>
                    </HeaderRow>

                    <Row>
                        <Cell>Nouveau bénévole</Cell>
                        <Cell>Lorsqu&apos;un bénévole s&apos;inscrit</Cell>
                    </Row>

                    <Row>
                        <Cell>Code</Cell>
                        <Cell>Lorsqu&apos;un bénévole se connecte</Cell>
                    </Row>
                </Table>
            </Flex>

            <Flex width={1} height={300}>
                <Table>
                    <HeaderRow>
                        <HeaderCell
                            css={{
                                flex: '0 50px',
                                justifyContent: 'center',
                                padding: '0'
                            }}
                        >
                            <Checkbox
                                state={mainCheckboxState}
                                onClick={(state) => {
                                    if (state === 'unchecked') {
                                        setCheckboxes(
                                            Object.fromEntries(
                                                sellData.map(({ id }: any) => [id, false])
                                            )
                                        );
                                    } else {
                                        setCheckboxes(
                                            Object.fromEntries(
                                                sellData.map(({ id }: any) => [id, true])
                                            )
                                        );
                                    }
                                }}
                            />
                        </HeaderCell>
                        <HeaderCell>ID</HeaderCell>
                        <HeaderCell>Date</HeaderCell>
                        <HeaderCell>Status</HeaderCell>
                        <HeaderCell>Name</HeaderCell>
                        <HeaderCell />
                    </HeaderRow>

                    <Box
                        color="inherit"
                        css={{
                            flex: '1',
                            overflow: 'auto',
                            scrollbarWidth: 'none',
                            '& > div:last-child': {
                                borderBottom: 'none'
                            },
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            }
                        }}
                    >
                        {sellData.map(({ id, date, name, status }: any, index: number) => (
                                <Row key={index}>
                                    <Cell
                                        css={{
                                            flex: '0 50px',
                                            justifyContent: 'center',
                                            padding: '0'
                                        }}
                                    >
                                        <Checkbox
                                            state={
                                                checkboxes[id] === true ? 'checked' : 'unchecked'
                                            }
                                            onClick={() => {
                                                setCheckboxes({
                                                    ...checkboxes,
                                                    [id]: !checkboxes[id]
                                                });
                                            }}
                                        />
                                    </Cell>
                                    <Cell>
                                        <Box color="gray500" ellipsis>
                                            {id}
                                        </Box>
                                    </Cell>
                                    <Cell>{date}</Cell>
                                    <Cell>
                                        <Badge
                                            color={
                                                status === 'Paid'
                                                    ? 'primary'
                                                    : status === 'Cancelled'
                                                    ? 'error'
                                                    : 'gray'
                                            }
                                        >
                                            {status}
                                        </Badge>
                                    </Cell>
                                    <Cell>
                                        <Box color="gray500" ellipsis>
                                            {name}
                                        </Box>
                                    </Cell>
                                    <Cell css={{ justifyContent: 'center' }}>
                                        <Button onClick={noop}>Update</Button>
                                    </Cell>
                                </Row>
                            ))}
                    </Box>

                    <Row
                        css={{
                            borderTop: '1px solid $gray200'
                        }}
                    >
                        <Cell>Footer</Cell>
                    </Row>
                </Table>
            </Flex>
        </Flex>
    );
};
