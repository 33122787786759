import { EventId, OrganizationId, SegmentId, SegmentType } from 'common/src/generated/types';
import {
    Columns as ColumnsVO,
    Filter,
    FilterPredicate,
    PossibleColumn,
    Predicates,
    SegmentFragment
} from 'common/src/vo/segment';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { ICheckboxState } from '../designSystem/components/checkbox';
import { MassActionsStates } from '../hooks/useMassActions';

interface ISegmentsContext<MassId, SortAttribute> {
    areColumnsOpen: boolean;
    areSegmentsOpen: boolean;
    columns: ColumnsVO;
    columnsExport: ColumnsVO;
    columnsSearch: ColumnsVO;
    eventId?: EventId;
    filters: Filter[];
    filtersPredicates: FilterPredicate[];
    isAdmin: boolean;
    isEditMode: boolean;
    isFilterOpen: boolean;
    isSaveSegmentVisible: boolean;
    isSaveVisible: boolean;
    isSegmentSelected: boolean;
    limit: number;
    massEditSelectedIds: MassId[];
    massEditSegmentId: SegmentId | null;
    name: string;
    nameDebounced: string;
    numberOfSelected: number;
    offset: number;
    organizationId: OrganizationId;
    possibleColumns: PossibleColumn[];
    predicates: Predicates;
    reloadKey: number;
    rendering: string;
    segment: SegmentFragment;
    segmentId: SegmentId;
    segmentType: SegmentType;
    selectAllState: ICheckboxState;
    selectedFilterPredicate: FilterPredicate | null;
    sort: Sort<SortAttribute> | null;
    states: MassActionsStates<MassId>;

    clearNameCursors(): void;
    getSegmentPath(id: SegmentId): string;
    onMassEditSuccess(): void;
    onSegmentClick(segmentId: SegmentId): void;
    reload(): void;
    setAreColumnsOpen(areColumnsOpen: boolean): void;
    setAreSegmentsOpen(areSegmentsOpen: boolean): void;
    setColumns(columns: ColumnsVO): void;
    setColumnsExport(columns: ColumnsVO): void;
    setColumnsSearch(columns: ColumnsVO): void;
    setIsEditMode(isEditMode: boolean): void;
    setIsFilterOpen(isFilterOpen: boolean): void;
    setIsSaveSegmentVisible(isSaveSegmentVisible: boolean): void;
    setIsSegmentSelected(isSegmentSelected: boolean): void;
    setLimit(limit: number): void;
    setName(name: string): void;
    setNameDebounced(name: string): void;
    setOffset(offset: number): void;
    setPredicates(predicates: Predicates): void;
    setReloadKey(reloadKey: number): void;
    setRendering(rendering: string): void;
    setSelectedFilterPredicate(selectedFilterPredicate: FilterPredicate | null): void;
    setSort(sort: Sort<SortAttribute> | null): void;
    setStates(states: MassActionsStates<MassId>): void;
    toggleRow(key: string, newState: ICheckboxState): void;
    toggleSelectAll(state: ICheckboxState): void;
}

export const SegmentsContext = React.createContext({} as any);

export function useSegmentsContext<MassId, SortAttribute>(): ISegmentsContext<
    MassId,
    SortAttribute
> {
    return React.useContext(SegmentsContext) as any;
}
