import { Button } from 'common-front/src/designSystem/components/button';
import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { LocalStorageKeys } from 'common-front/src/util/localStorage';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { ALL_FEATURE, OrganizationsSortAttributes } from 'common/src/generated/types';
import { useLocalStorage } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { OrganizationsPaths } from 'common/src/util/paths/organizationsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { useBackofficeOrganizationsQuery } from '../generated/graphqlHooks';
import { OrganizationRow } from './organizationRow';

export const BackofficeOrganizations = () => {
    const { history, translate } = useHeavent();
    const localStorage = useLocalStorage();
    const [name, nameDebounced, setName] = useStateDebounce(
        localStorage.getItem(LocalStorageKeys.BACKOFFICE_SEARCH) || ''
    );
    const [features, setFeatures] = React.useState([]);
    const [isAdmin, setIsAdmin] = React.useState<boolean | null>(
        localStorage.getItem(LocalStorageKeys.BACKOFFICE_IS_ADMIN) === 'true'
    );
    const [isActive, setIsActive] = React.useState<boolean | null>(
        localStorage.getItem(LocalStorageKeys.BACKOFFICE_IS_ACTIVE) === 'true'
    );
    const [offset, setOffset] = React.useState(0);
    const [sort, setSort] = React.useState<Sort<OrganizationsSortAttributes> | null>(() => {
        const sortStorage = localStorage.getItem(LocalStorageKeys.BACKOFFICE_SORT);

        if (sortStorage) {
            return JSON.parse(sortStorage) as Sort<OrganizationsSortAttributes> | null;
        } else {
            return null;
        }
    });
    const { data, isLoading, reload } = useBackofficeOrganizationsQuery({
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        features,
        isAdmin,
        isActive,
        offset,
        sort
    });
    const _setSort = (newSort: Sort<OrganizationsSortAttributes> | null) => {
        setSort(newSort);
        setOffset(0);
        localStorage.setItem(LocalStorageKeys.BACKOFFICE_SORT, JSON.stringify(newSort));
    };

    return (
        <Box height={1} width={1} css={{ overflowY: 'auto' }}>
            <Flex direction="column" width={1} css={{ padding: '$7' }}>
                <Flex gap="4">
                    <Box
                        color="gray900"
                        fontSize="displayXs"
                        fontWeight="semiBold"
                        css={{ flex: '1' }}
                    >
                        Organisations
                    </Box>

                    <Button
                        color="white"
                        leftIcon="plus"
                        to={OrganizationsPaths.CREATE_ORGANIZATION}
                    >
                        {translate('cr_er_une_organ_06669')}
                    </Button>

                    <Button
                        onClick={() => {
                            history.push(HeaventPaths.HOME);
                        }}
                    >
                        Home
                    </Button>

                    <Dropdown>
                        <Trigger>
                            <Button color="white" leftIcon="ellipsis-vertical" />
                        </Trigger>
                        <Menu placement="bottom-end">
                            <ItemIcon
                                icon="eye"
                                onClick={() => {
                                    history.push(HeaventPaths.BACKOFFICE_INVOICES);
                                }}
                            >
                                Voir les factures
                            </ItemIcon>
                        </Menu>
                    </Dropdown>
                </Flex>

                <Spacer height="7" />

                <TableFilters
                    filters={
                        <Flex align="center" gap="3">
                            <Box width={320}>
                                <TextInput
                                    icon="magnifying-glass"
                                    placeholder={translate('id_ou_nom_25064')}
                                    value={name}
                                    onChange={(newName: string) => {
                                        setName(newName);
                                        setOffset(0);
                                        localStorage.setItem(
                                            LocalStorageKeys.BACKOFFICE_SEARCH,
                                            newName
                                        );
                                    }}
                                />
                            </Box>

                            <Box width={320}>
                                <RichSelect
                                    isSearchVisible={true}
                                    multiple={true}
                                    values={features}
                                    onChange={setFeatures}
                                >
                                    {ALL_FEATURE.map((feature, index) => (
                                        <option key={index} value={feature}>
                                            {feature}
                                        </option>
                                    ))}
                                </RichSelect>
                            </Box>

                            <CheckboxText
                                state={isAdmin === true ? 'checked' : 'unchecked'}
                                onClick={(state) => {
                                    setIsAdmin(state === 'checked');
                                    setOffset(0);
                                    localStorage.setItem(
                                        LocalStorageKeys.BACKOFFICE_IS_ADMIN,
                                        (state === 'checked').toString()
                                    );
                                }}
                            >
                                {translate('est_admin_98471')}
                            </CheckboxText>

                            <CheckboxText
                                state={isActive === true ? 'checked' : 'unchecked'}
                                onClick={(state) => {
                                    setIsActive(state === 'checked');
                                    setOffset(0);
                                    localStorage.setItem(
                                        LocalStorageKeys.BACKOFFICE_IS_ACTIVE,
                                        (state === 'checked').toString()
                                    );
                                }}
                            >
                                {translate('est_active_05309')}
                            </CheckboxText>
                        </Flex>
                    }
                    headerCells={
                        <>
                            <HeaderCell width={100}>ID</HeaderCell>
                            <HeaderCell>{translate('full_name')}</HeaderCell>
                            <HeaderCellSort
                                width={200}
                                attribute={OrganizationsSortAttributes.InsertedAt}
                                sort={sort}
                                setSort={_setSort}
                            >
                                {translate('date_de_cr_atio_00749')}
                            </HeaderCellSort>
                            <HeaderCellSort
                                width={200}
                                attribute={OrganizationsSortAttributes.PeriodEndAt}
                                sort={sort}
                                setSort={_setSort}
                            >
                                {translate('date_de_fin_de_86731')}
                            </HeaderCellSort>
                            <HeaderCell>{translate('fonctionnalit_s_47714')}</HeaderCell>
                            <HeaderCell width={200}>{translate('nombre_d_v_nem_14214')}</HeaderCell>
                            <HeaderCell width={100} />
                        </>
                    }
                    numberOfPages={data.backoffice?.organizations.numberOfPages ?? 0}
                    offset={offset}
                    rows={
                        isLoading ? (
                            <>
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                            </>
                        ) : (
                            <>
                                {data.backoffice.organizations.nodes.map((organization) => (
                                    <OrganizationRow
                                        key={organization.id}
                                        organization={organization}
                                        reload={reload}
                                    />
                                ))}
                            </>
                        )
                    }
                    title={translate('liste_des_organ_72680')}
                    totalCount={data.backoffice?.organizations.totalCount ?? 0}
                    setOffset={setOffset}
                />
            </Flex>
        </Box>
    );
};
