import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { theme } from 'common/src/designSystem/components/stitches';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { removeAtIndex } from 'common/src/util/array';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

interface ICustomFieldsReorderRowProps {
    column: PossibleColumn;
    index: number;
}

export const VolunteersExportColumnsSelectorRow = ({
    column,
    index
}: ICustomFieldsReorderRowProps) => {
    const { columnsExport, setColumnsExport } = useSegmentsContext();

    return (
        <Draggable draggableId={`s-${column.slug}`} index={index}>
            {(draggableProvided: DraggableProvided) => (
                    <Flex
                        css={{
                            padding: '$2'
                        }}
                        width={1}
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                    >
                        <Row
                            css={{
                                height: 'auto',
                                background: 'white',
                                border: 'unset',
                                padding: '$2 auto'
                            }}
                        >
                            <Cell
                                gap="3"
                                css={{
                                    padding: '$3',
                                    background: 'white',
                                    border: '1px solid $gray200',
                                    borderRadius: '$1'
                                }}
                            >
                                <Box
                                    color="gray300"
                                    css={{ cursor: 'pointer' }}
                                    {...draggableProvided.dragHandleProps}
                                >
                                    <I iconStyle="solid" icon="grip-vertical" />
                                </Box>

                                <Box font="gray900 textSm medium">{column.name}</Box>
                            </Cell>

                            <Cell
                                gap="4"
                                css={{
                                    padding: '$4',
                                    flexGrow: 0
                                }}
                            >
                                <Box
                                    color="gray500"
                                    fontSize="textMd"
                                    css={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() =>
                                        setColumnsExport(removeAtIndex(columnsExport, index))
                                    }
                                >
                                    <I
                                        primaryColor="white"
                                        secondaryColor={theme.colors.gray500.value}
                                        iconStyle="solid"
                                        icon="circle-xmark"
                                    />
                                </Box>
                            </Cell>
                        </Row>
                    </Flex>
                )}
        </Draggable>
    );
};
