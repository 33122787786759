import * as React from 'react';
import { Field } from 'react-final-form';
import { IRadioSize, RadioText as RadioTextComponent } from '../components/radio';

interface IRadioTextProps {
    name: string;
    value: any;
    size?: IRadioSize;
    disabled?: boolean;
    children: React.ReactNode;
    subtext?: string;
}

export const RadioText = (props: IRadioTextProps) => (
    <Field
        name={props.name}
        type={'radio'}
        value={props.value}
        render={(fieldProps) => {
            const state = fieldProps.input.checked ? 'checked' : 'unchecked';

            return (
                <RadioTextComponent
                    size={props.size}
                    disabled={props.disabled}
                    state={state}
                    subtext={props.subtext}
                    onClick={() => {
                        fieldProps.input.onChange(props.value);
                    }}
                >
                    {props.children}
                </RadioTextComponent>
            );
        }}
    />
);
