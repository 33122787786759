import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    Event,
    PositionsPositionSlotFragment,
    PositionsSlotId,
    PositionsSlotInput,
    PositionState,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import { PositionDefaultColumns } from 'common/src/vo/segments/positionsSegmentsService';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { usePositionSlotUpdateMutation } from '../../generated/graphqlHooks';
import { CreatePositionCreateSlotModal } from '../../positions/create/createPositionCreateSlotModal';
import { PositionCreatedByCell } from '../../positions/list/list/cells/positionCreatedByCell';
import { PositionsAcronymCell } from '../../positions/list/list/cells/positionsAcronymCell';
import { PositionsCategoryCell } from '../../positions/list/list/cells/positionsCategoryCell';
import { PositionsColorCell } from '../../positions/list/list/cells/positionsColorCell';
import { PositionsConditionsCell } from '../../positions/list/list/cells/positionsConditionsCell';
import { PositionsIconCell } from '../../positions/list/list/cells/positionsIconCell';
import { PositionsNameCell } from '../../positions/list/list/cells/positionsNameCell';
import { PositionsTagsCell } from '../../positions/list/list/cells/positionsTagsCell';
import { PositionRowResources } from '../../positions/list/list/positionRowResources';
import { PositionSlotNameCell } from './cells/positionSlotNameCell';

interface IPositionSlotRowProps {
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    event: Pick<Event, 'startAt' | 'endAt'>;
    positionSlot: PositionsPositionSlotFragment;

    reloadPositionSlot(id: PositionsSlotId): void;
}

export const PositionSlotRow = (props: IPositionSlotRowProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const { isPositionCategoryAdmin } = useEventContext();
    const intervalService = useService(IntervalService);
    const fieldService = useService(FieldService);
    const { mutate: positionSlotUpdate } = usePositionSlotUpdateMutation();
    const slugToCustomField = React.useMemo(
        () => new Map(props.customFields.map((cf) => [cf.slug, cf])),
        [props.customFields]
    );
    const reloadPositionSlot = React.useCallback(() => {
        props.reloadPositionSlot(props.positionSlot.id);
    }, [props.positionSlot, props.reloadPositionSlot]);
    const [isEditOpen, setIsEditOpen] = React.useState(false);

    return (
        <>
            <Row
                css={{
                    '&:hover': {
                        background: '$gray100'
                    }
                }}
            >
                {props.columns.map((field) => {
                    const customField = slugToCustomField.get(field.slug);

                    return field.slug === PositionDefaultColumns.Id ? (
                        <Cell key={field.slug} width={100}>
                            {props.positionSlot.id}
                        </Cell>
                    ) : field.slug === PositionDefaultColumns.Category ? (
                        <PositionsCategoryCell
                            key={field.slug}
                            positionId={props.positionSlot.position.id}
                            positionCategory={props.positionSlot.positionCategory}
                            reload={reloadPositionSlot}
                        />
                    ) : field.slug === PositionDefaultColumns.Name ? (
                        <PositionsNameCell
                            key={field.slug}
                            position={props.positionSlot.position}
                            reload={reloadPositionSlot}
                        />
                    ) : field.slug === PositionDefaultColumns.Slot ? (
                        <Cell key={field.slug}>
                            {intervalService.toDisplayString(props.positionSlot.range)}
                        </Cell>
                    ) : field.slug === PositionDefaultColumns.SlotName ? (
                        <PositionSlotNameCell
                            key={field.slug}
                            positionSlot={props.positionSlot}
                            reload={reloadPositionSlot}
                        />
                    ) : field.slug === PositionDefaultColumns.Resources ? (
                        <Cell key={field.slug} width={256}>
                            <PositionRowResources
                                assignedResources={props.positionSlot.assignedResources}
                                resources={props.positionSlot.resources}
                            />
                        </Cell>
                    ) : field.slug === PositionDefaultColumns.Tags ? (
                        <PositionsTagsCell
                            key={field.slug}
                            position={props.positionSlot.position}
                            reload={reloadPositionSlot}
                        />
                    ) : field.slug === PositionDefaultColumns.Conditions ? (
                        <PositionsConditionsCell
                            key={field.slug}
                            position={props.positionSlot.position}
                        />
                    ) : field.slug === PositionDefaultColumns.Address ? (
                        <Cell key={field.slug}>{props.positionSlot.position.address}</Cell>
                    ) : field.slug === PositionDefaultColumns.Latitude ? (
                        <Cell key={field.slug}>{props.positionSlot.position.latitude}</Cell>
                    ) : field.slug === PositionDefaultColumns.Longitude ? (
                        <Cell key={field.slug}>{props.positionSlot.position.longitude}</Cell>
                    ) : field.slug === PositionDefaultColumns.Acronym ? (
                        <PositionsAcronymCell
                            key={field.slug}
                            position={props.positionSlot.position}
                            reload={reloadPositionSlot}
                        />
                    ) : field.slug === PositionDefaultColumns.Color ? (
                        <PositionsColorCell
                            key={field.slug}
                            position={props.positionSlot.position}
                            reload={reloadPositionSlot}
                        />
                    ) : field.slug === PositionDefaultColumns.Icon ? (
                        <PositionsIconCell
                            key={field.slug}
                            position={props.positionSlot.position}
                            reload={reloadPositionSlot}
                        />
                    ) : field.slug === PositionDefaultColumns.State ? (
                        <Cell key={field.slug}>
                            {props.positionSlot.state === PositionState.Full ? (
                                <Badge color="success">{translate(props.positionSlot.state)}</Badge>
                            ) : (
                                <Badge color="warning">{translate(props.positionSlot.state)}</Badge>
                            )}
                        </Cell>
                    ) : field.slug === PositionDefaultColumns.FillingRate ? (
                        <Cell key={field.slug}>{props.positionSlot.fillingRate}%</Cell>
                    ) : field.slug == PositionDefaultColumns.CheckedInRate ? (
                        <Cell key={field.slug}>{props.positionSlot.checkedInRate || 0}%</Cell>
                    ) : field.slug === PositionDefaultColumns.CreatedBy ? (
                        <PositionCreatedByCell
                            key={field.slug}
                            createdBy={props.positionSlot.position.createdBy}
                        />
                    ) : (
                        <Cell key={field.slug}>
                            {customField
                                ? fieldService.getValueString(
                                      customField,
                                      props.positionSlot.position,
                                      {
                                          fileReturnValue: 'name'
                                      }
                                  )
                                : ''}
                        </Cell>
                    );
                })}

                {isPositionCategoryAdmin(props.positionSlot.positionCategory.id) && (
                    <CellControls justify="center">
                        <Tooltip>
                            <Trigger>
                                <Box>
                                    <I
                                        icon="pen"
                                        onClick={() => {
                                            setIsEditOpen(true);
                                        }}
                                    />
                                </Box>
                            </Trigger>

                            <Content placement="top">{translate('_diter_62574')}</Content>
                        </Tooltip>
                    </CellControls>
                )}
            </Row>

            {isEditOpen && (
                <CreatePositionCreateSlotModal
                    event={props.event}
                    slot={props.positionSlot}
                    onClose={() => {
                        setIsEditOpen(false);
                    }}
                    onSuccess={async (updatedSlot: PositionsSlotInput) => {
                        await positionSlotUpdate({
                            eventId,
                            positionId: props.positionSlot.position.id,
                            positionSlotId: props.positionSlot.id,
                            positionSlot: updatedSlot
                        });

                        reloadPositionSlot();
                    }}
                />
            )}
        </>
    );
};
