import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { AccreditationId, DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeMernartProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeMernart = (props: IBadgeMernartProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const accreditationsIds = vr.accreditationsUsersInfos.map((aui) => aui.accreditation.id);
    const isCollector = accreditationsIds.includes(3472 as AccreditationId);
    const hideHours = ui.id === 802397 || ui.id === 802401;

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/menart/bg_menart_v2.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$rubik',
                position: 'relative'
            }}
        >
            <Flex
                direction="column"
                gap="2"
                width={470}
                css={{
                    fontSize: '32px',
                    fontWeight: '600',
                    lineHeight: '24px',
                    left: '61px',
                    position: 'absolute',
                    top: '216px'
                }}
            >
                <Box>{ui.firstName}</Box>
                <Box>{ui.lastName}</Box>
            </Flex>

            <Box
                width={350}
                css={{
                    color: '#c64937',
                    fontSize: '24px',
                    fontWeight: '600',
                    lineHeight: '24px',
                    left: '61px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '303px'
                }}
            >
                {isCollector ? 'Preview Collectors & Institutions' : 'Opening & Cocktail'}
            </Box>

            <Box
                width={470}
                css={{
                    fontSize: '22px',
                    left: '61px',
                    position: 'absolute',
                    top: '367px'
                }}
            >
                Thursday Sept. 19, 2024
            </Box>

            {!hideHours && (
                <Box
                    width={470}
                    css={{
                        fontSize: '22px',
                        left: '61px',
                        position: 'absolute',
                        top: '399px'
                    }}
                >
                    {isCollector ? 'From 3pm to 10pm' : 'From 6pm to 10pm'}
                </Box>
            )}

            <Box
                height={90}
                width={90}
                css={{
                    left: '439px',
                    position: 'absolute',
                    top: '335px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=90&width=90`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
