import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';

interface ITabPanelProps {
    children: React.ReactNode;
    // eslint-disable-next-line react/no-unused-prop-types
    path: string | string[]; // Prop value accessed in `tabs.tsx`.
}

export const TabPanel = (props: ITabPanelProps) => <Box role="tabpanel">{props.children}</Box>;

TabPanel.displayName = 'TabPanel';
