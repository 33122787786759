import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    Accreditation,
    AccreditationsAccreditationCustomFieldFragment
} from 'common/src/generated/types';
import { displayConditionValue } from 'common/src/vo/positionCustomField';
import * as React from 'react';

interface IAccreditationsConditionsCellProps {
    accreditation: Pick<Accreditation, 'id'> & {
        accreditationsCustomFields: AccreditationsAccreditationCustomFieldFragment[];
    };
}

export const AccreditationsConditionsCell = (props: IAccreditationsConditionsCellProps) => {
    const { translate } = useHeavent();
    const accreditationsCustomFields = React.useMemo(
        () => props.accreditation.accreditationsCustomFields || [],
        [props.accreditation]
    );
    const badges = React.useMemo(
        () =>
            accreditationsCustomFields.map((accreditationCustomField, index) => ({
                id: index,
                color: 'gray',
                icon: null,
                text: `${accreditationCustomField.customField.name} : ${displayConditionValue(
                    translate,
                    accreditationCustomField.customField,
                    accreditationCustomField.conditionValue
                )}`
            })),
        [accreditationsCustomFields]
    );

    return (
        <Cell>
            <BadgesPlus badges={badges} />
        </Cell>
    );
};
