import { Buttons } from './buttons';
import { Content } from './content';
import { Header } from './header';
import { Portal } from './portal';
import { Root } from './root';
import { Trigger } from './trigger';

export const Modal = {
    Buttons,
    Content,
    Header,
    Portal,
    Root,
    Trigger
};
