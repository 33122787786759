import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { DateTimeService } from '../../../services/dateTimeService';
import { useService } from '../../../util/dependencies/dependencies';
import { LocaleFormats } from '../../../util/luxon';
import { A4_SIZES, A6_SIZES } from '../../../util/pdf';

interface IHideProps {
    left: number;
    visible: boolean;
}

const Hide = (props: IHideProps) => (
    <Box
        height={210}
        width={40}
        css={{
            background: '#f6e100',
            left: `${props.left}px`,
            position: 'absolute',
            top: '336px',
            visibility: props.visible ? 'visible' : 'hidden'
        }}
    />
);

interface IExplosivBadgeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const ExplosivBadge = (props: IExplosivBadgeProps) => {
    const dateTimeService = useService(DateTimeService);
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const acronyms = vr.accreditationsUsersInfos.map((aui) => aui.accreditation.acronym);
    const dates = uniqBy(
        sortBy(
            vr.accreditationsUsersInfos.flatMap((aui) =>
                aui.accreditationSlot.date ? [aui.accreditationSlot.date!.startOf('day')] : []
            ),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    )
        .map((d) => dateTimeService.toLocaleString(d, LocaleFormats.DateOnly.MonthShort))
        .join(' | ');

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/explosiv/badge_v2.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$montserrat',
                position: 'relative'
            }}
        >
            <Flex
                justify="center"
                width={A6_SIZES['96dpi'].width}
                css={{
                    position: 'absolute',
                    top: '110px'
                }}
            >
                <Box
                    css={{
                        background: '#343a8e',
                        borderRadius: '$1',
                        color: 'white',
                        fontSize: '22px',
                        fontWeight: '700',
                        padding: '2px 5px',
                        textTransform: 'uppercase'
                    }}
                >
                    {ui.firstName}
                </Box>
            </Flex>

            <Flex
                justify="center"
                width={A6_SIZES['96dpi'].width}
                css={{
                    position: 'absolute',
                    top: '150px'
                }}
            >
                <Box
                    css={{
                        background: '#343a8e',
                        borderRadius: '$1',
                        color: 'white',
                        fontSize: '22px',
                        fontWeight: '700',
                        padding: '2px 5px',
                        textTransform: 'uppercase'
                    }}
                >
                    {ui.lastName}
                </Box>
            </Flex>

            <Flex
                justify="center"
                width={A6_SIZES['96dpi'].width}
                css={{
                    position: 'absolute',
                    top: '196px'
                }}
            >
                <Box
                    css={{
                        background: '#343a8e',
                        borderRadius: '$1',
                        color: 'white',
                        fontSize: '22px',
                        fontWeight: '500',
                        padding: '2px 5px'
                    }}
                >
                    {ui.population}
                </Box>
            </Flex>

            <Flex
                justify="center"
                width={A6_SIZES['96dpi'].width}
                css={{
                    position: 'absolute',
                    top: '245px'
                }}
            >
                <Box
                    css={{
                        background: '#343a8e',
                        borderRadius: '$1',
                        color: 'white',
                        fontSize: '13px',
                        fontWeight: '700',
                        padding: '2px 5px',
                        textTransform: 'uppercase'
                    }}
                >
                    {dates}
                </Box>
            </Flex>

            <Hide left={18} visible={!acronyms.includes('1')} />
            <Hide left={65} visible={!acronyms.includes('2')} />
            <Hide left={112} visible={!acronyms.includes('3')} />
            <Hide left={157} visible={!acronyms.includes('4')} />
            <Hide left={204} visible={!acronyms.includes('5')} />
            <Hide left={250} visible={!acronyms.includes('6')} />
            <Hide left={297} visible={!acronyms.includes('7')} />
            <Hide left={344} visible={!acronyms.includes('8')} />
        </Flex>
    );
};
