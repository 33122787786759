import { CSS } from '@stitches/react';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { ILinkProps, Link } from 'common/src/designSystem/components/link';
import { styled, theme } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { LoaderSvg } from '../../components/loader/loader';

const style = {
    alignItems: 'center',
    borderRadius: '$1',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: '$semiBold',
    gap: '$2',
    padding: '$1 $4',
    userSelect: 'none',
    '& .loader': {
        borderRadius: '$1'
    },
    variants: {
        color: {
            primary: {
                background: '$primary700',
                border: '1px solid $primary700',
                color: 'white',
                '&:hover': {
                    background: '$primary800',
                    border: '1px solid $primary800'
                },
                '& .loader': {
                    background: '$primary700',
                    border: '1px solid $primary700'
                },
                '& .loader:hover': {
                    background: '$primary800',
                    border: '1px solid $primary800'
                }
            },
            gray: {
                background: '$gray100',
                border: '1px solid $gray100',
                color: '$gray800',
                '&:hover': {
                    background: '$gray100',
                    border: '1px solid $gray100'
                },
                '& .loader': {
                    background: '$gray100',
                    border: '1px solid $gray100'
                },
                '& .loader:hover': {
                    background: '$gray100',
                    border: '1px solid $gray100'
                }
            },
            white: {
                background: 'white',
                border: '1px solid $gray300',
                color: '$gray700',
                '&:hover': {
                    background: '$gray50',
                    color: '$gray800'
                },
                '& .loader': {
                    background: 'white',
                    border: '1px solid $gray300'
                },
                '& .loader:hover': {
                    background: '$gray50',
                    color: '$gray800'
                }
            },
            error: {
                background: '$error600',
                border: '1px solid $error600',
                color: 'white',
                '&:hover': {
                    background: '$error700',
                    border: '1px solid $error600'
                },
                '& .loader': {
                    background: '$error600',
                    border: '1px solid $error600'
                },
                '& .loader:hover': {
                    background: '$error700',
                    border: '1px solid $error600'
                }
            },
            invisible: {
                background: 'none',
                border: 'none',
                color: '$primary700',
                fontSize: '$textSm',
                fontWeight: '$medium',
                padding: '$1 $2',
                '&:hover': {
                    color: '$primary900'
                },
                '& .h-button-content:has( + .loader)': {
                    color: 'transparent'
                }
            }
        },
        disabled: {
            true: {
                cursor: 'not-allowed'
            }
        },
        size: {
            sm: {
                height: '32px',
                fontSize: '$textSm'
            },
            md: {
                height: '40px',
                fontSize: '$textSm'
            },
            lg: {
                height: '44px',
                fontSize: '$textMd'
            },
            xl: {
                height: '48px',
                fontSize: '$textMd'
            },
            '2xl': {
                height: '60px',
                fontSize: '$textLg'
            }
        },
        iconOnly: {
            true: { justifyContent: 'center', px: '0' }
        }
    },
    compoundVariants: [
        {
            color: 'primary',
            disabled: true,
            css: {
                color: '$gray400',
                background: '$gray100',
                border: '1px solid $gray100',
                '&:hover': {
                    background: '$gray100',
                    border: '1px solid $gray100'
                },
                '& .loader': {
                    background: '$gray100',
                    border: '1px solid $gray100'
                },
                '& .loader:hover': {
                    background: '$gray100',
                    border: '1px solid $gray100'
                }
            }
        },
        {
            color: 'gray',
            disabled: true,
            css: {
                background: '$primary25',
                border: '1px solid $primary25',
                color: '$primary300',
                '&:hover': {
                    background: '$primary25',
                    border: '1px solid $primary25'
                },
                '& .loader': {
                    background: '$primary25',
                    border: '1px solid $primary25'
                },
                '& .loader:hover': {
                    background: '$primary25',
                    border: '1px solid $primary25'
                }
            }
        },
        {
            color: 'transparent',
            disabled: true,
            css: {
                color: '$gray400',
                background: '$primary100',
                border: '1px solid $primary100',
                '&:hover': {
                    color: '$gray400',
                    background: '$primary100',
                    border: '1px solid $primary100'
                },
                '& .loader': {
                    background: '$primary100',
                    border: '1px solid $primary100'
                },
                '& .loader:hover': {
                    background: '$primary100',
                    border: '1px solid $primary100'
                }
            }
        },
        {
            color: 'invisible',
            disabled: true,
            css: {
                color: '$gray400',
                '&:hover': {
                    color: '$gray400'
                }
            }
        },
        {
            color: 'error',
            disabled: true,
            css: {
                background: '$error200',
                border: '1px solid $error200',
                '&:hover': {
                    background: '$error200',
                    border: '1px solid $error200'
                },
                '& .loader': {
                    background: '$error200',
                    border: '1px solid $error200'
                },
                '& .loader:hover': {
                    background: '$error200',
                    border: '1px solid $error200'
                }
            }
        },
        {
            size: 'sm',
            iconOnly: true,
            css: {
                width: '32px'
            }
        },

        {
            size: 'md',
            iconOnly: true,
            css: {
                width: '40px'
            }
        },
        {
            size: 'lg',
            iconOnly: true,
            css: {
                width: '44px'
            }
        },
        {
            size: 'xl',
            iconOnly: true,
            css: {
                width: '48px'
            }
        },
        {
            size: '2xl',
            iconOnly: true,
            css: {
                width: '56px'
            }
        }
    ],
    defaultVariants: {
        color: 'primary',
        size: 'md'
    }
};

export const StyledButton = styled('button', style);

export const StyledLinkButton = styled('div', style);

export type IButtonColor = 'primary' | 'gray' | 'white' | 'error' | 'invisible';

export type IButtonSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

interface IButtonProps
    extends Omit<
        Partial<React.ButtonHTMLAttributes<HTMLButtonElement>> & Partial<ILinkProps>,
        'onAbort'
    > {
    color?: IButtonColor;
    isLoading?: boolean;
    css?: CSS;
    leftIcon?: IIcon;
    rightIcon?: IIcon;
    size?: IButtonSize;
    textAlign?: 'start' | 'end' | 'center' | 'justify';
}

export const Button = React.forwardRef(
    (
        {
            children,
            className,
            color,
            css,
            download,
            disabled,
            isLoading,
            leftIcon,
            onClick,
            to,
            rightIcon,
            size,
            target,
            textAlign,
            title
        }: IButtonProps,
        ref: React.Ref<HTMLButtonElement | HTMLDivElement>
    ) => {
        const iconOnly =
            (!!leftIcon && !children && !rightIcon) || (!leftIcon && !children && !!rightIcon);

        const commonWrapperProps = {
            className: `button ${className || ''}`,
            color,
            css: {
                ...css,
                position: 'relative'
            },
            disabled, // TODO: Use `aria-disabled` instead for better a11y
            iconOnly,
            size,
            title
        };
        const commonButtonProps = {
            children,
            color,
            leftIcon,
            isLoading,
            rightIcon,
            textAlign
        };

        return to ? (
            <Link
                download={download}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    if (!disabled && !isLoading) {
                        if (onClick) {
                            onClick(event);
                        }
                    }
                }}
                target={target}
                title={title}
                to={to}
            >
                <StyledLinkButton {...commonWrapperProps} ref={ref as React.Ref<HTMLDivElement>}>
                    <ButtonContent {...commonButtonProps} />
                </StyledLinkButton>
            </Link>
        ) : (
            <StyledButton
                {...commonWrapperProps}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    if (!disabled && !isLoading) {
                        if (onClick) {
                            onClick(event);
                        }
                    }
                }}
                ref={ref as React.Ref<HTMLButtonElement>}
                type="button"
            >
                <ButtonContent {...commonButtonProps} />
            </StyledButton>
        );
    }
);
Button.displayName = 'Button';
Button.toString = () => '.button';

const ButtonContent = ({
    children,
    color,
    isLoading,
    leftIcon,
    rightIcon,
    textAlign
}: Pick<
    IButtonProps,
    'children' | 'color' | 'isLoading' | 'leftIcon' | 'rightIcon' | 'textAlign'
>) => (
        <>
            {leftIcon && (
                <Flex>
                    <I icon={leftIcon} />
                </Flex>
            )}

            {children && (
                <Box className="h-button-content" textAlign={textAlign} css={{ flex: '1' }}>
                    {children}
                </Box>
            )}

            {rightIcon && (
                <Flex>
                    <I icon={rightIcon} />
                </Flex>
            )}

            {isLoading && (
                <Flex
                    className="loader"
                    align="center"
                    justify="center"
                    css={{
                        height: 'calc(100% + 2px)',
                        left: '-1px',
                        position: 'absolute',
                        top: '-1px',
                        width: 'calc(100% + 2px)',
                        zIndex: 10
                    }}
                >
                    <LoaderSvg
                        color={
                            !color || color === 'primary' || color === 'error'
                                ? 'white'
                                : theme.colors.gray800.value
                        }
                        height="40px"
                        strokeWidth="3"
                        width="40px"
                    />
                </Flex>
            )}
        </>
    );
