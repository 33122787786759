import { useBodyClick } from 'common-front/src/hooks/useBodyClick';
import { useEsc } from 'common-front/src/hooks/useEsc';
import { AcronymIcon } from 'common-front/src/localDatabase';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { theme } from 'common/src/designSystem/components/stitches';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IAcronymIconSelectorProps {
    selectedAcronymIcon: AcronymIcon;

    onChange(acronymIcon: AcronymIcon): void;
    onClose(): void;
}

export const AcronymIconSelector = (props: IAcronymIconSelectorProps) => {
    const translate = useTranslate();

    useEsc(props.onClose);
    useBodyClick(props.onClose);

    return (
        <Flex
            direction="column"
            css={{
                background: 'white',
                borderRadius: '$1',
                boxShadow: '$xs',
                padding: '$1 $3',
                width: '180px'
            }}
            onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
            }}
        >
            <Flex
                align="center"
                gap="2"
                css={{
                    borderBottom: '1px solid $gray200',
                    cursor: 'pointer',
                    py: '$2'
                }}
                onClick={() => {
                    props.onChange('acronym');
                }}
            >
                <Box fontSize="textMd">
                    <I icon="text" />
                </Box>

                <Box color="gray800" css={{ flex: '1' }}>
                    {translate('acronymes_11499')}
                </Box>

                {props.selectedAcronymIcon === 'acronym' && (
                    <Flex
                        align="center"
                        justify="center"
                        css={{
                            fontSize: '$textMd'
                        }}
                    >
                        <I
                            iconStyle="duotone"
                            icon="circle-check"
                            primaryColor="white"
                            secondaryColor={theme.colors.primary600.value}
                        />
                    </Flex>
                )}
            </Flex>

            <Flex
                align="center"
                gap="2"
                css={{
                    cursor: 'pointer',
                    py: '$2'
                }}
                onClick={() => {
                    props.onChange('icon');
                }}
            >
                <Box fontSize="textMd">
                    <I icon="location-dot" />
                </Box>

                <Box color="gray800" css={{ flex: '1' }}>
                    {translate('ic_nes_16022')}
                </Box>

                {props.selectedAcronymIcon === 'icon' && (
                    <Flex
                        align="center"
                        justify="center"
                        css={{
                            fontSize: '$textMd'
                        }}
                    >
                        <I
                            iconStyle="duotone"
                            icon="circle-check"
                            primaryColor="white"
                            secondaryColor={theme.colors.primary600.value}
                        />
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};
