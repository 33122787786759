import { UpdateCell } from 'common-front/src/components/updateCell';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { AccreditationPositionProperty, PositionId } from 'common/src/generated/types';
import * as React from 'react';
import { usePositionUpdateFieldMutation } from '../../../../generated/graphqlHooks';

interface IPositionsUpdateCellProps<T> {
    cellCss?: any;
    children: React.ReactNode;
    initialValue: T;
    positionId: PositionId;
    property: AccreditationPositionProperty;

    reload(): void;
    renderInput(value: T, setValue: (v: T) => void): React.ReactNode;
}

export const PositionsUpdateCell = <T extends {}>(props: IPositionsUpdateCellProps<T>) => {
    const {
        params: { eventId }
    } = useHeavent();
    const { mutate } = usePositionUpdateFieldMutation();

    return (
        <UpdateCell
            cellCss={props.cellCss}
            initialValue={props.initialValue}
            mutate={async (value) =>
                mutate({
                    eventId,
                    positionId: props.positionId,
                    property: props.property,
                    value
                })
            }
            reload={props.reload}
            renderInput={props.renderInput}
        >
            {props.children}
        </UpdateCell>
    );
};
