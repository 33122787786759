import { Button } from 'common-front/src/designSystem/components/button';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { EventId, PositionsSlotsSortAttributes, SegmentId } from 'common/src/generated/types';
import { IMassAssignValues } from 'common/src/input/massAssignInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { useMassAssignSlotsQuery } from '../../generated/graphqlHooks';
import { MassAssignFilters } from './massAssignFilters';
import { MassAssignSlotRow } from './massAssignSlotRow';

interface IMassAffectStep2Props {
    eventId: EventId;
    numberOfSelected: number;
    submitting: boolean;
    values: IMassAssignValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

export const MassAssignStep2 = (props: IMassAffectStep2Props) => {
    const translate = useTranslate();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [segmentId, setSegmentId] = React.useState<SegmentId>(-1 as SegmentId);
    const [excludeFull, setExcludeFull] = React.useState(false);
    const [offset, setOffset] = React.useState(0);
    const [sort, setSort] = React.useState<Sort<PositionsSlotsSortAttributes> | null>(null);
    const { data, isLoading } = useMassAssignSlotsQuery({
        eventId: props.eventId,
        positionName: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        segmentId: segmentId === -1 ? undefined : segmentId,
        excludeFull,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.positionsSlots);
    const _setSort = (newSort: Sort<PositionsSlotsSortAttributes> | null) => {
        setSort(newSort);
        setOffset(0);
    };

    return (
        <>
            <TableFilters
                filters={
                    <>
                        <MassAssignFilters
                            excludeFull={excludeFull}
                            name={name}
                            segmentId={segmentId}
                            segments={data.event?.segments ?? []}
                            setExcludeFull={(newExcludeFull) => {
                                setOffset(0);
                                setExcludeFull(newExcludeFull);
                            }}
                            setName={setName}
                            setOffset={setOffset}
                            setSegmentId={(newSegmentId) => {
                                setOffset(0);
                                setSegmentId(newSegmentId);
                            }}
                        />
                    </>
                }
                headerCells={
                    <>
                        <HeaderCell width={48} css={{ paddingRight: 0 }} />
                        <HeaderCellSort
                            attribute={PositionsSlotsSortAttributes.Position}
                            sort={sort}
                            setSort={_setSort}
                        >
                            {translate('nom_de_la_missi_64605')}
                        </HeaderCellSort>
                        <HeaderCellSort
                            attribute={PositionsSlotsSortAttributes.Range}
                            sort={sort}
                            setSort={_setSort}
                        >
                            {translate('Date')}
                        </HeaderCellSort>
                        <HeaderCell />
                    </>
                }
                numberOfPages={numberOfPages}
                offset={offset}
                rows={
                    isLoading ? (
                        <>
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                        </>
                    ) : (
                        data.event.positionsSlots.nodes.map((ps) => (
                            <MassAssignSlotRow
                                key={ps.id}
                                positionsSlotsIds={props.values.massAssign.positionsSlotsIds}
                                slot={ps}
                                change={props.change}
                            />
                        ))
                    )
                }
                title={
                    <Flex direction="column" gap="1">
                        <Box>{translate('s_lectionner_le_26300')}</Box>
                        <Box font="gray500 textSm regular">
                            {translate('les_membres_s_l_82866')}
                        </Box>
                    </Flex>
                }
                totalCount={totalCount}
                setOffset={setOffset}
            />

            <Spacer height="7" />

            <Flex>
                <Button isLoading={props.submitting} onClick={props.handleSubmit}>
                    {translate('affecter_les_1_56142', props.numberOfSelected)}
                </Button>
            </Flex>
        </>
    );
};
