import { Button } from 'common-front/src/designSystem/components/button';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    CampaignType,
    CreateCampaignEmailSenderFragment,
    EmailsSenderId
} from 'common/src/generated/types';
import { ICreateUpdateCampaignValues } from 'common/src/input/campaignInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';
import { Email } from './email';
import { Sms } from './sms';

interface ICampaignTextProps {
    emailSenderId: Emptyable<EmailsSenderId>;
    emailsSenders: CreateCampaignEmailSenderFragment[];
    formProps: FormRenderProps<ICreateUpdateCampaignValues>;
    isSent: boolean;
}

export const CampaignText = (props: ICampaignTextProps) => {
    const transalte = useTranslate();

    return (
        <>
            {props.formProps.values.campaign.campaignType === CampaignType.Email ? (
                <Email
                    emailSenderId={props.emailSenderId}
                    emailsSenders={props.emailsSenders}
                    form={props.formProps.form}
                    values={props.formProps.values}
                />
            ) : (
                <Sms formProps={props.formProps} />
            )}

            <Flex>
                <Button onClick={props.formProps.handleSubmit}>
                    {props.isSent ? `Mettre à jour la campagne` : transalte('suivant_desti_10756')}
                </Button>
            </Flex>
        </>
    );
};
