import { IBadgeColor } from '../designSystem/components/badge';
import { IIcon } from '../designSystem/components/i';
import {
    AccreditationState,
    VolunteerRegistrationState,
    VolunteersRegistration
} from '../generated/types';
import { assertUnreachable } from '../util/assertUnreachable';

export interface IBadgeColorIcon {
    color: Exclude<IBadgeColor, 'white'>;
    leftIcon: IIcon;
}

export function getStateBadgeColorIcon(state: VolunteerRegistrationState): IBadgeColorIcon {
    switch (state) {
        case VolunteerRegistrationState.Assigned:
            return { color: 'success', leftIcon: 'check' };
        case VolunteerRegistrationState.Refused:
            return { color: 'error', leftIcon: 'xmark' };
        case VolunteerRegistrationState.PreAssigned:
            return { color: 'pink', leftIcon: 'wand-magic-sparkles' };
        case VolunteerRegistrationState.WaitingAssignment:
            return { color: 'warning', leftIcon: 'hourglass-empty' };
        case VolunteerRegistrationState.NotApplicable:
            return { color: 'gray', leftIcon: 'infinity' };
        default:
            return assertUnreachable(state);
    }
}

export function getAccreditationStateBadgeColorIcon(
    accreditationState: AccreditationState
): IBadgeColorIcon {
    switch (accreditationState) {
        case AccreditationState.Accredited:
            return { color: 'success', leftIcon: 'check' };
        case AccreditationState.Refused:
            return { color: 'error', leftIcon: 'xmark' };
        case AccreditationState.WaitingAccreditation:
            return { color: 'warning', leftIcon: 'hourglass-empty' };
        case AccreditationState.NotApplicable:
            return { color: 'gray', leftIcon: 'infinity' };
        default:
            return assertUnreachable(accreditationState);
    }
}

export function shouldWeezeventCreate(vr: VolunteersRegistration): boolean {
    return (
        vr.state === VolunteerRegistrationState.Assigned ||
        vr.accreditationState === AccreditationState.Accredited ||
        vr.organizationId === 2472
    );
}
