import * as React from 'react';

interface IFieldSetProps {
    children: React.ReactNode;
    hideTitle?: boolean;
    title?: string;
}

export const FieldSet = ({ children, hideTitle, title }: IFieldSetProps) => {
    return (
        <fieldset style={{ border: 0, padding: 0, margin: 0 }}>
            <legend style={{ visibility: hideTitle ? 'hidden' : undefined }}>{title}</legend>

            {children}
        </fieldset>
    );
};
